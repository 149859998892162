import React, { useState, useEffect } from "react";
import { Button, makeStyles, withStyles, Typography, AppBar, Tabs, Tab, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BreadcrumbsHeader } from "../../components/header";
import IconLabel from "../../components/iconLabel";
import EditIcon from "@material-ui/icons/Edit";
import { calcHeight, calcWidth, getImage, parseDate, TableWithPagination } from "../../components/table";
import { PAGE_CONFIG } from "../../config/page_config";
import MerchantModal from "../modals/new_edit_merchant";
import Toggle from "../../components/toggle";
import EndPoints from "../../webservices/endpoints";
import { post } from "../../webservices/apiservice";
import { Content, Header, Notification, Whisper, Popover } from "rsuite";
import { Placeholder } from "rsuite";
import ErrorPage from "../../components/error_page";
import AddIcon from "@material-ui/icons/Add";
import { globalStyles } from "../../config/theme";
import MerchantCreativesModal from "../modals/new_edit_merchant_creatives";
import { TableLoader } from "../../components/loader";

import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import BlockIcon from "@material-ui/icons/Block";
import GradeIcon from "@material-ui/icons/Grade";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ConfirmationBox from "../../components/confirmation_box";
import { TriStateToggle } from "../../components/toggle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DealModal from "../modals/new_edit_deal";
import MerchantLocationModal from "../modals/new_edit_merchant_location";
import AssignDealLocation from "../modals/assign_deal_location";

const useStyles = makeStyles(theme => ({
  topBar: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    borderRadius: "3px",
    borderColor: "#3489EC",
    fontWeight: 500,
    fontFamily: "Roboto",
    fontSize: 14,
    marginRight: 5,
    color: "#3489EC",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  container: {
    margin: 10,
    borderRadius: "4px",
    backgroundColor: theme.palette.white,
    width: "inherit",
    // minWidth: 500,
    height: "100%",
    // minHeight: 500,
    // overflow: "scroll",
  },
  tableContainer: {
    borderRadius: "4px",
    backgroundColor: theme.palette.white,
    width: "100%",
    height: "100%",
    // overflowX: "scroll",
    boxShadow: "none",
  },
  containerStyle: {
    justifyContent: "center",
    paddingLeft: `2px !important`,
  },
  bodyWrapper: {
    display: "flex",
  },
  body: {
    borderColor: "white",
    width: "50%",
    height: "100%",
    paddingTop: 24,
  },
  dataWrapper: {
    display: "flex",
    // fontSize: "1.1rem",
  },
  label: {
    color: "#9397AA",
    width: "25%",
    marginLeft: 32,
    marginRight: 10,
    marginTop: 16,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
  },
  value: {
    marginTop: 16,
    color: "#2E384D",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    maxWidth: "70%",
    overflowWrap: "break-word",
  },
  featuredPhoto: {
    backgroundColor: theme.palette.primary.background,
    margin: `10px auto auto 0px`,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 360,
    minHeight: 233,
    fontFamily: "Roboto",
  },
  imagesContainer: {
    width: "99%",
    paddingRight: "20%",
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 15,
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: calcHeight(30, 10),
    marginBottom: 40,
  },
  image: {
    width: "22%",
    height: "25%",
    margin: 10,
    position: "relative",
  },
  imageLoader: {
    width: "100%",
    height: "100%",
    position: "relative",
    // backgroundColor: "#c4c4c4",
    borderRadius: 4,
    border: "1px solid red",
    minHeight: "100%",
  },

  featuredTag: {
    width: "max-content",
    height: "max-content",
    borderRadius: "4px",
    backgroundColor: "#F2C94C",
    color: "#000",
    position: "absolute",
    fontSize: 12,
    fontFamily: "Roboto",
    top: 5,
    left: 5,
    padding: "4px 8px",
  },
  merchantMoreIconDisabled: {
    color: theme.palette.primary.main,
    fontSize: 22,
  },
  merchantMoreIcon: {
    color: theme.palette.primary.main,
    fontSize: 22,
    "&:hover": {
      cursor: "pointer",
    },
  },
  actionButton: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#545964",
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 25,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
}));

const activate_merchant_url = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.ACTIVATE;
const getMerchantDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.POST_GET_MERCHANT_DETAILS;
const featureGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FEATURE;
const featureGroupedDealMany = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FEATURE_MANY;
const activateGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.ACTIVATE;
const activateGroupedDealMany = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.ACTIVATE_MANY;
const activateLocation = EndPoints.BOOST_DOMAIN + EndPoints.LOCATIONS.ACTIVATE;
const activateLocationMany = EndPoints.BOOST_DOMAIN + EndPoints.LOCATIONS.ACTIVATE_MANY;

export const TabMerchantDetails = props => {
  //constants
  const classes = useStyles();
  //useStates
  const [merchantDetails, setMerchantDetails] = useState(props.data.details);
  const [isUpdating, setUpdating] = useState(false);

  //functions
  const handleToggle = async (id, state) => {
    setUpdating(true);
    try {
      await props.handleToggle(id, state);
      setMerchantDetails(prev => ({ ...prev, merchant_active: state }));
    } catch (error) {
      setMerchantDetails(prev => ({ ...prev, merchant_active: !state }));
    }
    setUpdating(false);
  };

  const generateAddress = object => {
    let fields1 = ["corp_address", "corp_city", "corp_state"];
    let fields2 = ["corp_zipcode", "corp_country"];
    let address1 = [];
    let address2 = [];
    fields1.map(field => {
      if (object.hasOwnProperty(field) && object[field] !== null && object[field] !== "" && object[field] !== undefined && object[field] !== "None") {
        address1.push(object[field]);
      }
    });
    fields2.map(field => {
      if (object.hasOwnProperty(field) && (object[field] !== null || object[field] !== "" || object[field] !== undefined || object[field] !== "None")) {
        address2.push(object[field]);
      }
    });

    return address1.length > 0 && address2.length > 0 ? address1.join(", ").concat("- ", address2.join(", ")) : "-";
  };

  // useEffects
  useEffect(() => {}, []);
  useEffect(() => {}, [merchantDetails]);

  useEffect(() => {}, [isUpdating]);

  //renders

  return (
    <>
      <div className={classes.bodyWrapper}>
        <div className={classes.body}>
          {(merchantDetails !== null || merchantDetails !== undefined || Object.keys(merchantDetails).length > 0) &&
            Object.entries(PAGE_CONFIG.merchants.details_screen.details).map(([key, value], index) => {
              return (
                <div key={`merchant-detail-div-${key}-${index}`} className={classes.dataWrapper} id={`div-${key}`}>
                  <Typography className={classes.label}>{value}</Typography>
                  {PAGE_CONFIG.merchants.toggleKey.includes(key) ? (
                    isUpdating ? (
                      <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
                        <Placeholder.Graph style={{ width: 34, height: 16, borderRadius: 10, paddingTop: 10 }} active />
                      </div>
                    ) : (
                      <Toggle
                        id={merchantDetails[PAGE_CONFIG.merchants.id]}
                        initialState={merchantDetails[key]}
                        containerStyle={classes.value}
                        handler={handleToggle}
                      />
                    )
                  ) : key === "logourl" ? (
                    getImage(merchantDetails[key]) ? (
                      <img
                        src={getImage(merchantDetails[key])}
                        alt=""
                        style={{
                          width: "17%",
                          height: "17%",
                          marginTop: 5,
                          borderRadius: 2,
                          border: `1px solid lightgrey`,
                          padding: 3,
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          marginTop: 8,
                          padding: 8,
                          borderRadius: 4,
                          minWidth: 90,
                          minHeight: 40,
                          backgroundColor: "#EEF1F6",
                          fontSize: 12,
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Roboto",
                        }}>
                        No logo available
                      </div>
                    )
                  ) : PAGE_CONFIG.merchants.date.includes(key) ? (
                    <Typography className={classes.label}>{parseDate(merchantDetails[key])}</Typography>
                  ) : key === "address" ? (
                    <Typography className={classes.value}>{generateAddress(merchantDetails)}</Typography>
                  ) : (
                    <Typography className={classes.value}>
                      {!merchantDetails.hasOwnProperty(key) || merchantDetails[key] === "" || merchantDetails[key] === null ? "-" : merchantDetails[key]}
                    </Typography>
                  )}
                </div>
              );
            })}
        </div>
        <div>
          <Typography
            style={{
              marginTop: 32,
              width: "max-content",
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: 400,
              color: "#9397AA",
            }}>
            Photo
          </Typography>
          <div className={classes.featuredPhoto}>
            {merchantDetails.hasOwnProperty("merchantBGImage") && getImage(merchantDetails["merchantBGImage"]) ? (
              <img src={getImage(merchantDetails["merchantBGImage"])} style={{ width: 360, height: 233, borderRadius: 2 }} />
            ) : (
              "No image available"
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const TabMerchantDeals = props => {
  //constants
  const classes = useStyles();
  const history = useHistory();

  //useStates
  const [deals, setDeals] = useState(props.data.deals);
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });
  const [resetTristateToggle, setTristateToggleToDefault] = useState(false);
  const [dealModalView, setDealModalView] = useState(false);
  const [assignModalView, setAssignModalView] = useState(false);
  const [editData, setEditData] = useState({});
  const [assignData, setAssignData] = useState({});

  //functions
  const handleDealModalView = state => {
    typeof state === "boolean" && setDealModalView(state);
  };

  const handleAssignModalView = state => {
    typeof state === "boolean" && setAssignModalView(state);
  };

  const handleFeatureToggle = async (id, state) => {
    let payload = {
      offer: id,
      is_featured: state,
    };
    sessionStorage.setItem("refreshFeaturedDeals", true);
    try {
      await post(featureGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Featured Deal - ${id} successfully` : `Defeatured Deal - ${id} successfully`,
      });
      setDeals(prev => prev.map(deal => (deal.offer === id ? { ...deal, is_featured: state } : deal)));
    } catch (error) {
      console.log(error);
      Notification["error"]({
        title: "Error",
        description:
          error.hasOwnProperty("statusText") && error.statusText !== null
            ? error.statusText
            : state
            ? `Couldn't Feature Deal - ${id}`
            : `Couldn't Defeature Deal - ${id}`,
      });
      handleReload();
    }
  };

  const handleActivateToggle = async (id, state) => {
    let payload = {
      offer: id,
      deal_active: state,
    };
    try {
      await post(activateGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Deal - ${id} successfully` : `Deactivated Deal - ${id} successfully`,
      });
      setDeals(prev => prev.map(deal => (deal.offer === id ? { ...deal, deal_active: state } : deal)));
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Deal - ${id}` : `Couldn't Deactivate Deal - ${id}`,
      });
      handleReload();
    }
  };

  const handleView = (event, data) => {
    event.stopPropagation();
    history.push({
      pathname: `/deals/detail:?deal_id=${data.offer}`,
      state: { offer: data.offer },
    });
  };

  const onRowClick = id => {
    history.push({
      pathname: `/deals/detail:?deal_id=${id}`,
      state: { offer: id },
    });
  };

  const handleEdit = (event, data) => {
    event.stopPropagation();
    let payload = { merchant_id: props.data.merchant.id, dbasortname: props.data.merchant.name, logourl: props.data.merchant.logo, ...data };
    setEditData(payload);
    handleDealModalView(true);
  };

  const assignLocationsToDeal = (event, data) => {
    event.stopPropagation();
    let assignData = {
      deals: data,
      locations: props.data.locations,
      merchant: props.data.merchant,
      map: props.data.deal_location_map[data.offer] !== undefined ? props.data.deal_location_map[data.offer] : [],
    };
    setAssignData(assignData);
    handleAssignModalView(true);
  };

  const handleOnSuccessfulEdit = state => {
    if (typeof state === "boolean" && state === true) {
      handleReload();
    }
  };

  const handleReload = async () => {
    try {
      await props.onReload({ merchant_id: props.data.merchant.id });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: `Couldn't reload the page, try again.`,
      });
    }
  };

  const onHideConfirmationBox = () => {
    setTristateToggleToDefault(true);
    setTimeout(() => {
      setTristateToggleToDefault(false);
    }, 100);
    setConfirmationBoxModal(false);
  };

  const onBatchActivate = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Activate` : `Deactivate`,
      description: [`Do you want to ${state === true ? `Activate` : `Deactivate`} ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}? `],
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          deal_active: state,
          offerlist: [],
        };
        selectedItems.forEach(el => {
          payload.offerlist.push({ offer: el });
        });
        try {
          await post(activateGroupedDealMany, payload);
          Notification["success"]({
            title: "Success",
            description: state
              ? `Activated ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`
              : `Deactivated ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state ? `Couldn't Activate ${selectedItems.length}` : `Couldn't Deactivate ${selectedItems.length}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchFeature = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Feature` : `Defeature`,
      description: [`Do you want to ${state === true ? `Feature` : `Defeature`} ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}? `],
      helperText: "Note: Once defeatured, the deal will not be available in this table.",
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          is_featured: state,
          offerlist: [],
        };
        selectedItems.forEach(el => {
          payload.offerlist.push({ offer: el });
        });
        try {
          await post(featureGroupedDealMany, payload);
          Notification["success"]({
            title: "Success",
            description: state
              ? `Featured ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`
              : `Defeatured ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state
              ? `Couldn't Feature ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}`
              : `Couldn't Defeature ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchDelete = () => {};

  const onBatchExport = selectedItems => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      type: "export",
      data: deals,
      selected: selectedItems,
      pageConfig: PAGE_CONFIG.merchants.details_screen.deals,
      onCancel: onHideConfirmationBox,
      filename: `${props.data.merchant.name.toLocaleUpperCase()}-DEALS`,
    });
  };

  const onBatchAssign = selectedItems => {
    let assignData = {
      deals: deals.filter(deal => selectedItems.includes(deal.offer)),
      locations: props.data.locations,
      merchant: props.data.merchant,
      // map: props.data.deal_location_map[data.offer] !== undefined ? props.data.deal_location_map[data.offer] : [],
    };
    setAssignData(assignData);
    handleAssignModalView(true);
  };

  const handleOnlineDeals = data => {
    return data.redemption === "CLICK" ? true : false;
  };

  const handleActiveOption = data => {
    return data.deal_active === true ? `Deactivate` : `Activate`;
  };

  const handeToggleOption = (event, data) => {
    event.stopPropagation();
    handleActivateToggle(data.offer, !data.deal_active);
  };

  const checkboxOptions = [
    {
      key: "Toggle right to Activate and left to Deactivate",
      icon: TriStateToggle,
      handler: onBatchActivate,
      isHidden: false,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },
    {
      key: "Toggle right to Feature and left to Defeature",
      icon: GradeIcon,
      icon: TriStateToggle,
      handler: onBatchFeature,
      isHidden: true,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },
    {
      key: "Delete",
      icon: DeleteIcon,
      handler: onBatchDelete,
      isHidden: true,
    },
    {
      key: "Export",
      icon: VerticalAlignBottomIcon,
      handler: onBatchExport,
      isHidden: false,
    },
    {
      key: "Assign",
      icon: LocationOnIcon,
      handler: onBatchAssign,
      isHidden: false,
    },
  ];

  //useEffects
  useEffect(() => {}, []);
  useEffect(() => {}, [deals]);
  useEffect(() => {}, [resetTristateToggle]);

  //render
  return (
    <>
      {deals.length < 1 ? (
        <ErrorPage data={"empty-data"} title="Deals" />
      ) : (
        <TableWithPagination
          data={deals}
          totalItems={props.hasOwnProperty("data") && props.data.hasOwnProperty("deals") && Array.isArray(props.data.deals) ? props.data.deals.length : 0}
          pageConfig={PAGE_CONFIG.merchants.details_screen.deals}
          handleReload={handleReload}
          checkbox={checkboxOptions}
          handleRowClick={onRowClick}
          handleToggle={{ is_featured: handleFeatureToggle, deal_active: handleActivateToggle }}
          handleEdit={handleEdit}
          // disableRowOptions
          rowActions={[
            { title: "View", handler: handleView },
            { title: "Edit", handler: handleEdit, isDisabled: null },
            { title: "Assign to Location", handler: assignLocationsToDeal, isDisabled: handleOnlineDeals },
            { title: handleActiveOption, handler: handeToggleOption },
          ]}
          containerStyles={classes.tableContainer}
          tableHeight={"83%"}
        />
      )}
      <DealModal dealData={editData} show={dealModalView} onCreate={handleOnSuccessfulEdit} onHide={() => handleDealModalView(false)} />
      <AssignDealLocation
        show={assignModalView}
        type={"l2d"}
        data={assignData}
        onSuccessfulUpdate={handleOnSuccessfulEdit}
        onHide={() => {
          handleAssignModalView(false);
        }}
      />
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </>
  );
};

export const TabMerchantLocations = props => {
  //constants
  const classes = useStyles();

  //useStates
  const [locations, setLocations] = useState(props.data.locations);
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });
  const [resetTristateToggle, setTristateToggleToDefault] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [presentLocation, setPresentLocation] = useState({});
  const [assignModalView, setAssignModalView] = useState(false);
  const [assignData, setAssignData] = useState({});
  const [isReadOnly, setReadOnly] = useState(false);

  //functions
  const handleReload = async () => {
    try {
      await props.onReload({ merchant_id: props.data.merchant.id });
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = async (id, state) => {
    let payload = {
      merchant_id: props.data.merchant.id,
      redemptionlocation: id,
      merchant_location_active: state,
    };
    try {
      await post(activateLocation, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Location - ${id} successfully` : `Deactivated Location - ${id} successfully`,
      });
      setLocations(prev => prev.map(location => (location.redemptionlocation === id ? { ...location, merchant_location_active: state } : location)));
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Location - ${id}` : `Couldn't Deactivate Location - ${id}`,
      });
      handleReload();
    }
  };

  const handleView = (event, obj) => {
    setPresentLocation(obj);
    setReadOnly(true);
    handleLocationModalView(true);
  };

  const onRowClick = id => {
    let res = locations.filter(el => el.redemptionlocation === id);
    setPresentLocation(res[0]);
    setReadOnly(true);
    handleLocationModalView(true);
  };

  const handleEdit = (event, obj) => {
    event.preventDefault();
    event.stopPropagation();
    setReadOnly(false);
    setPresentLocation(obj);
    handleLocationModalView(true);
  };

  const handleLocationModalView = state => {
    typeof state === "boolean" && setLocationModal(state);
  };

  const handleAssignModalView = state => {
    typeof state === "boolean" && setAssignModalView(state);
  };

  const handleOnSuccessfulEdit = state => {
    if (typeof state === "boolean" && state === true) {
      handleReload();
    }
  };

  const assignDealsToLocation = (event, data) => {
    event.stopPropagation();
    let assignData = {
      deals: props.data.deals,
      locations: data,
      merchant: props.data.merchant,
      map: props.data.location_deal_map[data.redemptionlocation] !== undefined ? props.data.location_deal_map[data.redemptionlocation] : [],
    };
    setAssignData(assignData);
    handleAssignModalView(true);
  };

  const onHideConfirmationBox = () => {
    setTristateToggleToDefault(true);
    setTimeout(() => {
      setTristateToggleToDefault(false);
    }, 100);
    setConfirmationBoxModal(false);
  };

  const onBatchActivate = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Activate` : `Deactivate`,
      description: [`Do you want to ${state === true ? `Activate` : `Deactivate`} ${selectedItems.length} location${selectedItems.length > 1 ? `s` : ""}? `],
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          merchant_location_active: state,
          redemptionlocationlist: [],
        };
        selectedItems.forEach(el => {
          payload.redemptionlocationlist.push({ merchant_id: props.data.merchant.id, redemptionlocation: el });
        });
        try {
          await post(activateLocationMany, payload);
          Notification["success"]({
            title: "Success",
            description: state
              ? `Activated ${selectedItems.length} location${selectedItems.length > 1 ? `s` : ""} successfully`
              : `Deactivated ${selectedItems.length} location${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state
              ? `Couldn't Activate ${selectedItems.length} location${selectedItems.length > 1 ? `s` : ""}`
              : `Couldn't Deactivate ${selectedItems.length} location${selectedItems.length > 1 ? `s` : ""}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchDelete = () => {};

  const onBatchExport = selectedItems => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      type: "export",
      data: locations,
      selected: selectedItems,
      pageConfig: PAGE_CONFIG.merchants.details_screen.locations,
      onCancel: onHideConfirmationBox,
      filename: `${props.data.merchant.name.toLocaleUpperCase()}-LOCATIONS`,
    });
  };

  const onBatchAssign = selectedItems => {
    let assignData = {
      deals: props.data.deals,
      locations: locations.filter(location => selectedItems.includes(location.redemptionlocation)),
      merchant: props.data.merchant,
      // map: props.data.deal_location_map[data.offer] !== undefined ? props.data.deal_location_map[data.offer] : [],
    };
    setAssignData(assignData);
    handleAssignModalView(true);
  };

  const handleActiveOption = data => {
    return data.merchant_location_active === true ? `Deactivate` : `Activate`;
  };

  const handeToggleOption = (event, data) => {
    event.stopPropagation();
    handleToggle(data.redemptionlocation, !data.merchant_location_active);
  };

  const checkboxOptions = [
    {
      key: "Toggle right to Activate and left to Deactivate",
      icon: TriStateToggle,
      handler: onBatchActivate,
      isHidden: false,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },
    {
      key: "Delete",
      icon: DeleteIcon,
      handler: onBatchDelete,
      isHidden: true,
    },
    {
      key: "Export",
      icon: VerticalAlignBottomIcon,
      handler: onBatchExport,
      isHidden: false,
    },
    {
      key: "Assign",
      icon: LocationOnIcon,
      handler: onBatchAssign,
      isHidden: false,
    },
  ];

  //useEffects
  useEffect(() => {}, []);
  useEffect(() => {}, [locations]);
  useEffect(() => {}, [resetTristateToggle]);
  useEffect(() => {}, [isReadOnly]);

  //render
  return (
    <>
      {locations.length < 1 ||
      (locations.length === 1 &&
        (locations[0].redemptionlocation === null ||
          locations[0].redemptionlocation === "None" ||
          locations[0].redemptionlocation === "" ||
          locations[0].redemptionlocation === undefined)) ? (
        <ErrorPage data={"empty-data"} title="Locations" />
      ) : (
        <TableWithPagination
          data={locations}
          totalItems={locations.length}
          pageConfig={PAGE_CONFIG.merchants.details_screen.locations}
          handleReload={handleReload}
          handleRowClick={onRowClick}
          checkbox={checkboxOptions}
          handleToggle={handleToggle}
          rowActions={[
            { title: "View", handler: handleView },
            { title: "Edit", handler: handleEdit, isDisabled: null },
            { title: "Assign Deals", handler: assignDealsToLocation },
            { title: handleActiveOption, handler: handeToggleOption },
          ]}
          handleEdit={handleEdit}
          containerStyles={classes.tableContainer}
          tableHeight={"83%"}
        />
      )}
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
      <AssignDealLocation
        show={assignModalView}
        type={"d2l"}
        data={assignData}
        onSuccessfulUpdate={handleOnSuccessfulEdit}
        onHide={() => {
          handleAssignModalView(false);
        }}
      />
      <MerchantLocationModal
        show={locationModal}
        editData={presentLocation}
        readOnly={isReadOnly}
        onHide={() => handleLocationModalView(false)}
        onCreate={handleReload}
      />
    </>
  );
};

export const TabMerchantCreatives = props => {
  //constants
  const commonClasses = useStyles();
  const globalClasses = globalStyles();

  //useStates
  const [images, setImages] = useState(props.data.creatives);
  const [modalView, setModalView] = useState(false);
  const [isLoading, setLoading] = useState(true);

  //functions
  const handleModalView = state => {
    typeof state === "boolean" && setModalView(state);
  };

  const onReload = async () => {
    await props.onReload({ merchant_id: props.data.merchant_id });
  };

  //useEffects
  useEffect(() => {
    if (images.length < 1) {
      setLoading(false);
    }
  }, [images]);
  useEffect(() => {}, [modalView]);
  useEffect(() => {}, [isLoading]);

  //render
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", padding: `10px 24px` }}>
        <Typography className={commonClasses.label} style={{ marginLeft: 0 }}>
          Merchant Photos
        </Typography>
        <Button onClick={() => handleModalView(true)} className={globalClasses.buttonSecondary} startIcon={<AddIcon />}>
          {props.data.creatives.length > 0 ? `Edit Photos` : `Add Photos`}
        </Button>
      </div>
      <div className={commonClasses.imagesContainer} style={isLoading ? { display: "flex" } : { display: "none" }}>
        {isLoading &&
          [...Array(images.length)].map(el => {
            return (
              <div className={commonClasses.image}>
                <Placeholder.Graph width={"100%"} height={100} active style={{ borderRadius: 4 }} />
              </div>
            );
          })}
      </div>
      {images.length < 1 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: calcHeight(38, 10), width: "100%" }}>
          <ErrorPage data={"empty-data"} title={"Creatives"} disableHeight />
        </div>
      ) : (
        <div className={commonClasses.imagesContainer} style={isLoading ? { display: "none" } : { display: "flex" }}>
          {images.map((image, index) => {
            return (
              <div className={commonClasses.image}>
                {!isLoading && image.is_image_featured && <Typography className={commonClasses.featuredTag}>Featured</Typography>}
                <img
                  src={getImage(image.image_path)}
                  alt=""
                  style={{ width: "100%", height: "90%", borderRadius: 2 }}
                  onLoad={() => {
                    if (index === images.length - 1) {
                      setLoading(false);
                    }
                  }}
                />
                <div style={{ display: "flex", padding: `2px 4px`, width: "100%", flexWrap: "wrap" }}>
                  <Typography style={{ fontSize: 12, color: "#9397AA", marginRight: 10 }}>{!isLoading && image.image_file_name}</Typography>
                  <Typography style={{ fontSize: 12, color: "#9397AA", marginRight: 10 }}>{!isLoading && image.image_file_size}</Typography>
                  <Typography style={{ fontSize: 12, color: "#9397AA" }}>{!isLoading && image.image_resolution}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <MerchantCreativesModal show={modalView} onHide={() => handleModalView(false)} data={props.data} handleReload={onReload} />
    </div>
  );
};

const TabPanel = props => {
  return (
    <div
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`full-width-tabpanel-${props.index}`}
      aria-labelledby={`full-width-tab-${props.index}`}
      style={{ height: "100%" }}>
      {props.value === props.index ? props.children : null}
    </div>
  );
};

const otherProps = index => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const MerchantDetails = props => {
  //Constants
  const classes = useStyles();
  const globalClasses = globalStyles();
  const history = useHistory();

  //States
  const [merchant, setMerchant] = useState({
    details: {},
  });
  const [modal, setModal] = useState(false);
  const [dealModal, setDealModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const merchantActionsRef = React.createRef();

  //Functions
  const handleChangeTabIndex = (event, value) => {
    setTabIndex(value);
    if (value > 0) {
      handleReload(true);
    }
  };

  const handleModalView = state => {
    typeof state === "boolean" && setModal(state);
  };

  const handleDealModalView = state => {
    typeof state === "boolean" && setDealModal(state);
  };

  const handleLocationModalView = state => {
    typeof state === "boolean" && setLocationModal(state);
  };

  const handleReload = async payload => {
    if (typeof payload === "boolean") {
      await fetchMerchant({ merchant_id: merchant.details.merchant_id });
    } else {
      await fetchMerchant(payload);
    }
  };

  const handleToggle = async (id, state) => {
    let payload = {
      merchant_id: id,
      merchant_active: state,
    };
    try {
      await post(activate_merchant_url, payload);
      if (sessionStorage.getItem("updated_merchant") === null) {
        sessionStorage.setItem("updated_merchant", JSON.stringify(payload));
      } else {
        let existingUpdate = JSON.parse(sessionStorage.getItem("updated_merchant"));
        existingUpdate = { ...existingUpdate, ["merchant_active"]: state };
        sessionStorage.setItem("updated_merchant", JSON.stringify(existingUpdate));
      }
      setMerchant(prev => ({ ...prev, details: { ...prev.details, merchant_active: state } }));

      Notification["success"]({
        title: `Success`,
        description: state ? `${merchant.details.dbasortname} successfully activated..` : `${merchant.details.dbasortname} successfully deactivated..`,
      });
      return false;
    } catch (err) {
      Notification["error"]({
        title: `Error`,
        description: state ? `Couldn't activate ${merchant.details.dbasortname} ` : `Couldn't deactivate ${merchant.details.dbasortname}`,
      });
      setMerchant(prev => ({ ...prev, details: { ...prev.details, merchant_active: !state } }));
      return false;
    }
  };

  const handleUpdateData = updatedData => {
    setMerchant(prev => ({ ...prev, ...updatedData }));
    sessionStorage.setItem("updated_merchant", JSON.stringify({ ...merchant, ...updatedData }));
  };

  const fetchMerchant = async payload => {
    setError(false);
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await post(getMerchantDetailsUrl, payload);
        setMerchant({
          details: { ...response.merchantDetails[0], merchantBGImage: response.merchantBGImage },
          deals: response.offerGrouped,
          locations: response.merchantLocations,
          creatives: response.creativeList,
          deal_details: response.offerDetails,
        });
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };

  const onMerchantActionView = () => {
    setTabIndex(0);
    merchantActionsRef.current.hide();
  };
  const onMerchantActionEdit = () => {
    handleModalView(true);
    merchantActionsRef.current.hide();
  };
  const onMerchantActionAddNewDeal = () => {
    handleDealModalView(true);
    merchantActionsRef.current.hide();
  };
  const onMerchantActionAddNewLocation = () => {
    handleLocationModalView(true);
    merchantActionsRef.current.hide();
  };

  const onMerchantActionActivateDeactivate = async () => {
    merchantActionsRef.current.hide();
    setLoading(true);
    await handleToggle(merchant.details.merchant_id, !merchant.details.merchant_active);
    setLoading(false);
  };

  const getLocationsCount = locations => {
    if (locations !== undefined) {
      if (
        locations.length < 1 ||
        (locations.length === 1 &&
          (locations[0].redemptionlocation === null ||
            locations[0].redemptionlocation === "None" ||
            locations[0].redemptionlocation === "" ||
            locations[0].redemptionlocation === undefined))
      ) {
        return 0;
      } else {
        return locations.length;
      }
    } else {
      return 0;
    }
  };

  const groupByKey = (list, key) => {
    let res = {};

    list.forEach(el =>
      res[el[key]]
        ? res[el[key]].push({ offer: el.offer, offer_detail_id: el.offer_detail_id, redemptionlocation: el.redemptionlocation })
        : ((res[el[key]] = []), res[el[key]].push({ offer: el.offer, offer_detail_id: el.offer_detail_id, redemptionlocation: el.redemptionlocation }))
    );
    return res;
  };

  const merchantActions = [
    { title: "View", handler: onMerchantActionView, isDisabled: tabIndex === 0 },
    // { title: "Edit", handler: onMerchantActionEdit, isDisabled: merchant.details.deal_provider_id === 10 || merchant.details.deal_provider === 10 },
    {
      title: "Add New Deal",
      handler: onMerchantActionAddNewDeal,
      isDisabled: merchant.details.deal_provider_id === 10 || merchant.details.deal_provider === 10,
    },
    {
      title: "Add Location",
      handler: onMerchantActionAddNewLocation,
      isDisabled: merchant.details.deal_provider_id === 10 || merchant.details.deal_provider === 10,
    },
    { title: merchant.details.merchant_active === true ? `Deactivate` : `Activate`, handler: onMerchantActionActivateDeactivate },
  ];

  //Loader for JSX
  const LoaderJsx = index => {
    switch (index) {
      case 0:
        return (
          <>
            <div>
              <div className={classes.bodyWrapper}>
                <div className={classes.body}>
                  {Object.entries(PAGE_CONFIG.merchants.details_screen.details).map(([key, value], index) => {
                    return (
                      <div key={`merchant-detail-div-${key}-${index}`} className={classes.dataWrapper} id={`div-${key}`}>
                        <Typography className={classes.label}>{value}</Typography>
                        {PAGE_CONFIG.merchants.toggleKey.includes(key) ? (
                          <Placeholder.Graph height={20} width={45} style={{ borderRadius: 10, marginTop: 16 }} active />
                        ) : key === "logourl" ? (
                          <Placeholder.Graph height={40} width={90} style={{ borderRadius: 10, marginTop: 16 }} active />
                        ) : key === "address" ? (
                          <Placeholder.Graph height={60} width={250} style={{ borderRadius: 10, marginTop: 16 }} active />
                        ) : (
                          <Placeholder.Graph height={20} width={250} style={{ borderRadius: 10, marginTop: 16 }} active />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    style={{
                      marginTop: 32,
                      width: "max-content",
                      fontFamily: "Roboto",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#9397AA",
                    }}>
                    Photo
                  </Typography>
                  <Placeholder.Graph width={360} height={233} className={classes.featuredPhoto} active />
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <TableLoader
            header={PAGE_CONFIG.merchants.details_screen.deals.header_mapper}
            hasEditOption
            hasMoreOption
            checkbox
            containerStyles={classes.tableContainer}
            tableHeight={calcHeight(30.8, 10)}
          />
        );
      case 2:
        return (
          <TableLoader
            header={PAGE_CONFIG.merchants.details_screen.locations.header_mapper}
            hasEditOption
            checkbox
            containerStyles={classes.tableContainer}
            tableHeight={calcHeight(30.8, 10)}
          />
        );
      case 3:
        return (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", padding: `10px 24px` }}>
              <Typography className={classes.label} style={{ marginLeft: 0 }}>
                Merchant Photos
              </Typography>
              <Button disabled className={globalClasses.buttonSecondary} startIcon={<AddIcon />}>
                Edit Photos
              </Button>
            </div>
            <div className={classes.imagesContainer}>
              {[...Array(10)].map(el => {
                return (
                  <div className={classes.image}>
                    <Placeholder.Graph width={"100%"} height={100} active style={{ borderRadius: 4 }} />
                  </div>
                );
              })}
            </div>
          </>
        );
    }
  };

  //Custom Styles

  const CustomTabContainer = withStyles(theme => ({
    root: {
      borderRadius: 1,
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #E6E7ED",
      color: "#000000",
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  }))(Tabs);

  const CustomTab = withStyles(theme => ({
    root: {
      textTransform: "none",
      minWidth: "max-content",
      maxHeight: 30,
      fontSize: 14,
      padding: `1px 16px 1px 16px`,
      fontWeight: 500,
      marginRight: theme.spacing(2),
      fontFamily: "Roboto",
      "&:hover": {
        color: "#3489EC",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
        fontWeight: 500,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  //Effects
  useEffect(() => {
    fetchMerchant(history.location.state);
  }, []);
  useEffect(() => {}, [merchant]);
  useEffect(() => {}, [isLoading]);

  //Render
  return (
    <div className={globalClasses.mainContainer}>
      <Header className={globalClasses.headerContainer}>
        <BreadcrumbsHeader
          options={[
            { order: 0, title: "Deals", to: "/deals" },
            { order: 1, title: "Merchants", to: "/merchants" },
            { order: 2, title: "Merchant Detail" },
          ]}
        />
      </Header>
      <Content className={globalClasses.contentContainer}>
        {isLoading ? (
          <div className={classes.topBar}>
            <Placeholder.Graph height={30} width={140} style={{ borderRadius: 10 }} active />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button variant="outlined" startIcon={<EditIcon className={classes.icon} />} className={classes.button} disabled={true}>
                Edit
              </Button>
              <MoreVertIcon className={classes.merchantMoreIconDisabled} disabled={true} />
            </div>
          </div>
        ) : (
          <div className={classes.topBar}>
            <IconLabel label={merchant.details.dbasortname} logo={getImage(merchant.details.logourl)} containerStyle={classes.containerStyle} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                startIcon={<EditIcon className={classes.icon} />}
                className={classes.button}
                disabled={merchant.details.deal_provider_id === 10 || merchant.details.deal_provider === 10}
                onClick={() => handleModalView(true)}>
                Edit
              </Button>
              <Whisper
                trigger="click"
                placement={"bottomEnd"}
                full
                triggerRef={merchantActionsRef}
                speaker={
                  <Popover style={{ padding: 0 }} visible={false}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {merchantActions.map((action, index) => {
                        return (
                          <Button
                            disabled={action.hasOwnProperty("isDisabled") && action.isDisabled}
                            key={`rowAction-${index}`}
                            className={classes.actionButton}
                            onClick={action.handler}>
                            {action.title}
                          </Button>
                        );
                      })}
                    </div>
                  </Popover>
                }>
                <MoreVertIcon className={classes.merchantMoreIcon} />
              </Whisper>
            </div>
          </div>
        )}
        <div className={classes.container} style={{ overflow: "hidden" }}>
          <div>
            <AppBar position="static" color="default" style={{ borderRadius: 1 }}>
              <CustomTabContainer value={tabIndex} onChange={handleChangeTabIndex}>
                <CustomTab label="Details" {...otherProps(0)} />
                <CustomTab label="Deals" {...otherProps(1)} />
                <CustomTab label="Locations" {...otherProps(2)} />
                <CustomTab label="Creatives" {...otherProps(3)} />
              </CustomTabContainer>
            </AppBar>
          </div>
          {isLoading ? (
            LoaderJsx(tabIndex)
          ) : isError ? (
            <ErrorPage data={"no-vpn"} title="Merchant" />
          ) : Object.keys(merchant).length < 1 ? (
            <ErrorPage data={"no-data"} title="Merchant" />
          ) : (
            <>
              <div style={{ height: "100%" }}>
                <TabPanel value={tabIndex} index={0}>
                  <TabMerchantDetails
                    onReload={handleReload}
                    data={{ details: merchant.details, merchant_id: merchant.details.merchant_id }}
                    handleToggle={handleToggle}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <TabMerchantDeals
                    onReload={handleReload}
                    data={{
                      deals: merchant.deals,
                      locations: merchant.locations,
                      merchant: {
                        id: merchant.details.merchant_id,
                        name: merchant.details.dbasortname,
                        logo: merchant.details.logourl,
                        deal_provider: merchant.details.deal_provider,
                      },
                      deal_location_map: groupByKey(merchant.deal_details, "offer"),
                    }}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={2} style={{ height: "inherit", overflow: "hidden" }}>
                  <TabMerchantLocations
                    onReload={handleReload}
                    data={{
                      locations: merchant.locations,
                      deals: merchant.deals,
                      merchant: { id: merchant.details.merchant_id, name: merchant.details.dbasortname, deal_provider: merchant.details.deal_provider },
                      location_deal_map: groupByKey(merchant.deal_details, "redemptionlocation"),
                    }}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                  <TabMerchantCreatives onReload={handleReload} data={{ creatives: merchant.creatives, merchant_id: merchant.details.merchant_id }} />
                </TabPanel>
              </div>
            </>
          )}
        </div>
      </Content>
      <MerchantModal show={modal} onEdit={handleUpdateData} onHide={() => handleModalView(false)} merchantData={merchant.details} />
      <DealModal
        show={dealModal}
        onHide={() => handleDealModalView(false)}
        dealData={{}}
        hasMerchantDetails={{ merchant_id: merchant.details.merchant_id, dbasortname: merchant.details.dbasortname, logourl: merchant.details.logourl }}
        onCreate={handleReload}
      />
      <MerchantLocationModal
        hasMerchantDetails={{
          merchant_id: merchant.details.merchant_id,
          dbasortname: merchant.details.dbasortname,
          logourl: merchant.details.logourl,
          stubcopy: merchant.details.stubcopy,
          category: merchant.details.category,
          subcategory: merchant.details.subcategory,
          cuisinetype: merchant.details.cuisinetype,
          detailcategory: merchant.details.detailcategory,
          locationCount: getLocationsCount(merchant.locations),
        }}
        show={locationModal}
        onHide={() => handleLocationModalView(false)}
        onCreate={handleReload}
      />
    </div>
  );
};

export default MerchantDetails;
