import React, { useState, useEffect } from "react";
import { filterModalStyles, searchFilterContainerStyles } from "../../config/theme";
import { InputGroup, Icon, Input, FlexboxGrid, Modal, Button, Radio, RadioGroup, DateRangePicker } from "rsuite";
import { Button as MaterialButton, Typography } from "@material-ui/core";
import { parseDate, parseDateRange } from "../../components/table";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";

export const CoinsSearch = props => {
  const classes = searchFilterContainerStyles();
  const [modal, setModal] = useState(false);
  const [searchVal, setVal] = useState("");
  const [filterObject, setFilterObject] = useState(props.filterObject);
  const [searchIn, setSearchIn] = useState(["Email", "Phone", "Account Number", "Keyword"]);
  const [selectedSearchIn, setSelectedSearchIn] = useState("Email");
  const [filtersToView, setFiltersToView] = useState([]);
  const [isSearchApplied, setSearchApplied] = useState(false);

  const handleModalView = state => {
    typeof state === "boolean" && setModal(state);
  };

  const handleSetSearchInput = event => {
    setVal(event);
    setSearchApplied(false);
  };

  const handleClearSearch = event => {
    if (event.target.accessKey === "clear") {
      setVal("");
      isSearchApplied && props.onClearSearchInput();
    }
  };

  const getFiltersFromModal = (filtersToAppend, filtersToView) => {
    setFiltersToView(filtersToView);
    if (isSearchApplied) {
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend }));
      props.onSearch({ ...filterObject, filters: filtersToAppend });
    } else {
      setVal("");
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend, search: "", phone: "", email: "", accountNumber: "" }));
      props.onSearch({ ...filterObject, filters: filtersToAppend, search: "", phone: "", email: "", accountNumber: "" });
    }
  };

  const handleRemoveAllFilters = () => {
    setFiltersToView([]);
    setFilterObject(prev => ({ ...prev, ["filters"]: [] }));
    props.onSearch({
      ...filterObject,
      filters: [],
    });
  };

  const handleRemoveOneFilter = (id, value) => {
    setFiltersToView(filtersToView.filter(filter => filter.id !== id));
    setFilterObject(prev => ({ ...prev, ["filters"]: prev.filters.filter(filter => filter.field !== id) }));
    props.onSearch({
      ...filterObject,
      filters: filterObject.filters.filter(filter => filter.field !== id),
    });
  };

  const handleSearch = () => {
    let inputHTMLElement = document.querySelector("#points-search-input");
    let emailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    let phoneReg = /^[0-9]{10}$|^[0-9]{12}$/;
    if (selectedSearchIn === "Email" && emailReg.test(searchVal)) {
      setSearchApplied(true);
      props.onSearchClick(true);
      props.onSearch();
    } else if (selectedSearchIn === "Phone" && phoneReg.test(searchVal)) {
      setSearchApplied(true);
      props.onSearchClick(true);
      props.onSearch();
    }else if(selectedSearchIn === "Account Number"){
      setSearchApplied(true);
      props.onSearchClick(true);
      props.onSearch();
    } else if (selectedSearchIn === "Keyword") {
      setSearchApplied(true);
      props.onSearchClick(false);
      props.onSearch();
    } else {
      inputHTMLElement.style.borderColor = "red";
    }
  };

  //useEffects

  useEffect(() => {
    let inputHTMLElement = document.querySelector("#points-search-input");
    if (selectedSearchIn === "Email") {
      setFilterObject(prev => ({ ...prev, ["email"]: searchVal, ["phone"]: "", ["search"]: "", ["accountNumber"]: "" }));
    } else if (selectedSearchIn === "Phone") {
      setFilterObject(prev => ({ ...prev, ["phone"]: searchVal, ["email"]: "", ["search"]: "", ["accountNumber"]: "" }));
    } else if (selectedSearchIn === "Account Number") {
      setFilterObject(prev => ({ ...prev, ["accountNumber"]: searchVal, ["email"]: "", ["search"]: "", ["phone"]: "" }));
    } else {
      setFilterObject(prev => ({ ...prev, ["search"]: searchVal, ["email"]: "", ["phone"]: "", ["accountNumber"]: "" }));
    }
    inputHTMLElement.style.borderColor = "#e5e5ea";
  }, [searchVal, selectedSearchIn]);

  useEffect(() => {
    props.passFilter(filterObject);
  }, [filterObject]);

  useEffect(() => {}, [filtersToView]);

  useEffect(() => {}, [isSearchApplied]);

  useEffect(() => {
    setVal("");
    props.onClearSearchInput();
  }, [props.clearSearchInput]);

  //Render

  return (
    <>
      <div>
        <div className={classes.container}>
          <InputGroup inside>
            <Input
              value={selectedSearchIn === "Phone" ? searchVal.replace(/\D/g, "").substr(0, 12) : searchVal}
              onChange={handleSetSearchInput}
              className={classes.search}
              placeholder={`Search by ${selectedSearchIn}`}
              id="points-search-input"
              onPressEnter={handleSearch}
            />
            <InputGroup.Addon>
              <Icon
                accessKey={searchVal.length > 0 ? "clear" : undefined}
                icon={searchVal.length === 0 ? "search" : "close"}
                onClick={handleClearSearch}
                className={classes.searchIcon}
              />
            </InputGroup.Addon>
          </InputGroup>
          <MaterialButton onClick={handleSearch} className={classes.filterButton}>
            Search
          </MaterialButton>
          <MaterialButton
            startIcon={<FilterListIcon className={classes.blueIcon} />}
            endIcon={<ExpandMoreIcon className={classes.blueIcon} />}
            onClick={() => handleModalView(true)}
            className={classes.filterButton}>
            Filter
          </MaterialButton>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>Search by:</Typography>
          <RadioGroup inline name="searchInOptions" value={selectedSearchIn} onChange={e => setSelectedSearchIn(e)}>
            {searchIn.map((option, i) => {
              return (
                <Radio key={`coins-radio-searchIn-${i}`} value={option}>
                  <Typography>{option}</Typography>
                </Radio>
              );
            })}
          </RadioGroup>
        </div>
        <div className={classes.filterContainer}>
          {filtersToView.length > 0 &&
            filtersToView.map((filter, index) => {
              return (
                <>
                  {typeof filter.value === "string" ? (
                    <MaterialButton
                      key={`coins-filters-to-view-${index}`}
                      className={classes.filterListButton}
                      endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                      {`${filter.key} - ${filter.value}`}
                    </MaterialButton>
                  ) : Array.isArray(filter.value) && filter.value.length > 0 ? (
                    <MaterialButton
                      className={classes.filterListButton}
                      endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                      {`${filter.key} : ${filter.value.map(el => parseDate(el, "short")).join(" - ")}`}
                    </MaterialButton>
                  ) : null}
                </>
              );
            })}
          {filtersToView.length > 0 && (
            <span className={classes.clearFilters} onClick={handleRemoveAllFilters}>
              Clear Filters
            </span>
          )}
        </div>
      </div>
      <CoinsFilterModal
        show={modal}
        onHide={() => handleModalView(false)}
        getFilter={getFiltersFromModal}
        modalUpdateOnFilterChange={filtersToView.map(filter => filter.id)}
      />
    </>
  );
};

export const CoinsFilterModal = props => {
  //Constants & States

  const classes = filterModalStyles();
  const [showModal, setShowModal] = useState(props.show);
  const [filters, setFilters] = useState([
    {
      header: "Delivery Type",
      options: ["All", "Rewarded Video", "Apps & Games", "Offerwall"],
      active: "All",
      key: "deliverytype",
      type: "button",
    },
    // {
    //   header: "Action",
    //   options: ["All", "Watch", "Install", " Redeem Points"],
    //   active: "All",
    //   key: "deliverytype",
    //   type: "button",
    // },
    {
      header: "Status",
      options: ["All", "Completed", "Rejected", "In Progress", "Earned"],
      active: "All",
      key: "eventtype",
      type: "button",
    },
    {
      header: "Provider",
      options: ["All", "IronSrc", "BOOST"],
      active: "All",
      key: "provider",
      type: "button",
    },
    {
      header: "Created",
      active: [],
      key: "inserttime",
      type: "date-range",
    },
  ]);

  //Functions

  const onHide = () => {
    setShowModal(false);
    props.onHide();
  };

  const setISODate = (inputDate, isEnd = false) => {
    let date = new Date(inputDate);
    let yr = date.getFullYear();
    let mn = date.getMonth() + 1;
    mn = mn >= 10 ? mn : "0" + mn;
    let dt = date.getDate();
    dt = dt >= 10 ? dt : "0" + dt;
    let time = isEnd ? "23:59:59.000Z" : "00:00:00.000Z";
    return `${yr}-${mn}-${dt}T${time}`;
  };

  const onApply = () => {
    let filtersToAppend = [];
    let filtersToView = [];
    filters.map(filter => {
      let objectToAppend = {};
      let objectToView = {};
      if (filter.active !== "All") {
        if (filter.key === "inserttime") {
          if (filter.active.length > 0) {
            objectToAppend["field"] = filter.key;
            objectToAppend["start"] = setISODate(filter.active[0]);
            objectToAppend["end"] = setISODate(filter.active[1], true);
            objectToAppend["condition"] = "between";
            if (filter.active[0] !== null) {
              objectToView["id"] = filter.key;
              objectToView["key"] = filter.header;
              objectToView["value"] = filter.active;
            }
            filtersToAppend.push(objectToAppend);
            filtersToView.push(objectToView);
          }
        } else {
          objectToAppend["field"] = filter.key;
          objectToAppend["value"] = filter.active;
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      }
    });
    props.getFilter(filtersToAppend, filtersToView);
    onHide();
  };

  const onCancel = () => {
    onHide();
  };

  const handleSelectFilter = (key, value) => {
    setFilters(filters.map(filter => (filter.key === key ? { ...filter, active: value } : filter)));
  };

  //useEffects

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, [filters]);

  useEffect(() => {
    if (props.modalUpdateOnFilterChange.length > 0) {
      setFilters(
        filters.map(filter =>
          filter.type === "date-range"
            ? props.modalUpdateOnFilterChange.includes(filter.key)
              ? filter
              : { ...filter, active: [] }
            : props.modalUpdateOnFilterChange.includes(filter.key)
            ? filter
            : { ...filter, active: "All" }
        )
      );
    } else {
      setFilters(filters.map(filter => (filter.type === "date-range" ? { ...filter, active: [] } : { ...filter, active: "All" })));
    }
  }, [props.modalUpdateOnFilterChange]);

  //Render

  return (
    <div>
      <Modal show={showModal} onHide={() => onHide()} size="md" style={{ minHeight: "60vh" }}>
        <Modal.Header>
          <Modal.Title className={classes.title}>Filter Coins</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal} style={{ flexWrap: "nowrap", flexDirection: "column" }}>
          {filters.map((filter, index) => {
            return (
              <div key={`coins-filters-${filter.header}-${index}`} className={classes.container}>
                <div>
                  <span className={classes.subtitle}>{filter.header}</span>
                  <FlexboxGrid>
                    {filter.type === "button" ? (
                      filter.options.map((option, i) => {
                        return (
                          <Button
                            className={`${classes.optionButton} ${option === filter.active && classes.active}`}
                            active={option === filter.active ? true : false}
                            value={option}
                            onClick={() => handleSelectFilter(filter.key, option)}
                            key={`buttons-${i}`}>
                            {option}
                          </Button>
                        );
                      })
                    ) : filter.type === "date-range" ? (
                      <DateRangePicker
                        value={filter.active}
                        onChange={e => handleSelectFilter(filter.key, e)}
                        cleanable={true}
                        placeholder="Created in between"
                        ranges={[]}
                        placement="rightEnd"
                        key={`date-range-${index}`}
                      />
                    ) : null}
                  </FlexboxGrid>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <FlexboxGrid justify="end">
            <Button className={classes.cancelBtn} onClick={onCancel}>
              Cancel
            </Button>
            <Button className={classes.saveBtn} onClick={onApply}>
              Apply
            </Button>
          </FlexboxGrid>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
