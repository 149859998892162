import React, { useState, useEffect } from "react";
import { Typography, makeStyles, IconButton, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { Button, Modal, Notification, IconButton as RIconButton } from "rsuite";
import Gallery from "react-photo-gallery";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { globalStyles } from "../../config/theme";
import PhotoIcon from "../../icons/photo.png";
import CreativesCancelIcon from "../../icons/creatives_cancel.png";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { upload, post } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { getImage } from "../../components/table";

const useStyles = makeStyles(theme => ({
  card: {
    width: 360,
    // minWidth: 300,
    height: 233,
    backgroundColor: "#fff",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 10,
    // position: "absolute",
  },
  cardSmall: {
    width: 175,
    height: 112,
    backgroundColor: "#fff",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // position: "absolute",
  },
  uploadCard: {
    width: 215,
    height: 115,
    backgroundColor: "#E6E7ED",
    borderRadius: 2,
    margin: `5px 10px`,
  },
  cardWithHover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  image: {
    width: 215,
    height: 115,
    zIndex: 10000,
    // width: "100%",
    // height: "100%",
    // border: "2px solid lightgrey",
    pointerEvents: "none",
    // borderRadius: 2,
  },
  imageOnPreview: {
    outline: "2px solid #3489EC",
  },
  featuredTag: {
    width: "max-content",
    height: "max-content",
    borderRadius: "4px",
    backgroundColor: "#F2C94C",
    color: "#000",
    position: "absolute",
    fontSize: 12,
    fontFamily: "Roboto",
    top: 5,
    left: 5,
    padding: "2px 5px",
  },
  cancelBtn: {
    position: "absolute",
    right: 0,
    top: 3,
    color: "#fff",
    fontSize: 20,
    transition: "0.1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
    },
  },
  label: {
    color: "#9397AA",
    width: "25%",
    marginLeft: 32,
    marginTop: 16,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    color: "#2E384D",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#2E384D",
    marginBottom: 10,
  },
  sliderContainer: {
    width: 360,
    height: 233,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  sliderBtnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: 10,
    top: "50%",
    transform: "translate(0px, -10px)",
    position: "absolute",
  },
  sliderBtn: {
    display: "flex",
    fontSize: 18,
    backgroundColor: "#fff",
    borderRadius: "50%",
    transition: "0.1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
    },
  },
}));

const creatviesPayloadMap = [
  "image_path",
  "creative_order",
  "is_image_featured",
  "creative_merchant_id",
  "image_file_size",
  "image_file_name",
  "image_resolution",
];

const addCreativesUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.ADD_CREATIVES;

const MerchantCreativesModal = props => {
  //constants
  const classes = useStyles();
  const globalClasses = globalStyles();

  //useStates
  const [images, setImages] = useState(props.hasOwnProperty("data") && props.data.creatives.length > 0 ? props.data.creatives : []);
  const [modal, setModalView] = useState(props.show);
  const [isLoading, setLoading] = useState(false);
  const [invalidImages, setInvalidImages] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: null, description: null, handler: null });
  const [hasChanges, setHasChanges] = useState(false);

  //functions
  const handleModalView = state => {
    typeof state === "boolean" && setModalView(state);
  };

  const handleConfirmDialogView = state => {
    typeof state === "boolean" && setConfirmDialog(state);
  };

  const onHide = () => {
    handleModalView(false);
    props.onHide();
    setInvalidImages([]);
  };

  const onCancel = e => {
    if (hasChanges) {
      setDialog({
        title: "Cancel",
        description: null,
        helperText: `All unsaved changes will be lost. Are you sure you want to cancel?`,
        handler: () => {
          setImages(props.hasOwnProperty("data") && props.data.creatives.length > 0 ? props.data.creatives : []);
          onHide();
          setConfirmDialog(false);
          setHasChanges(false);
        },
      });
      setConfirmDialog(true);
    } else {
      setImages(props.hasOwnProperty("data") && props.data.creatives.length > 0 ? props.data.creatives : []);
      onHide();
    }
  };

  const uploadMany = async images => {
    for (let i = 0; i < images.length; i++) {
      if (images[i].image_path === null) {
        try {
          let response = await upload(`${EndPoints.S3_UPLOAD_PATH.merchant.creatives}${props.data.merchant_id}/`, images[i].image_file_name, images[i].data);
          images[i].image_path = response.key;
        } catch (err) {
          images[i].image_path = null;
        }
      }
    }
    return images.filter(image => image.image_path !== null);
  };

  const formatPayload = images => {
    let payload = { merchant_id: props.data.merchant_id, creativeList: [] };
    images.map(image => {
      let obj = {};
      Object.keys(image).map(key => {
        if (creatviesPayloadMap.includes(key)) {
          obj[key] = image[key];
        }
      });
      if (obj.image_path !== null) {
        payload.creativeList.push(obj);
      }
    });
    return payload;
  };

  const onSave = async e => {
    e.target.blur();
    try {
      setLoading(true);
      let onUploadedData = await uploadMany(images);
      setImages(onUploadedData);
      await post(addCreativesUrl, formatPayload(onUploadedData));
      Notification["success"]({
        title: `Success`,
        description: props.hasOwnProperty("data") && props.data.creatives.length > 0 ? `Updated Creatives successfully` : `Added Creatives Successfully`,
      });
      setLoading(false);
      props.handleReload();
      onHide();
    } catch (error) {
      Notification["error"]({
        title: "Failure",
        description:
          props.hasOwnProperty("data") && props.data.creatives.length > 0 ? `Couldn't update Creatives. Try again.` : `Couldn't add Creatives. Try again.`,
      });
      setLoading(false);
      props.handleReload();
      onHide();
    }
  };

  const formatSize = bytes => {
    let units = ["B", "KB", "MB", "GB", "TB", "PB"];
    let i = 0;
    for (i; bytes > 1024; i++) {
      bytes /= 1024;
    }
    return bytes.toFixed(1) + units[i];
  };

  const handleInput = inputs => {
    let currentLength = images.length;
    let invalidFiles = [];
    if (inputs.length > 0) {
      setHasChanges(true);
    }
    if (currentLength + inputs.length > 10) {
      setInvalidImages([`You have selected more than 10 images`]);
      return;
    }
    inputs.map((image, index) => {
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        let url = URL.createObjectURL(image);
        let img = new Image();
        img.onload = e => {
          let dimensions = {
            image_resolution: `${e.target.naturalWidth}x${e.target.naturalHeight}`,
          };
          URL.revokeObjectURL(img.src);
          let imageRes = dimensions.image_resolution.split("x");
          let imageWidth = parseInt(imageRes[0]);
          let imageHeight = parseInt(imageRes[1]);
          let aspectRatio = imageWidth / imageHeight;
          if (image.size <= 2097152 && imageWidth >= 720 && imageHeight >= 460 && aspectRatio >= 1.3 && aspectRatio <= 1.8) {
            setImages(prev => [
              ...prev,
              {
                image_path: null,
                creative_order: prev.length,
                is_image_featured: prev.length === 0 ? true : false,
                creative_merchant_id: props.data.merchant_id,
                image_file_size: formatSize(image.size),
                image_file_name: image.name,
                type: image.type,
                data: image,
                preview: reader.result,
                isPreviewed: prev.length === 0 ? true : false,
                ...dimensions,
              },
            ]);
          } else {
            let errors = [];
            if (image.size > 2097152) {
              errors.push(formatSize(image.size));
            }
            if (aspectRatio < 1.3 || aspectRatio > 1.8) {
              errors.push(dimensions.image_resolution);
            }
            invalidFiles.push(`${image.name} | ${errors.join(" | ")}`);
          }
        };
        img.src = url;
      };
    });
    setInvalidImages(invalidFiles);
  };

  const handlePreview = direction => {
    if (direction === "prev") {
      let prevIndex = 0;
      let current = images.findIndex(img => img.isPreviewed);
      if (current === 0) {
        prevIndex = images.length - 1;
      } else {
        prevIndex = current - 1;
      }
      setImages(prev => prev.map((img, index) => (index === prevIndex ? { ...img, isPreviewed: true } : { ...img, isPreviewed: false })));
    } else if (direction === "next") {
      let nextIndex = 0;
      let current = images.findIndex(img => img.isPreviewed);
      if (current === images.length - 1) {
        nextIndex = 0;
      } else {
        nextIndex = current + 1;
      }
      setImages(prev => prev.map((img, index) => (index === nextIndex ? { ...img, isPreviewed: true } : { ...img, isPreviewed: false })));
    }
  };

  const onRemoveOneImage = image => {
    if (image.isPreviewed || image.is_image_featured) {
      let temp = images.filter(el => el.creative_order !== image.creative_order);
      if (temp.length > 0) {
        temp[0].isPreviewed = true;
        temp[0].is_image_featured = true;
        setHasChanges(true);
      } else {
        if (props.hasOwnProperty("data") && props.data.creatives.length > 0) {
          setHasChanges(true);
        } else {
          setHasChanges(false);
        }
      }
      setImages(temp);
    }
    setImages(prev => prev.filter(el => el.creative_order !== image.creative_order));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let newArray = arrayMove(images, oldIndex, newIndex);
      setHasChanges(true);
      setImages(
        newArray.map((image, index) =>
          index === 0 ? { ...image, is_image_featured: true, creative_order: index } : { ...image, is_image_featured: false, creative_order: index }
        )
      );
    }
  };

  const imgWithClick = { cursor: "pointer", zIndex: 10000 };

  //components
  const UploadCard = props => {
    //constants
    const classes = useStyles();

    //functions
    const handleSelect = e => {
      props.onInput(Array.from(e.target.files));
    };

    //render
    return (
      <Button component="label" className={classes.uploadCard}>
        <label className={classes.cardWithHover}>
          <img src={PhotoIcon} alt="" style={{ width: 30, height: 30 }} />
          <Typography className={classes.subtitle} style={{ fontWeight: 700, marginTop: 5, marginBottom: 0 }}>
            Add Photo
          </Typography>
          <Typography className={classes.label} style={{ width: "85%", whiteSpace: "break-spaces", margin: 0, fontSize: 10 }}>
            Maximum file size is 2MB and minimum dimensions 720x460
          </Typography>
          <input type="file" accept="image/*" onChange={handleSelect} hidden multiple />
        </label>
      </Button>
    );
  };

  const Photo = ({ index, onClick, photo, margin, direction, top, left, onRemoveOneImage }) => {
    //constants
    const classes = useStyles();
    const imgStyle = { margin: margin };
    if (direction === "column") {
      imgStyle.position = "absolute";
      imgStyle.left = left;
      imgStyle.top = top;
      imgStyle.zIndex = 1000000;
    }

    const handleClick = event => {
      onClick(event, { photo, index });
    };

    useEffect(() => {}, []);
    useEffect(() => {}, [images]);

    return (
      <div key={photo.creative_order} style={{ zIndex: 10000, width: 215, margin: `5px 10px` }}>
        <div style={{ width: "100%" }}>
          <div style={{ float: "top", zIndex: 10, position: "relative", width: "100%" }}>
            {photo.is_image_featured && <Typography className={classes.featuredTag}>Featured</Typography>}
            <IconButton
              className={classes.cancelBtn}
              style={{ zIndex: 10003, background: `url(${CreativesCancelIcon})`, backgroundSize: "cover", padding: 10, margin: 3 }}
              onClick={e => {
                setDialog({
                  title: "Remove Image",
                  description: [
                    `Image Details`,
                    `Name : ${photo.image_file_name}`,
                    `Size : ${photo.image_file_size}`,
                    `Dimensions : ${photo.image_resolution}`,
                  ],
                  helperText: `Are you sure you want to remove?`,
                  handler: () => {
                    onRemoveOneImage(photo);
                    setConfirmDialog(false);
                  },
                });
                setConfirmDialog(true);
              }}
            />
            <img
              className={`${photo.isPreviewed && classes.imageOnPreview} ${classes.image}`}
              style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
              src={photo.hasOwnProperty("preview") ? photo.preview : photo.image_path ? getImage(photo.image_path) : null}
              key={photo.creative_order}
              onClick={onClick ? handleClick : null}
              alt="img"
            />
            <div style={{ display: "flex", padding: `2px 2px`, width: "100%" }}>
              <Typography
                style={{
                  fontSize: 12,
                  color: "#9397AA",
                }}>{`${photo.image_file_size}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${photo.image_resolution}`}</Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SortablePhoto = SortableElement(item => <Photo {...item} onRemoveOneImage={onRemoveOneImage} />);

  const SortableGallery = SortableContainer(({ items }) => <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />);

  //useEffects
  useEffect(() => {
    images.map(image => {
      if (!image.hasOwnProperty("isPreviewed")) {
        image["isPreviewed"] = image.creative_order === 0 ? true : false;
      }
    });
  }, [images]);

  useEffect(() => {
    if (props.show !== modal) {
      setModalView(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, []);
  useEffect(() => {}, [confirmDialog]);
  useEffect(() => {}, [hasChanges]);
  useEffect(() => {}, [isLoading]);
  useEffect(() => {
    setTimeout(() => {
      if (invalidImages.length > 0) {
        Notification["warning"]({
          title: "Couldn't add the following Files",
          duration: 10000,
          description: (
            <div>
              {invalidImages.map((el, i) => (
                <Typography>{`${i + 1}. ${el}`}</Typography>
              ))}
              <Typography style={{ marginTop: 10, fontStyle: "italic", fontSize: 12, display: "inline-flex", alignItems: "base-line" }}>
                <Typography style={{ fontWeight: 500, fontSize: 12 }}>Note</Typography>: Maximum file size is 2MB and width-to-height ratio should be in the
                range 1.3 to 1.8
              </Typography>
              <div style={{ marginTop: 15, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className={globalClasses.buttonPrimary}
                  onClick={() => {
                    Notification.close();
                  }}>
                  Okay
                </Button>
              </div>
            </div>
          ),
        });
      }
    }, 100);
  }, [invalidImages]);

  //render
  return (
    <>
      <Modal show={modal} onShow={() => handleModalView(true)} onHide={!isLoading ? onCancel : null} size="lg">
        <Modal.Header>
          <Modal.Title>
            <Typography className={classes.title}>Add Photos</Typography>
          </Modal.Title>
          <Modal.Body>
            <div style={{ display: "flex", padding: 10, minWidth: 500, minHeight: "60vh" }}>
              <div style={{ width: "45%", padding: 5, minWidth: 365 }}>
                <Typography className={classes.subtitle}>Preview</Typography>
                {images.length === 0 ? (
                  <div
                    className={classes.sliderContainer}
                    style={{ backgroundColor: "#F2F5FA", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 4 }}>
                    <Typography className={classes.subtitle} style={{ fontWeight: 700, marginTop: 5, marginBottom: 5 }}>
                      Your Photo Preview
                    </Typography>
                    <Typography className={classes.label} style={{ width: "50%", whiteSpace: "break-spaces", margin: 0, fontSize: 12, textAlign: "center" }}>
                      As you add your photos you can preview them here
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.sliderContainer}>
                    {images.length > 1 && (
                      <div className={classes.sliderBtnContainer}>
                        <div className={classes.sliderBtn} onClick={() => handlePreview("prev")}>
                          <ChevronLeftIcon style={{ pointerEvents: "none" }} />
                        </div>
                        <div className={classes.sliderBtn} onClick={() => handlePreview("next")}>
                          <ChevronRightIcon style={{ pointerEvents: "none" }} />
                        </div>
                      </div>
                    )}
                    {images.length > 0 &&
                      images.map(
                        image =>
                          image.hasOwnProperty("isPreviewed") &&
                          image.isPreviewed === true && (
                            <div>
                              <img src={image.image_path ? getImage(image.image_path) : image.preview} alt="" style={{ width: 360, height: 233 }} />
                              <div style={{ display: "flex", padding: `2px 2px`, width: "max-content" }}>
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    color: "#9397AA",
                                  }}>{`${image.image_file_size}\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${image.image_resolution}`}</Typography>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                )}
              </div>
              <div style={{ width: "60%", padding: 5 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography className={classes.subtitle}>Upload Photos</Typography>
                  <Typography style={{ fontFamily: "Roboto", fontSize: 12, color: "#545964", fontWeight: 400 }}>
                    {`Photos ${images.length}/10 - You can add upto 10 photos`}
                  </Typography>
                </div>
                <div style={{ display: "inline-flex", flexWrap: "wrap", position: "absolute" }}>
                  <SortableGallery items={images} onSortEnd={onSortEnd} axis={"xy"} />
                  <UploadCard onInput={handleInput} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button className={globalClasses.buttonSecondary} disabled={isLoading} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className={globalClasses.buttonPrimary}
                onClick={e => {
                  if (hasChanges) {
                    setDialog({
                      title: "Submit",
                      description: null,
                      helperText: `Are you sure you want to submit?`,
                      handler: () => {
                        onSave(e);
                        setConfirmDialog(false);
                        setHasChanges(false);
                      },
                    });
                    e.target.blur();
                    setConfirmDialog(true);
                  } else {
                    onHide();
                  }
                }}
                loading={isLoading}>
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Header>
      </Modal>
      <Dialog open={confirmDialog} onClose={() => handleConfirmDialogView(false)}>
        <DialogContent>
          <DialogTitle style={{ padding: 0 }}>{`Confirm ${dialog.title}`}</DialogTitle>
          <div style={{ padding: `5px 30px`, width: "20vw" }}>
            {dialog.description !== null &&
              dialog.description.map((desc, i) => {
                return <Typography>{desc}</Typography>;
              })}
            <Typography style={{ marginTop: 5, marginBottom: 5, whiteSpace: "break-spaces" }}>{dialog.helperText}</Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: `0px 10px 10px 10px` }}>
          <Button
            className={globalClasses.buttonSecondary}
            onClick={() => {
              setDialog({ title: null, description: null, handler: null });
              handleConfirmDialogView(false);
            }}>
            Cancel
          </Button>
          <Button className={globalClasses.buttonPrimary} onClick={dialog.handler}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MerchantCreativesModal;
