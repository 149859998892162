import React from "react";
import ErrorPage from "../components/error_page";
import { BreadcrumbsHeader } from "../components/header";

function Themes(props) {
  return (
    <div>
      <BreadcrumbsHeader title={"Themes"} />
      <ErrorPage data={"work-in-progress"} />
    </div>
  );
}

export default Themes;
