import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "max-content !important",
    height: "fit-content",
    borderRadius: "2px",
    margin: `5px auto 5px 0px`,
    alignItems: "center",
    textDecoration: "none",
    "&:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  containerActive: {
    backgroundColor: theme.palette.primary.background,
    width: "90%",
  },
  logoStyle: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  labelStyle: {
    fontFamily: "Roboto",
    fontSize: 16,
    // fontWeight: 400,
    color: "#2E384D",
  },
  subNavContainer: {
    display: "flex",
    flexDirection: "column",
    width: "83%",
    cursor: "pointer",
    textDecoration: "none",
    margin: "5px 35px",
    padding: 5,
    borderRadius: "2px",
    "&:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: theme.palette.primary.background,
    },
  },
  subNavContainerActive: {
    backgroundColor: theme.palette.primary.background,
  },
  subNavLabelStyle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    color: theme.typography.color,
    fontSize: "0.9rem",
  },
  withThumbnailPreview: {
    width: 24,
    height: "auto",
    "&:hover": {
      width: 120,
      height: "auto",
      zIndex: 999,
      display: "block",
      position: "relative",
      top: "-25px",
      left: "-35px",
    },
  },
  withoutThumbnailPreview: {
    width: 24,
    height: "auto",
  },
}));

const IconLabel = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div
        className={`${classes.container} ${props.containerStyle} ${!props.hasSubNavs && props.isActive && classes.containerActive}`}
        component={props.path ? Link : Typography}
        to={!props.hasSubNavs && props.path}>
        {props.logo && (
          <div className={`${classes.logoStyle} ${props.logoStyle}`}>
            <img src={props.logo} alt="" className={props.hasThumbnailPreview === true ? classes.withThumbnailPreview : classes.withoutThumbnailPreview} />
          </div>
        )}
        <div style={{ display: "flex" }} className={`${classes.labelStyle} ${props.labelStyle}`}>
          <Typography>{props.label}</Typography>
          {props.hasOwnProperty("boldLabel") && (
            <Typography noWrap={true} style={{ fontWeight: 600, marginLeft: 5, maxWidth: "50%" }}>{`/ ${props.boldLabel}`}</Typography>
          )}
        </div>
      </div>
      {props.hasSubNavs &&
        props.subNavs.map((nav, index) => {
          return (
            <div
              className={`${classes.subNavContainer} ${props.subNavContainer} ${props.presentPath === nav.path && classes.subNavContainerActive}`}
              component={Link}
              to={nav.path}
              key={`subNav-${index}`}>
              <Typography className={`${classes.subNavLabelStyle} ${props.subNavLabelStyle}`}>{nav.label}</Typography>
            </div>
          );
        })}
    </React.Fragment>
  );
};

export default IconLabel;
