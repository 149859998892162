import { createMuiTheme, makeStyles } from "@material-ui/core";
import { calcHeight, calcWidth } from "../components/table";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff8e8c",
      main: "#3c8dde",
      background: "#EEF1F6",
      dark: "#c62035",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4da9b7",
      main: "#017a87",
      dark: "#004e5a",
      contrastText: "#000",
    },
    dullGrey: "#ededed",
    white: "#ffffff",
    fontLabelColor: "#7F7F7F",
  },
  typography: {
    fontFamily: "Roboto",
    textTransform: "none",
    color: "#414243",
    fontSize: "16px",
    h6: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 20,
      color: "#2E384D",
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      color: "#2E384D",
    },
    button: {
      textTransform: "none",
    },
  },
  props: {
    MuiInput: { inputProps: { spellCheck: "true" } },
  },
});
export default theme;

export const globalStyles = makeStyles(theme => ({
  buttonPrimary: {
    width: "max-content",
    height: "fit-content",
    fontFamily: "Roboto",
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.background,
      opacity: 0.9,
    },
  },
  buttonSecondary: {
    width: "max-content",
    height: "fit-content",
    fontFamily: "Roboto",
    paddingLeft: 25,
    paddingRight: 25,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    "&:hover, focus": {
      backgroundColor: "white",
    },
  },
  input: {
    width: "99%",
    margin: 5,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.background,
    boxShadow: "0 0 0 1px transparent",
    fontWeight: 600,
    "&:hover, &:focus": {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "0 0 0 1px white",
    },
  },
  label: {
    color: theme.palette.fontLabelColor,
    fontWeight: 500,
    margin: 5,
  },
  divM5: {
    margin: 5,
  },
  divP5: {
    padding: 5,
  },
  divFlexSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainContainer: {
    display: " flex",
    flexDirection: "column",
    flex: 1,
    height: "inherit",
  },
  headerContainer: {
    flex: "0 0 50px",
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
  },
  icon: {
    fontSize: 20,
    padding: "0px 30px 0px auto",
    transition: "0.1s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
}));

export const searchFilterContainerStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "35vw",
  },
  filterContainer: {
    display: "flex",
    marginTop: 10,
    width: "100%",
    flexWrap: "wrap",
  },
  search: {
    fontFamily: "Roboto",
    height: "fit-content",
    borderRadius: 2,
    border: `1px solid ${theme.palette.white}`,
    "&:hover, &:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.white,
    },
  },
  filterListButton: {
    minWidth: "max-content",
    height: "fit-content",
    margin: 3,
    marginRight: 10,
    borderRadius: 3,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.white}`,
    "&:hover, &:focus": {
      cursor: "initial",
      border: `1px solid transparent`,
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main,
    },
  },

  filterButton: {
    minWidth: "max-content",
    height: "fit-content",
    marginLeft: 10,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 2,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.white}`,
    "&:hover, &:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main,
    },
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  searchIcon: {
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  clearBtn: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  clearFilters: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    cursor: "pointer",
    marginLeft: 5,
  },
}));

export const filterModalStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  title: {
    marginLeft: "10px !important",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "20px !important",
    color: "#2E384D !important",
  },
  subtitle: {
    marginLeft: 5,
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 14,
    color: "#2E384D",
  },
  container: {
    margin: `15px 15px 15px 5px`,
  },
  saveBtn: {
    fontFamily: "Roboto",
    width: 100,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: `2px !important`,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 500,
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.background,
      opacity: 0.9,
    },
  },
  active: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.white} !important`,
  },
  cancelBtn: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 14,
    width: 100,
    margin: 10,
    borderRadius: `2px !important`,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: "#e9eff6",
      color: theme.palette.primary.main,
    },
  },
  optionButton: {
    width: "max-content",
    margin: 5,
    borderRadius: `2px !important`,
    fontWeight: 500,
    fontFamily: "Roboto",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: "#e9eff6",
      color: theme.palette.primary.main,
    },
  },
  optionButtonWoBorderRadius: {
    width: "max-content",
    minHeight: 38,
    minWidth: 34,
    padding: `4px 10px`,
    margin: 5,
    fontWeight: 500,
    fontFamily: "Roboto",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: "#e9eff6",
      color: theme.palette.primary.main,
    },
  },
  errorButtonGroup: {
    boxShadow: "0px 0px 3px red",
  },
  dropdownButton: {
    margin: 5,
  },
  menuClass: {
    minWidth: 200,
    borderRadius: 2,
    maxHeight: 100,
    overflow: "auto",
    "& input::placeholder": {
      color: "#9397AA !important",
    },
  },
  placeholderText: {
    borderRadius: 8,
    marginLeft: 5,
  },
  placeholderButton: {
    borderRadius: 2,
    margin: 5,
  },
  input: {
    width: 50,
    border: `1px solid #E6E7ED`,
    color: "#2E384D",
    padding: "8px 12px",
    borderRadius: 3,
    fontWeight: 900,
    fontSize: 14,
    fontFamily: "Roboto",
    transition: `0.2s ease-in-out`,
    "&:hover, &:focus": {
      border: `1px solid ${theme.palette.primary.main}`,
      outline: "none",
      boxShadow: "0 0 0 1px white",
    },
  },
  clearFilters: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Roboto",
    height: "max-content",
    "&:hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    marginLeft: 5,
    fontFamily: "Roboto",
    fontSize: 10,
    color: "#ff0000",
  },
}));

export const detailScreenStyles = makeStyles(theme => ({
  subHeader: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  detailContainer: {
    margin: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.white,
    width: calcWidth(15, 10),
    minWidth: 500,
    height: calcHeight(18, 10),
    overflow: "auto",
    minHeight: 500,
  },
}));
