import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { BreadcrumbsHeader } from "../components/header";
import { TableLoader as Loader } from "../components/loader";
import { TableWithPagination, setPOSTPayload } from "../components/table";
import UtilityBar from "../components/utility_bar";
import { PAGE_CONFIG } from "../config/page_config";
import AddIcon from "@material-ui/icons/Add";
import MerchantModal from "./modals/new_edit_merchant";
import { batchDelete, deleteRecord, post, put } from "../webservices/apiservice";
import EndPoints from "../webservices/endpoints";
import ErrorPage from "../components/error_page";
import { Content, Header, Notification } from "rsuite";
import { MerchantSearch } from "./modals/filter_merchants";
import { SearchContainer } from "../components/search_filter";
import { globalStyles } from "../config/theme";

import { TriStateToggle } from "../components/toggle";
import GradeIcon from "@material-ui/icons/Grade";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ConfirmationBox from "../components/confirmation_box";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";

const getUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.POST_GET;
const putUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.PUT;
const deleteUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.DELETE;
const activateUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.ACTIVATE;
const activateManyUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.ACTIVATE_MANY;

const Merchants = () => {
  //constants
  const history = useHistory();
  const classes = globalStyles();
  const location = useLocation();

  //useStates
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [payloadFilter, setPayloadFilter] = useState({
    startRecIndex: 0,
    limitPerPage: 500,
    filters: [],
    search: "",
  });
  const [totalItems, setTotalItems] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [merchantData, setMerchantData] = useState(undefined);
  const [currentObjAfterLoad, setCurrentObjAfterLoad] = useState({ page: 0, rowsPerPage: null });

  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });
  const [resetTristateToggle, setTristateToggleToDefault] = useState(false);

  //functions
  const handleModalView = state => {
    typeof state === "boolean" && setModal(state);
  };

  const onHideConfirmationBox = () => {
    setTristateToggleToDefault(true);
    setTimeout(() => {
      setTristateToggleToDefault(false);
    }, 100);
    setConfirmationBoxModal(false);
  };

  const handleRowClick = (id, secondId = null) => {
    history.push({
      pathname: `/merchants/detail:?merchant_id=${id}`,
      state: { merchant_id: id },
    });
  };

  const handleToggle = async (id, state) => {
    let payload = {
      merchant_id: id,
      merchant_active: state,
    };
    try {
      await post(activateUrl, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Merchant ${id} successfully activated` : `Merchant ${id} successfully deactivated`,
      });
    } catch (err) {
      Notification["error"]({
        title: "Error",
        description: "Couldn't activate/deactivate the Merchant. Reloading again",
      });
      handleReload();
    }
  };

  const handleEdit = (event, data) => {
    event.stopPropagation();
    setMerchantData(undefined);
    setTimeout(() => {
      setMerchantData(data);
    }, 10);
    handleModalView(true);
  };

  const handleDelete = async (id, row, data, handler) => {
    let payload = { merchant_id: row.merchant_id, redemptionlocation: row.redemptionlocation };
    try {
      await batchDelete(deleteUrl, payload);
      data.splice(id, 1);
      setData(
        data.filter(rec => {
          for (let key in payload) {
            if (rec[key] === undefined || rec[key] !== payload[key]) {
              return true;
            }
          }
          return false;
        })
      );
      handler(data);
      Notification["success"]({
        title: "Success",
        description: `Deleted ${row.dbasortname}`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: `Unable to delete ${row.dbasortname}`,
      });
    }
  };

  const handleReload = async () => {
    try {
      await fetchData();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLoadMore = async currentObj => {
    setIndex(index + 1);
    setLoading(true);
    // currentObj.page -= 1;
    try {
      let response = await post(getUrl, { ...payloadFilter, startRecIndex: payloadFilter.startRecIndex + 1 });
      setData([...data, ...modifyResponse(response.items)]);
      setCurrentObjAfterLoad(currentObj);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const fetchData = async (payload = null) => {
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await post(getUrl, payload ? payload : payloadFilter);
        setData(modifyResponse(response.items));
        setTotalItems(response.totalItems);
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };

  const modifyResponse = responseArray => {
    return responseArray.map(merchant =>
      merchant.hasOwnProperty("locationcount") && merchant.locationcount !== null && merchant.location !== "None" && merchant.location !== ""
        ? { ...merchant, locationcount: parseInt(merchant.locationcount) }
        : merchant
    );
  };

  const getResultOnParams = (payload = null) => {
    payload !== null && setPayloadFilter(payload);
    fetchData(payload);
    setCurrentObjAfterLoad(prev => ({ ...prev, page: 0 }));
  };

  const handleOnSuccessfulCreate = state => {
    if (typeof state === "boolean" && state === true) {
      handleReload();
    }
  };

  const onBatchActivate = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Activate` : `Deactivate`,
      description: [`Do you want to ${state === true ? `Activate` : `Deactivate`} ${selectedItems.length} merchant${selectedItems.length > 1 ? `s` : ""}? `],
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          merchant_active: state,
          merchant_idlist: [],
        };
        selectedItems.forEach(el => {
          payload.merchant_idlist.push({ merchant_id: el });
        });
        try {
          await post(activateManyUrl, payload);
          Notification["success"]({
            title: "Success",
            description: state ? `Activated ${selectedItems.length} successfully` : `Deactivated ${selectedItems.length} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state ? `Couldn't Activate ${selectedItems.length}` : `Couldn't Deactivate ${selectedItems.length}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchExport = selectedItems => {
    console.log("export btn clicked");
  };

  const onBatchDelete = selectedItems => {
    console.log("Batch delete clicked", selectedItems);
  };

  const onBatchAssign = selectedItems => {
    console.log("Batch assign clicked", selectedItems);
  };

  const checkboxOptions = [
    {
      key: "Toggle right to Activate and left to Deactivate",
      icon: TriStateToggle,
      handler: onBatchActivate,
      isHidden: false,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },

    {
      key: "Delete",
      icon: DeleteIcon,
      handler: onBatchDelete,
      isHidden: true,
    },
    {
      key: "Export",
      icon: VerticalAlignBottomIcon,
      handler: onBatchExport,
      isHidden: true,
    },
    {
      key: "Assign",
      icon: LocationOnIcon,
      handler: onBatchAssign,
      isHidden: true,
    },
  ];

  //useEffects
  useEffect(async () => {
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        const response = await post(getUrl, setPOSTPayload(index));
        response.items.map((item, index) => {
          item.merchant_added_date = item.merchant_added_date.substring(0, 10);
          item["redemptions"] = item.redeemcount === null ? "-" : parseInt(item.redeemcount) < 10 ? "0" + item.redeemcount : item.redeemcount;
          return item;
        });
        setData(modifyResponse(response.items));
        setTotalItems(response.totalItems);
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/merchants",
      });
    }
  }, []);

  useEffect(() => {}, [data]);
  useEffect(() => {}, [resetTristateToggle]);
  useEffect(() => {}, [totalItems]);
  useEffect(() => {}, [merchantData]);
  useEffect(() => {
    if (location.pathname === "/merchants") {
      if (sessionStorage.getItem("updated_merchant") !== null) {
        setLoading(true);
        let update = JSON.parse(sessionStorage.getItem("updated_merchant"));
        setData(prev => prev.map(item => (item.merchant_id === update.merchant_id ? { ...item, ...update } : item)));
        sessionStorage.removeItem("updated_merchant");
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    }
  }, [location]);

  //Render
  return (
    <div className={classes.mainContainer}>
      <Header className={classes.headerContainer}>
        <BreadcrumbsHeader options={[{ order: 0, title: "Merchants" }]} />
      </Header>
      <Content className={classes.contentContainer}>
        <UtilityBar
          searchBar={<MerchantSearch payloadFilter={payloadFilter} onSearch={getResultOnParams} />}
          button={
            <Button
              className={classes.buttonPrimary}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setMerchantData(undefined);
                handleModalView(true);
              }}>
              New Merchant
            </Button>
          }
        />
        {isLoading ? (
          <Loader header={PAGE_CONFIG.merchants.header_mapper} hasEditOption checkbox />
        ) : error ? (
          <ErrorPage data={"no-vpn"} title="Merchant" />
        ) : data.length < 1 ? (
          <ErrorPage data={"no-data"} title="Merchant" />
        ) : (
          <>
            <TableWithPagination
              data={data}
              totalItems={totalItems}
              checkbox={checkboxOptions}
              pageConfig={PAGE_CONFIG.merchants}
              handleRowClick={handleRowClick}
              handleToggle={handleToggle}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleReload={handleReload}
              handleLoadMore={handleLoadMore}
              currentObjAfterLoad={currentObjAfterLoad}
            />
          </>
        )}
      </Content>
      <MerchantModal show={modal} onCreate={handleOnSuccessfulCreate} onHide={() => handleModalView(false)} merchantData={merchantData} />
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </div>
  );
};

export default Merchants;
