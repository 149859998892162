import { makeStyles, Container } from "@material-ui/core";
import { ROUTES } from "../config/routes";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";

import BoostLogo from "../icons/boost_logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "15%",
    height: "100vh",
    backgroundColor: "#FFFFFF",
    borderRight: "1px solid #d9d9d9 ",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  brandLogoContainer: {
    height: "10%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  containerStyle: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 8,
    paddingBottom: 8,
    "&:focus, &:hover": {
      backgroundColor: theme.palette.primary.background,
    },
  },
  mainNavNoHover: {
    "&:focus, &:hover": {
      backgroundColor: "#FFFFFF",
      cursor: "context-menu !important",
    },
  },
  container: {
    display: "flex",
    width: "90%",
    marginLeft: 16,
    marginBottom: 8,
    alignItems: "center",
    textDecoration: "none",
    "&:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  containerActive: {
    backgroundColor: theme.palette.primary.background,
    width: "90%",
  },
  logoStyle: {
    marginRight: 8,
  },
  labelStyle: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 500,
    color: "#414243",
  },
  subNavContainer: {
    display: "flex",
    width: "75%",
    height: 36,
    paddingLeft: 42,
    paddingRight: 0,
    marginBottom: 4,
    alignItems: "center",
    cursor: "pointer",
    "&:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: theme.palette.primary.background,
    },
  },
  subNavContainerActive: {
    backgroundColor: theme.palette.primary.background,
  },
  subNavLabelStyle: {
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#5F697A",
    fontSize: 14,
  },
}));

const SideNavBar = props => {
  const history = useHistory();
  const classes = useStyles();
  const [presentPath, setPath] = useState(history.location.pathname);

  useEffect(() => {
    return history.listen(location => {
      setPath(location.pathname);
    });
  }, [history]);

  const SideBarIconLabel = props => {
    const classes = useStyles();
    return (
      <React.Fragment>
        {props.path ? (
          <Container
            className={`${classes.container} ${props.containerStyle} ${props.hasSubNavs && classes.mainNavNoHover} ${
              !props.hasSubNavs && props.isActive && classes.containerActive
            }`}
            component={Link}
            to={!props.hasSubNavs && props.path}>
            {props.logo && (
              <div className={`${classes.logoStyle} ${props.logoStyle}`}>
                <img src={props.logo} style={{ width: "100%", height: "auto" }} alt="" />
              </div>
            )}
            <span className={`${classes.labelStyle} ${props.labelStyle}`}>{props.label}</span>
          </Container>
        ) : (
          <Container
            className={`${classes.container} ${props.containerStyle} ${props.hasSubNavs && classes.mainNavNoHover} ${
              !props.hasSubNavs && props.isActive && classes.containerActive
            }`}>
            {props.logo && (
              <div className={`${classes.logoStyle} ${props.logoStyle}`}>
                <img src={props.logo} style={{ width: "100%", height: "auto" }} alt="" />
              </div>
            )}
            <span className={`${classes.labelStyle} ${props.labelStyle}`}>{props.label}</span>
          </Container>
        )}
        {props.hasSubNavs &&
          props.subNavs.map((nav, index) => {
            return (
              <Container
                className={`${classes.subNavContainer} ${props.presentPath === nav.path && classes.subNavContainerActive}`}
                component={Link}
                to={nav.path ? nav.path : "#"}
                key={`subNav-${index}`}>
                <span className={`${classes.subNavLabelStyle} ${props.subNavLabelStyle}`}>{nav.label}</span>
              </Container>
            );
          })}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <div style={{ paddingTop: 20 }}>
        <div className={classes.brandLogoContainer}>
          <img src={BoostLogo} alt="" style={{ width: 32, height: 32, marginLeft: 32, marginRight: 16 }} />
          <label style={{ fontFamily: "Work Sans", color: "#2E384D", fontWeight: 700, fontSize: 18 }}>Boost Perks</label>
        </div>
        {ROUTES.map((route, index) => {
          return (
            <SideBarIconLabel
              label={route.label}
              logo={route.logo !== undefined && route.logo !== "" ? route.logo : undefined}
              path={route.hasOwnProperty("path") ? route.path : null}
              isActive={presentPath === route.path || (route.hasOwnProperty("subRoutes") && Object.values(route.subRoutes).includes(presentPath))}
              hasSubNavs={route.hasOwnProperty("subRoutes")}
              subNavs={route.subRoutes}
              key={`nav-${index}`}
              containerStyle={classes.containerStyle}
              presentPath={presentPath}
            />
          );
        })}
      </div>
      <div style={{ paddingBottom: 20, textAlign: "center", fontStyle: 'italic' }}>
        {/* {`v${process.env.REACT_APP_VERSION}`} */}
        </div>
    </div>
  );
};

export default SideNavBar;
