import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, makeStyles, Typography, withStyles, Tab, Tabs, AppBar } from "@material-ui/core";
import DealModal from "../modals/new_edit_deal";
import { BreadcrumbsHeader } from "../../components/header";
import IconLabel from "../../components/iconLabel";
import EditIcon from "@material-ui/icons/Edit";
import { calcWidth, calcHeight, getImage } from "../../components/table";
import Toggle from "../../components/toggle";
import { parseDate } from "../../components/table";
import { post } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { Notification, Placeholder, Header, Content } from "rsuite";
import { PAGE_CONFIG } from "../../config/page_config";
import { globalStyles } from "../../config/theme";
import ErrorPage from "../../components/error_page";

const getDealDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.POST_GET_DEAL_DETAILS;
const getGroupedDealDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.POST_GET_GROUPED_DEAL_DETAILS;
const dealActivateUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.ACTIVATE;
const getCouponDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.GET_COUPON_DETAILS;

const useStyles = makeStyles(theme => ({
  topBar: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    borderRadius: "3px",
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginRight: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  container: {
    margin: 10,
    borderRadius: "4px",
    backgroundColor: theme.palette.white,
    width: "inherit",
    // minWidth: 500,
    height: "100%",
    overflow: "auto",
    // minHeight: 500,
    "&:hover, focus": {
      cursor: "default !important",
    },
  },

  containerTopNav: {
    height: 48,
    borderBottom: `2px solid ${theme.palette.dullGrey}`,
  },
  labelStyle: {
    color: "#2E384D",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25,
  },
  topNav: {
    display: "flex",
    textAlign: "center",
    width: "max-content",
    padding: "8px 13px 4px 13px",
    fontSize: 14,
    height: 48,
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 700,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  body: {
    display: "flex",
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    marginTop: 16,
    marginLeft: 32,
  },
  leftContainer: {
    borderColor: " white",
    maxWidth: "60%",
    minWidth: "60%",
    height: "100%",
    paddingBottom: 30,
  },
  rightContainer: {
    maxWidth: "35%",
    minWidth: "35%",
  },
  containerTitle: {
    width: "34%",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 700,
    color: "#2E384D",
    letterSpacing: 0.1,
    margin: `20px auto 10px 32px`,
    paddingBottom: 10,
    borderBottom: "1px solid #EEF1F7",
  },
  previewBox: {
    width: "max-content",
    maxWidth: 300,
    // minHeight: "50vh",

    margin: 10,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.background,
  },
  dataWrapper: {
    display: "flex",
    marginTop: 5,
    marginBottom: 5,
    alignItems: "baseline",
  },
  label: {
    color: "#9397AA",
    width: "25%",
    marginLeft: 32,
    marginTop: 9,
    fontFamily: "Roboto",
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 400,
  },
  containerStyle: {
    justifyContent: "center",
    paddingLeft: `2px !important`,
    fontSize: 13,
    "&:hover": {
      cursor: "default",
    },
  },
  marginTopIconLabelStyle: {
    marginTop: 9,
  },
  value: {
    marginTop: 9,
    color: "#2E384D",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    width: "60%",
    overflowWrap: "break-word",
  },
  featuredPhoto: {},
  toggleContainer: {
    paddingTop: 16,
  },
  image: {
    width: "90%",
    margin: 10,
    minWidth: 220,
    minHeight: 220,
  },
}));

const DealDetails = () => {
  //constants
  const classes = useStyles();
  const globalClasses = globalStyles();
  const history = useHistory();

  //useStates
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isCouponsLoading, setCouponsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  //functions
  const handleModalView = state => {
    typeof state === "boolean" && setShowModal(state);
  };

  const handleChangeTabIndex = (event, value) => {
    setTabIndex(value);
  };

  const handleEdit = () => {
    handleModalView(true);
  };

  const handleUpdateData = updatedData => {
    setLoading(true);
    setData(updatedData);
    setTimeout(() => {
      setLoading(false);
    }, 10);
    sessionStorage.setItem("updated_deal", JSON.stringify(updatedData));
  };

  const handleToggle = async (id, state) => {
    let payload = {
      offer_detail_id: id,
      mapping_active: state,
    };
    try {
      await post(dealActivateUrl, payload);
      if (sessionStorage.getItem("updated_deal") === null) {
        sessionStorage.setItem("updated_deal", JSON.stringify(payload));
      } else {
        let existingUpdate = JSON.parse(sessionStorage.getItem("updated_deal"));
        existingUpdate = { ...existingUpdate, ["mapping_active"]: state };
        sessionStorage.setItem("updated_deal", JSON.stringify(existingUpdate));
      }
      setData(prevState => ({ ...prevState, ["mapping_active"]: state }));
      Notification["success"]({
        title: `Success`,
        description: state ? `${data.offertextshort} successfully activated..` : `${data.offertextshort} successfully deactivated..`,
      });
      return true;
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: `Couldnt activate/deactivate ${data.offertextshort}`,
      });
      setData(prevState => ({ ...prevState, ["mapping_active"]: !state }));
      return false;
    }
  };

  const isValidBarcode = barcode => {
    if (barcode === null || barcode === undefined || barcode === "None" || barcode === "") {
      return false;
    }
    return true;
  };

  const fetchDealDetail = async payload => {
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        let response = await post(getDealDetailsUrl, payload);
        setData(response.offerDetailsList[0] !== undefined && response.offerDetailsList[0] !== null ? response.offerDetailsList[0] : {});
        if (response.offerDetailsList[0].hasOwnProperty("offertype") && response.offerDetailsList[0].offertype === "MULTI_COUPON") {
          try {
            setCouponsLoading(true);
            let couponResponse = await post(getCouponDetailsUrl, { offer_id: response.offerDetailsList[0].offer_detail_id });
            setCoupons(couponResponse.couponDetails);
            setCouponsLoading(false);
          } catch (error) {
            setCoupons([]);
            setCouponsLoading(false);
          }
          setError(false);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };
  const fetchGroupedDealDetail = async payload => {
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        let response = await post(getGroupedDealDetailsUrl, payload);
        setData(response.offerlist[0] !== undefined && response.offerlist[0] !== null ? response.offerlist[0] : {});
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };

  //Loader for JSX
  const LoaderJsx = index => {
    switch (index) {
      case 0:
        return (
          <>
            <div className={classes.body}>
              <div className={classes.leftContainer}>
                <Typography className={classes.containerTitle}>Deal Details</Typography>
                {Object.entries(PAGE_CONFIG.deals.detail_screen.details)
                  .filter(
                    ([key, value]) =>
                      (Object.keys(history.location.state)[0] === "offer" && key !== "offer_detail_id") ||
                      (Object.keys(history.location.state)[0] === "offer_detail_id" && key !== "offer")
                  )
                  .map(([key, value], index) => {
                    return (
                      <div key={`deal-detail-div-${key}-${index}`} className={classes.dataWrapper} id={`div-${key}`}>
                        <Typography className={classes.label}>{value}</Typography>
                        {PAGE_CONFIG.deals.toggleKey.includes(key) ? (
                          <Placeholder.Graph height={20} width={45} style={{ borderRadius: 10, marginTop: 16 }} active />
                        ) : (
                          <Placeholder.Graph height={20} width={250} style={{ borderRadius: 10, marginTop: 16 }} active />
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className={classes.rightContainer}>
                <Typography className={classes.containerTitle} style={{ marginLeft: 10, marginBottom: 20, width: "max-content" }}>
                  Coupon Details
                </Typography>
                <div className={classes.previewBox} style={{ minWidth: 300 }}>
                  <Placeholder.Graph height={18} width={"90%"} style={{ borderRadius: 10, marginTop: 16 }} active />
                  <Placeholder.Graph height={15} width={"50%"} style={{ borderRadius: 10 }} active />
                  <Placeholder.Graph height={250} width={250} style={{ borderRadius: 10, marginLeft: 25 }} active />
                  <div style={{ margin: 10, display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <Typography style={{ marginLeft: 0, color: "#2E384D", width: "auto", fontWeight: 700, fontSize: 14 }}>Promo Code</Typography>
                      <Placeholder.Graph height={18} width={"100%"} style={{ borderRadius: 10 }} active />
                    </div>
                    <div>
                      <Typography style={{ marginLeft: 0, color: "#2E384D", width: "auto", fontWeight: 700, fontSize: 14 }}>Expire Date</Typography>
                      <Placeholder.Graph height={18} width={"100%"} style={{ borderRadius: 10 }} active />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  //Tabs related functions
  const TabPanel = props => {
    return (
      <div role="tabpanel" hidden={props.value !== props.index} id={`full-width-tabpanel-${props.index}`} aria-labelledby={`full-width-tab-${props.index}`}>
        {props.value === props.index ? props.children : null}
      </div>
    );
  };

  const otherProps = index => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  //sub-components

  const TabDealDetails = props => {
    //constants
    const classes = useStyles();
    const globalClasses = globalStyles();

    //useStates
    const [data, setData] = useState(props.data);
    const [isUpdating, setUpdating] = useState(false);

    //functions
    const handleToggle = async (id, state) => {
      setUpdating(true);
      setUpdating(await props.handleToggle(id, state));
    };

    //useEffects
    useEffect(() => {}, []);
    useEffect(() => {}, [data]);
    useEffect(() => {}, [isUpdating]);

    //render
    return (
      <>
        <div style={{ display: "flex" }}>
          <div className={classes.body}>
            <div className={classes.leftContainer}>
              <Typography className={classes.containerTitle}>Deal Details</Typography>
              {Object.keys(PAGE_CONFIG.deals.detail_screen.details).map((key, i) => {
                return (
                  Object.keys(data).includes(key) && (
                    <div className={classes.dataWrapper} key={`div-${i}`}>
                      <Typography className={classes.label}>{PAGE_CONFIG.deals.detail_screen.details[key]}</Typography>
                      {PAGE_CONFIG.deals.hasOwnProperty("iconLabels") && Object.keys(PAGE_CONFIG.deals.iconLabels).includes(key) ? (
                        <IconLabel
                          label={data[key]}
                          logo={getImage(data[PAGE_CONFIG.deals.iconLabels[key]["logo"]])}
                          containerStyle={`${classes.containerStyle} ${classes.marginTopIconLabelStyle}`}
                          labelStyle={classes.labelStyle}
                          hasThumbnailPreview={true}
                        />
                      ) : PAGE_CONFIG.deals.hasOwnProperty("toggleKey") && PAGE_CONFIG.deals.toggleKey.includes(key) ? (
                        isUpdating ? (
                          <Placeholder.Graph style={{ width: 34, height: 16, borderRadius: 10, marginTop: 15 }} active />
                        ) : (
                          <Toggle
                            initialState={data[PAGE_CONFIG.deals.toggleKey] ? data[PAGE_CONFIG.deals.toggleKey] : false}
                            containerStyle={classes.toggleContainer}
                            id={data[PAGE_CONFIG.deals.id]}
                            handler={handleToggle}
                          />
                        )
                      ) : PAGE_CONFIG.deals.hasOwnProperty("date") && PAGE_CONFIG.deals.date.includes(key) ? (
                        <Typography className={classes.value}>{data[key] !== undefined && parseDate(data[key].toString().replace("Z", ""), "long")}</Typography>
                      ) : PAGE_CONFIG.deals.hasOwnProperty("alternate_value") && PAGE_CONFIG.deals.alternate_value.hasOwnProperty(key) ? (
                        <Typography className={classes.value}>
                          {data.hasOwnProperty(key) && PAGE_CONFIG.deals.alternate_value[key].hasOwnProperty(data[key])
                            ? PAGE_CONFIG.deals.alternate_value[key][data[key]]
                            : data[key]}
                        </Typography>
                      ) : data[key] === null || data[key] === "" ? (
                        <Typography className={classes.value}>-</Typography>
                      ) : (
                        <Typography className={classes.value}>{data[key]}</Typography>
                      )}
                    </div>
                  )
                );
              })}
            </div>
            <div className={classes.rightContainer}>
              <Typography className={classes.containerTitle} style={{ marginLeft: 10, marginBottom: 20, width: "max-content" }}>
                Coupon Details
              </Typography>
              <div className={classes.previewBox}>
                <div style={{ padding: 10, height: "-webkit-fill-available" }}>
                  <Typography
                    // className={classes.label}
                    noWrap={true}
                    style={{ marginLeft: 0, color: "#2E384D", width: "auto", fontWeight: 700, fontSize: 14 }}>
                    {data.offertextshort}
                  </Typography>
                  <Typography
                    style={{
                      marginLeft: 0,
                      color: "#9397AA",
                      fontSize: 14,
                      fontWeight: 400,
                    }}>
                    {data.dbasortname}
                  </Typography>
                  {data.offertype === "SINGLE_COUPON" || isValidBarcode(data.barcode) ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        margin: 15,
                        backgroundColor: "white",
                        maxWidth: 250,
                        maxHeight: 250,
                        minWidth: 230,
                        minHeight: 230,
                      }}>
                      {isValidBarcode(data.barcode) ? (
                        <img src={getImage(data.barcode)} alt="" className={classes.image} />
                      ) : (
                        <Typography>No QR/Barcode</Typography>
                      )}
                    </div>
                  ) : data.offertype === "MULTI_COUPON" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 250,
                        maxHeight: 250,
                        minWidth: 230,
                        minHeight: 230,
                        padding: 10,
                        margin: 15,
                        backgroundColor: "white",
                        overflow: "scroll",
                      }}>
                      {isCouponsLoading ? (
                        <div>
                          {[...Array(3)].map((box, index) => {
                            return (
                              <div key={`coupon-loader-${index}`}>
                                <Placeholder.Graph height={50} width={240} style={{ borderRadius: 4, margin: 3 }} active />
                              </div>
                            );
                          })}
                        </div>
                      ) : coupons !== undefined && coupons.length === 0 ? (
                        <div style={{ textAlign: "center" }}>No Coupons Available</div>
                      ) : (
                        coupons !== undefined &&
                        coupons.map((coupon, index) => {
                          return (
                            <div key={`coupon-${index}`} style={{ border: `1px solid lightgrey`, borderRadius: 3, padding: 3 }}>
                              <Typography>{`Filename : ${coupon.added_file_name}`}</Typography>
                              <Typography>{`Count : ${coupon.count}`}</Typography>
                              <Typography>{`Used : ${coupon.is_used ? `True` : `False`}`}</Typography>
                            </div>
                          );
                        })
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        margin: 15,
                        backgroundColor: "white",
                        maxWidth: 250,
                        maxHeight: 250,
                        minWidth: 230,
                        minHeight: 230,
                      }}>
                      <Typography>No QR/Barcode</Typography>
                    </div>
                  )}
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "50%" }}>
                      <Typography style={{ marginLeft: 0, color: "#2E384D", width: "auto", fontWeight: 700, fontSize: 14 }}>Promo Code</Typography>
                      <Typography noWrap={true} style={{ width: "95%" }}>
                        {data.trackingcode === "" ? " - " : data.trackingcode}
                      </Typography>
                    </div>
                    <div>
                      <Typography style={{ marginLeft: 0, color: "#2E384D", width: "auto", fontWeight: 700, fontSize: 14 }}>Expire Date</Typography>
                      <Typography>{data.expiredate !== undefined && parseDate(data.expiredate.toString().replace("Z", ""), "long")}</Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  //Custom Styles

  const CustomTabContainer = withStyles(theme => ({
    root: {
      borderRadius: 1,
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #E6E7ED",
      color: "#000000",
    },
    indicator: {
      backgroundColor: "#1890ff",
    },
  }))(Tabs);

  const CustomTab = withStyles(theme => ({
    root: {
      textTransform: "none",
      minWidth: "max-content",
      maxHeight: 30,
      fontSize: 14,
      padding: `1px 16px 1px 16px`,
      fontWeight: 500,
      marginRight: theme.spacing(2),
      fontFamily: "Roboto",
      "&:hover": {
        color: "#3489EC",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
        fontWeight: 500,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />);

  //useEffects
  useEffect(() => {
    if (Object.keys(history.location.state)[0] === "offer") {
      fetchGroupedDealDetail(history.location.state);
    } else {
      fetchDealDetail(history.location.state);
    }
  }, []);
  useEffect(() => {}, [isLoading]);
  useEffect(() => {}, [isError]);
  useEffect(() => {}, [isCouponsLoading]);
  useEffect(() => {}, [data]);

  //render
  return (
    <div className={globalClasses.mainContainer}>
      <Header className={globalClasses.headerContainer}>
        <BreadcrumbsHeader
          options={[
            { order: 0, title: "Deals", to: "/deals" },
            { order: 1, title: "Deal Details" },
          ]}
        />
      </Header>
      <Content className={globalClasses.contentContainer}>
        {isLoading ? (
          <div className={classes.topBar}>
            <Placeholder.Graph height={30} width={140} style={{ borderRadius: 10 }} active />
            <Button variant="outlined" startIcon={<EditIcon className={classes.icon} />} className={globalClasses.buttonSecondary} disabled>
              Edit
            </Button>
          </div>
        ) : (
          <div className={classes.topBar}>
            <IconLabel
              label={data.dbasortname ? data.dbasortname : "Merchant"}
              boldLabel={data.offertextshort ? data.offertextshort : "Deal"}
              logo={getImage(data.logourl)}
              containerStyle={classes.containerStyle}
            />
            <Button
              variant="outlined"
              startIcon={<EditIcon className={classes.icon} />}
              className={globalClasses.buttonSecondary}
              disabled={
                (data.hasOwnProperty("deal_provider") && data.deal_provider === 10) ||
                (data.hasOwnProperty("deal_provider_id") && data.deal_provider_id === 10) ||
                Object.keys(data).length === 0
              }
              onClick={handleEdit}>
              Edit
            </Button>
          </div>
        )}

        <div className={classes.container} style={{ overflow: "hidden " }}>
          <div>
            <AppBar position="static" color="default" style={{ borderRadius: 1 }}>
              <CustomTabContainer value={tabIndex} onChange={handleChangeTabIndex}>
                <CustomTab label="Details" {...otherProps(0)} />
              </CustomTabContainer>
            </AppBar>
          </div>
          {isLoading ? (
            LoaderJsx(tabIndex)
          ) : isError ? (
            <ErrorPage data={"no-vpn"} title="Deal" />
          ) : Object.keys(data).length < 1 ? (
            <ErrorPage data={"no-data"} title="Deal" />
          ) : (
            <>
              <div style={{ maxHeight: "90%", overflowY: "scroll" }}>
                <TabPanel value={tabIndex} index={0}>
                  <TabDealDetails data={data} handleToggle={handleToggle} />
                </TabPanel>
              </div>
            </>
          )}
        </div>
      </Content>
      <DealModal dealData={data} onEdit={handleUpdateData} show={showModal} onHide={() => handleModalView(false)} />
    </div>
  );
};

export default DealDetails;
