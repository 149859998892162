import Auth from "@aws-amplify/auth";
import { Storage } from "aws-amplify";
import EndPoints from "./endpoints";

const getAccessJwtToken = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const session = await Auth.currentSession();
      const jwtToken = session.getAccessToken().getJwtToken();
      sessionStorage.setItem("jwt", jwtToken);
      EndPoints.updateRequestHeader(jwtToken);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const get = async uri => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri, {
        headers: EndPoints.getRequestHeader(),
      });
      const body = await response.json();
      if (response.status === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await get(uri));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const post = async (uri, payload) => {

  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch(uri, {
        method: "POST",
        headers: EndPoints.getRequestHeader(),
        body: JSON.stringify(payload),
      });
      let body = await response.json();
      if (response.status === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await post(uri, payload));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(body);
      }
    } catch (error) {
      
      reject(error);
    }
  });
};

export const put = async (uri, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri, {
        method: "PUT",
        headers: EndPoints.getRequestHeader(),
        body: JSON.stringify(payload),
      });
      const body = await response.json();
      if (body.statusCode === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await put(uri, payload));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(body.status);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const upload = async (path, fileName, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      await Auth.currentUserCredentials();
      let body = await Storage.put(path + fileName, file, {
        level: "public",
        contentType: file.type,
      });
      resolve(body);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteRecord = async (uri, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri + id, {
        method: "DELETE",
        headers: EndPoints.getRequestHeader(),
      });
      const body = await response.json();
      if (body.statusCode === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await deleteRecord(uri, id));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(body);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const batchDelete = async (uri, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri, {
        method: "DELETE",
        headers: EndPoints.getRequestHeader(),
        body: JSON.stringify(payload),
      });
      const body = await response.json();
      if (body.statusCode === 200) {
        resolve(body);
      } else if (response.status === 401) {
        try {
          await getAccessJwtToken();
          resolve(await batchDelete(uri, payload));
        } catch (error) {
          reject(error);
        }
      } else {
        reject(body);
      }
    } catch (error) {
      reject(error);
    }
  });
};
