import React, { useEffect, useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Input, Modal, Uploader } from "rsuite";
import csvLogo from "../../icons/csv-logo.svg";
import Papa from "papaparse";
import { globalStyles } from "../../config/theme";

const localStyles = makeStyles((theme) => ({
  textBlue: {
    color: theme.palette.primary.main,
  },
  mgTop: {
    marginTop: 20,
  },
}));

const ImportSegment = (props) => {
  const globalClasses = globalStyles();
  const localClasses = localStyles();
  const [showModal, setShowModal] = useState(props.show);
  const [segmentName, setSegmentName] = useState("");
  const [uploadCSV, setUploadCSV] = useState({});

  const addCSV = (e) => {
    setUploadCSV(e[0]);
  };

  const removeCSV = () => {
    setUploadCSV({});
  };

  const onOpen = () => {
    setShowModal(true);
  };

  const onHide = () => {
    setShowModal(false);
    props.onHide();
  };

  const onSave = () => {};

  const onCancel = () => {
    onHide();
  };

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(async () => {
    if (uploadCSV !== null && uploadCSV !== undefined) {
      if (uploadCSV.hasOwnProperty("blobFile")) {
        uploadCSV.blobFile.arrayBuffer().then((res) => {
          const decoder = new TextDecoder("utf-8");
          const csv = decoder.decode(res);
        });
      }
    }
  }, [uploadCSV]);

  return (
    <div className={localClasses.modalContainer}>
      <Modal backdrop="static" size="md" show={showModal} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Import New Segment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Segments allow you to target specific groups of people in your offers,
          earnout actions and sweepstakes.
          <div className={`${globalClasses.divM5} ${localClasses.mgTop}`}>
            <Typography className={`${globalClasses.label}`}>
              Segment Name
            </Typography>
            <Input
              className={`${globalClasses.input}`}
              value={segmentName}
              onChange={(e) => setSegmentName(e)}
            />
          </div>
          <div className={`${globalClasses.divM5}`}>
            <Typography className={`${globalClasses.label}`}>
              Upload File
            </Typography>
            <Uploader
              className={`${globalClasses.divM5}`}
              draggable
              autoUpload={false}
              multiple={false}
              disabledFileItem={false}
              accept=".csv"
              onChange={addCSV}
              onRemove={removeCSV}
            >
              <div>
                <img src={csvLogo} />
                <Typography style={{ fontWeight: 600 }}>
                  Drag and Drop your CSV here
                </Typography>
                <Typography> Maximum file size is 50MB</Typography>
                <Typography className={localClasses.textBlue}>
                  Or select it from your computer
                </Typography>
              </div>
            </Uploader>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className={`${globalClasses.divM5} ${globalClasses.divP5} ${globalClasses.divFlexSB}`}
          >
            <Button
              className={globalClasses.buttonSecondary}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button className={globalClasses.buttonPrimary} onClick={onSave}>
              Import Segment
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportSegment;
