import React, { useState, useEffect, useRef } from "react";
import { Button, FlexboxGrid, Modal, Notification } from "rsuite";
import ConfirmationBox from "../../components/confirmation_box";
import GooglePlaceAutocomplete from "../../components/google_autocomplete";
import { SetLabelInput } from "../../components/modal_input";
import { globalStyles } from "../../config/theme";
import { post } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const MerchantLocationModal = props => {
  //constants
  const globalClasses = globalStyles();
  const createLocationUrl = EndPoints.BOOST_DOMAIN + EndPoints.LOCATIONS.CREATE;

  //useState
  const [modal, setModal] = useState(props.show);

  const [merchantDetails, setMerchantDetails] = useState({});
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [gid, setGid] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isActive, setActive] = useState(false);

  const [isGPlaceLoading, setGPlaceLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });
  const [isReadOnly, setReadOnly] = useState(false);

  //useRef
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipcodeRef = useRef(null);
  const countryRef = useRef(null);
  const phoneRef = useRef(null);
  const gidRef = useRef(null);
  const latitudeRef = useRef(null);
  const longitudeRef = useRef(null);

  //funtions
  const onHide = () => {
    setModal(false);
    props.onHide();
  };

  const clearInputs = () => {
    setAddress("");
    setCity("");
    setCountry("");
    setState("");
    setZipcode("");
    setPhone("");
    setGid("");
    setLatitude("");
    setLongitude("");
  };

  const onModalOpen = () => {
    if (props.hasOwnProperty("editData") && Object.keys(props.editData).length > 0) {
      setMerchantDetails({
        merchant_id: props.editData.merchant_id,
        dbasortname: props.editData.dbasortname,
        logourl: props.editData.logourl,
        stubcopy: props.editData.stubcopy,
        category: props.editData.category,
        subcategory: props.editData.subcategory,
        cuisinetype: props.editData.cuisinetype,
        detailcategory: props.editData.detailcategory,
      });
      setAddress(props.editData.address);
      setCity(props.editData.city);
      setState(props.editData.state);
      setZipcode(props.editData.zipcode);
      setCountry(props.editData.country);
      setPhone(props.editData.phone);
      setGid(props.editData.google_id);
      setLatitude(props.editData.latitude);
      setLongitude(props.editData.longitude);
      setActive(props.editData.merchant_location_active);
    } else if (props.hasOwnProperty("hasMerchantDetails") && Object.keys(props.hasMerchantDetails).length > 0) {
      setMerchantDetails({
        merchant_id: props.hasMerchantDetails.merchant_id,
        dbasortname: props.hasMerchantDetails.dbasortname,
        logourl: props.hasMerchantDetails.logourl,
        stubcopy: props.hasMerchantDetails.stubcopy,
        category: props.hasMerchantDetails.category,
        subcategory: props.hasMerchantDetails.subcategory,
        cuisinetype: props.hasMerchantDetails.cuisinetype,
        detailcategory: props.hasMerchantDetails.detailcategory,
      });
    }
    if (props.hasOwnProperty("readOnly") && props.readOnly === true) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  const getLocation = (recievedAddressObject, onlyAddress = false) => {
    if (onlyAddress) {
      setAddress(recievedAddressObject.address);
    } else {
      setAddress(recievedAddressObject.address);
      setCity(recievedAddressObject.city);
      setCountry(recievedAddressObject.country);
      setState(recievedAddressObject.state);
      setZipcode(recievedAddressObject.postal_code);
      setPhone(recievedAddressObject.phone);
      setGid(recievedAddressObject.place_id);
      setLatitude(recievedAddressObject.latitude);
      setLongitude(recievedAddressObject.longitude);
    }
  };

  const handleGooglePlaceAddressLoader = state => {
    typeof state === "boolean" && setGPlaceLoading(state);
  };

  const onSave = async e => {
    e.target.blur();
    if (hasChanges && checkInputs()) {
      setLoading(true);
      let payload = {
        redemptionlocation: generateRedemptionLocationId(),
        ...merchantDetails,
        address: address,
        city: city,
        state: state,
        zipcode: zipcode,
        country: country,
        phone: phone,
        google_id: gid,
        latitude: latitude,
        longitude: longitude,
        merchant_location_active: isActive,
      };
      try {
        await post(createLocationUrl, payload);
        if (props.hasOwnProperty("onCreate")) {
          props.onCreate(true);
        }
        clearInputs();
        setLoading(false);
        onHide();
        Notification["success"]({
          title: "Success",
          description: props.hasOwnProperty("editData")
            ? `Edited Location - ${generateRedemptionLocationId()} successfully`
            : `Added Location to ${merchantDetails.dbasortname} successfully`,
        });
      } catch (error) {
        Notification["error"]({
          title: "Failure",
          description: props.hasOwnProperty("editData")
            ? `Couldn't edit Location - ${generateRedemptionLocationId()}. Try again.`
            : `Couldn't add Location to ${merchantDetails.dbasortname}. Try again.`,
        });
        setLoading(false);
      }
    } else {
      !hasChanges && onHide();
    }
  };

  const onCancel = () => {
    if (hasChanges) {
      setConfirmationBoxModal(true);
      setConfirmationDialog({
        title: "Close",
        description: ["Do you want to cancel?"],
        helperText: "All unsaved changes will be lost",
        onCancel: () => {
          setConfirmationBoxModal(false);
        },
        onConfirm: () => {
          clearInputs();
          onHide();
          setConfirmationBoxModal(false);
        },
      });
    } else {
      onHide();
    }
  };

  const checkInputs = () => {
    let errorState = true;
    if (address === "") {
      addressRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (city === "") {
      cityRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (country === "") {
      countryRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (state === "") {
      stateRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (zipcode === "") {
      zipcodeRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    // if (phone === "") {
    //   phoneRef.current.style.borderBottom = "2px solid red";
    //   errorState = errorState && false;
    // }
    if (gid === "") {
      gidRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (latitude === "") {
      latitudeRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    if (longitude === "") {
      longitudeRef.current.style.borderBottom = "2px solid red";
      errorState = errorState && false;
    }
    return errorState;
  };

  const generateRedemptionLocationId = () => {
    if (props.hasOwnProperty("editData") && Object.keys(props.editData).length > 0) {
      return props.editData.redemptionlocation;
    } else {
      return `LOCATION_${props.hasMerchantDetails.merchant_id}_${props.hasMerchantDetails.locationCount + 1}_${zipcode}`;
    }
  };

  const onEditWithinModal = () => {
    setReadOnly(false);
  };

  //useEffects
  useEffect(() => {
    if (props.show !== modal) {
      setModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, [isReadOnly]);

  useEffect(() => {
    if (
      !props.hasOwnProperty("editData") &&
      (address !== "" || city !== "" || state !== "" || zipcode !== "" || country !== "" || phone !== "" || gid !== "" || latitude !== "" || longitude !== "")
    ) {
      setHasChanges(true);
    } else if (
      props.hasOwnProperty("editData") &&
      Object.keys(props.editData).length > 0 &&
      (address !== props.editData.address ||
        city !== props.editData.city ||
        state !== props.editData.state ||
        zipcode !== props.editData.zipcode ||
        country !== props.editData.country ||
        phone !== props.editData.phone ||
        gid !== props.editData.google_id ||
        latitude !== props.editData.latitude ||
        longitude !== props.editData.longitude ||
        isActive !== props.editData.merchant_location_active)
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [address, city, state, zipcode, country, phone, gid, latitude, longitude, isActive]);

  //render
  return (
    <>
      <Modal size="md" show={modal} onHide={onCancel} onEnter={onModalOpen}>
        <Modal.Header>
          <Modal.Title style={{ display: "flex", alignItems: "center" }}>
            {isReadOnly === true
              ? `Location Details`
              : props.hasOwnProperty("editData") && Object.keys(props.editData).length > 0
              ? `Edit Location`
              : `Add Location`}
            {isReadOnly && (
              <IconButton
                className={globalClasses.icon}
                aria-label="edit"
                onClick={onEditWithinModal}
                disabled={props.hasOwnProperty("editData") && Object.keys(props.editData).length > 0 && props.editData.deal_provider === 10}>
                <EditIcon />
              </IconButton>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "max-content" }}>
          <FlexboxGrid justify="space-around">
            <GooglePlaceAutocomplete
              value={address}
              label={"Address"}
              placeholder={"1476 W Maple Rd"}
              prefix={props.hasOwnProperty("hasMerchantDetails") ? [props.hasMerchantDetails.dbasortname] : []}
              passDetails={getLocation}
              handleLoadUntilResult={handleGooglePlaceAddressLoader}
              componentRef={addressRef}
              required
              readOnly={isReadOnly}
            />
            <SetLabelInput
              label={"City"}
              inputName={"city"}
              inputType={"text"}
              placeholder={"City"}
              width={"38%"}
              valueVariable={city}
              onChangeHandler={setCity}
              required
              isLoading={isGPlaceLoading}
              componentRef={cityRef}
              readOnly={isReadOnly}
            />
          </FlexboxGrid>
          <FlexboxGrid justify="space-around">
            <SetLabelInput
              label={"Country"}
              inputName={"country"}
              inputType={"text"}
              placeholder={"Country"}
              width={"30%"}
              valueVariable={country}
              onChangeHandler={setCountry}
              required
              isLoading={isGPlaceLoading}
              componentRef={countryRef}
              readOnly={isReadOnly}
            />
            <SetLabelInput
              label={"State"}
              inputName={"state"}
              inputType={"text"}
              placeholder={"State"}
              width={"20%"}
              valueVariable={state}
              onChangeHandler={setState}
              required
              isLoading={isGPlaceLoading}
              componentRef={stateRef}
              readOnly={isReadOnly}
            />
            <SetLabelInput
              label={"Zip Code"}
              inputName={"zipcode"}
              inputType={"text"}
              placeholder={"Zip Code"}
              width={"20%"}
              valueVariable={zipcode}
              onChangeHandler={setZipcode}
              required
              isLoading={isGPlaceLoading}
              componentRef={zipcodeRef}
              readOnly={isReadOnly}
            />
            <SetLabelInput
              label={"Phone"}
              inputName={"phone"}
              inputType={"text"}
              placeholder={"Phone"}
              width={"20%"}
              valueVariable={phone}
              onChangeHandler={setPhone}
              // required
              isLoading={isGPlaceLoading}
              componentRef={phoneRef}
              readOnly={isReadOnly}
            />
          </FlexboxGrid>
          <FlexboxGrid>
            <SetLabelInput
              label={"Google Place ID"}
              inputName={"gid"}
              inputType={"text"}
              placeholder={"Google Place ID"}
              width={"58%"}
              valueVariable={gid}
              onChangeHandler={setGid}
              required
              isLoading={isGPlaceLoading}
              componentRef={gidRef}
              readOnly={isReadOnly}
            />
          </FlexboxGrid>
          <FlexboxGrid>
            <SetLabelInput
              label={"Latitude"}
              inputName={"latitude"}
              inputType={"number"}
              placeholder={"Latitude"}
              width={"45%"}
              valueVariable={latitude}
              onChangeHandler={setLatitude}
              required
              isLoading={isGPlaceLoading}
              componentRef={latitudeRef}
              readOnly={isReadOnly}
            />
            <SetLabelInput
              label={"Longtitude"}
              inputName={"longitude"}
              inputType={"number"}
              placeholder={"Longtitude"}
              width={"45%"}
              valueVariable={longitude}
              onChangeHandler={setLongitude}
              required
              isLoading={isGPlaceLoading}
              componentRef={longitudeRef}
              readOnly={isReadOnly}
            />
          </FlexboxGrid>
          <FlexboxGrid style={{ marginBottom: 40 }}>
            {/* <SetLabelInput
              // inline
              label={"Active"}
              inputName={"active"}
              inputType={"toggle"}
              placeholder={"Active"}
              width={"15%"}
              valueVariable={isActive}
              onChangeHandler={setActive}
            /> */}
          </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
          {props.hasOwnProperty("readOnly") && isReadOnly === true ? (
            <Button className={globalClasses.buttonPrimary} onClick={onCancel}>
              Close
            </Button>
          ) : (
            <FlexboxGrid justify="space-between">
              <Button className={globalClasses.buttonSecondary} onClick={onCancel}>
                Cancel
              </Button>
              <Button className={globalClasses.buttonPrimary} style={{ paddingLeft: 45, paddingRight: 45 }} loading={isLoading} onClick={onSave}>
                Save
              </Button>
            </FlexboxGrid>
          )}
        </Modal.Footer>
      </Modal>
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </>
  );
};

export default MerchantLocationModal;
