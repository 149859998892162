import { Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Modal, Notification } from "rsuite";
import ConfirmationBox from "../../components/confirmation_box";
import { globalStyles } from "../../config/theme";
import { post, deleteRecord } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { TableWithPagination } from "../../components/table";
import { PAGE_CONFIG } from "../../config/page_config";
import { SetLabelInput } from "../../components/modal_input";

const useStyles = makeStyles(theme => ({
  tableContainer: {
    borderRadius: "4px",
    backgroundColor: theme.palette.white,
    width: "99%",
    height: "99%",
    // overflowX: "scroll",
    // boxShadow: "none",
  },
}));

const AssignDealLocation = props => {
  //constants
  const globalClasses = globalStyles();
  const classes = useStyles();
  const assignDealsToLocationsUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.ASSIGN_DEALS_TO_LOCATIONS;
  const deleteOfferLocationMappingUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.DELETE_OFFER_LOCATION_MAPPING;
  const featureGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FEATURE;
  const activateGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.ACTIVATE;
  const activateLocation = EndPoints.BOOST_DOMAIN + EndPoints.LOCATIONS.ACTIVATE;

  //useStates
  const [modal, setModal] = useState(props.show);

  const [deals, setDeals] = useState(props.hasOwnProperty("data") && Object.keys(props.data).includes("deals") ? props.data.deals : []);
  const [locations, setLocations] = useState(props.hasOwnProperty("data") && Object.keys(props.data).includes("locations") ? props.data.locations : []);

  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isOnAssignLoading, setOnAssignLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });

  //functions
  const onHide = () => {
    if (hasChanges) {
      props.onSuccessfulUpdate(true);
    }
    setModal(false);
    props.onHide();
  };

  const handleReload = () => {};

  const onCancel = () => {
    if (hasChanges) {
      setConfirmationBoxModal(true);
      setConfirmationDialog({
        title: "Close",
        description: ["Do you want to cancel?"],
        helperText: "All unsaved changes will be lost",
        onCancel: () => {
          setConfirmationBoxModal(false);
        },
        onConfirm: () => {
          onHide();
          setConfirmationBoxModal(false);
        },
      });
    } else {
      onHide();
    }
  };

  const handleFeatureToggle = async (id, state) => {
    let payload = {
      offer: id,
      is_featured: state,
    };
    try {
      setHasChanges(true);
      await post(featureGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Featured Deal - ${id} successfully` : `Defeatured Deal - ${id} successfully`,
      });
    } catch (error) {
      console.log(error);
      Notification["error"]({
        title: "Error",
        description:
          error.hasOwnProperty("statusText") && error.statusText !== null
            ? error.statusText
            : state
            ? `Couldn't Feature Deal - ${id}`
            : `Couldn't Defeature Deal - ${id}`,
      });
      props.onSuccessfulUpdate(true);
    }
  };

  const handleActivateToggle = async (id, state) => {
    let payload = {
      offer: id,
      deal_active: state,
    };
    try {
      await post(activateGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Deal - ${id} successfully` : `Deactivated Deal - ${id} successfully`,
      });
      setHasChanges(true);
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Deal - ${id}` : `Couldn't Deactivate Deal - ${id}`,
      });
      props.onSuccessfulUpdate(true);
    }
  };

  const handleLocationToggle = async (id, state) => {
    let payload = {
      merchant_id: props.data.merchant.id,
      redemptionlocation: id,
      merchant_location_active: state,
    };
    try {
      await post(activateLocation, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Location - ${id} successfully` : `Deactivated Location - ${id} successfully`,
      });
      setHasChanges(true);
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Location - ${id}` : `Couldn't Deactivate Location - ${id}`,
      });
      props.onSuccessfulUpdate(true);
    }
  };

  const handleSelected = selected => {
    let noChanges = true;
    let existingSelects =
      Object.keys(props.data).includes("map") && Array.isArray(props.data.map) && props.data.map.length > 0
        ? props.data.map.map(el => (props.type === "l2d" ? el.redemptionlocation : el.offer))
        : [];
    if (existingSelects.length > 0 && selected.length === 0) {
      noChanges = noChanges && false;
    }
    selected.forEach(el => {
      if (existingSelects.includes(el)) {
        noChanges = noChanges && true;
      } else {
        noChanges = noChanges && false;
      }
    });
    if (!noChanges) {
      setHasChanges(true);
    }
    setSelected(selected);
  };

  const generatePayload = type => {
    let offerLocationsArray = [];
    if (type === "d2l") {
      selected.length > 0 &&
        selected.map(el => {
          if (Array.isArray(locations) && locations.length > 0) {
            locations.forEach(loc => {
              let obj = {
                offer: el,
                redemptionlocation: loc.redemptionlocation,
                mapping_active: false,
              };
              offerLocationsArray.push(obj);
            });
          } else {
            let obj = {
              offer: el,
              redemptionlocation: locations.redemptionlocation,
              mapping_active: false,
            };
            offerLocationsArray.push(obj);
          }
        });
    } else {
      selected.length > 0 &&
        selected.map(el => {
          if (Array.isArray(deals) && deals.length > 0) {
            deals.forEach(deal => {
              let obj = {
                offer: deal.offer,
                redemptionlocation: el,
                mapping_active: false,
              };
              offerLocationsArray.push(obj);
            });
          } else {
            let obj = {
              offer: deals.offer,
              redemptionlocation: el,
              mapping_active: false,
            };
            offerLocationsArray.push(obj);
          }
        });
    }
    return {
      mappinglist: [{ merchant_id: props.data.merchant.id, deal_provider: props.data.merchant.deal_provider, offerlocationlist: offerLocationsArray }],
    };
  };

  const onAssign = async event => {
    event.target.blur();
    let payload = generatePayload(props.type);
    setOnAssignLoading(true);
    sessionStorage.setItem("refreshDeals", true);
    if (props.data.hasOwnProperty("map") && Array.isArray(props.data.map) && props.data.map.length > 0) {
      props.data.map.forEach(async (el, i, arr) => {
        if (!selected.includes(props.type === "d2l" ? el.offer : el.redemptionlocation)) {
          try {
            selected.length === 0 && setOnAssignLoading(true);
            await deleteRecord(deleteOfferLocationMappingUrl, `?offer_detail_id=${el.offer_detail_id}`);
            i === arr.length && setOnAssignLoading(false);
          } catch (error) {
            Notification["error"]({
              title: "Error",
              description: `Couldn't Unassign the existing assinged Deals`,
            });
          }
        }
      });
    }
    if (selected.length > 0) {
      try {
        await post(assignDealsToLocationsUrl, payload);
        setOnAssignLoading(false);
        onHide();
        props.onSuccessfulUpdate(true);
        Notification["success"]({
          title: "Success",
          description: `Assigned Deal(s) to ${Array.isArray(locations) ? `Locations` : `Location - ${locations.redemptionlocation}`}.`,
        });
      } catch (error) {
        setOnAssignLoading(false);
        Notification["error"]({
          title: "Error",
          description: `Couldn't assign Deal(s) to ${Array.isArray(locations) ? `Locations` : `Location - ${locations.redemptionlocation}`}`,
        });
      }
    } else {
      if (!isOnAssignLoading) {
        props.onSuccessfulUpdate(true);
        onHide();
      }
    }
  };

  //useEffects
  useEffect(() => {
    if (props.show !== modal) {
      setModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.hasOwnProperty("data")) {
      Object.keys(props.data).includes("deals") && setDeals(props.data.deals);
      Object.keys(props.data).includes("locations") && setLocations(props.data.locations);
      Object.keys(props.data).includes("map") &&
        setSelected(
          props.data.map.map(el => {
            if (props.type === "d2l") {
              return el.offer;
            } else if (props.type === "l2d") {
              return el.redemptionlocation;
            }
          })
        );
    }
  }, [props.data]);

  useEffect(() => {}, [selected]);
  useEffect(() => {}, [hasChanges]);

  //render
  return (
    <>
      <Modal size={props.type === "d2l" ? "lg" : "md"} show={modal} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{props.type === "d2l" ? `Assign Deal(s) to Location` : `Assign Location(s) to Deal`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>Segments allow you to target specific groups of people in your offers, earnout actions and sweepstakes.</Typography>
          <Typography style={{ marginBottom: 10 }}>{`${selected.length} ${props.type === "d2l" ? `Deals` : `Locations`} selected.`}</Typography>
          <TableWithPagination
            data={props.type === "d2l" ? deals : locations}
            totalItems={props.type === "d2l" ? (deals ? deals.length : 0) : locations ? locations.length : 0}
            pageConfig={props.type === "d2l" ? PAGE_CONFIG.merchants.details_screen.deals : PAGE_CONFIG.merchants.details_screen.locations}
            // handleReload={handleReload}
            checkbox
            handleToggle={props.type === "d2l" ? { is_featured: handleFeatureToggle, deal_active: handleActivateToggle } : handleLocationToggle}
            pagination={false}
            disableRowOptions
            containerStyles={classes.tableContainer}
            tableHeight={"83%"}
            selected={selected}
            getSelected={handleSelected}
          />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button className={globalClasses.buttonSecondary} onClick={onCancel}>
              Cancel
            </Button>
            <Button className={globalClasses.buttonPrimary} style={{ paddingLeft: 40, paddingRight: 40 }} onClick={onAssign} loading={isOnAssignLoading}>
              Assign
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </>
  );
};

export default AssignDealLocation;
