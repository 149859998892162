export const PAGE_CONFIG = {
  deals: {
    header_mapper: {
      mapping_active: "Active",
      offer_detail_id: "Deal ID",
      category: "Category",
      dbasortname: "Merchant",
      offertextshort: "Deal Name",
      start_date: "Start Date",
      expiredate: "Expire Date",
      offerusedcount: "Used",
      totalsavings: "Total Savings",
    },
    detail_screen: {
      details: {
        offer_detail_id: "Deal Detail ID",
        offer: "Deal ID",
        merchant_id: "Merchant ID",
        dbasortname: "Merchant",
        offertextshort: "Deal Name",
        offertextlong: "Deal Description",
        discountvaluetext: "Value",
        discountvalueamount: "Max Discount",
        freqtime: "Redemption Interval",
        freqvalue: "Redemption Frequency",
        offerqualifier: "Terms",
        start_date: "Start Date",
        expiredate: "Expire Date",
        redemption: "Redemption Type",
        url: "URL",
        trackingcode: "Promo Code",
        deal_provider: "Provider",
        offertype: "Promo Type",
        mapping_active: "Active",
      },
    },

    id: "offer_detail_id",
    toggleKey: ["mapping_active"],
    date: ["start_date", "expiredate"],
    iconLabels: {
      dbasortname: {
        name: "dbasortname",
        logo: "logourl",
        hasThumbnailView: true,
        onClickRedirect: {
          hasClickEvent: true,
          path: "/merchants/detail:?merchant_id=",
          key: "merchant_id",
        },
      },
      category: {
        name: "category",
        logo: "icon",
        onClickRedirect: {
          hasClickEvent: false,
          path: null,
          key: "category_id",
        },
      },
    },
    alternate_value: {
      deal_provider: {
        0: "DISH",
        10: "Entertainment.com",
      },
      offertype: {
        SINGLE_COUPON: "Single Coupon",
        MULTI_COUPON: "Multi Coupon",
      },
      redemption: {
        CLICK: "Online",
        MOBILE: "In-Store",
      },
    },
    provider: {
      key: "deal_provider_id",
      value: 0,
    },
  },
  featured_deals: {
    id: "offer",
    header_mapper: {
      deal_active: "Active",
      is_featured: "Featured",
      merchantbgimage: "Image",
      category: "Category",
      dbasortname: "Merchant",
      discountvaluetext: "Deal",
      offer: "Deal ID",
      createddate: "Created",
      expiredate: "Expires",
    },
    csv_map: {
      offer: "Deal ID",
      deal_active: "Active",
      is_featured: "Featured",
      category: "Category Name",
      category_id: "Category ID",
      dbasortname: "Merchant Name",
      merchant_id: "Merchant ID",
      discountvaluetext: "Deal",
      offertextlong: "Deal Description",
      createddate: "Created",
      expiredate: "Expires",
    },
    date: ["createddate", "expiredate"],
    toggleKey: ["is_featured", "deal_active"],
    iconLabels: {
      category: {
        name: "category",
        logo: "category_icon",
        onClickRedirect: {
          hasClickEvent: false,
          path: null,
          key: "category_id",
        },
      },
      dbasortname: {
        name: "dbasortname",
        logo: "logourl",
        hasThumbnailView: true,
        hasClickEvent: true,
        onClickRedirect: {
          hasClickEvent: true,
          path: "/merchants/detail:?merchant_id=",
          key: "merchant_id",
        },
      },
    },
    image: ["merchantbgimage"],
    provider: {
      key: "deal_provider",
      value: 0,
    },
    filename: "FEATURED-DEALS",
  },
  merchants: {
    header_mapper: {
      merchant_active: "Active",
      dbasortname: "Name",
      merchant_id: "Merchant ID",
      category: "Category",
      subcategory: "Sub Category",
      cuisinetype: "Cuisine Type",
      locationcount: "Locations(#)",
      redemptions: "Redemptions",
      merchant_added_date: "Created",
    },
    details_screen: {
      details: {
        merchant_id: "Merchant ID",
        dbasortname: "Name",
        category: "Category",
        subcategory: "First Category",
        cuisinetype: "Second Category",
        logourl: "Logo",
        address: "Corporate Address",
        corp_phone: "Phone",
        merchant_active: "Active",
      },
      deals: {
        id: "offer",
        header_mapper: {
          deal_active: "Active",
          is_featured: "Featured",
          offertextshort: "Deal",
          offer: "Deal ID",
          redemption: "Type",
          createddate: "Created",
          expiredate: "Expires",
        },
        csv_map: {
          offer: "Deal ID",
          offertextshort: "Deal Name",
          deal_active: "Active",
          is_featured: "Featured",
          offertextshort: "Deal Description",
          offer_dbasortname: "Merchant",
          offer_merchant_id: "Merchant ID",
          createddate: "Created",
          expiredate: "Expires",
        },
        date: ["createddate", "expiredate"],
        toggleKey: ["is_featured", "deal_active"],
        provider: {
          key: "deal_provider",
          value: 0,
        },
        alternate_value: {
          redemption: {
            CLICK: "Online",
            MOBILE: "In-Store",
          },
        },
      },
      locations: {
        id: "redemptionlocation",
        header_mapper: {
          merchant_location_active: "Active",
          address: "Address",
          city: "City",
          state: "State",
          redemptionlocation: "Location ID",
          merchant_location_added_date: "Created",
        },
        csv_map: {
          redemptionlocation: "Redemption Location ID",
          merchant_location_active: "Active",
          merchant_id: "Merchant ID",
          dbasortname: "Merchant",
          address: "Address",
          city: "City",
          state: "State",
          zipcode: "Zip Code",
          country: "Country",
          phone: "Phone",
          latitude: "Latitude",
          longitude: "Longitude",
          google_id: "Google Place ID",
          location_popularity_index: "Location Popularity Index",
          merchant_location_added_date: "Added Date",
        },
        toggleKey: ["merchant_location_active"],
        date: ["merchant_location_added_date"],
        provider: {
          key: "deal_provider",
          value: 0,
        },
      },
    },
    id: "merchant_id",
    second_id: "redemptionlocation",
    toggleKey: ["merchant_active"],
    iconLabels: {
      dbasortname: { name: "dbasortname", logo: "logourl", hasThumbnailView: true },
      category_name: { name: "category_name", logo: "icon" },
    },
    date: ["merchant_added_date", "merchant_location_added_date"],
    provider: {
      key: "deal_provider_id",
      value: 0,
    },
  },
  categories: {
    header_mapper: {
      category_active: "Active",
      category_id: "ID",
      category_name: "Name",
      display_name: "Display Name",
      parent_category: "Parent Category",
      display_order: "Display Order",
      category_type: "Type",
    },
    category_mapper: {
      maincategory: "Main Category",
      subcategory: "Sub Category",
      detailcategory: "Detail Category",
      cuisinetype: "Cuisine Type",
      null: "Main Category",
    },
    filter: "category_type",
    id: "category_id",
    toggleKey: ["category_active"],
    inputField: "display_order",
    iconLabels: {
      category_name: { name: "category_name", logo: "icon" },
    },
  },
  rewards: {
    header_mapper: {
      appuserid: "App User ID",
      provider: "Provider",
      itemname: "Item Name",
      deliverytype: "Category",
      inserttime: "Created Date",
      eventtype: "Status",
      rewards: "Points",
    },
    csv_map: {
      eventuniqueid: "Action ID",
      provideractionid: "Provider Action ID",
      appuserid: "App User ID",
      provider: "Provider",
      itemname: "Item Name",
      deliverytype: "Category",
      timeformatted: "Created Date",
      eventtype: "Status",
      rewards: "Points",
    },
    date: ["inserttime"],
    id: "eventuniqueid",
    no_column_sort: ["appuserid"],
    points: {
      rewards: {
        sign: "eventtype",
        value: "rewards",
        provider: "provider",
      },
    },
    filename: "EARN-COINS",
  },
  import: {
    order: [
      "execution_id",
      "state_name",
      "triggersource",
      "source",
      "status",
      "numberofoffers",
      "numberofmerchants",
      "numberofcitystates",
      "execution_date",
      "execution_time",
      "downloadfile",
    ],
    header_mapper: {
      execution_id: "ID",
      state_name: "Name",
      triggersource: "Import Type",
      source: "Source",
      status: "Status",
      numberofoffers: "Total Deals",
      numberofmerchants: "Total Merchants",
      numberofcitystates: "Total City/States",
      execution_date: "Date",
    },
  },
};

export const TABLE_CONFIG = {
  ROWS_PER_PAGE_OPTIONS: [25, 50, 100, 250],
};
