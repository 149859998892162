import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { AutoComplete, DatePicker, Dropdown, Loader } from "rsuite";
import Toggle from "./toggle";

const ModalStyles = makeStyles(theme => ({
  container: {
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    width: "100%",
  },
  optionLabel: {
    fontFamily: "Roboto",
    fontWeight: 500,
    // fontSize: "16px",
    margin: 5,
    color: "#7F7F7F",
    display: "flex",
  },
  userInput: {
    "&::placeholder": {
      color: "#9397AA !important",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginTop: 5,
    fontFamily: "Roboto",
    color: "#414243",
    // fontSize: "16px",
    fontWeight: 500,
    paddingLeft: 16,
    border: 0,
    borderRadius: 3,
    backgroundColor: "#EEF1F6",
    outline: "none",
    height: 36,
    width: "100%",
    "& input": {
      marginTop: 5,
      fontFamily: "Roboto",
      color: "#414243",
      // fontSize: "16px",
      fontWeight: 500,
      paddingLeft: 16,
      border: 0,
      borderRadius: 3,
      backgroundColor: "#EEF1F6",
      outline: "none",
      height: 36,
      width: "100%",
    },
  },
  textArea: {
    marginTop: 5,
    fontFamily: "Roboto",
    color: "#414243",
    // fontSize: "16px",
    fontWeight: 500,
    paddingLeft: 16,
    paddingTop: 9,
    border: 0,
    borderRadius: 3,
    backgroundColor: "#EEF1F6",
    outline: "none",
    height: 50,
    width: "100%",
    resize: "none",
  },
  dropdown: {
    marginTop: 5,
    width: "100%",
    "& a": {
      width: "100%",
    },
    "& ul": {
      width: "100%",
    },
  },
  dropdownMenu: {
    height: 100,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  borderBottomRed: {
    borderBottom: `2px solid red`,
  },
  readOnlyValue: {
    fontFamily: "Roboto",
    color: "#414243",
    fontSize: 16,
    fontWeight: 500,
    margin: 5,
    padding: "7px 0px 5px 7px ",
    overflowWrap: "break-word",
  },
}));

const handleChange = (val, handler) => {
  handler(val);
};
// const useStyles = ModalStyles();
const setInputError = (e, cssClass) => {
  if (e.target.value === null || e.target.value === undefined || e.target.value === "") {
    e.target.style.borderBottom = "2px solid red";
  }
};

const resetInputError = (e, cssClass) => {
  e.target.style.borderBottom = "0px";
};

export const SetLabelInput = props => {
  const classes = ModalStyles();
  return (
    <div
      className={classes.container}
      style={
        props.hasOwnProperty("inline")
          ? { flexDirection: "row", width: props.width, alignItems: "center", justifyContent: "space-between" }
          : { flexDirection: "column", width: props.width }
      }>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label className={classes.optionLabel}>
          {props.label}
          <label style={{ color: "red", marginLeft: 2, fontSize: 10 }}>
            {props.hasOwnProperty("required") && !(props.hasOwnProperty("readOnly") && props.readOnly === true) ? `*` : ``}
          </label>
        </label>
        {props.isLoading && <Loader size="xs" style={{ marginLeft: 3 }} />}
      </div>
      {props.hasOwnProperty("readOnly") && props.readOnly === true ? (
        <Typography className={classes.readOnlyValue}>{props.valueVariable}</Typography>
      ) : props.inputType === "date" ? (
        <DatePicker
          placement="rightStart"
          oneTap
          format="MMMM DD, YYYY"
          placeholder={props.placeholder}
          name={props.inputName}
          onChange={e => handleChange(e, props.onChangeHandler)}
          ranges={[]}
          style={{ width: "100%", marginRight: 10, marginTop: 5 }}
          ref={props.componentRef}
        />
      ) : props.inputType === "textarea" ? (
        <textarea
          name={props.inputName}
          onChange={e => handleChange(e.target.value, props.onChangeHandler)}
          spellcheck="true"
          className={classes.textArea}
          value={props.valueVariable}
          disabled={props.isDisabled}
          onFocus={e => props.required && resetInputError(e, classes)}
          onBlur={e => props.required && setInputError(e, classes)}
          ref={props.componentRef}
        />
      ) : props.inputType === "toggle" ? (
        <Toggle id={""} initialState={props.valueVariable} handler={(i, val) => props.onChangeHandler(val)} />
      ) : (
        <input
          name={props.inputName}
          type={props.inputType}
          onChange={e => handleChange(e.target.value, props.onChangeHandler)}
          spellcheck="true"
          placeholder={props.placeholder}
          className={classes.userInput}
          value={props.valueVariable}
          disabled={props.isDisabled}
          onFocus={e => props.required && resetInputError(e, classes)}
          onBlur={e => props.required && setInputError(e, classes)}
          min={props.hasOwnProperty("min") ? props.min : 0}
          ref={props.componentRef}
        />
      )}
    </div>
  );
};

export const SetLabelDropdown = props => {
  const classes = ModalStyles();

  return (
    <div
      className={classes.container}
      style={props.hasOwnProperty("inline") ? { flexDirection: "row", width: props.width } : { flexDirection: "column", width: props.width }}>
      <label className={classes.optionLabel}>{props.label}</label>
      {props.autocomplete && props.autocomplete.state ? (
        <AutoComplete
          data={props.itemList}
          value={props.autocomplete.value}
          onChange={e => handleChange(e, props.selectCallback)}
          menuClassName={classes.dropdownMenu}
          className={classes.userInput}
          style={{ paddingLeft: 0 }}
        />
      ) : (
        <Dropdown
          disabled={props.hasOwnProperty("disabled")}
          title={props.title}
          className={classes.dropdown}
          onSelect={e => handleChange(e, props.selectCallback)}>
          {props.itemList.map((item, index) => {
            return <Dropdown.Item eventKey={item}>{item}</Dropdown.Item>;
          })}
        </Dropdown>
      )}
    </div>
  );
};
