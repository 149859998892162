import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Tooltip, IconButton as Button, Typography } from "@material-ui/core";
import ContentCopyIcon from "../icons/content_copy.svg";
import "./custom_styles.css";
import { Input } from "rsuite";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

const LabelInput = props => {
  const componentStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "row",
      height: props.multiline ? "96px" : "48px",
      paddingLeft: 24,
      paddingRight: 24,
      alignItems: "center",
    },
    label: {
      fontFamily: "Roboto",
      color: "#7F7F7F",
      fontSize: 14,
      flex: 0.25,
      margin: "0 auto",
      textAlign: "right",
      fontWeight: 500,
      marginRight: 16,
    },
    userInput: {
      "&::placeholder": {
        color: "#9397AA !important",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
      flex: 0.6,
      margin: "0 auto",
      fontFamily: "Roboto",
      color: "#414243",
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 16,
      paddingTop: 5,
      paddingBottom: 5,
      border: 0,
      borderRadius: 3,
      backgroundColor: props.disabled ? "#FFFFFF" : "#EEF1F6",
      outline: props.isEmptyError ? "#FF0000" : "none",
      resize: "none",
      letterSpacing: 0.2,
    },
    borderBottomRed: {
      borderBottom: `2px solid red`,
    },
  }));
  const useStyles = componentStyles();

  const setInputError = e => {
    if (e.target.value === null || e.target.value === undefined || e.target.value === "") {
      e.target.classList.add(useStyles.borderBottomRed);
    }
  };

  const resetInputError = e => {
    e.target.classList.remove(useStyles.borderBottomRed);
    // if (e.target.classList.includes(componentStyles.borderBottomRed)) {
    // }
  };

  const rows = props.multiline ? "3" : "1";
  const containerStyleClass = useStyles.container.concat(props.containerStyle !== undefined ? " " + props.containerStyle : "");

  useEffect(() => {
    let element = undefined;
    let nRows = props.multiline ? "3" : "1";
    if (nRows === "1") {
      element = document.querySelector("#" + "user_input_" + props.name);
    } else {
      element = document.querySelector("#" + "text_area_" + props.name);
    }
    element.style.outline = props.isEmptyError ? "#FF0000 solid 1px" : "none";
  }, [props.isEmptyError]);

  const onUserInput = evt => {
    if (props.onInputChange !== undefined) {
      props.onInputChange(evt.target.name, evt.target.value);
    }
  };

  const copyIdToClipboard = evt => {
    let input = document.getElementById("user_input_category_id");
    input.disabled = false;
    input.focus();
    input.select();
    document.execCommand("copy");
    input.disabled = true;
  };

  const getCopyIcon = flag => {
    return (
      <>
        {flag && document.queryCommandSupported("copy") ? (
          <Tooltip title="Copy to Clipboard">
            <div style={{ height: 48, display: "flex", alignItems: "center" }}>
              <img src={ContentCopyIcon} alt="" style={{ cursor: "pointer", width: 18, height: 18 }} onClick={copyIdToClipboard} />
            </div>
          </Tooltip>
        ) : null}
      </>
    );
  };

  return (
    <Fragment>
      <div className={containerStyleClass}>
        <label className={useStyles.label}>
          {props.label}
          {props.isMandatory ? <sup style={{ color: "#FF0000" }}> *</sup> : ""}
        </label>
        {props.multiline ? (
          <textarea
            id={"text_area_" + props.name}
            type="text"
            name={props.name}
            spellCheck="true"
            rows={rows}
            placeholder={props.placeholder}
            className={useStyles.userInput}
            value={props.value}
            onChange={onUserInput}
          />
        ) : (
          <>
            <input
              id={"user_input_" + props.name}
              type={props.hasOwnProperty("type") ? props.type : "text"}
              name={props.name}
              spellCheck="true"
              placeholder={props.placeholder}
              className={useStyles.userInput}
              disabled={props.disabled}
              value={props.value}
              onChange={onUserInput}
              onBlur={props.required && setInputError}
              onFocus={props.required && resetInputError}
            />
            {getCopyIcon(props.disabled)}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default LabelInput;

const singleInputStyles = makeStyles(theme => ({
  flexDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconDiv: {
    visibility: "hidden",
    "&:hover, focus": {
      visibility: "visible",
    },
  },
  icon: {
    fontSize: 20,
    padding: 5,
    "&:hover": {
      cursor: "pointer",
      color: theme?.palette?.primary?.main,
    },
  },
}));

export const SingleInput = props => {
  const classes = singleInputStyles();
  const [initialValue, setInitialValue] = useState(props.value);
  const [value, setValue] = useState(props.value);
  const [visible, setVisibility] = useState(false);
  const [isChanged, setHasChanged] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const handleVisibility = state => {
    typeof state === "boolean" && setVisibility(state);
  };

  const handleInput = e => {
    setHasChanged(true);
    setValue(e);
  };

  const handleSubmit = () => {
    setSubmitted(true);
    props.handler(props.id, value);
    setVisibility(false);
  };

  const handleCancel = () => {
    setValue(initialValue);
    setHasChanged(false);
    setVisibility(false);
  };

  useEffect(() => {}, [visible]);
  useEffect(() => {
    if (initialValue === value) {
      setHasChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    setInitialValue(props.value);
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (initialValue === value) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
  }, [value]);

  return (
    <div
      className={classes.flexDiv}
      style={{ width: 70 }}
      onMouseOver={e => handleVisibility(true)}
      onMouseLeave={e => {
        if (!isSubmitted) {
          if (initialValue !== value) {
            setValue(initialValue);
          }
        }
        handleVisibility(false);
      }}>
      {visible ? (
        <div className={classes.flexDiv}>
          <Input value={value} onChange={handleInput} style={{ minWidth: 50, maxWidth: 70 }} min={1} type="number" />
          {isChanged && (
            <div className={`${classes.flexDiv}`} style={{ marginLeft: 2 }}>
              <Button className={classes.icon} onClick={handleSubmit}>
                <CheckCircleRoundedIcon />
              </Button>
              <Button className={classes.icon} onClick={handleCancel}>
                <CancelRoundedIcon />
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Typography style={{ paddingLeft: 10 }}>{value}</Typography>
      )}
    </div>
  );
};
