import React, { useEffect, useState } from "react";
import ErrorPage from "../components/error_page";
import { BreadcrumbsHeader } from "../components/header";
import { post } from "../webservices/apiservice";
import EndPoints from "../webservices/endpoints";
import { TableLoader as Loader } from "../components/loader";
import { PAGE_CONFIG } from "../config/page_config";
import { setPOSTPayload, TableWithPagination } from "../components/table";
import { makeStyles, Typography } from "@material-ui/core";
import UtilityBar from "../components/utility_bar";
import { useHistory } from "react-router-dom";
import { CoinsSearch } from "./modals/filter_coins";
import { Button } from "@material-ui/core";
import ExportModal from "./modals/export_modal";
import { globalStyles } from "../config/theme";
import BlueExportIcon from "../icons/export_blue.png";
import { Content, Header, Notification } from "rsuite";

const localStyles = makeStyles(theme => ({
  clearSearchResult: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const post_getUrl = EndPoints.BOOST_DOMAIN + EndPoints.REWARD_POINTS.POST_GET;

const Coins = () => {
  //constants
  const history = useHistory();
  const classes = localStyles();
  const globalClasses = globalStyles();

  //useStates
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [isLoading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    email: "",
    phone: "",
    accountNumber: "",
    filters: [],
    sortby: [],
    startRecIndex: 0,
    limitPerPage: 1000,
    sendTotalitems: true,
  });
  const [index, setIndex] = useState(0);
  const [currentObjAfterLoad, setCurrentObjAfterLoad] = useState({
    page: 0,
    rowsPerPage: null,
  });
  const [userData, setUserData] = useState({});
  const [modalExport, setModalExport] = useState(false);
  const [clearSearchInput, setClearInput] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  //functions
  const handleExportView = state => {
    typeof state === "boolean" && setModalExport(state);
  };

  const handleFilter = recievedFilterObject => {
    setFilter(recievedFilterObject);
  };

  const getResultOnParams = (payload = null) => {
    setUserData({});
    fetchData(payload);
  };

  const fetchData = async (payload = null) => {
    setLoading(true);
   
    if (EndPoints.hasLoggedIn()) {
      try {
        const response = await post(post_getUrl, payload ? payload : filter);
        setData(response.items);
        setTotalItems(response.totalItems);
        if (response.hasOwnProperty("userDetails")) {
          setUserData(prev => ({ ...prev, userDetails: response.userDetails }));
        }
        if (response.hasOwnProperty("userPoints")) {
          setUserData(prev => ({ ...prev, userPoints: response.userPoints }));
        }
        setLoading(false);
        setError(false);
      } catch (error) {
       
        console.error(error);
        Notification["error"]({
          title: "Error",
          description: error.hasOwnProperty("statusText") ? `${error.statusText}` : `Error fetching the data. Try again.`,
        });
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/points",
      });
    }
  };

  const handleGetSelected = selectedData => {
    setSelected(selectedData);
  };

  const handleLoadMore = async currentObj => {
    setIndex(index + 1);
    setFilter(prev => ({
      ...prev,
      startRecIndex: index + 1,
    }));
    setLoading(true);
    try {
      let response = await post(post_getUrl, { ...filter, startRecIndex: index + 1 });
      setData([...data, ...response.items]);
      setCurrentObjAfterLoad(currentObj);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const handleReload = async () => {
    try {
      await fetchData();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleClearFetchResult = () => {
    setClearInput(true);
    setUserData({});
    setFilter(prev => ({ ...prev, search: "", email: "", phone: "", accountNumber: "" }));
    fetchData({ ...filter, search: "", email: "", phone: "", accountNumber: "" });
    setIsSearched(false);
  };

  const properCase = inputStr => {
    let res = inputStr.replace("-", " ");
    return res[0].toUpperCase() + res.slice(1);
  };

  const handleOnSearchClick = state => {
    typeof state === "boolean" && setIsSearched(state);
  };

  //useEffects
  useEffect(() => {}, [data]);

  useEffect(() => {}, [userData]);

  useEffect(() => {
    setClearInput(false);
  }, [filter]);

  useEffect(async () => {
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        const response = await post(post_getUrl, filter);
        setData(response.items);
        setTotalItems(response.totalItems);
        if (response.hasOwnProperty("userDetails")) {
          setUserData(prev => ({ ...prev, userDetails: response.userDetails }));
        }
        if (response.hasOwnProperty("userPoints")) {
          setUserData(prev => ({ ...prev, userPoints: response.userPoints }));
        }
        setLoading(false);
        setError(false);
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/coins",
      });
    }
  }, []);

  useEffect(() => {}, [selected]);

  useEffect(() => {}, [totalItems]);

  useEffect(() => {}, [clearSearchInput]);

  useEffect(() => {}, [isSearched]);

  useEffect(() => {}, [userData]);

  //Renders
  return (
    <div className={globalClasses.mainContainer}>
      <Header className={globalClasses.headerContainer}>
        <BreadcrumbsHeader options={[{ order: 0, title: "Coins" }]} />
      </Header>
      <Content className={globalClasses.contentContainer}>
        <UtilityBar
          searchBar={
            <CoinsSearch
              filterObject={filter}
              passFilter={handleFilter}
              onSearch={getResultOnParams}
              clearSearchInput={clearSearchInput}
              onClearSearchInput={handleClearFetchResult}
              onSearchClick={handleOnSearchClick}
            />
          }
          button={
            <Button
              startIcon={<img style={{ width: 24 }} src={BlueExportIcon} />}
              className={globalClasses.buttonSecondary}
              onClick={() => handleExportView(true)}>
              Export
            </Button>
          }
        />
        {isSearched && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column", marginBottom: 5 }}>
                <span>
                  <b>Email</b> : {userData.hasOwnProperty("userDetails") ? userData.userDetails.email : filter?.email !== "" ? filter?.email : "NA"}
                </span>
                <span>
                  <b>Phone</b> : {userData.hasOwnProperty("userDetails") ? userData.userDetails.phoneNumber : filter.phone !== "" ? filter.phone : "NA"}
                </span>
                <span>
                  <b>Account Number</b> : {userData.hasOwnProperty("userDetails") ? userData.userDetails.accountNumber : filter.accountNumber !== "" ? filter.accountNumber : "NA"}
                </span>
                <span>
                  <b>APP User ID</b> : {userData.hasOwnProperty("userPoints") ? userData.userPoints.appuserid : "NA"}
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginBottom: 5 }}>
                <span>
                  <b>Total Points</b> : {userData?.userPoints?.totalpoints}
                </span>
                {Object.keys(userData).length > 0 &&
                  userData.hasOwnProperty("userPoints") &&
                  Object.entries(userData.userPoints).map(([key, value], index) => {
                    if (key !== "appuserid" && key !== "totalpoints" && key !== "credit-Error") {
                      return (
                        <span key={`userData-userPoints-${index}`}>
                          <b>{properCase(key)}</b> : {value}
                        </span>
                      );
                    }
                  })}
              </div>
            </div>
            <Typography>
              <a className={classes.clearSearchResult} onClick={handleClearFetchResult}>
                Clear search results
              </a>
            </Typography>
          </div>
        )}
        {isLoading ? (
          <Loader header={PAGE_CONFIG.rewards.header_mapper} checkbox disableRowOptions />
        ) : error ? (
          <ErrorPage data={"no-vpn"} title="Coins" />
        ) : data.length < 1 ? (
          <ErrorPage data={"empty-data"} title="Coins" />
        ) : noData ? (
          <ErrorPage data={"no-data"} title="Coins" />
        ) : (
          <TableWithPagination
            data={data}
            totalItems={totalItems}
            checkbox
            pageConfig={PAGE_CONFIG.rewards}
            disableRowOptions
            handleReload={handleReload}
            handleLoadMore={handleLoadMore}
            currentObjAfterLoad={currentObjAfterLoad}
            getSelected={handleGetSelected}
          />
        )}
      </Content>
      <ExportModal
        modal={modalExport}
        onHide={() => handleExportView(false)}
        data={data}
        selected={selected}
        pageConfig={PAGE_CONFIG.rewards}
        fetchQuery={{ url: post_getUrl, payload: { ...filter, startRecIndex: 0, limitPerPage: totalItems } }}
      />
    </div>
  );
};

export default Coins;
