import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { BreadcrumbsHeader } from "../components/header";
import { TableLoader as Loader } from "../components/loader";
import { TableWithPagination } from "../components/table";
import UtilityBar from "../components/utility_bar";
import { PAGE_CONFIG } from "../config/page_config";
import AddNewCategory from "./modals/new_edit_category";
import AddIcon from "@material-ui/icons/Add";
import { get, put, deleteRecord, batchDelete } from "../webservices/apiservice";
import EndPoints from "../webservices/endpoints";
import CategoriesFilter from "./modals/filter_categories";
import ErrorPage from "../components/error_page";
import { Notification, Header, Content } from "rsuite";
import { globalStyles } from "../config/theme";

const uri = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET + EndPoints.WITH_LIMIT + "1000";
const putUri = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.PUT;
const deleteUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.DELETE + "?category_id=";
const batchDeleteUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.BATCH_DELETE;

const Categories = () => {
  const history = useHistory();
  const classes = globalStyles();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [queryFilter, setQueryFilter] = useState([]);

  // Handlers

  const handleToggle = async (id, state) => {
    let payload = {
      category_id: id,
      category_active: state,
    };
    if (filteredData.length > 0) {
      setFilteredData(filteredData.map(item => (item.category_id === id ? { ...item, category_active: state } : item)));
      setData(data.map(item => (item.category_id === id ? { ...item, category_active: state } : item)));
    } else {
      setData(data.map(item => (item.category_id === id ? { ...item, category_active: state } : item)));
    }
    try {
      await put(putUri, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Successfully Activated Category - ${id}` : `Successfully Deactivated Category - ${id}`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: "Couldn't activate/deactivate the Category. Reloading again",
      });
      handleReload();
    }
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    onAddNewCategoryClick({ isEdit: true, data: row });
  };

  const onAddNewCategoryClick = evt => {
    setShowModal(true);
    if (evt.isEdit) {
      setIsEdit(true);
      setEditData(evt.data);
    }
  };

  const onModalHide = () => {
    setShowModal(false);
    setIsEdit(false);
    setEditData({});
  };

  const handleFilter = (filterArray, inputData = null) => {
    setQueryFilter(filterArray);
    setNoData(false);
    setFilterApplied(false);
    let temp = inputData ? inputData : data;
    (inputData ? inputData : data).map(obj => {
      filterArray.map(filt => {
        temp = temp.filterArray(filt, "display_name", ["category_id", "category_name", "parent_category", "category_type"], "category_id");
      });
    });
    temp.length > 0 ? setFilteredData(temp) : setNoData(true);
    setFilterApplied(true);
  };

  const handleDelete = async (id, row, recievedData, handler) => {
    setLoading(true);
    setNoData(false);
    try {
      await deleteRecord(deleteUrl, row.category_id);
      let dataToFind = filteredData.length > 0 ? filteredData : data;
      let index = dataToFind.findIndex(prop => prop.category_id === row.category_id);
      dataToFind.splice(index, 1);
      // dataToFind.length > 0 ? setFilteredData(dataToFind) : setNoData(true);
      // setData(dataToFind);
      setFilteredData(filteredData.filter(data => data.category_id !== row.category_id));
      setData(data.filter(rec => rec.category_id !== row.category_id));
      handler(dataToFind);
      Notification["success"]({
        title: "Deleted Successfully",
      });
      setLoading(false);
    } catch (error) {
      Notification["error"]({
        title: "Delete Unsuccessful",
      });
      setLoading(false);
    }
  };

  const handleBatchDelete = async arrayOfIds => {
    try {
      await batchDelete(batchDeleteUrl, arrayOfIds);
      Notification["success"]({
        title: "Success",
        description: `Deleted ${arrayOfIds.length} categories`,
      });
      return true;
    } catch (error) {
      Notification["error"]({
        title: "Failure",
        description: `Couldn't delete ${arrayOfIds.length} categories`,
      });
      return false;
    }
  };

  const handleSearch = filterArray => {
    handleFilter(filterArray);
  };

  const handleReload = async () => {
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        const response = await get(uri);
        setData(response);
        setLoading(false);
        setError(false);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      history.push({
        pathname: "/",
        state: "/categories",
      });
    }
  };

  const handleRowClick = (id, secondId = null) => {
    let objToPass = isFilterApplied ? filteredData.filter(item => item.category_id === id) : data.filter(item => item.category_id === id);
    if (Object.keys(objToPass).length > 0) {
      history.push({
        pathname: `/category/detail:?categoryId=${id}`,
        state: objToPass[0],
      });
    }
  };

  const getFilter = mainData => {
    setFilter([
      {
        header: "Parent Category",
        key: "category_name",
        options: ["All", ...Array.from(new Set(mainData.filterArray({ category_type: "maincategory" }).filterArray("category_name")))],
        type: "button",
      },
      {
        header: "Status",
        key: "category_active",
        options: ["All", "Active", "Inactive"],
        type: "button",
      },
      {
        header: "Category Type",
        key: "category_type",
        options: ["All", ...Array.from(new Set([...mainData.filterArray("category_type")]))],
        type: "button",
      },
    ]);
  };

  const handleUpdateDisplayOrder = async (id, value) => {
    let payload = { category_id: id, display_order: value };
    if (filteredData.length > 0) {
      setFilteredData(filteredData.map(item => (item.category_id === id ? { ...item, display_order: value } : item)));
      setData(data.map(item => (item.category_id === id ? { ...item, display_order: value } : item)));
    } else {
      setData(data.map(item => (item.category_id === id ? { ...item, display_order: value } : item)));
    }
    try {
      await put(putUri, payload);
      Notification["success"]({
        title: "Success",
        description: `Category ID: ${id} - Updated Display Order to ${value}`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Failure",
        description: "Couldn't change the order. Reloading again",
      });
      handleReload();
    }
  };

  // UseEffects

  useEffect(async () => {
    if (EndPoints.hasLoggedIn()) {
      try {
        const response = await get(uri);
        setData(response);
        getFilter(response);
        setError(false);
        setLoading(false);
      } catch (error) {
        setData([]);
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/categories",
      });
    }
    setNoData(false);
  }, []);

  useEffect(() => {
    if (queryFilter.length > 0) {
      handleFilter(queryFilter);
    }
  }, [data]);
  useEffect(() => {}, [filter]);
  useEffect(() => {}, [filteredData]);

  useEffect(() => {
    isFilterApplied && setLoading(true);
    isFilterApplied &&
      setTimeout(() => {
        setLoading(false);
      }, 100);
  }, [showModal, isEdit, editData, filteredData, noData]);

  useEffect(() => {
    if (location.pathname === "/categories") {
      if (sessionStorage.getItem("updated_category") !== null) {
        let update = JSON.parse(sessionStorage.getItem("updated_category"));
        if (filteredData.length > 0) {
          setFilteredData(filteredData.map(item => (item.category_id === update.category_id ? { ...item, ...update } : item)));
          setData(data.map(item => (item.category_id === update.category_id ? { ...item, ...update } : item)));
          sessionStorage.removeItem("updated_category");
        } else {
          setData(data.map(item => (item.category_id === update.category_id ? { ...item, ...update } : item)));
          sessionStorage.removeItem("updated_category");
        }
      }
    }
  }, [location]);

  // Render

  return (
    <div className={classes.mainContainer}>
      <Header className={classes.headerContainer}>
        <BreadcrumbsHeader options={[{ order: 0, title: "Deal Categories" }]} />
      </Header>
      <Content className={classes.contentContainer}>
        <UtilityBar
          searchBar={
            <CategoriesFilter isLoading={isLoading} filterHeader={"Categories"} data={filter} filterHandler={handleFilter} searchHandler={handleSearch} />
          }
          button={
            <Button className={classes.buttonPrimary} variant="contained" startIcon={<AddIcon />} onClick={onAddNewCategoryClick}>
              New Category
            </Button>
          }
        />
        {isLoading ? (
          <Loader header={PAGE_CONFIG.categories.header_mapper} hasEditOption checkbox />
        ) : error ? (
          <ErrorPage data={"no-vpn"} title="Category" />
        ) : noData ? (
          <ErrorPage data={"no-data"} title="Category" hasNoSubtitle={true} />
        ) : (
          <>
            <TableWithPagination
              data={filteredData.length > 0 ? filteredData : data}
              pageConfig={PAGE_CONFIG.categories}
              checkbox
              addCategoryCallback={onAddNewCategoryClick}
              handleToggle={handleToggle}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleBatchDelete={handleBatchDelete}
              handleRowClick={handleRowClick}
              handleReload={handleReload}
              handleInputEdit={handleUpdateDisplayOrder}
              // hideToolBars="left"
            />
          </>
        )}
      </Content>
      <AddNewCategory show={showModal} onModalSubmit={handleReload} onHide={onModalHide} isEdit={isEdit} editData={editData} />
    </div>
  );
};

export default Categories;
