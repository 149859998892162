import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Dropdown, Modal, Notification, Button, Loader } from "rsuite";
import Auth from "@aws-amplify/auth";
import { Storage } from "aws-amplify";
import ContentCopyIcon from "../../icons/content_copy.svg";
import EndPoints from "../../webservices/endpoints";
import GooglePlaceAutocomplete from "../../components/google_autocomplete";

import "../../custom_styles/modal_custom_styles.css";
import { Tooltip } from "@material-ui/core";
import { get, post } from "../../webservices/apiservice";

var activeSessionToken = "";

const modalStyles = makeStyles({
  optionLabel: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 14,
    color: "#545964",
    letterSpacing: 0.25,
  },
  userInput: {
    "&::placeholder": {
      color: "#9397AA !important",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginTop: 5,
    fontFamily: "Roboto",
    color: "#414243",
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 16,
    border: 0,
    borderRadius: 3,
    backgroundColor: "#EEF1F6",
    outline: "none",
    height: 36,
    width: "100%",
  },
  textArea: {
    "&::placeholder": {
      color: "#9397AA !important",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginTop: 5,
    fontFamily: "Roboto",
    color: "#414243",
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 16,
    paddingTop: 9,
    border: 0,
    borderRadius: 3,
    backgroundColor: "#EEF1F6",
    outline: "none",
    height: 180,
    width: "100%",
    resize: "none",
  },
  dropdown: {
    marginTop: 5,
    width: "100%",
    "& a": {
      width: "100%",
    },
    "& ul": {
      width: "100%",
    },
  },
});

const MerchantModal = props => {
  const [showModal, setShowModal] = useState(props.show);

  const [merchantName, setMerchantName] = useState("");
  const [merchantAddress, setMerchantAddress] = useState("");
  const [merchantCity, setMerchantCity] = useState("");
  const [merchantCountry, setMerchantCountry] = useState("");
  const [merchantState, setMerchantState] = useState("");
  const [merchantZipCode, setMerchantZipCode] = useState("");
  const [merchantPhone, setMerchantPhone] = useState("");
  const [merchantGooglePlaceId, setMerchantGooglePlaceId] = useState("");
  const [merchantCategory, setMerchantCategory] = useState("None");
  const [merchantSubCategory, setMerchantSubCategory] = useState("None");
  // const [merchantDetailCategory, setMerchantDetailCategory] = useState("None");
  const [merchantCuisineType, setMerchantCuisineType] = useState("None");
  const [merchantDescription, setMerchantDescription] = useState("");
  const [merchantLogoPath, setMerchantLogoPath] = useState("");

  const [rootCategories, setRootCategories] = useState(["None"]);
  const [subCategories, setSubCategories] = useState(["None"]);
  // const [detailCategories, setDetailCategories] = useState(["None"]);
  const [cuisineTypes, setCuisineTypes] = useState(["None"]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [merchantLogoFile, setMerchantLogoFile] = useState(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [isSubmitting, setSubmitStatus] = useState(false);

  const [googleAddressPlaceLoader, setGooglePlaceAddressLoader] = useState(false);

  // useEffect(() => {
  //   updateSessionToken();
  // }, []);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleForEdit = data => {
    setMerchantName(data.dbasortname);
    setMerchantAddress(data.corp_address);
    setMerchantCity(data.corp_city);
    setMerchantCountry(data.corp_country);
    setMerchantState(data.corp_state);
    setMerchantZipCode(data.corp_zipcode);
    setMerchantPhone(data.corp_phone);
    setMerchantGooglePlaceId(data.corp_google_id);

    data.category !== null && data.category !== "" ? setMerchantCategory(data.category) : setMerchantCategory("None");
    data.subcategory !== null && data.subcategory !== "" ? setMerchantSubCategory(data.subcategory) : setMerchantSubCategory("None");
    // data.detailcategory !== null && data.detailcategory !== ""
    //   ? setMerchantDetailCategory(data.detailcategory)
    //   : setMerchantDetailCategory("None");
    data.cuisinetype !== null && data.cuisinetype !== "" ? setMerchantCuisineType(data.cuisinetype) : setMerchantCuisineType("None");

    setMerchantDescription(data.stubcopy);
    setMerchantLogoPath(data.logourl);
  };
  useEffect(() => {
    if (props.merchantData !== undefined) {
      handleForEdit(props.merchantData);
    }
  }, [props.merchantData]);

  useEffect(() => {}, [isSubmitting]);
  useEffect(() => {}, [googleAddressPlaceLoader]);
  useEffect(() => {}, [merchantAddress]);

  const useStyles = modalStyles();

  const getRootCategories = async () => {
    let getRootcategoryUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET_ROOT_CATEGORY;

    try {
      let response = await get(getRootcategoryUrl);
      let mainCategories = [];
      response.map((item, index) => {
        if ((item.parent_category === null || item.parent_category === "") && item.category_name !== null && item.category_name !== "Online") {
          mainCategories.push(item.category_name);
        }
        return index;
      });

      setRootCategories(mainCategories);
    } catch (error) {
      console.log(error);
    }

    // EndPoints.getRequestHeader().Authorization !== "" &&
    //   fetch(getRootcategoryUrl, {
    //     headers: EndPoints.getRequestHeader(),
    //   })
    //     .then(response => response.json())
    //     .then(data => {
    //       let mainCategories = [];
    //       data.map((item, index) => {
    //         if ((item.parent_category === null || item.parent_category === "") && item.category_name !== null && item.category_name !== "Online") {
    //           mainCategories.push(item.category_name);
    //         }
    //         return index;
    //       });

    //       setRootCategories(mainCategories);
    //     });
  };

  const getLabelInput = (label, inputName, placeholder, valueVariable, isDisabled, isMandatory, isLoading = false, rest = {}) => {
    return (
      <div style={{ marginRight: 16, marginTop: 8, marginBottom: 8, flex: 1, alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className={useStyles.optionLabel}>
            {label}
            {isMandatory === true ? <sup style={{ color: "#FF0000" }}> *</sup> : ""}
          </label>
          {isLoading && <Loader size="xs" style={{ marginLeft: 3 }} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <input
            onChange={onUserInput}
            type="text"
            spellCheck="true"
            placeholder={placeholder}
            className={useStyles.userInput}
            name={inputName}
            value={valueVariable}
            disabled={isDisabled}
            {...rest}
          />
          {getCopyIcon(isDisabled)}
        </div>
      </div>
    );
  };

  const getLabelDropdown = (label, title, itemList, selectCallback, isMandatory) => {
    return (
      <div style={{ marginRight: 16, marginTop: 16, flex: 1 }}>
        <label className={useStyles.optionLabel}>
          {label}
          {isMandatory === true ? <sup style={{ color: "#FF0000" }}> *</sup> : ""}
        </label>
        <br />
        <Dropdown title={title} onSelect={selectCallback} className={useStyles.dropdown}>
          {itemList.map((item, index) => {
            return (
              <Dropdown.Item key={index} eventKey={item}>
                {item}
              </Dropdown.Item>
            );
          })}
        </Dropdown>
      </div>
    );
  };

  const getLabelTextArea = (label, inputName, placeholder, valueVariable, isDisabled) => {
    return (
      <div style={{ marginRight: 16, marginTop: 16, flex: 1 }}>
        <label className={useStyles.optionLabel}>{label}</label>
        <br />
        <textarea
          type="text"
          spellCheck="true"
          placeholder={placeholder}
          rows="5"
          name={inputName}
          className={useStyles.textArea}
          onChange={onUserInput}
          value={valueVariable}
        />
      </div>
    );
  };

  const getLabelIcon = (label, iconpath) => {
    return (
      <div style={{ marginRight: 16, marginTop: 16, flex: 1 }}>
        <label className={useStyles.optionLabel}>{label}</label>
        <br />
        <div
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          {iconpath === "" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <input id="merchant_logo_upload" type="file" accept="image/*" onChange={onImageSelection} />
              <button
                disabled={merchantLogoFile === null || uploadInProgress}
                onClick={uploadToS3}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 3,
                }}>
                {uploadInProgress ? "Uploading..." : "Upload"}
              </button>
            </div>
          ) : (
            <div
              style={{
                marginTop: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <img src={getLogoUrl()} alt="" style={{ width: 128, height: "auto" }} />
              <label
                style={{
                  marginLeft: 32,
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#3489ec",
                  cursor: "pointer",
                }}
                onClick={deleteMerchantLogo}>
                Remove Logo
              </label>
            </div>
          )}
        </div>

        {/* <div style={{ marginTop: 16 }}>
          <label style={{}}>Logo URL</label>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
            height: 36,
            width: "100%",
            alignItems: "center",
          }}>
          <input type="checkbox" onChange={onCheckBoxToggle} style={{ width: 18, height: 18 }} />
          <input
            value={getLogoUrl()}
            type="text"
            disabled={!isChecked}
            onChange={onLogoUrlInput}
            style={{
              marginLeft: 16,
              border: "none",
              backgroundColor: "#EEF1F6",
              height: "100%",
              borderRadius: 3,
              width: "100%",
              paddingLeft: 16,
              outline: "none",
            }}
          />
        </div> */}
      </div>
    );
  };

  const getCopyIcon = flag => {
    return (
      <>
        {flag && document.queryCommandSupported("copy") ? (
          <Tooltip title="Copy to Clipboard">
            <div
              style={{
                height: 36,
                backgroundColor: "#EEF1F6",
                display: "flex",
                alignItems: "center",
                marginTop: 5,
                padding: 4,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
              }}>
              <img src={ContentCopyIcon} alt="" style={{ cursor: "pointer", width: 18, height: 18 }} onClick={copyIdToClipboard} />
            </div>
          </Tooltip>
        ) : null}
      </>
    );
  };

  const onCheckBoxToggle = evt => {
    setIsChecked(evt.target.checked);
  };

  const onLogoUrlInput = evt => {
    setMerchantLogoPath(evt.target.value);
    updateUnsavedChangesStatus();
  };

  const copyIdToClipboard = evt => {
    let input = document.getElementsByName("merchant_id")[0];
    input.disabled = false;
    input.focus();
    input.select();
    document.execCommand("copy");
    input.disabled = true;
  };

  const deleteMerchantLogo = () => {
    if (window.confirm("Are you sure to remove logo?")) {
      setMerchantLogoPath("");
      updateUnsavedChangesStatus();
    }
  };

  const onMainCategorySelect = name => {
    setMerchantCategory(name);
    getChildCategories(name);
    updateUnsavedChangesStatus();
  };

  const onSubCategorySelect = name => {
    setMerchantSubCategory(name);
    updateUnsavedChangesStatus();
  };

  // const onDetailCategorySelect = name => {
  //   // setMerchantDetailCategory(name);
  //   updateUnsavedChangesStatus();
  // };

  const onCuisineTypeSelect = name => {
    setMerchantCuisineType(name);
    updateUnsavedChangesStatus();
  };

  const validateNumber = input => {
    let numberAscii = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    let specialCharacterAscii = [32, 40, 41, 43, 45];
    let res = "";
    let numberCount = 0;
    for (let i = 0; i < input.length; i++) {
      if (numberAscii.includes(input.charCodeAt(i))) {
        numberCount++;
      }
    }
    for (let i = 0; i < input.length; i++) {
      if (numberAscii.includes(input.charCodeAt(i)) || specialCharacterAscii.includes(input.charCodeAt(i))) {
        res += input[i];
      }
    }
    return {
      count: numberCount,
      result: res.toString(),
    };
  };

  const onUserInput = evt => {
    let inputVal = evt.target.value;

    switch (evt.target.name) {
      case "merchant_name":
        setMerchantName(inputVal);
        break;
      case "merchant_address":
        setMerchantAddress(inputVal);
        break;
      case "merchant_city":
        setMerchantCity(inputVal);
        break;
      case "merchant_country":
        setMerchantCountry(inputVal);
        break;
      case "merchant_state":
        setMerchantState(inputVal);
        break;
      case "merchant_zip":
        inputVal = inputVal.replace(/\D/g, "").substr(0, 6);
        setMerchantZipCode(inputVal);
        break;
      case "merchant_phone":
        setMerchantPhone(validateNumber(inputVal).result);
        break;
      case "merchant_place_id":
        setMerchantGooglePlaceId(inputVal);
        break;
      case "merchant_description":
        setMerchantDescription(inputVal);
        break;
      default:
        return null;
    }
    updateUnsavedChangesStatus();
  };

  const onHide = () => {
    setShowModal(false);

    setMerchantName("");
    setMerchantAddress("");
    setMerchantCity("");
    setMerchantCountry("");
    setMerchantState("");
    setMerchantZipCode("");
    setMerchantPhone("");
    setMerchantGooglePlaceId("");

    setRootCategories(["None"]);
    setSubCategories(["None"]);
    // setDetailCategories(["None"]);
    setCuisineTypes(["None"]);

    setMerchantCategory("None");
    setMerchantSubCategory("None");
    // setMerchantDetailCategory("None");
    setMerchantCuisineType("None");

    setMerchantDescription("");
    setMerchantLogoPath("");

    setHasUnsavedChanges(false);

    setMerchantLogoFile(null);
    setUploadInProgress(false);

    setHasError(false);

    activeSessionToken = "";

    props.onHide();
  };

  const updateUnsavedChangesStatus = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  const checkUnsavedChanges = () => {
    let retVal = false;
    if (hasUnsavedChanges) {
      retVal = window.confirm("You have unsaved changes. Are sure to discard the changes?");
    }
    return retVal;
  };

  const onCancel = () => {
    if (checkUnsavedChanges() || !hasUnsavedChanges) {
      onHide();
    }
  };

  const onSave = async (onUpload = false, status = "", logoRelativePath = "") => {
    if (!hasUnsavedChanges) {
      onHide();
      return;
    } else if (merchantName === "" || merchantCategory === "None") {
      setHasError(true);
      return;
    }

    if (merchantLogoFile !== null && status !== "uploadComplete") {
      uploadToS3(true);
      return;
    }

    setSubmitStatus(true);

    let url = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.POST_CREATE;
    let postData = {
      dbasortname: merchantName,
      corp_address: merchantAddress,
      corp_city: merchantCity,
      corp_country: merchantCountry,
      corp_state: merchantState,
      corp_zipcode: merchantZipCode,
      corp_phone: merchantPhone,
      corp_google_id: merchantGooglePlaceId,
      category: merchantCategory,
      subcategory: merchantSubCategory,
      // detailcategory: merchantDetailCategory,
      cuisinetype: merchantCuisineType,
      stubcopy: merchantDescription,
      logourl: logoRelativePath !== "" ? EndPoints.CLOUD_FRONT + "public/" + logoRelativePath : merchantLogoPath,
    };

    if (props.merchantData !== undefined) {
      postData["merchant_id"] = props.merchantData.merchant_id;
    }

    try {
      await post(url, postData);
      Notification["success"]({
        title: "Success",
        description: props.merchantData !== undefined ? `Updated ${merchantName} successfully` : `Created ${merchantName} successfully`,
      });
      if (props.hasOwnProperty("onEdit")) {
        props.onEdit(postData);
      } else if (props.hasOwnProperty("onCreate")) {
        props.onCreate(true);
      }
      setSubmitStatus(false);
      onHide();
    } catch (error) {
      setSubmitStatus(false);
      Notification["error"]({
        title: "Failure",
        description: props.merchantData !== undefined ? `Failed to update ${merchantName}` : `Couldn't create ${merchantName}`,
      });
    }

    // fetch(url, {
    //   method: "POST",
    //   headers: EndPoints.getRequestHeader(),
    //   body: JSON.stringify(postData),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     if (!data.hasOwnProperty("statusCode") || (data.hasOwnProperty("statusCode") && data.statusCode === 200)) {
    //       Notification["success"]({
    //         title: "Success",
    //         description: props.merchantData !== undefined ? `Updated ${merchantName} successfully` : `Created ${merchantName} successfully`,
    //       });
    //       if (props.hasOwnProperty("onEdit")) {
    //         props.onEdit(postData);
    //       } else if (props.hasOwnProperty("onCreate")) {
    //         props.onCreate(true);
    //       }
    //       setSubmitStatus(false);
    //       onHide();
    //     } else if (data.hasOwnProperty("statusCode") && data.statusCode !== 200) {
    //       setSubmitStatus(false);
    //       Notification["error"]({
    //         title: "Failure",
    //         description: props.merchantData !== undefined ? `Failed to update ${merchantName}` : `Couldn't create ${merchantName}`,
    //       });
    //     }
    //   })
    //   .catch(err => {
    //     setSubmitStatus(false);
    //     Notification["error"]({
    //       title: "Failure",
    //       description: props.merchantData !== undefined ? `Failed to update ${merchantName}` : `Couldn't create ${merchantName}`,
    //     });
    //   });
  };

  const getChildCategories = async rootName => {
    let url = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET_SUB_CHILD_CATEGORY + "?category=" + rootName;

    try {
      let response = await get(url);
      let subCategories = ["None"];
      let detailCategories = ["None"];
      let cuisineTypes = ["None"];
      response.map((item, index) => {
        if (item.category_type !== null && item.category_type.toLowerCase() === "subcategory") {
          subCategories.push(item.category_name);
        } else if (item.category_type !== null && item.category_type.toLowerCase() === "detailcategory") {
          detailCategories.push(item.category_name);
        } else if (item.category_type !== null && item.category_type.toLowerCase() === "cuisinetype") {
          cuisineTypes.push(item.category_name);
        }
        return index;
      });

      setSubCategories([...new Set(subCategories)]);
      // setDetailCategories([...new Set(detailCategories)]);
      setCuisineTypes([...new Set(cuisineTypes)]);
    } catch (error) {
      console.log("GET CHILD CATEGORY ,", error);
    }

    // fetch(url, {
    //   headers: EndPoints.getRequestHeader(),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     let subCategories = ["None"];
    //     let detailCategories = ["None"];
    //     let cuisineTypes = ["None"];

    //     data.map((item, index) => {
    //       if (item.category_type !== null && item.category_type.toLowerCase() === "subcategory") {
    //         subCategories.push(item.category_name);
    //       } else if (item.category_type !== null && item.category_type.toLowerCase() === "detailcategory") {
    //         detailCategories.push(item.category_name);
    //       } else if (item.category_type !== null && item.category_type.toLowerCase() === "cuisinetype") {
    //         cuisineTypes.push(item.category_name);
    //       }
    //       return index;
    //     });

    //     setSubCategories([...new Set(subCategories)]);
    //     // setDetailCategories([...new Set(detailCategories)]);
    //     setCuisineTypes([...new Set(cuisineTypes)]);
    //   });
  };

  const onImageSelection = evt => {
    if (evt.target.files !== undefined) {
      setHasUnsavedChanges(true);
      setMerchantLogoFile(evt.target.files[0]);
    }
  };

  const uploadToS3 = evt => {
    Auth.currentUserCredentials().then(auth => {
      setUploadInProgress(true);
      Storage.put("boostone/merchant/images/" + new Date().getTime() + "/" + merchantLogoFile.name, merchantLogoFile, {
        level: "public",
        contentType: merchantLogoFile.type,
      })
        .then(result => {
          setMerchantLogoPath(result["key"]);
          setMerchantLogoFile(null);
          setUploadInProgress(false);
          if (evt === true) {
            onSave(true, "uploadComplete", result["key"]);
          }
        })
        .catch(error => {
          setUploadInProgress(false);
        });
    });
  };

  const getLogoUrl = () => {
    let logoUrl =
      merchantLogoPath !== "" && merchantLogoPath !== undefined && !merchantLogoPath.includes("http")
        ? EndPoints.CLOUD_FRONT + "public/" + merchantLogoPath
        : merchantLogoPath;
    return logoUrl;
  };

  // const getLogoRelativePath = value => {
  //   let relativePath = value;
  //   if (value !== "" && value.includes("hhtp")) {
  //     relativePath = value.replace(/.*\/\/[^\/]*/, "");
  //     relativePath = relativePath.substr(1, relativePath.length);
  //   }
  //   return relativePath;
  // };

  const getLocation = (recievedAddressObject, onlyAddress = false) => {
    if (onlyAddress) {
      setMerchantAddress(recievedAddressObject.address);
    } else {
      setMerchantAddress(recievedAddressObject.address);
      setMerchantCity(recievedAddressObject.city);
      setMerchantState(recievedAddressObject.state);
      setMerchantZipCode(recievedAddressObject.postal_code);
      setMerchantCountry(recievedAddressObject.country);
      setMerchantPhone(recievedAddressObject.phone);
      setMerchantGooglePlaceId(recievedAddressObject.place_id);
    }
    setHasUnsavedChanges(true);
  };

  const handleGooglePlaceAddressLoader = state => {
    typeof state === "boolean" && setGooglePlaceAddressLoader(state);
  };

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={onCancel}
      onEntered={() => {
        if (props.merchantData !== undefined) {
          handleForEdit(props.merchantData);
        }
        getRootCategories();
      }}>
      <Modal.Header>
        <Modal.Title>{props.merchantData !== undefined ? "Edit Merchant" : "Create New Merchant"}</Modal.Title>
      </Modal.Header>
      <label style={{ marginTop: 4, position: "absolute", right: 0, paddingRight: 16, fontFamily: "Roboto", fontWeight: 400, fontSize: 12, color: "#FF0000" }}>
        (* marked fields are mandatory)
      </label>

      <div style={{ alignSelf: "center", paddingTop: 25 }}>
        {props.merchantData !== undefined ? getLabelInput("Merchant ID", "merchant_id", "1234", props.merchantData.merchant_id, true) : null}
        {getLabelInput("Merchant Name", "merchant_name", "Shield's Pizza", merchantName, false, true)}

        <div style={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
          <GooglePlaceAutocomplete
            value={props.merchantData !== undefined && props.merchantData.corp_address !== null ? props.merchantData.corp_address : ""}
            label={"Corporate Address"}
            placeholder={"1476 W Maple Rd"}
            prefix={[merchantName, "corporate office"]}
            passDetails={getLocation}
            style={useStyles}
            handleLoadUntilResult={handleGooglePlaceAddressLoader}
          />
          {/* {getLabelInput("Address", "merchant_address", "1476 W Maple Rd", merchantAddress, false)} */}
          {getLabelInput("City", "merchant_city", "Troy", merchantCity, false, false, googleAddressPlaceLoader)}
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {getLabelInput("Country", "merchant_country", "United States", merchantCountry, false, false, googleAddressPlaceLoader)}
          {getLabelInput("State", "merchant_state", "MI", merchantState, false, false, googleAddressPlaceLoader)}
          {getLabelInput("Zip Code", "merchant_zip", "48084", merchantZipCode, false, false, googleAddressPlaceLoader)}
          {getLabelInput("Phone", "merchant_phone", "+1 (248) 637-3131", merchantPhone, false, false, googleAddressPlaceLoader, { maxLength: 20 })}
        </div>

        {/* {getLabelInput("Google Place ID", "merchant_place_id", "ChIJdweR1oPGJIgRZhBRQzAsGDs", merchantGooglePlaceId, false, false, googleAddressPlaceLoader)} */}

        <div style={{ display: "flex", flexDirection: "row" }}>
          {getLabelDropdown("Category", merchantCategory, rootCategories, onMainCategorySelect, true)}
          {getLabelDropdown("Sub Category", merchantSubCategory, subCategories, onSubCategorySelect, true)}
          {/* {getLabelDropdown(
            "Detail Category",
            merchantDetailCategory,
            detailCategories,
            onDetailCategorySelect
          )} */}
          {getLabelDropdown("Cuisine Type", merchantCuisineType, cuisineTypes, onCuisineTypeSelect)}
        </div>

        {getLabelTextArea("Description", "merchant_description", "Description", merchantDescription, false)}

        {getLabelIcon("Logo", merchantLogoPath)}

        {hasError ? (
          <label style={{ marginTop: 16, textAlign: "right", display: "block", color: "#FF0000", fontFamily: "Roboto", fontWeight: 500, fontSize: 12 }}>
            Please fill all mandatory fields
          </label>
        ) : null}

        <div style={{ marginTop: hasError ? 4 : 24, height: 48, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <Button
            onClick={onCancel}
            style={{
              display: "flex",
              alignItems: "center",
              width: 100,
              height: 42,
              borderColor: "#579EEF",
              borderRadius: 3,
              borderStyle: "solid",
              borderWidth: 0.5,
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
            }}>
            <label style={{ margin: "auto", fontSize: 16, fontFamily: "Roboto", color: "#579EEF", fontWeight: 500, cursor: "pointer" }}>Cancel</label>
          </Button>
          <Button
            disabled={uploadInProgress}
            onClick={onSave}
            loading={isSubmitting}
            style={{
              cursor: "pointer",
              display: "flex",
              width: 100,
              height: 42,
              borderColor: "#579EEF",
              borderRadius: 3,
              borderStyle: "solid",
              borderWidth: 0.5,
              backgroundColor: "#579EEF",
            }}>
            {!isSubmitting && (
              <label
                style={{ cursor: "pointer", margin: "auto", fontSize: 16, fontFamily: "Roboto", color: "#FFFFFF", fontWeight: 500, verticalAlign: "middle" }}>
                Save
              </label>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MerchantModal;
