const DOMAINS = {
  dev: "https://perks-api-dev.rw.dish.com",
  int: "https://perks-api-int.rw.dish.com",
  test: "https://perks-api-test.rw.dish.com",
  prod: "https://perks-api.rw.dish.com",
};

const BOOST_DOMAIN = process.env.REACT_APP_STAGE ? DOMAINS[process.env.REACT_APP_STAGE] : DOMAINS["dev"];

const CLOUD_FRONT_URLS = {
  dev: "https://d1o8suv774oy3w.cloudfront.net/",
  int: "https://dzm2x97p3ve6x.cloudfront.net/",
  test: "https://dznviz0fujrym.cloudfront.net/",
  prod: "https://dtx7g8y7smfv3.cloudfront.net/",
};

const CLOUD_FRONT = process.env.REACT_APP_STAGE ? CLOUD_FRONT_URLS[process.env.REACT_APP_STAGE] : CLOUD_FRONT_URLS["dev"];

const LOGIN_SERVICES = {
  dev: "https://dishwireless-perks-admin-dev.auth.us-west-2.amazoncognito.com/login",
  int: "https://dishwireless-perks-admin-int.auth.us-west-2.amazoncognito.com/login",
  test: "https://dishwireless-perks-admin-test.auth.us-west-2.amazoncognito.com/login",
};

const LOGIN = process.env.REACT_APP_STAGE ? LOGIN_SERVICES[process.env.REACT_APP_STAGE] : LOGIN_SERVICES["dev"];

const S3_UPLOAD_PATH = {
  category: "/boostone/category/images/",
  deals: "boostone/deals/images/",
  merchant: {
    images: "boostone/merchant/images/",
    creatives: "boostone/merchant/creatives/",
  },
};

const xapikey = process.env.REACT_APP_STAGE === "prod" ? "vNROc644ZMRJxfG9tq7t697KIVqIrfY3PysVz8U4" : "UOKtMLUvnD50eEwq9a0cfKTQwUA0VDP5Lo5OR5t4";
let HEADERS = {
  "Content-Type": "application/json",
  "x-api-key": xapikey,
  Authorization: "",
};

var HAS_LOGGED_IN = false;

const GET_CITY_STATES = "/perks/admin/citystate/getlistofcitystates";

const CATEGORY = {
  GET: "/perks/admin/category",
  DELETE: "/perks/admin/category",
  POST: "/perks/admin/category",
  PUT: "/perks/admin/category",
  GET_ROOT_CATEGORY: "/perks/admin/category/getrootcategory",
  GET_SUB_CHILD_CATEGORY: "/perks/admin/category/getsubcategoryforcategory",
  BATCH_DELETE: "/perks/admin/category/mulitple",
};

const MERCHANT = {
  POST_GET: "/perks/admin/getmerchantlist",
  POST_CREATE: "/perks/admin/createmerchant",
  POST_PUT: "/perks/admin/createmerchantandlocation",
  DELETE: "/perks/admin/deletemerchant",
  FILTER: "/perks/admin/getmerchantfilter",
  ACTIVATE: "/perks/admin/activatemerchant",
  ACTIVATE_MANY: "/perks/admin/activatemerchantmultiple",
  AUTOCOMPLETE: "/perks/admin/address/autocomplete",
  GET_PLACE: "/perks/admin/address/details",
  POST_GET_MERCHANT_DETAILS: "/perks/admin/getmerchantdetailsgrouped",
  ADD_CREATIVES: "/perks/admin/addcreativesformerchant",
  ASSIGN_DEALS_TO_LOCATIONS: "/perks/admin/assigndealstolocation",
  DELETE_OFFER_LOCATION_MAPPING: "/perks/admin/deleteofferlocationmapping",
};

const LOCATIONS = {
  CREATE: "/perks/admin/createmerchantlocation",
  DELETE: "/perks/admin/deletemerchantlocation",
  ACTIVATE: "/perks/admin/activatemerchantlocation",
  ACTIVATE_MANY: "/perks/admin/activatemerchantlocationmultiple",
};

const DEALS = {
  POST_GET: "/perks/admin/getdeallist",
  POST_CREATE: "/perks/admin/createdeal",
  DELETE: "/perks/admin/deleteoffer?offer_detail_id=",
  FILTER: "/perks/admin/getdealsfilter",
  ACTIVATE: "/perks/admin/activatedealmapping",
  ACTIVATE_MANY: "/perks/admin/activatedealmappingmultiple",
  POST_GET_DEAL_DETAILS: "/perks/admin/getofferdetails",
  GET_COUPON_DETAILS: "/perks/admin/getcoupondetailsfordeal",
  DELETE_COUPON_DETAILS: "/perks/admin/deletecoupondetailsfordeal",
};

const FEATURED_DEALS = {
  POST_GET: "/perks/admin/featureddealslist",
  FILTER: "/perks/admin/getfeatureddealsfilter",
  POST_GET_GROUPED_DEAL_DETAILS: "/perks/admin/getoffer",
  ACTIVATE: "/perks/admin/activatedeal",
  ACTIVATE_MANY: "/perks/admin/activatedealmultiple",
  FEATURE: "/perks/admin/featuredeal",
  FEATURE_MANY: "/perks/admin/featuredealmultiple",
};

const REWARD_POINTS = {
  GET: "/rewards/admin/rewardpoints",
  POST_GET: "/rewards/admin/getrewards",
  DELETE: "/rewards/admin/rewardpoints",
  POST: "/rewards/admin/rewardpoints",
  PUT: "/rewards/admin/rewardpoints",
};

const AUDIT_LOG = "/perks/admin/etlstatus";

const WITH_LIMIT = "?limit=";

const updateRequestHeader = jwtToken => {
  HEADERS["Authorization"] = jwtToken;
  HAS_LOGGED_IN = true;
};

const getRequestHeader = () => {
  return HEADERS;
};

const hasLoggedIn = () => {
  if (sessionStorage.getItem("jwt") === null) {
    return false;
  } else {
    HEADERS["Authorization"] = sessionStorage.getItem("jwt");
    return true;
  }
};

const logOut = () => {
  HAS_LOGGED_IN = false;
};

const EndPoints = {
  BOOST_DOMAIN,
  CLOUD_FRONT,
  LOGIN,
  S3_UPLOAD_PATH,
  HEADERS,
  GET_CITY_STATES,
  CATEGORY,
  MERCHANT,
  LOCATIONS,
  DEALS,
  FEATURED_DEALS,
  REWARD_POINTS,
  AUDIT_LOG,
  WITH_LIMIT,
  updateRequestHeader,
  getRequestHeader,
  hasLoggedIn,
  logOut,
};

export default EndPoints;
