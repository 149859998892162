import React, { useState, useEffect, useRef } from "react";
import EndPoints from "../webservices/endpoints";
import { get } from "../webservices/apiservice";
import { makeStyles, Button, Typography, LinearProgress } from "@material-ui/core";
import { Loader, Placeholder } from "rsuite";

const styles = makeStyles(theme => ({
  root: {
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    flex: 1,
    display: "block",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
  },
  optionLabel: {
    fontFamily: "Roboto",
    fontWeight: 500,
    margin: 5,
    fontSize: 14,
    color: "#7F7F7F",
    display: "flex",
    display: "flex",
  },
  userInput: {
    "&::placeholder": {
      color: "#9397AA !important",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    marginTop: 5,
    fontFamily: "Roboto",
    color: "#414243",
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 16,
    border: 0,
    borderRadius: 3,
    backgroundColor: "#EEF1F6",
    outline: "none",
    height: 36,
    width: "100%",
  },
  dropdownContainer: {
    display: "block",
    position: "absolute",
    zIndex: 1100011,
    backgroundColor: theme.palette.white,
    width: 364,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: 2,
  },
  dropdown: {
    padding: 7,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
  showClose: {
    display: "block",
  },
  hideClose: {
    display: "none",
  },
  closeBtnContainer: {
    padding: 7,
    display: "flex",
    justifyContent: "flex-end",
  },
  closeBtn: {
    width: "15%",
    // height: 25,
    fontWeight: 500,
    fontSize: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
  },
  readOnlyValue: {
    fontFamily: "Roboto",
    color: "#414243",
    fontSize: 16,
    fontWeight: 500,
    margin: 5,
    padding: "7px 0px 5px 7px",
  },
}));

const GooglePlaceAutocomplete = props => {
  //Constants
  const GET_PLACES_FIELDS = ["address_components", "formatted_address", "formatted_phone_number", "place_id", "geometry"];

  //States
  const [inputValue, setInputValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAddress, setLoadingAddress] = useState(false);
  const [isError, setError] = useState(false);
  const [toBeSearched, setToBeSearched] = useState(false);

  //refs
  const dropdownContainer = useRef(null);

  //Styles
  const localStyles = styles();

  //Functions
  const getPredictions = async queryString => {
    let queryUrl = `${EndPoints.BOOST_DOMAIN}${EndPoints.MERCHANT.AUTOCOMPLETE}?components=country:us&input=${queryString}`;
    setError(false);
    setLoading(true);
    if (EndPoints.hasLoggedIn() && toBeSearched) {
      try {
        let response = await get(queryUrl);
        if (response.status === "OK" || response.status === "ZERO_RESULTS") {
          setPredictions(response.predictions);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };

  const handleLoadingAddress = state => {
    if (typeof state === "boolean") {
      setLoadingAddress(state);
      props.handleLoadUntilResult(state);
    }
  };

  const getPlaceDetails = async placeId => {
    let queryUrl = `${EndPoints.BOOST_DOMAIN}${EndPoints.MERCHANT.GET_PLACE}?fields=${GET_PLACES_FIELDS.toString()}&place_id=${placeId.toString()}`;
    setError(false);
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await get(queryUrl);
        if (response.status === "OK") {
          constructAddress(response.result);
        }
      } catch (error) {
        setError(true);
      }
    }
  };

  const constructAddress = result => {
    let address = [];
    let city = "";
    let state = "";
    let postalCode = "";
    let country = "";
    let phone = "";
    let placeId = "";
    let latitude = "";
    let longitude = "";
    result.address_components !== undefined &&
      result.address_components.map((component, i) => {
        if (component.types.includes("locality") || component.types.includes("sublocality")) {
          city = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.short_name;
        } else if (
          !component.types.includes("administrative_area_level_2") &&
          !component.types.includes("neighborhood") &&
          !component.types.includes("postal_code_suffix")
        ) {
          address.push(component.short_name);
        }
      });
    if (result.hasOwnProperty("formatted_phone_number")) {
      phone = result.formatted_phone_number;
    }
    if (result.hasOwnProperty("place_id")) {
      placeId = result.place_id;
    }
    if (result.hasOwnProperty("geometry") && result.geometry.hasOwnProperty("location")) {
      if (result.geometry.location.hasOwnProperty("lat")) {
        latitude = result.geometry.location.lat;
      }
      if (result.geometry.location.hasOwnProperty("lng")) {
        longitude = result.geometry.location.lng;
      }
    }
    setInputValue(address.toString().replaceAll(",", ", "));
    setPredictions([]);
    props.passDetails({
      address: address.toString().replaceAll(",", ", "),
      city: city,
      state: state,
      postal_code: postalCode,
      country: country,
      phone: phone,
      place_id: placeId,
      latitude: latitude,
      longitude: longitude,
    });
    handleLoadingAddress(false);
  };

  const handleInput = event => {
    dropdownContainer.current.style.display = "block";
    setToBeSearched(true);
    setInputValue(event.target.value);
    if (inputValue !== props.value && inputValue.length > 0) {
      getPredictions(encodeURIComponent(`${props.prefix.join().replaceAll(",", ", ")}, ${inputValue}`));
    } else {
      setPredictions([]);
    }
  };

  const handleClickPrediction = prediction => {
    setToBeSearched(false);
    handleLoadingAddress(true);
    getPlaceDetails(prediction.place_id);
  };

  const setInputError = e => {
    if (e.target.value === null || e.target.value === undefined || e.target.value === "") {
      e.target.style.borderBottom = "2px solid red";
    }
  };

  const resetInputError = e => {
    e.target.style.borderBottom = "0px";
  };

  //Effects

  useEffect(() => {
    setInputValue(props.value);
    dropdownContainer.current.style.display = "none";
  }, [props.value]);

  useEffect(() => {
    if (inputValue !== props.value && inputValue.length > 0 && inputValue.length < 2) {
      props.passDetails({ address: inputValue }, true);
      setLoading(true);
    }
  }, [inputValue]);
  useState(() => {}, [predictions]);
  useState(() => {}, [isLoading]);
  useState(() => {}, [isLoadingAddress]);
  useState(() => {}, [isError]);
  useState(() => {}, [toBeSearched]);
  useEffect(() => {}, []);

  //Render
  return (
    <div className={localStyles.root}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label className={localStyles.optionLabel}>
          {props.label} <label style={{ color: "red", marginLeft: 2, fontSize: 10 }}>{props.hasOwnProperty("required") ? `*` : ``}</label>
        </label>
        {isLoadingAddress && <Loader size="xs" style={{ marginLeft: 3 }} />}
      </div>
      {props.hasOwnProperty("readOnly") && props.readOnly === true ? (
        <Typography className={localStyles.readOnlyValue}>{inputValue}</Typography>
      ) : (
        <input
          id="google-autocomplete"
          type="text"
          ref={props.componentRef}
          value={inputValue}
          onChange={handleInput}
          className={localStyles.userInput}
          placeholder={props.placeholder}
          onFocus={e => props.hasOwnProperty("required") && resetInputError(e)}
          onBlur={e => props.hasOwnProperty("required") && setInputError(e)}
          onClick={e => {
            if (inputValue !== props.value) {
              dropdownContainer.current.style.display = "block";
            }
          }}
        />
      )}
      <div className={localStyles.dropdownContainer} ref={dropdownContainer}>
        <div style={{ overflow: "auto" }}>
          {isLoading ? (
            <div className={localStyles.dropdown} style={{ opacity: 0.8, fontStyle: "italic" }}>
              <Typography style={{ textAlign: "center", margin: 10 }}>Loading...</Typography>
            </div>
          ) : isError ? (
            <div className={localStyles.dropdown}>
              <Typography style={{ textAlign: "center", margin: 10 }}>Error Loading Data, Re-try</Typography>
            </div>
          ) : predictions.length === 0 && inputValue.length > 1 ? (
            <div className={localStyles.dropdown}>
              <Typography style={{ textAlign: "center", margin: 10 }}>No Results found</Typography>
            </div>
          ) : (
            predictions.map((prediction, index) => {
              return (
                <div
                  className={localStyles.dropdown}
                  onClick={() => {
                    handleClickPrediction(prediction);
                    dropdownContainer.current.style.display = "none";
                  }}
                  key={`merchant-location-dropdown-${index}`}>
                  {prediction.description}
                </div>
              );
            })
          )}
        </div>
        <div className={`${inputValue.length > 0 ? localStyles.showClose : localStyles.hideClose}`}>
          <div className={localStyles.closeBtnContainer}>
            <Button className={localStyles.closeBtn} disabled={isLoading} onClick={() => (dropdownContainer.current.style.display = "none")}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GooglePlaceAutocomplete;
