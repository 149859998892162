
const REDIRECTS = {
  'dev': 'https://perks-admin-dev.rw.dish.com/',
  'int': 'https://perks-admin-int.rw.dish.com/',
  'test': 'https://perks-admin-test.rw.dish.com/',
  'prod': 'https://perks-admin.rw.dish.com/'
}

const redirectURL = process.env.NODE_ENV === 'development' ? ('http://localhost:3000/') : process.env.REACT_APP_STAGE ? REDIRECTS[process.env.REACT_APP_STAGE] : REDIRECTS['dev'];

const awsConfigs = {
  dev: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:4fe12741-0aba-4f7e-95fc-6f8a814d9428',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_pjp8Q6vMf',
    aws_user_pools_web_client_id: '1n4tphk602qjcqbj1mb30i5ii1',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'dishwireless-perks-admin-dev.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile','https://retail-wireless-customscope.com/admin.perks'],
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: 'boostone-assets-dev',
        region: "us-west-2",
        identityPoolId: "us-west-2:4fe12741-0aba-4f7e-95fc-6f8a814d9428",
      },
    }
  },

  int: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:146b9754-8647-4798-b675-edb86180f415',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_CPEpGqWdj',
    aws_user_pools_web_client_id: '54vb3hmmkkm6p2pvqsljghqtg4',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'dishwireless-perks-admin-int.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile','https://retail-wireless-customscope.com/admin.perks'],
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: 'boostone-assets-int',
        region: "us-west-2",
        identityPoolId: "us-west-2:146b9754-8647-4798-b675-edb86180f415",
      },
    }
  },

  test: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:9ef6e8c6-92e3-456b-8e27-a55c126f43d2',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_crx7ggcDc',
    aws_user_pools_web_client_id: '4d3bsa5mbv88ivvo345d983q76',
    Auth: {
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'dishwireless-perks-admin-test.auth.us-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile','https://retail-wireless-customscope.com/admin.perks'],
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: 'boostone-assets-test',
        region: "us-west-2",
        identityPoolId: "us-west-2:9ef6e8c6-92e3-456b-8e27-a55c126f43d2",
      },
    }
  },
  prod: {
      aws_project_region: 'us-west-2',
      aws_cognito_identity_pool_id: 'us-west-2:6efcf0d5-25c4-4882-ad94-622129cd9881',
      aws_cognito_region: 'us-west-2',
      aws_user_pools_id: 'us-west-2_AESvhqTI4',
      aws_user_pools_web_client_id: '286jmi89re68kh0df4i8fihv5c',
      Auth: {
        authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
          domain: 'dishwireless-perks-admin.auth.us-west-2.amazoncognito.com',
          scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile','https://retail-wireless-customscope.com/admin.perks'],
          redirectSignIn: redirectURL,
          redirectSignOut: redirectURL,
          responseType: 'code',
        },
      },
      Storage: {
        AWSS3: {
          bucket: 'boostone-assets-prod',
          region: "us-west-2",
          identityPoolId: "us-west-2:6efcf0d5-25c4-4882-ad94-622129cd9881",
        },
      }
    }
}

// const AWSCONFIG = process.env.NODE_ENV === "development"  ? awsConfigs['dev'] : process.env.REACT_APP_STAGE ? awsConfigs[process.env.REACT_APP_STAGE] : awsConfigs['int'];

const AWSCONFIG = process.env.REACT_APP_STAGE ? awsConfigs[process.env.REACT_APP_STAGE] : awsConfigs['dev'];

export default AWSCONFIG;
