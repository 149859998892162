import React, { useState, useEffect, useRef } from "react";
import {
  DatePicker,
  FlexboxGrid,
  Input,
  Modal,
  Button as UploadButton,
  Notification,
  Uploader,
  InputGroup,
  Icon,
  Placeholder,
  IconButton,
  Alert,
} from "rsuite";
import { makeStyles, Button, Typography, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import EndPoints from "../../webservices/endpoints";
import { batchDelete, post, upload } from "../../webservices/apiservice";
import Toggle from "../../components/toggle";
import { SetLabelDropdown, SetLabelInput } from "../../components/modal_input";
import { globalStyles } from "../../config/theme";
import csvLogo from "../../icons/csv-logo.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import Papa from "papaparse";
import { getImage } from "../../components/table";
import Garbage from "../../icons/confirmation_modal/garbage.png";

const redemptionTypeOptions = ["Online", "In-Store"];
const redemptionTypeMap = { Online: "CLICK", "In-Store": "MOBILE" };
const offerTypeOptions = ["Single Coupon", "Multi Coupon"];
const offerTypeMap = { "Single Coupon": "SINGLE_COUPON", "Multi Coupon": "MULTI_COUPON" };
const providerOptions = ["DISH", "Entertainment.com"];
const frequencyTimeOptions = ["Annual", "Monthly", "Weekly", "Daily"];

const getMerchants = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.POST_GET;
const postUri = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.POST_CREATE;
// const putUri = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.PUT;
const getCouponDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.GET_COUPON_DETAILS;
const deleteCouponDetailsUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.DELETE_COUPON_DETAILS;

const useStyles = makeStyles(theme => ({
  modalContainer: {},
  body: {
    display: "flex",
    width: "100%",
    // fontSize: 16,
    fontFamily: "Roboto",
  },
  container1: {
    width: "70%",
  },
  fullWidthInput: {
    width: "90%",
  },
  labelInputWrapper: {
    width: "90%",
    margin: 5,
  },
  input: {
    margin: 5,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.background,
    boxShadow: "0 0 0 1px transparent",
    fontWeight: 600,
    "&:hover, &:focus": {
      border: "1px solid transparent",
      outline: "none",
      boxShadow: "0 0 0 1px white",
    },
  },
  label: {
    color: theme.palette.fontLabelColor,
    fontWeight: 500,
    margin: 5,
  },
  container2: {
    width: "31%",
    height: 500,
    marginRight: 10,
  },
  dropdown: {
    // margin: 5,
    width: "100%",
    "& a": {
      width: "98%",
    },
    "& ul": {
      width: "98%",
    },
  },
  preview: {
    width: "100%",
    height: 350,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.background,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  uploader: {},
  footer: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  saveBtn: {
    width: "15%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.background,
      opacity: 0.9,
    },
  },
  cancelBtn: {
    width: "15%",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
  },
  textBtn: {
    // width: "15%",
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.7,
    },
  },
  error: {
    // borderRadius: "5px",
    // outline: "#FF0000 solid 1px",
    border: "1px solid transparent",
    boxShadow: "0 0 0 1px red",
    padding: 5,
  },
  toggleContainer: {
    margin: 5,
  },
  userInput: {
    border: "1px solid transparent",
    outline: "none",
    "&:hover": { borderColor: "none" },
  },
  couponBoxDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    alignItems: "center",
    fontSize: 14,
    fontFamily: "Robotto",
    border: `1px solid transparent`,
    borderRadius: 4,
    "&:hover, focus": {
      border: `1px solid ${theme.palette.primary.background}`,
    },
  },
  searchBoxDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    alignItems: "center",
    fontSize: 14,
    fontFamily: "Robotto",
    borderRadius: 4,
    "&:hover, focus": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
  clearIcon: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    padding: 3,
    "&:hover, foucs": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.background,
    },
  },
  searchBoxCurrentMerchant: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 3,
    margin: 2,
    padding: `5px 10px 5px 10px`,
    backgroundColor: theme.palette.primary.background,
  },
  searchBoxCursorPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const getDateString = (inputDate, isOnlyDate = false) => {
  let date = new Date(inputDate);
  let yr = date.getFullYear();
  let mn = date.getMonth() + 1;
  mn = mn > 9 ? mn : "0" + mn;
  let dt = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
  let hr = date.getHours() > 10 ? date.getHours() : "0" + date.getHours();
  let min = date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes();
  let sec = date.getSeconds() > 10 ? date.getSeconds() : "0" + date.getSeconds();
  let time = `${hr}:${min}:${sec}.${date.getMilliseconds()}`;
  if (isOnlyDate) {
    return `${yr}${mn}${dt}${hr}${min}${sec}`;
  } else {
    return `${yr}-${mn}-${dt}T${time}`;
  }
};

const DealModal = props => {
  //constants
  const globalClasses = globalStyles();
  const classes = useStyles();

  //useStates
  const [showModal, setShowModal] = useState(props.show);
  const [uploader, setUploadLoader] = useState(false);
  const [merchantBoxView, setMerchantBoxView] = useState(false);
  const [merchantBoxOffset, setMerchantBoxOffset] = useState({});

  const [offerId, setOfferId] = useState("");

  const [modalTitle, setModalTitle] = useState("Create New Deal");
  const [merchant, setMerchant] = useState({});
  const [dealName, setDealName] = useState("");
  const [dealDesc, setDealDesc] = useState("");
  const [term, setTerm] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);

  const [dealValue, setDealValue] = useState("");
  const [maxDiscount, setMaxDiscount] = useState(1);
  const [startDate, setStartDate] = useState(getDateString(new Date().setHours(0, 0, 0, 0)));

  const [url, setUrl] = useState("");
  const [redemptionType, setRedemptionType] = useState(redemptionTypeOptions[0]);
  const [offerType, setOfferType] = useState(offerTypeOptions[0]);
  const [promoCode, setPromoCode] = useState(null);
  const [provider, setProvider] = useState(providerOptions[0]);
  const [freqencyValue, setFrequencyValue] = useState(1);
  const [freqencyTime, setFrequencyTime] = useState(frequencyTimeOptions[0]);

  const [csv, setCsv] = useState({});
  const [coupons, setCoupons] = useState([]);
  const [isCouponsLoading, setCouponsLoading] = useState(false);
  const [filename, setFilename] = useState("");

  const [barcode, setBarcode] = useState(null);
  const [voucherImage, setVoucherImage] = useState(null);
  const [isBarcodeUploaded, setBarcodeUploadStatus] = useState(false);
  const [hasBarcode, setHasBarcode] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [status, setStatus] = useState(true);
  const [isRemoveLoading, setRemoveLoading] = useState(false);

  const [isDisabled, setDisable] = useState(true);
  const [isSubmitting, setSubmitStatus] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [deleteCouponDialog, setDeleteCouponDialog] = useState(false);
  const [couponToRemove, setCouponToRemove] = useState({});

  //useRefs
  const multiCouponUploaderRef = useRef(null);

  //Handlers
  const handleChange = (val, handler) => {
    handler(val);
  };

  const onOpen = () => {
    setShowModal(true);
  };

  const onHide = () => {
    !props.hasOwnProperty("onEdit") && clearInputs();
    setShowModal(false);
    props.onHide();
  };

  const onChangePicture = e => {
    if (e.target.files[0]) {
      if (e.target.files[0].type.includes("image/")) {
        setVoucherImage(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
        setBarcodeUploadStatus(false);
        setHasBarcode(false);
      } else {
        window.alert("Selected file format is not supported. Please select any of JPEG, JPG or PNG file.");
      }
    }
  };

  const removeBarcode = async e => {
    e.stopPropagation();
    if (window.confirm("You are trying to remove the Barcode/QR file.\nPlease select 'OK' to confirm and 'Cancel' to ignore.")) {
      setRemoveLoading(true);
      if (Object.keys(props.dealData).length > 0 && (getImage(barcode) !== null || isBarcodeUploaded === true)) {
        let payload = constructPayload();
        payload = { ...payload.post, barcode: null };
        try {
          await post(postUri, payload);
          setHasBarcode(false);
          setVoucherImage(null);
          setImgData(null);
          setBarcode(null);
          setBarcodeUploadStatus(null);
          setRemoveLoading(false);
        } catch (error) {
          console.log(error);
          setRemoveLoading(false);
        }
      } else {
        setHasBarcode(false);
        setVoucherImage(null);
        setImgData(null);
        setBarcode(null);
        setBarcodeUploadStatus(null);
        setRemoveLoading(false);
      }
    } else {
      e.target.blur();
    }
  };

  const uploadImage = async () => {
    setUploadLoader(true);
    try {
      let response = await upload(`${EndPoints.S3_UPLOAD_PATH.deals}${merchant.merchant_id}/${offerId}/`, `barcode.png`, voucherImage);
      setBarcode(`public/${response.key}`);
      setBarcodeUploadStatus(true);
      setUploadLoader(false);
      return `public/${response.key}`;
    } catch (error) {
      setBarcodeUploadStatus(false);
      setUploadLoader(false);
      return null;
    }
  };

  const clearInputs = () => {
    setOfferId("");
    setMerchant({});
    setDealName("");
    setDealDesc("");
    setTerm("");
    setExpiryDate(null);
    setDealValue("");
    setStartDate(getDateString(new Date().setHours(0, 0, 0, 0)));
    setMaxDiscount(1);
    setUrl("");
    setPromoCode(null);
    setOfferType(offerTypeOptions[0]);
    setRedemptionType(redemptionTypeOptions[0]);
    setBarcode(null);
    setVoucherImage(null);
    setBarcodeUploadStatus(null);
    setImgData(null);
    setProvider(providerOptions[0]);
    setFrequencyValue(1);
    setFrequencyTime(frequencyTimeOptions[0]);
    setStatus(true);
    setCsv({});
    setCoupons([]);
    setHasBarcode(false);
    setUploadedFiles([]);
    setFilename("");
  };

  const onCancel = () => {
    Object.keys(props.dealData).length === 0 && clearInputs();
    onHide();
  };

  const handleToggle = (id, state) => {
    setStatus(state);
  };

  const rest = (a, b) => {
    return Object.entries(b).reduce((c, [k, v]) => Object.assign(c, a[k] ? {} : { [k]: v }), {});
  };

  const addExcelFile = value => {
    const reader = new FileReader();
    reader.readAsText(value[value.length - 1].blobFile);
    reader.onload = () => {
      Papa.parse(reader.result, {
        header: false,
        complete: async res => {
          let arrayOfCoupons = res.data.flat().slice(1);
          setCoupons([
            {
              added_file_name: `FILE_${getDateString(new Date(), true)}`,
              count: arrayOfCoupons.length,
              is_used: false,
              data: arrayOfCoupons,
            },
          ]);
        },
      });
    };
  };

  const removeExcelFile = () => {
    multiCouponUploaderRef.current.cleanInputValue();
    multiCouponUploaderRef.current.state.fileList = [];
    setFilename("");
    setCoupons([]);
  };

  const getMerchantDetails = merchantData => {
    setMerchant(merchantData);
  };
  const constructPayload = () => {
    let payload = {
      offer: offerId,
      merchant_id: merchant.merchant_id,
      dbasortname: merchant.dbasortname,
      offertextshort: dealName,
      offertextlong: dealDesc,
      expiredate: getDateString(expiryDate),
      start_date: getDateString(startDate),
      offerqualifier: term,
      discountvaluetext: dealValue,
      discountvalueamount: parseInt(maxDiscount, 10),
      redemption: redemptionTypeMap[redemptionType],
      offertype: offerTypeMap[offerType],
      mapping_active: status,
      freqtime: freqencyTime,
      freqvalue: freqencyValue,
    };
    if (provider === "DISH") {
      payload = { ...payload, deal_provider: 0, deal_provider_id: 0, deal_provider_name: "DISH" };
    } else if (provider === "Entertainment.com") {
      payload = {
        ...payload,
        deal_provider: 0,
        deal_provider_id: 10,
        deal_provider_name: "Entertainment.com",
      };
    }
    if (offerType === "Single Coupon") {
      payload = { ...payload, barcode: barcode, trackingcode: promoCode };
    } else if (offerType === "Multi Coupon" && filename !== "") {
      payload = {
        ...payload,
        barcode: null,
        added_file_name: coupons[0]?.added_file_name,
        coupons: coupons[0]?.data,
      };
    }
    if (redemptionType === "In-Store") {
      payload = { ...payload, url: null };
    } else if (redemptionType === "Online") {
      if (url === "") {
        payload = { ...payload, url: null };
      } else {
        payload = { ...payload, url: url };
      }
    }
    return { post: payload, all: { ...props.dealData, ...payload } };
  };

  const onSave = async () => {
    setSubmitStatus(true);
    let payload = constructPayload();
    if (offerType === "Single Coupon" && voucherImage !== null && (isBarcodeUploaded === false || isBarcodeUploaded === null)) {
      let response = await uploadImage();
      payload.post.barcode = response;
      payload.all.barcode = response;
    }
    try {
      await post(postUri, payload.post);
      if (props.hasOwnProperty("onEdit")) {
        props.onEdit(payload.all);
      } else if (props.hasOwnProperty("onCreate")) {
        props.onCreate(true);
        clearInputs();
      }
      onHide();
      Notification["success"]({
        title: `Success`,
        description: Object.keys(props.dealData).length > 0 ? `Updated ${props.dealData.offertextshort}` : `Created offer successfully`,
      });
      setSubmitStatus(false);
      if (
        !payload.all.hasOwnProperty("redemptionlocation") ||
        (payload.all.hasOwnProperty("redemptionlocation") && (payload.all.redemptionlocation === null || payload.all.redemptionlocation === "None"))
      ) {
        Alert.warning(
          "The deal you have created just now requires attention!\nAssign a location to it (or vice versa) from Merchant Details Section.",
          15000,
          Alert.close()
        );
      }
    } catch (err) {
      Notification["error"]({
        title: "Failure",
        description: Object.keys(props.dealData).length > 0 ? `Couldn't edit ${props.dealData.offertextshort}` : `Couldn't create the Offer`,
      });
      setSubmitStatus(false);
    }
  };

  const getOffset = el => {
    const rect = el.getBoundingClientRect();
    const width = el.offsetWidth;
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
      width: width,
    };
  };

  const handleMerchantSearchModal = state => {
    typeof state === "boolean" && setMerchantBoxView(state);
  };

  const handleCouponDeleteDialog = state => {
    typeof state === "boolean" && setDeleteCouponDialog(state);
  };

  const passCouponDetailsToDialog = couponToRemove => {
    setCouponToRemove(couponToRemove);
    handleCouponDeleteDialog(true);
  };

  const handleRemoveOneCoupon = async couponToRemove => {
    if (Object.keys(props.dealData).length > 0 && uploadedFiles.includes(couponToRemove.added_file_name)) {
      try {
        await batchDelete(deleteCouponDetailsUrl, {
          offer_id: offerId,
          added_file_name: couponToRemove.added_file_name,
        });
        Notification["success"]({
          title: "Success",
          description: `Deleted ${couponToRemove.added_file_name}`,
        });
        setCoupons(coupons.filter(coupon => coupon !== couponToRemove));
        setCouponToRemove({});
        setFilename("");
      } catch (error) {
        console.log(error);
        Notification["error"]({
          title: "Failure",
          description: "Unable to delete the coupon, try again",
        });
      }
    } else {
      setCoupons(coupons.filter(coupon => coupon !== couponToRemove));
      setCouponToRemove({});
      setFilename("");
    }
  };

  const generateOfferId = () => {
    if (Object.keys(props.dealData).length > 0) {
      setOfferId(props.dealData.offer_detail_id);
    } else {
      let generatedOfferId = `OFFER_${Math.random().toString(16).slice(2).toLocaleUpperCase()}_${new Date().getTime()}`;
      setOfferId(generatedOfferId);
    }
  };

  const isValidBarcode = barcode => {
    if (barcode === null || barcode === undefined || barcode === "None" || barcode === "") {
      return false;
    }
    return true;
  };

  //useEffects

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  const setEditModal = async data => {
    setModalTitle("Edit Deal");
    setOfferId(data.hasOwnProperty("offer") ? data.offer : data.offer_detail_id);
    setMerchant({
      merchant_id: data.merchant_id,
      dbasortname: data.dbasortname,
      logourl: data.logourl,
    });
    setDealName(data.offertextshort);
    setDealDesc(data.offertextlong);
    setTerm(data.offerqualifier);
    setExpiryDate(getDateString(data.expiredate.toString().replace("Z", "")));
    setStartDate(getDateString(data.start_date.toString().replace("Z", "")));
    setDealValue(data.discountvaluetext);
    setMaxDiscount(data.discountvalueamount);
    setRedemptionType(data.redemption);
    setRedemptionType(Object.keys(redemptionTypeMap).find(key => redemptionTypeMap[key] === data.redemption));
    setUrl(data.url);
    setPromoCode(data.trackingcode);
    setProvider(data.deal_provider_name ? data.deal_provider_name : providerOptions[0]);
    setFrequencyTime(data.freqtime);
    setFrequencyValue(data.freqvalue);
    setBarcode(data.barcode);
    setHasBarcode(isValidBarcode(data.barcode));
    setStatus(data.mapping_active);
    setOfferType(Object.keys(offerTypeMap).find(key => offerTypeMap[key] === data.offertype));
    if (props.dealData.offertype === "MULTI_COUPON") {
      setCouponsLoading(true);
      try {
        let response = await post(getCouponDetailsUrl, { offer_id: props.dealData.offer_detail_id });
        setCoupons(response.couponDetails);
        setUploadedFiles(response.couponDetails.map(item => item.added_file_name));
        setCouponsLoading(false);
      } catch (error) {
        setCoupons([]);
        setCouponsLoading(false);
      }
    }
  };

  // useEffect(async () => {
  //   if (Object.keys(props.dealData).length > 0) {
  //     setEditModal(props.dealData);
  //   } else {
  //     setModalTitle("Create New Deal");
  //     // clearInputs();
  //   }
  // }, [props.dealData]);

  useEffect(() => {
    setBarcodeUploadStatus(null);
  }, [imgData]);

  useEffect(() => {
    if (Object.keys(merchant).length <= 0 || dealName === "" || dealValue === "" || maxDiscount === null || expiryDate === null || startDate === null) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [merchant, dealName, dealValue, maxDiscount, expiryDate, startDate]);

  useEffect(() => {}, [merchant]);
  useEffect(() => {}, [merchantBoxOffset]);
  useEffect(() => {}, [offerId]);
  useEffect(() => {}, [barcode]);
  useEffect(() => {}, [hasBarcode]);
  useEffect(() => {}, [isBarcodeUploaded]);
  useEffect(() => {}, [isCouponsLoading]);
  useEffect(() => {}, [coupons]);
  useEffect(() => {}, [filename]);
  useEffect(() => {}, [uploadedFiles]);
  useEffect(() => {}, [isSubmitting]);
  useEffect(() => {}, [couponToRemove]);
  useEffect(() => {}, [isRemoveLoading]);

  useEffect(() => {
    if (expiryDate !== null) {
      if (new Date(startDate) > new Date(expiryDate)) {
        window.alert("Please select Start Date earlier than Expiry Date.");
        setExpiryDate(getDateString(new Date(startDate).setHours(23, 59)));
      }
    }
  }, [startDate, expiryDate]);

  //Render
  return (
    <>
      <div className={classes.modalContainer}>
        <Modal
          size="lg"
          show={showModal}
          onEntered={() => {
            generateOfferId();
            if (Object.keys(props.dealData).length > 0) {
              setEditModal(props.dealData);
            } else {
              setModalTitle("Create New Deal");
              if (props.hasOwnProperty("hasMerchantDetails") && Object.keys(props.hasMerchantDetails).length > 0) {
                setMerchant(props.hasMerchantDetails);
              }
            }
          }}
          onExited={() => setImgData(null)}
          onHide={onHide}>
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.body}>
            <div className={classes.container1}>
              <div style={{ justifyContent: "space-between", width: "90%", margin: 5 }}>
                <Typography className={classes.label}>Merchant</Typography>
                <Button
                  onClick={event => {
                    event.stopPropagation();
                    setMerchantBoxOffset(getOffset(event.target));
                    handleMerchantSearchModal(true);
                  }}
                  style={{
                    width: "100%",
                    backgroundColor: "#EEF1F6",
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  {Object.keys(merchant).length > 0 ? `${merchant.dbasortname}` : `Select Merchant`}
                  {Object.keys(merchant).length > 0 ? (
                    <ClearIcon
                      onClick={event => {
                        event.stopPropagation();
                        setMerchant({});
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={event => {
                        event.stopPropagation();
                        setMerchantBoxOffset(getOffset(event.target.parentElement.parentElement));
                        handleMerchantSearchModal(true);
                      }}
                    />
                  )}
                </Button>
              </div>
              <div className={classes.labelInputWrapper}>
                <SetLabelInput
                  label={"Deal Name"}
                  inputName={"dealName"}
                  inputType={"text"}
                  placeholder={"Deal Name"}
                  valueVariable={dealName}
                  onChangeHandler={setDealName}
                  required
                />
              </div>
              <div className={classes.labelInputWrapper}>
                <SetLabelInput
                  label={"Deal Description"}
                  inputName={"dealDesc"}
                  inputType={"textarea"}
                  placeholder={"Description"}
                  valueVariable={dealDesc}
                  onChangeHandler={setDealDesc}
                />
              </div>
              <FlexboxGrid style={{ width: "75%" }}>
                <div className={classes.labelInputWrapper} style={{ width: "45%" }}>
                  <SetLabelInput
                    label={"Value"}
                    inputName={"value"}
                    inputType={"text"}
                    placeholder={"Value"}
                    valueVariable={dealValue}
                    onChangeHandler={setDealValue}
                    required
                  />
                </div>
                <div className={classes.labelInputWrapper} style={{ width: "45%" }}>
                  <SetLabelInput
                    label={"Max Discount"}
                    inputName={"maxDiscount"}
                    inputType={"number"}
                    placeholder={"Max Discount"}
                    valueVariable={maxDiscount}
                    onChangeHandler={setMaxDiscount}
                    min={1}
                    required
                  />
                </div>
              </FlexboxGrid>
              <FlexboxGrid style={{ width: "75%" }}>
                <div className={classes.labelInputWrapper} style={{ width: "45%" }}>
                  <SetLabelDropdown label={"Redemption Interval"} title={freqencyTime} itemList={frequencyTimeOptions} selectCallback={setFrequencyTime} />
                </div>
                <div className={classes.labelInputWrapper} style={{ width: "45%" }}>
                  <SetLabelInput
                    label={"Redemption Frequency"}
                    inputName={"freqencyValue"}
                    inputType={"number"}
                    placeholder={"Redemption Frequency"}
                    valueVariable={freqencyValue}
                    onChangeHandler={setFrequencyValue}
                    min={1}
                  />
                </div>
              </FlexboxGrid>
              <div className={classes.labelInputWrapper} style={{ width: "75%" }}>
                <Typography className={classes.label}>Start Date</Typography>
                <FlexboxGrid>
                  <DatePicker
                    placement="rightStart"
                    oneTap
                    placeholder="Date"
                    format="MMMM DD, YYYY"
                    value={startDate}
                    disabledDate={date => date < new Date().setDate(new Date().getDate() - 1)}
                    onChange={e => handleChange(getDateString(new Date(e).setHours(0, 0, 0, 0)), setStartDate)}
                    onClean={() => {
                      Object.keys(props.dealData).length > 0
                        ? handleChange(getDateString(props.dealData.start_date.toString().replace("Z", "")), setStartDate)
                        : handleChange(getDateString(new Date().setHours(0, 0, 0, 0)), setStartDate);
                    }}
                    ranges={[]}
                    style={{
                      marginRight: 10,
                      width: "45%",
                    }}
                    className={`input`}
                  />
                  <DatePicker
                    placement="rightStart"
                    placeholder="Time"
                    format="hh:mm A"
                    showMeridian
                    value={startDate}
                    onChange={e => {
                      handleChange(e, setStartDate);
                    }}
                    onClean={e => {
                      Object.keys(props.dealData).length > 0
                        ? handleChange(getDateString(props.dealData.start_date.toString().replace("Z", "")), setStartDate)
                        : handleChange(getDateString(new Date(startDate).setHours(23, 59, 59, 59)), setStartDate);
                    }}
                    ranges={[]}
                    style={{ marginRight: 10, width: "30%" }}
                    className={`input`}
                  />
                </FlexboxGrid>
              </div>
              <div className={classes.labelInputWrapper} style={{ width: "75%" }}>
                <Typography className={classes.label}>Expiry Date</Typography>
                <FlexboxGrid>
                  <DatePicker
                    placement="rightStart"
                    oneTap
                    placeholder="Date"
                    format="MMMM DD, YYYY"
                    value={expiryDate}
                    disabledDate={date => date < new Date(startDate)}
                    onChange={e => handleChange(getDateString(new Date(e).setHours(23, 59, 59, 59)), setExpiryDate)}
                    onClean={() => {
                      Object.keys(props.dealData).length > 0
                        ? handleChange(getDateString(props.dealData.expiredate.toString().replace("Z", "")), setExpiryDate)
                        : handleChange(null, setExpiryDate);
                    }}
                    ranges={[]}
                    style={{
                      width: "45%",
                      marginRight: 10,
                    }}
                    className={`input`}
                  />
                  <DatePicker
                    placement="rightStart"
                    placeholder="Time"
                    format="hh:mm A"
                    showMeridian
                    value={expiryDate}
                    onChange={e => {
                      handleChange(e, setExpiryDate);
                    }}
                    onClean={e => {
                      Object.keys(props.dealData).length > 0
                        ? handleChange(getDateString(props.dealData.expiredate.toString().replace("Z", "")), setExpiryDate)
                        : handleChange(getDateString(new Date(expiryDate).setHours(23, 59, 59, 59)), setExpiryDate);
                    }}
                    ranges={[]}
                    style={{ marginRight: 10, width: "30%" }}
                    className={`input`}
                  />
                </FlexboxGrid>
              </div>
              <div className={classes.labelInputWrapper}>
                <SetLabelInput
                  label={"Terms"}
                  inputName={"terms"}
                  inputType={"textarea"}
                  placeholder={"Terms"}
                  valueVariable={term}
                  onChangeHandler={setTerm}
                />
              </div>
              <FlexboxGrid style={{ width: "75%" }}>
                <div className={classes.labelInputWrapper} style={{ width: "35%" }}>
                  <SetLabelDropdown label={"Redemption Type"} title={redemptionType} itemList={redemptionTypeOptions} selectCallback={setRedemptionType} />
                </div>
                {redemptionType === "Online" && (
                  <div className={classes.labelInputWrapper} style={{ width: "55%" }}>
                    <SetLabelInput label={"URL"} inputName={"url"} inputType={"text"} placeholder={"URL"} valueVariable={url} onChangeHandler={setUrl} />
                  </div>
                )}
              </FlexboxGrid>
              <FlexboxGrid style={{ width: "92%" }}>
                <div className={classes.labelInputWrapper} style={{ width: "29%" }}>
                  <SetLabelDropdown
                    label={"Deal Provider"}
                    title={provider}
                    itemList={providerOptions}
                    selectCallback={setProvider}
                    disabled={Object.keys(props.dealData).length < 1}
                  />
                </div>
                <div className={classes.labelInputWrapper} style={{ width: "29%" }}>
                  <SetLabelDropdown
                    label={"Promo Type"}
                    title={offerType ? offerType : "Select Offer Type"}
                    itemList={offerTypeOptions}
                    selectCallback={setOfferType}
                  />
                </div>

                {offerType === "Single Coupon" && (
                  <div className={classes.labelInputWrapper} style={{ width: "36%" }}>
                    <SetLabelInput
                      label={"Promo Code"}
                      inputName={"promoCode"}
                      inputType={"text"}
                      placeholder={"Promo Code"}
                      valueVariable={promoCode}
                      onChangeHandler={setPromoCode}
                    />
                  </div>
                )}
              </FlexboxGrid>

              <div style={{ paddingBottom: 10 }}>
                <Typography className={classes.label}>Active Status</Typography>
                <Toggle initialState={status} id={"active-toggle"} handler={handleToggle} containerStyle={classes.toggleContainer} />
              </div>
            </div>
            <div className={classes.container2}>
              <div>
                <Typography className={classes.label}>Barcode/QR</Typography>
                <div className={classes.preview}>
                  {offerType === "Single Coupon" ? (
                    <>
                      <div
                        style={{
                          height: "85%",
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: 3,
                          padding: 10,
                        }}>
                        <img style={{ maxHeight: "99%", maxWidth: "99%" }} src={imgData ? imgData : getImage(barcode)} />
                      </div>
                      <FlexboxGrid justify="space-between" style={{ paddingTop: 10, width: "100%" }}>
                        {isValidBarcode(barcode) === false && imgData === null ? (
                          <Button component="label" className={classes.textBtn} style={{ width: "max-content" }}>
                            Select File
                            <input
                              className={classes.uploader}
                              type="file"
                              accept="image/*"
                              onChange={e => onChangePicture(e)}
                              style={{ marginTop: 5 }}
                              hidden
                            />
                          </Button>
                        ) : (
                          <UploadButton
                            appearance="subtle"
                            className={classes.textBtn}
                            style={{ width: "max-content" }}
                            loading={isRemoveLoading}
                            disabled={uploader}
                            onClick={e => removeBarcode(e)}>
                            Remove
                          </UploadButton>
                        )}
                        {isBarcodeUploaded === null ? (
                          <UploadButton
                            loading={uploader}
                            className={classes.textBtn}
                            onClick={uploadImage}
                            appearance="subtle"
                            disabled={(isValidBarcode(barcode) === false && imgData === null) || hasBarcode === true ? true : false}>
                            Upload
                          </UploadButton>
                        ) : isBarcodeUploaded === true ? (
                          <Typography style={{ color: "lightgreen", margin: 5 }}>Success</Typography>
                        ) : (
                          <Typography style={{ color: "red", margin: 5 }} onClick={uploadImage}>
                            Re-Upload
                          </Typography>
                        )}
                      </FlexboxGrid>
                    </>
                  ) : (
                    <>
                      <div
                        className={`${globalClasses.divM5}`}
                        style={{
                          height: 250,
                          margin: "auto",
                        }}>
                        <Uploader
                          className={`${globalClasses.divM5}`}
                          style={{ height: 250, width: "max-content" }}
                          draggable
                          autoUpload={false}
                          multiple={false}
                          disabled={coupons.length !== 0}
                          disabledFileItem={true}
                          accept=".csv, .xls, .xlsx"
                          fileListVisible={false}
                          onChange={addExcelFile}
                          onRemove={removeExcelFile}
                          ref={multiCouponUploaderRef}>
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <img src={csvLogo} style={{ maxHeight: 100 }} />
                            <Typography style={{ fontWeight: 600 }}>Drag and Drop your CSV here</Typography>
                            <Typography> Maximum file size is 50MB</Typography>
                            <Typography className={classes.textBlue}>Or select it from your computer</Typography>
                          </div>
                        </Uploader>
                      </div>
                    </>
                  )}
                </div>
                {offerType === "Multi Coupon" ? (
                  <div style={{ margin: 5 }}>
                    <Typography className={classes.label} style={{ fontSize: 17, overflow: "hidden" }}>
                      Uploaded Coupons
                    </Typography>
                    <div style={{ maxHeight: 200, overflow: "auto" }}>
                      {isCouponsLoading ? (
                        <div>
                          {[...Array(3)].map((box, index) => {
                            return (
                              <div key={`coupon-loader-${index}`}>
                                <Placeholder.Graph height={40} width={240} style={{ borderRadius: 4, margin: 3 }} active />
                              </div>
                            );
                          })}
                        </div>
                      ) : coupons.length < 1 ? (
                        <div>No Coupons available</div>
                      ) : (
                        coupons !== undefined &&
                        coupons.map((coupon, index) => {
                          return (
                            <div className={classes.couponBoxDiv}>
                              <div
                                key={`coupon-${index}`}
                                style={{
                                  padding: 3,
                                  width: 200,
                                  wordWrap: "break-word",
                                }}>
                                <Typography>{`Filename : ${coupon.added_file_name}`}</Typography>
                                <Typography>{`Count : ${coupon.count}`}</Typography>
                                <Typography>{`Used : ${coupon.is_used ? `True` : `False`}`}</Typography>
                              </div>
                              <IconButton style={{ padding: 1, width: 30, height: 30, backgroundColor: "transparent" }}>
                                <ClearIcon className={classes.clearIcon} onClick={() => passCouponDetailsToDialog(coupon)} />
                              </IconButton>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={classes.footer}>
              <Button className={classes.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
              <UploadButton
                style={{
                  cursor: "pointer",
                  display: "flex",
                  width: 120,
                  height: 42,
                  borderColor: "#579EEF",
                  borderRadius: 3,
                  borderStyle: "solid",
                  borderWidth: 0.5,
                  backgroundColor: "#579EEF",
                }}
                disabled={isDisabled}
                loading={isSubmitting}
                onClick={!isDisabled && onSave}>
                {!isSubmitting ? (
                  isDisabled ? (
                    <label
                      style={{
                        cursor: "not-allowed",
                        margin: "auto",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        color: "grey",
                        fontWeight: 500,
                        verticalAlign: "middle",
                      }}>
                      Save
                    </label>
                  ) : (
                    <label
                      style={{
                        cursor: "pointer",
                        margin: "auto",
                        fontSize: 16,
                        fontFamily: "Roboto",
                        color: "#FFFFFF",
                        fontWeight: 500,
                        verticalAlign: "middle",
                      }}>
                      Save
                    </label>
                  )
                ) : null}
              </UploadButton>
            </div>
          </Modal.Footer>
          <Dialog
            open={deleteCouponDialog}
            onClose={() => handleCouponDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <div style={{ display: "flex" }}>
                <div style={{ padding: 10 }}>
                  <img src={Garbage} />
                </div>
                <div>
                  <Typography variant="h6">Are you sure you want to delete?</Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      width: 300,
                      wordWrap: "wrap",
                    }}>{`Once deleted, ${couponToRemove.added_file_name} cannot be retrieved..`}</Typography>
                </div>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                borderTop: "1px solid lightgrey",
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Button onClick={() => handleCouponDeleteDialog(false)} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
                Oops! No Cancel
              </Button>
              <Button
                onClick={() => {
                  multiCouponUploaderRef.current.props.onRemove();
                  handleCouponDeleteDialog(false);
                }}
                className={globalClasses.buttonPrimary}
                style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <MerchantSearchBox
            show={merchantBoxView}
            onHide={() => handleMerchantSearchModal(false)}
            offset={merchantBoxOffset}
            selected={merchant}
            passSelected={getMerchantDetails}
          />
        </Modal>
      </div>
    </>
  );
};

export default DealModal;

const MerchantSearchBox = props => {
  const classes = useStyles();
  const [modal, setModal] = useState(props.show);
  const [offset, setOffset] = useState(props.offset);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(props.selected);
  const [isLoading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const onHide = () => {
    setModal(false);
    props.onHide();
  };

  const handleSelect = selectedMerchant => {
    setSelected(selectedMerchant);
    props.passSelected(selectedMerchant);
  };

  const handleClearSearch = () => {
    setSearch("");
    setIsSearched(false);
  };

  const constructMerchantAddress = merchant => {
    let res = [];
    if (
      merchant.hasOwnProperty("corp_city") &&
      merchant.corp_city !== undefined &&
      merchant.corp_city !== null &&
      merchant.corp_city !== "None" &&
      merchant.corp_city !== ""
    ) {
      res.push(merchant.corp_city);
    }
    if (
      merchant.hasOwnProperty("corp_state") &&
      merchant.corp_state !== undefined &&
      merchant.corp_state !== null &&
      merchant.corp_state !== "None" &&
      merchant.corp_state !== ""
    ) {
      res.push(merchant.corp_state);
    }
    if (
      merchant.hasOwnProperty("corp_zipcode") &&
      merchant.corp_zipcode !== undefined &&
      merchant.corp_zipcode !== null &&
      merchant.corp_zipcode !== "None" &&
      merchant.corp_zipcode !== ""
    ) {
      res.push(merchant.corp_zipcode);
    }
    return res.join(" | ");
  };

  //useEffects

  useEffect(() => {
    if (props.show !== modal) {
      setModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    setOffset({
      left: props.offset.left / 2 - 3,
      top: props.offset.top / 2 + 3,
      width: props.offset.width,
    });
  }, [props.offset]);

  useEffect(() => {}, [offset]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {}, [selected]);

  useEffect(async () => {
    if (search.length > 2) {
      setLoading(true);
      setError(false);
      try {
        let response = await post(getMerchants, {
          filters: [{ field: "deal_provider_name", value: "DISH" }],
          search: search,
        });
        setData(response.items);
        setIsSearched(true);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      setIsSearched(false);
    }
  }, [search]);

  //Render
  return (
    <Modal
      show={modal}
      onHide={onHide}
      size="sm"
      style={{
        margin: 20,
        borderRadius: `2px !important`,
        position: "absolute",
        padding: `2px important`,
        zIndex: 100000,
        top: offset.top,
        left: offset.left,
        width: `${offset.width}px !important`,
      }}>
      {Object.keys(selected).length > 0 ? (
        <div>
          <Typography className={classes.label} style={{ marginBottom: 0 }}>
            Current Merchant
          </Typography>
          <FlexboxGrid className={classes.searchBoxCurrentMerchant}>
            <FlexboxGrid style={{ alignItems: "center" }}>
              <img src={getImage(selected.logourl)} alt="" style={{ width: 50, paddingRight: 10 }} />
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>{selected.dbasortname}</Typography>
            </FlexboxGrid>
            {/* <ClearIcon onClick={() => handleSelect({})} /> */}
            <Icon icon="close" onClick={() => handleSelect({})} className={classes.searchBoxCursorPointer} />
          </FlexboxGrid>
        </div>
      ) : null}
      <Typography className={classes.label} style={{ marginBottom: 0, marginTop: 15 }}>
        Search Merchants
      </Typography>
      <div style={{ borderBottom: `1px solid lightgrey` }}>
        <InputGroup inside>
          <InputGroup.Button>
            <Icon icon="search" />
          </InputGroup.Button>
          <Input autoFocus={modal} placeholder={"Search merchant by name"} value={search} onChange={e => setSearch(e)} className={classes.userInput} />
          <InputGroup.Button>
            <Icon icon="close" onClick={handleClearSearch} />
          </InputGroup.Button>
        </InputGroup>
      </div>
      <div style={{ minHeight: 200, overflow: "auto", maxHeight: 250 }}>
        {isLoading ? (
          <Placeholder.Paragraph rows={7} columns={1} active />
        ) : error ? (
          <div>
            <Typography style={{ textAlign: "center", margin: 10, padding: 7 }}>Error, try again...</Typography>
          </div>
        ) : !isSearched ? (
          <div></div>
        ) : data.length < 1 ? (
          <div>
            <Typography style={{ textAlign: "center", margin: 10, padding: 7 }}>No Results found...</Typography>
          </div>
        ) : (
          <div>
            {data.map((merchant, index) => {
              return (
                <div
                  className={classes.searchBoxDiv}
                  onClick={() => {
                    handleSelect(merchant);
                    onHide();
                  }}
                  key={`searchBox-${index}`}>
                  <FlexboxGrid align="middle">
                    <img src={getImage(merchant.logourl)} alt="" style={{ width: 25, paddingRight: 10 }} />
                    <Typography style={{ fontSize: 15 }}>{merchant.dbasortname}</Typography>
                  </FlexboxGrid>
                  <FlexboxGrid>
                    <Typography>{constructMerchantAddress(merchant)}</Typography>
                  </FlexboxGrid>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Modal.Footer>
        <Button className={classes.cancelBtn} onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
