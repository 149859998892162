import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import Loader from "../../components/loader";
import { SetLabelDropdown, SetLabelInput } from "../../components/modal_input";
import { get } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";

const GET_CITIES = EndPoints.BOOST_DOMAIN + EndPoints.GET_CITY_STATES;

const useStyles = makeStyles((theme) => ({
  rowWrap: {
    display: "flex",
    flexDirection: "row",
  },
  footer: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  saveBtn: {
    width: "15%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.background,
      opacity: 0.9,
    },
  },
  cancelBtn: {
    width: "15%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
  },
}));

const genderList = ["Male", "Female", "I prefer not to say"];
const countryList = ["USA"];
const customerList = ["Subscriber"];

const AddNewCustomer = (props) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(props.show);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isDisabled, setDisable] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState(null);
  const [customerTypes, setCustomerTypes] = useState(customerList);
  const [selectedCustomer, setSelectedCustomer] = useState("Select Type");
  const [genders, setGenders] = useState(genderList);
  const [selectedGender, setSelectedGender] = useState("Select Gender");
  const [dob, setDob] = useState(null);
  const [countries, setCountries] = useState(countryList);
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [zip, setZip] = useState(null);
  const [street, setStreet] = useState("");

  const onOpen = () => {
    setShowModal(true);
  };

  const onHide = () => {
    setShowModal(false);
    props.onHide();
    clearInputs();
  };

  const onCancel = () => {
    clearInputs();
    onHide();
  };

  const onSave = () => {
    // validate();
  };

  const clearInputs = () => {
    setName("");
    setEmail("");
    setLastName("");
    setPhone(null);
    setSelectedCustomer("Select Customer");
    setSelectedGender("Select Gender");
    setSelectedCountry("Select Country");
    setSelectedCity("");
    setZip(null);
    setStreet("");
  };

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
    setCities(props.data);
  }, [props.show]);

  useEffect(() => {
    if (
      name === "" ||
      email === "" ||
      lastName === "" ||
      phone === null ||
      phone.length !== 10 ||
      selectedCustomer === "Select Customer" ||
      selectedCustomer === "" ||
      selectedGender === "Select Gender" ||
      selectedGender === "" ||
      dob === null ||
      selectedCountry === "Select Country" ||
      selectedCountry === "" ||
      selectedCity === "" ||
      selectedCity === "" ||
      zip === null ||
      zip.length < 5 ||
      street === ""
    )
      setDisable(true);
    else setDisable(false);
  }, [
    name,
    email,
    lastName,
    phone,
    selectedCustomer,
    selectedGender,
    dob,
    selectedCountry,
    selectedCity,
    zip,
    street,
  ]);

  return (
    <div className={classes.modalContainer}>
      <Modal size="sm" show={showModal} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.rowWrap}>
            <SetLabelInput
              label={"Name"}
              inputName={"name"}
              inputType={"text"}
              placeholder={"Name"}
              valueVariable={name}
              onChangeHandler={setName}
              required
            />
            <SetLabelInput
              label={"Email"}
              inputName={"email"}
              inputType={"email"}
              placeholder={"Email"}
              valueVariable={email}
              onChangeHandler={setEmail}
              required
            />
            {/* {getLabelInput("Name", "name", "text", "Name", name, setName)},
            {getLabelInput("Email", "email", "email", "Email", email, setEmail)} */}
          </div>
          <div className={classes.rowWrap}>
            <SetLabelInput
              label={"Last Name"}
              inputName={"lastName"}
              inputType={"text"}
              placeholder={"Last Name"}
              valueVariable={lastName}
              onChangeHandler={setLastName}
              required
            />
            <SetLabelInput
              label={"Phone"}
              inputName={"phone"}
              inputType={"text"}
              placeholder={"Phone"}
              valueVariable={phone}
              onChangeHandler={setPhone}
              required
            />
            {/* {getLabelInput(
              "Last Name",
              "lastName",
              "text",
              "Last Name",
              lastName,
              setLastName
            )}
            {getLabelInput("Phone", "phone", "text", "Phone", phone, setPhone)} */}
          </div>
          <div className={classes.rowWrap}>
            {/* <div style={{ width: "50%", display: "flex" }}> */}
            <SetLabelDropdown
              label={"Customer Type"}
              title={selectedCustomer}
              itemList={customerTypes}
              selectCallback={setSelectedCustomer}
            />
            <SetLabelDropdown
              label={"Gender"}
              title={selectedGender}
              itemList={genders}
              selectCallback={setSelectedGender}
            />
            <SetLabelInput
              label={"Date of Birth"}
              inputName={"dob"}
              inputType={"date"}
              placeholder={"Date of Birth"}
              valueVariable={dob}
              onChangeHandler={setDob}
              required
            />
            {/* {getLabelDropdown(
              "Customer Type",
              selectedCustomer,
              customerTypes,
              setSelectedCustomer
            )}
            {getLabelDropdown(
              "Gender",
              selectedGender,
              genders,
              setSelectedGender
            )}
            {getLabelInput(
              "Date of Birth",
              "dob",
              "date",
              "Date of Birth",
              dob,
              setDob
            )} 
            */}
          </div>
          <div className={classes.rowWrap}>
            <div
              style={{ width: "50%", display: "flex", alignItems: "center" }}
            >
              <SetLabelDropdown
                label={"Country"}
                title={selectedCountry}
                itemList={countries}
                selectCallback={setSelectedCountry}
              />
              <SetLabelDropdown
                label={"City"}
                title={selectedCity}
                itemList={cities}
                selectCallback={setSelectedCity}
                autocomplete={{ state: true, value: selectedCity }}
              />
              {/* {getLabelDropdown(
                "Country",
                selectedCountry,
                countries,
                setSelectedCountry
              )}
              {getLabelDropdown("City", selectedCity, cities, setSelectedCity, {
                state: true,
                value: selectedCity,
              })} */}
            </div>
            <SetLabelInput
              label={"Zip"}
              inputName={"zip"}
              inputType={"number"}
              placeholder={"ZipCode"}
              valueVariable={zip}
              onChangeHandler={setZip}
              required
            />
            {/* {getLabelInput("Zip", "zip", "number", "ZipCode", zip, setZip)} */}
          </div>
          <div className={classes.rowWrap}>
          <SetLabelInput
              label={"Street"}
              inputName={"street"}
              inputType={"text"}
              placeholder={"Street"}
              valueVariable={street}
              onChangeHandler={setStreet}
              required
            />
            {/* {getLabelInput(
              "Street",
              "street",
              "text",
              "Street",
              street,
              setStreet
            )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={classes.footer}>
            <Button className={classes.cancelBtn} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveBtn}
              disabled={isDisabled}
              onClick={!isDisabled && onSave}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewCustomer;
