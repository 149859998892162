import Deals from "../webpages/deals";
import FeaturedDeals from "../webpages/featured_deals";
import Merchants from "../webpages/merchants";
import Categories from "../webpages/categories";
import Coins from "../webpages/coins";
import Customers from "../webpages/customers";
import Segments from "../webpages/segments";
import MyProfile from "../webpages/profile";
import PortalUsers from "../webpages/portal_users";
import Themes from "../webpages/themes";
import Import from "../webpages/import";

import Tag from "../icons/tag.png";
import Dollar from "../icons/points.png";
import UserPic from "../icons/user.png";
import GearIcon from "../icons/settings.png";
import MerchantDetails from "../webpages/childpages/detail_merchant";
import DealDetails from "../webpages/childpages/detail_deal";
import CategoryDetails from "../webpages/childpages/detail_category";

export const ROUTES = [
  {
    label: "Deals",
    logo: Tag,
    subRoutes: [
      {
        //Set as homepage
        path: "/deals",
        component: Deals,
        label: "Deals",
      },
      {
        path: "/deals/featured",
        component: FeaturedDeals,
        label: "Featured",
      },
      {
        path: "/merchants",
        component: Merchants,
        label: "Merchants",
      },
      {
        path: "/categories",
        component: Categories,
        label: "Categories",
      },
    ],
  },
  {
    path: "/coins",
    component: Coins,
    label: "Coins",
    logo: Dollar,
  },
  // {
  //   label: "Customers",
  //   logo: UserPic,
  //   subRoutes: [
  //     {
  //       path: "/customers",
  //       label: "All Customers",
  //       component: Customers,
  //     },
  //     {
  //       path: "/segments",
  //       label: "Segments",
  //       component: Segments,
  //     },
  //   ],
  // },
  {
    label: "Settings",
    logo: GearIcon,
    subRoutes: [
      //   label: "Portal Users",
      //   component: PortalUsers,
      // },
      // {
      //   path: "/settings/themes",
      //   label: "Themes",
      //   component: Themes,
      // },
      // {
      //   path: "/merchants",
      //   component: Merchants,
      //   label: "Merchants",
      // },
      // {
      //   path: "/settings/myProfile",
      //   component: MyProfile,
      // },
      // {
      //   path: "/settings/portalUsers",
      //   label: "Portal Users",
      //   component: PortalUsers,
      // },
      // {
      //   path: "/settings/themes",
      //   label: "Themes",
      //   component: Themes,
      // },
      {
        path: "/settings/import",
        label: "Import",
        component: Import,
      },
    ],
  },
];

export const CHILD_ROUTES = [
  {
    path: "/merchants/detail:id",
    label: "Merchants",
    component: MerchantDetails,
  },
  {
    path: "/deals/detail:id",
    label: "Deals",
    component: DealDetails,
  },
  {
    path: "/category/detail:id",
    label: "Category",
    component: CategoryDetails,
  },
];
