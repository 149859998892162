import React, { useEffect, useState } from "react";
import { BreadcrumbsHeader } from "../components/header";
import { TableLoader as Loader } from "../components/loader";
import UtilityBar from "../components/utility_bar";
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddNewCustomer from "./modals/add_new_customer";
import { get } from "../webservices/apiservice";
import EndPoints from "../webservices/endpoints";
import ErrorPage from "../components/error_page";

const useStyles = makeStyles(theme => ({
  button: {
    height: "fit-content",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.dullGrey,
    },
    marginTop: 15,
    marginRight: 15,
  },
}));

const Customers = props => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [cityState, setCityState] = useState([]);
  const handleShowModal = () => {
    setModal(true);
  };

  const handleHideModal = () => {
    setModal(false);
  };

  const cityUri = EndPoints.BOOST_DOMAIN + EndPoints.GET_CITY_STATES;
  useEffect(async () => {
    try {
      let cityList = await get(cityUri);
      cityList = cityList.map(city => {
        return city.city + ", " + city.state;
      });
      cityList = cityList.sort();
      setCityState(cityList);
    } catch (error) {}
  }, []);

  return (
    <div>
      <BreadcrumbsHeader title={"Customer"} />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorPage data={"no-vpn"} />
      ) : (
        <>
          <UtilityBar
            button={
              <Button className={classes.button} variant="filled" startIcon={<AddIcon />} onClick={handleShowModal}>
                Add Customer
              </Button>
            }
          />
          <ErrorPage data={"work-in-progress"} />
        </>
      )}
      <AddNewCustomer show={modal} onHide={handleHideModal} data={cityState} />
    </div>
  );
};

export default Customers;
