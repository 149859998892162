import React, { useState, useEffect } from "react";
import "./App.css";
import SideNavBar from "./sidebar/sidenavbar";
import { ThemeProvider, makeStyles, Box } from "@material-ui/core";
import theme from "./config/theme";
import { BrowserRouter, Route } from "react-router-dom";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { ROUTES, CHILD_ROUTES } from "./config/routes";
import Login from "./webpages/login";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "./config/awsexport";

Amplify.configure(AwsConfig);
Auth.configure();

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    fontFamily: theme.typography.fontFamily,
    alignItems: "stretch",
  },
  sidebar: {
    width: "15%",
  },
  main: {
    backgroundColor: theme.palette.primary.background,
    width: "85%",
    height: "100vh",
  },
  cacheWrapper: {
    height: "100%",
  },
}));

const App = props => {
  //constants
  const appStyles = useStyles();

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CacheSwitch>
            <Route exact path={"/"} component={Login} key={"route-root-login"} />
          </CacheSwitch>
          <Box display="flex" flexDirection="row">
            <Box order={1} className={appStyles.sidebar}>
              <SideNavBar />
            </Box>
            <Box order={2} className={appStyles.main}>
              <CacheSwitch>
                {ROUTES.map((route, index) => {
                  return route.hasOwnProperty("subRoutes") ? (
                    route.subRoutes.map((subRoute, index) => {
                      return <CacheRoute className={appStyles.cacheWrapper} exact path={subRoute.path} component={subRoute.component} key={`route-${index}`} />;
                    })
                  ) : (
                    <CacheRoute className={appStyles.cacheWrapper} exact path={route.path} component={route.component} key={`route-${index}`} />
                  );
                })}
                {CHILD_ROUTES.map((child, index) => {
                  return (
                    <Route className={appStyles.cacheWrapper} exact path={child.path} component={child.component} key={`route-edit-${child.label}-${index}`} />
                  );
                })}
              </CacheSwitch>
            </Box>
          </Box>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
