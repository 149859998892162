import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const utilityBarStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: 'center',
    margin: `5px 10px 10px 10px`,
  },
  container1: {
    display: "flex",
    width: "max-content",
    justifyContent: "flex-start",
    // minWidth: "50%",
  },
  container2: {
    display: "flex",
    minWidth: "max-content",
    justifyContent: "flex-end",
  },
}));

const UtilityBar = props => {
  const classes = utilityBarStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container1}>{props.searchBar}</div>
      <div className={classes.container2}>{props.button}</div>
    </div>
  );
};

export default UtilityBar;
