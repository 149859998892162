import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import DishLogo from "../icons/dish-logo-svg.svg";
import Beacon from "../icons/boost_perks_beacon.png";
import RightArrow from "../icons/right_arrow.svg";
import { Auth, Hub } from "aws-amplify";
import { withOAuth } from "aws-amplify-react";
import { useHistory } from "react-router-dom";
import EndPoints from "../webservices/endpoints";

const loginStyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    alignSelf: "center",
    backgroundColor: "#246AEB",
  },
  formHolder: {
    width: "40%",
    height: "45vh",
    backgroundColor: "#FFFFFF",
    borderRadius: 3,
    margin: "auto",
    transform: "translateY(40%)",
    display: "flex",
    flexDirection: "column",
  },
});

const Login = props => {
  const browseHistory = useHistory();
  const [hasAccount, setHasAccount] = useState(false);
  const [userEmailOrId, setUserEmailOrId] = useState("");
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [isRedirection, setIsRedirection] = useState(browseHistory.location.search !== "");

  useEffect(() => {
    if (props.location?.state === "logout") {
      setIsLoginInProgress(false);
      EndPoints.logOut();
      Auth.signOut();
      sessionStorage.removeItem("jwt");
      return;
    }

    Auth.currentSession().then(cred => {
      userSignIn(undefined);
    });
  }, []);

  const onUserInput = event => {
    setUserEmailOrId(event.target.value);
  };

  const userSignIn = (userId, redirectUrl = null) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        updateAuthToken(true, redirectUrl);
      })
      .catch(error => {
        setIsLoginInProgress(true);
        Auth.federatedSignIn({ "provider": "BoostOneAdmin" });
      });
  };

  const updateAuthToken = (isToRedirect, redirectUrl = null) => {
    Auth.currentSession().then(cred => {
      console.log("AUTH currentSession " + JSON.stringify(cred));
      let jwtToken = cred.getAccessToken().getJwtToken();
      sessionStorage.setItem("jwt", jwtToken);
      EndPoints.updateRequestHeader(jwtToken);
      // setIsRedirection(false);
      if (isToRedirect && props.location?.state === undefined) {
        redirectUrl === null ? browseHistory.push({ pathname: "/deals" }) : browseHistory.push({ pathname: redirectUrl });
      } else if (isToRedirect && props.location?.state !== undefined) {
        browseHistory.push({ pathname: props.location?.state });
      }
    });
  };

  const listener = data => {
    console.log("listener" + JSON.stringify(data));
    switch (data.payload.event) {
      case "signIn":
        updateAuthToken(true);
        break;
      case "signOut":
        break;
      case "signIn_failure":
        setIsLoginInProgress(false);
        break;
    }
  };

  Hub.listen("auth", listener);

  return (
    <div className={loginStyles().container}>
      <div style={{ position: "fixed", height: 80, width: 300, display: "flex", alignItems: "center", marginLeft: 16 }}>
        <img src={DishLogo} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: 80,
          width: "100%",
          backgroundColor: "#FFFFFF",
          justifyContent: "center",
        }}>
        <label style={{ fontFamily: "Montserrat", fontSize: 27, fontWeight: 700, color: "#FE6100" }}>Perks Management Dashboard</label>
      </div>

      {isRedirection ? (
        <div style={{ display: "flex", width: "100%", height: "90%", alignItems: "center", justifyContent: "center" }}>
          <label style={{ fontFamily: "Roboto", fontSize: 30, color: "#FFFFFF", fontWeight: 700, letterSpacing: 1 }}>Redirecting... Please Wait...</label>
        </div>
      ) : (
        <div
          className={loginStyles().formHolder}
          style={{
            height: hasAccount ? "45vh" : "35vh",
            transform: hasAccount ? "translateY(40%)" : "translateY(65%)",
          }}>
          <div
            style={{
              margin: "auto",
              width: "100%",
              height: 100,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              marginTop: -35,
            }}>
            <img src={Beacon} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: hasAccount ? "75%" : "70%",
              position: "absolute",
              bottom: 0,
              alignItems: "center",
            }}>
            {/* {hasAccount ? 
              <>
                <label style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 700, color: '#7F7F7F'}}>
                  Select An Account
                </label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 48, marginBottom: 24, borderRadius: 3, borderStyle: 'solid', borderWidth: 1, borderColor: '#CFCFCF', width: '50%', marginTop: 8, padding: 16 }}>
                  <label style={{ textAlign: 'center', flex: 1, fontFamily: 'Roboto', fontSize: 16, fontWeight: 400, color: '#414243', outline: 'none' }}>manjunath.hegde@dish.com</label>
                  <div style={{ justifyContent: 'flex-end' }}>
                    <img src={RightArrow} alt="" />
                  </div>
                </div>
              </>
              :
              null
            } */}

            {/* <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
              <label style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600, color: '#9397AA'}}>
                {hasAccount ? 'OR ' : ''}Enter Email to Get Started
              </label>
              <input onChange={onUserInput} style={{ height: 48, borderRadius: 3, borderStyle: 'solid', borderWidth: 1, borderColor: '#CFCFCF', width: '50%', marginTop: 8, fontFamily: 'Roboto', fontSize: 14, fontWeight: 400, color: '#2E384D', outline: 'none', padding: 16, letterSpacing: '0.25px' }} placeholder="first.last@dish.com" />
            </div> */}

            <Button
              disabled={isLoginInProgress}
              onClick={userSignIn}
              style={{
                width: "50%",
                marginTop: 24,
                height: 48,
                backgroundColor: "#FE6100",
                fontFamily: "Montserrat",
                fontSize: 16,
                fontWeight: 700,
                color: "#FFFFFF",
                borderRadius: 3,
              }}>
              {isLoginInProgress ? "Signing In..." : "Sign In"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withOAuth(Login);
