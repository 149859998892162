import { makeStyles, Slider, Switch, withStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Whisper, Tooltip } from "rsuite";

const CustomSwitch = withStyles(theme => ({
  root: {
    width: 34,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(18px)",
      color: theme.palette.primary.main,
      "& + $track": {
        opacity: 1,
        backgroundColor: "white",
        borderColor: "lightgrey",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const Toggle = props => {
  const [state, setState] = useState(props.initialState);

  useEffect(() => {
    setState(props.initialState);
  }, [props.initialState]);

  useState(() => {}, [state]);
  const handleChange = () => {
    setState(!state);
    props.handler(props.id, !state);
  };
  return (
    <div className={props.containerStyle}>
      <CustomSwitch checked={state} onChange={handleChange} />
    </div>
  );
};

export default Toggle;

const triStateButtonStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 39,
    height: 19,
    padding: `0px 7.5px`,
    display: "flex",
    borderRadius: 10,
    backgroundColor: theme.palette.white,
    border: `1.4px solid lightgrey`,
    margin: `auto 10px`,
  },
}));

const CustomSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 10,
    "& .Mui-disabled": {
      marginTop: -1,
      marginLeft: -6,
      backgroundColor: `${theme.palette.dullGrey} !important`,
      border: "1px solid transparent !important",
      transition: "0.2s ease-in-out",
      height: 12,
      width: 12,
    },
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "1px solid grey",
    marginTop: -1,
    transition: "0.3s ease-in-out",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 0,
    width: 32,
    borderRadius: 4,
  },
  rail: {
    height: 0,
    width: 32,
    borderRadius: 4,
  },
}))(Slider);

export const TriStateToggle = props => {
  //constants
  const classes = triStateButtonStyles();

  //useStates
  const [value, setValue] = useState(2);
  const [state, setState] = useState(null);

  //useRef
  const sliderRef = useRef(null);
  const rootDivRef = useRef(null);

  //useEffect
  useEffect(() => {
    if (value === 3) {
      setState(true);
      props.handler(true);
      sliderRef.current.lastElementChild.style.backgroundColor = "#3c8dde";
      sliderRef.current.lastElementChild.style.border = "none";
    } else if (value === 1) {
      setState(false);
      props.handler(false);
      sliderRef.current.lastElementChild.style.backgroundColor = "#9e9e9e";
      sliderRef.current.lastElementChild.style.border = "none";
    }
  }, [value]);

  useEffect(() => {
    if (props.reset) {
      sliderRef.current.lastElementChild.style.backgroundColor = "#fff";
      sliderRef.current.lastElementChild.style.border = "1px solid grey";
      setValue(2);
      setState(null);
    }
  }, [props.reset]);

  //render
  return (
    <div ref={rootDivRef} className={classes.root}>
      <Whisper trigger="hover" key={`slider`} placement={"topStart"} speaker={<Tooltip>{props.tooltip}</Tooltip>}>
        <CustomSlider ref={sliderRef} min={1} max={3} value={value} onChange={(e, val) => val !== 2 && setValue(val)} disabled={props.disabled} />
      </Whisper>
    </div>
  );
};
