import React, { useState } from "react";
import { makeStyles, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from "@material-ui/core";
import { FlexboxGrid } from "rsuite";
import { Paragraph, Graph } from "rsuite/lib/Placeholder";
import { calcHeight, calcWidth } from "./table";

const loadStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "inherit",
    margin: 10,
    backgroundColor: theme.palette.white,
    borderRadius: "3px",
    overflowX: "auto",
    // position: "absolute",
  },
  tableContainer: {
    // width: calcWidth(15, 10),
    // maxHeight: calcHeight(25, 10),
  },
  toolBarContainer: {
    width: "100%",
    height: 50,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `10px 0px 10px 0px`,
  },
  leftToolbarContainer: {
    width: "20%",
    padding: `20px 0px 20px 10px`,
    display: "flex",
    alignItems: "center",
  },
  rightToolBarContainer: {
    width: "30%",
    padding: `20px 10px 20px 0px`,
    display: "flex",
    alignItems: "center",
  },
  cellContainer: {
    width: "max-content",
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#6F6F6F",
    height: "fit-content",
  },
  noCellPadding: {
    padding: "6px 24px 6px 16px",
  },
  gridCell: {
    borderRadius: 20,
  },
  placeholderText: {
    borderRadius: 8,
    marginLeft: 5,
  },
  placeholderButton: {
    borderRadius: 2,
    margin: 5,
  },
}));

export const TableLoader = props => {
  const classes = loadStyles();
  return (
    <Paper className={props.hasOwnProperty("containerStyles") ? props.containerStyles : classes.root}>
      <div className={classes.toolBarContainer}>
        <div className={classes.leftToolbarContainer}>
          <Graph active height={25} className={classes.gridCell} />
        </div>
        <div className={classes.rightToolBarContainer}>
          <Graph active height={25} className={classes.gridCell} />
        </div>
      </div>
      <TableContainer className={classes.tableContainer} style={props.hasOwnProperty("tableHeight") ? { maxHeight: props.tableHeight } : {}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {props.hasOwnProperty("checkbox") && <TableCell style={{ minWidth: 50 }}></TableCell>}
              {Object.entries(props.header).map(([key, value], index) => {
                return (
                  <TableCell key={`loader-table-cell-${index}`}>
                    <div className={classes.cellContainer}>{value}</div>
                  </TableCell>
                );
              })}
              {props.hasOwnProperty("hasEditOption") ? <TableCell className={classes.noCellPadding}></TableCell> : null}
              {props.hasOwnProperty("hasMoreOption") ? <TableCell className={classes.noCellPadding}></TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(props.hasOwnProperty("count") ? props.count : 25)].map((row, index) => {
              return (
                <TableRow key={`tabel-row-temp-${index}`}>
                  {props.hasOwnProperty("checkbox") && (
                    <TableCell>
                      <Graph active height={15} className={classes.gridCell} />
                    </TableCell>
                  )}
                  {Object.keys(props.header).map((cell, inI) => {
                    return (
                      <TableCell key={`table-cell-${inI}`} style={{ minWidth: 50 }}>
                        <Graph active height={15} className={classes.gridCell} />
                      </TableCell>
                    );
                  })}
                  {props.hasOwnProperty("hasEditOption") && (
                    <TableCell>
                      <Graph active height={15} className={classes.gridCell} />
                    </TableCell>
                  )}
                  {props.hasOwnProperty("hasMoreOption") && (
                    <TableCell>
                      <Graph active height={15} className={classes.gridCell} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const FilterLoader = props => {
  const classes = loadStyles();
  return (
    <div>
      {[...Array(4)].map((item, index) => {
        return (
          <div key={`filter-text-placeholder-${index}`} style={{ marginTop: 15, marginBottom: 10 }}>
            <Graph width={70} height={20} active className={classes.placeholderText} />
            <FlexboxGrid>
              {[...Array(6)].map((item, inIndex) => {
                return <Graph key={`filter-button-placeholder-${inIndex}`} active width={100} height={35} className={classes.placeholderButton} />;
              })}
            </FlexboxGrid>
          </div>
        );
      })}
    </div>
  );
};

export const CircularLoader = () => {
  return (
    <div style={{ height: "100%", width: "inherit", position: "absolute", marginLeft: "7.5%", top: "50%", left: "50%" }}>
      <CircularProgress color="primary" />
    </div>
  );
};
