import React from "react";
import { makeStyles, Typography, Breadcrumbs } from "@material-ui/core";
import { Header } from "rsuite";
import Logout from "./logout";
import { useHistory, Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    fontFamily: "Roboto",
    // position: "fixed",
    // width: "85%",
    height: 64,
    paddingLeft: 24,
    width: "100%",
  },
  title: {
    textDecoration: "none",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    // letterSpacing: 0.75,
    color: "#3489EC",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#2E384D",
    fontWeight: 400,
  },
  titleWrapper: {
    display: "flex",
    width: "auto",
    alignItems: "center",
  },
  wrapper: {
    marginRight: "25px",
  },
  containerWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: 100,
  },
}));

export const BreadcrumbsHeader = props => {
  const classes = useStyles();

  return (
    <Header className={classes.root}>
      <div className={classes.titleWrapper}>
        <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
          {props.options.map((option, index) =>
            index === option.order ? (
              option.hasOwnProperty("to") ? (
                <Link to={option.to} className={classes.title} key={`breadcrumb-${index}`}>
                  {option.title}
                </Link>
              ) : (
                <Typography className={classes.subtitle} key={`breadcrumb-${index}`}>
                  {option.title}
                </Typography>
              )
            ) : null
          )}
        </Breadcrumbs>
      </div>
      <div className={classes.containerWrapper}>
        <div className={classes.wrapper}>{props.notification}</div>
        <div className={classes.wrapper}>{props.user}</div>
        <div style={{ marginRight: 10 }}>
          <Logout />
        </div>
      </div>
    </Header>
  );
};
