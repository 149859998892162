import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, makeStyles, Typography, Button } from "@material-ui/core";
import { Button as IconButton, Radio, RadioGroup, Loader } from "rsuite";
import { globalStyles } from "../../config/theme";
import Papa, { unparse } from "papaparse";
import XLSX from "xlsx";
import Dossier from "../../icons/confirmation_modal/dossier.png";

import { post } from "../../webservices/apiservice";

const useStyles = makeStyles(theme => ({}));

const ExportModal = props => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [modal, setModal] = useState(props.modal);
  const [exportToOptions, setExportOptions] = useState(["csv", "xlsx", "json"]);
  const [exportTo, setExportTo] = useState(exportToOptions[0]);
  const [isLoading, setLoading] = useState(false);

  const handleModal = state => {
    typeof state === "boolean" && setModal(state);
  };

  const onHide = () => {
    setModal(false);
    props.onHide();
  };

  const remanmeObjectKeys = inputObject => {
    let resObject = {};
    Object.entries(props.pageConfig.csv_map).map(([key, value], index) => {
      resObject[value] = inputObject[key];
    });
    return resObject;
  };

  const handleExport = async () => {
    setLoading(true);
    let jsonResponse = [];
    if (props.selected.length > 0) {
      props.selected.map(idKey => {
        props.data.map(record => {
          if (record[props.pageConfig.id] === idKey) {
            jsonResponse.push(remanmeObjectKeys(record));
          }
        });
      });
      setLoading(false);
    } else {
      try {
        let response = await post(props.fetchQuery.url, props.fetchQuery.payload);
        if (response.hasOwnProperty("items")) {
          response.items.map(record => {
            jsonResponse.push(remanmeObjectKeys(record));
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    let exportData = "";
    let options = {};
    let fileDate = new Date()
      .toGMTString()
      .replace(/[\. ,:-]+/g, "-")
      .toUpperCase();
    if (jsonResponse.length > 0) {
      if (exportTo === "csv") {
        exportData = Papa.unparse(jsonResponse);
        options = { type: "text/csv;charset=utf-8;" };
        let fileData = new Blob([exportData], options);
        let fileUrl = window.URL.createObjectURL(fileData);
        let tempLink = document.createElement("a");
        tempLink.href = fileUrl;
        tempLink.setAttribute(
          "download",
          props.hasOwnProperty("filename") && props.filename !== ""
            ? `${props.filename}-${fileDate}.${exportTo}`
            : props.pageConfig.hasOwnProperty("filename")
            ? `${props.pageConfig.filename}-${fileDate}.${exportTo}`
            : `FILE-${fileDate}.${exportTo}`
        );
        tempLink.click();
      } else if (exportTo === "xlsx") {
        let ws = XLSX.utils.json_to_sheet(jsonResponse);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "EARN_POINTS");
        XLSX.writeFile(
          wb,
          props.hasOwnProperty("filename") && props.filename !== ""
            ? `${props.filename}-${fileDate}.${exportTo}`
            : props.pageConfig.hasOwnProperty("filename")
            ? `${props.pageConfig.filename}-${fileDate}.${exportTo}`
            : `FILE-${fileDate}.${exportTo}`
        );
      } else if (exportTo === "json") {
        exportData = JSON.stringify(jsonResponse);
        options = { type: "application/json" };
        let fileData = new Blob([exportData], options);
        let fileUrl = window.URL.createObjectURL(fileData);
        let tempLink = document.createElement("a");
        tempLink.href = fileUrl;
        tempLink.setAttribute(
          "download",
          props.hasOwnProperty("filename") && props.filename !== ""
            ? `${props.filename}-${fileDate}.${exportTo}`
            : props.pageConfig.hasOwnProperty("filename")
            ? `${props.pageConfig.filename}-${fileDate}.${exportTo}`
            : `FILE-${fileDate}.${exportTo}`
        );
        tempLink.click();
      }
      onHide();
    }
  };

  useEffect(() => {
    if (props.modal !== modal) {
      setModal(props.modal);
    }
  }, [props.modal]);

  return (
    <Dialog open={modal} onClose={onHide} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <div style={{ display: "flex" }}>
          <div className={classes.largeIcon}>
            <img src={Dossier} />
          </div>
          <div>
            <Typography variant="h6">Export to File</Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "75%",
                justifyContent: "space-between",
              }}>
              <Typography variant="subtitle1">
                {` Number of records : ${props.selected.length > 0 ? props.selected.length : props.fetchQuery.payload.limitPerPage}`}
              </Typography>
              <div>
                <RadioGroup inline name="radioList" value={exportTo} onChange={e => setExportTo(e)}>
                  {exportToOptions.map((option, i) => {
                    return (
                      <Radio key={`export-options-${i}`} value={option}>
                        {option}
                      </Radio>
                    );
                  })}
                </RadioGroup>
              </div>
              <Typography variant="subtitle1" style={{ fontSize: 12 }}>
                <b>Note</b>: Will be exported in <b>.{exportTo}</b> format
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          borderTop: "1px solid lightgrey",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Button onClick={onHide} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
          Cancel
        </Button>
        <div>
          {/* <Button
            onClick={handleExport}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
            Export
          </Button> */}
          <IconButton
            loading={isLoading}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757`, fontWeight: 500 }}
            onClick={handleExport}>
            Export
          </IconButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
