import React, { useState, useEffect } from "react";
import LogoutIcon from "../icons/logout.svg";
import { useHistory } from "react-router-dom";
import { Dialog, DialogContent, DialogActions, Typography, Button } from "@material-ui/core";
import { globalStyles } from "../config/theme";

const Logout = props => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const [dialog, setDialog] = useState(false);

  const handleDialogView = state => {
    typeof state === "boolean" && setDialog(state);
  };

  const onLogout = () => {
    history.push({
      pathname: "/",
      state: "logout",
    });
  };

  useEffect(() => {}, [dialog]);

  return (
    <>
      <div
        onClick={() => handleDialogView(true)}
        style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", width: 42, height: 42 }}>
        <img src={LogoutIcon} alt="" style={{ width: 24, height: 24 }} />
      </div>
      <Dialog open={dialog} onClose={() => handleDialogView(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div style={{ display: "flex" }}>
            <div>
              <img src={LogoutIcon} style={{ width: 75, height: 75, margin: 20 }} />
            </div>
            <div>
              <Typography variant="h6">Logout Confirmation</Typography>
              <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                You need to login again to use Dashboard
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: "1px solid lightgrey",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Button onClick={() => handleDialogView(false)} color="primary" variant="outlined" className={globalClasses.buttonSecondary}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onLogout();
              handleDialogView(false);
            }}
            className={globalClasses.buttonPrimary}
            style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }}>
            Confirm Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Logout;
