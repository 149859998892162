import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { globalStyles } from "../config/theme";
import ExportModal from "../webpages/modals/export_modal";
import { Button } from "rsuite";

const ConfirmationBox = props => {
  //constants
  const globalClasses = globalStyles();

  //useStates
  const [isLoading, setLoading] = useState(false);

  //useEffects
  useEffect(() => {}, [isLoading]);

  //functions

  const handleConfirm = e => {
    e.target.blur();
    props.dialog.onConfirm();
    setLoading(true);
  };

  const onClose = () => {
    setLoading(false);
    props.dialog.onCancel();
  };

  //useEffect
  useEffect(() => {
    setLoading(false);
  }, [props.open]);

  //render
  return (
    <>
      {props.hasOwnProperty("dialog") && props.dialog.type === "export" ? (
        <ExportModal
          modal={props.open}
          onHide={props.dialog.onCancel}
          data={props.dialog.data}
          selected={props.dialog.selected}
          pageConfig={props.dialog.pageConfig}
          filename={props.dialog.filename}
        />
      ) : (
        <Dialog open={props.open} onClose={onClose}>
          <DialogContent>
            <DialogTitle style={{ padding: 0 }}>{`Confirm ${props.dialog.title}`}</DialogTitle>
            <div style={{ padding: `5px 30px`, width: "20vw" }}>
              {props.dialog.description !== null &&
                props.dialog.description !== undefined &&
                props.dialog.description.map((desc, i) => {
                  return <Typography>{desc}</Typography>;
                })}
              <Typography style={{ marginTop: 5, marginBottom: 5, whiteSpace: "break-spaces", fontSize: 12, fontStyle: "italic" }}>
                {props.dialog.helperText}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: `0px 10px 10px 10px` }}>
            <Button className={globalClasses.buttonSecondary} onClick={onClose}>
              Cancel
            </Button>
            <Button className={globalClasses.buttonPrimary} onClick={handleConfirm} loading={isLoading}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmationBox;
