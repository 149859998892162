import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Notification, Toggle, Uploader, Button } from "rsuite";

import EndPoints from "../../webservices/endpoints";
import LabelInput from "../../components/label_input";

import "../../custom_styles/modal_custom_styles.css";
import Auth from "@aws-amplify/auth";
import { Storage } from "aws-amplify";
import { get, post, put } from "../../webservices/apiservice";

const AddNewCategory = props => {
  const [showModal, setShowModal] = useState(props.show);

  const [categoryName, setCategoryName] = useState("");
  const [isCategoryNameError, setIsCategoryNameError] = useState(false);

  const [categoryDisplayName, setCategoryDisplayName] = useState("");
  const [isCategoryDisplayNameError, setIsCategoryDisplayNameError] = useState(false);

  const categoryTypesDict = {
    "Main Category": "maincategory",
    "Sub Category": "subcategory",
    "Cuisine Type": "cuisinetype",
  };
  const categoryTypesList = ["Main Category", "Sub Category", "Cuisine Type"];
  const [categoryType, setCategoryType] = useState("Main Category");

  const [isParent, setIsParent] = useState(true);
  const [parentCategories, setParentCategories] = useState([]);
  const [parentCategoryName, setParentCategoryName] = useState("None");

  const [categoryDescription, setCategoryDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState(false);

  const [isActivated, setIsActivated] = useState(true);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const [errorText, setErrorText] = useState("An error occurred while saving (Status Code 500)");

  const [iconUrl, setIconUrl] = useState("");
  const [iconPath, setIconPath] = useState("");
  const [featuredPhotoUrl, setFeaturedPhotoUrl] = useState("");
  const [featuredPhotoPath, setFeaturedPhotoPath] = useState("");

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [iconFile, setIconFile] = useState(null);
  const [iconFileInfo, setIconFileInfo] = useState("");
  const [featuredPhotoFile, setFeaturedPhotoFile] = useState(null);
  const [featuredPhotoFileInfo, setFeaturedPhotoFileInfo] = useState("");

  const [iconFileIsUploading, setIconFileIsUploading] = useState(false);
  const [featuredPhotoIsUploading, setFeaturedPhotoIsUploading] = useState(false);

  const [displayOrder, setDisplayOrder] = useState("999");
  const [isChecked, setIsChecked] = useState(false);

  function onShow() {
    setShowModal(true);
  }

  function onHide() {
    setCategoryName("");
    setCategoryDisplayName("");
    setCategoryDescription("");
    setCategoryType("Main Category");
    setParentCategoryName("None");
    setIsParent(true);
    setIsSaveInProgress(false);
    setIsSaveError(false);
    setIconPath("");
    setFeaturedPhotoPath("");
    setIsCategoryNameError(false);
    setIsCategoryDisplayNameError(false);
    setIsDescriptionError(false);
    setHasUnsavedChanges(false);
    setIconFile(null);
    setIconUrl("");
    setFeaturedPhotoFile(null);
    setFeaturedPhotoUrl("");
    setFeaturedPhotoIsUploading(false);
    setIconFileIsUploading(false);
    setDisplayOrder("999");
    setFeaturedPhotoFileInfo("");
    setErrorText("An error occurred while saving (Status Code 500)");
    props.onHide();
  }

  function updateUnsavedChangeStatus() {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  }

  function displayUnsavedWarning() {
    let retVal = false;
    if (hasUnsavedChanges) {
      retVal = window.confirm("You have unsaved changes. Are you sure to close without saving?");
    }
    return retVal;
  }

  function onUserTextInput(name, value) {
    if (name === "category_name") {
      setCategoryName(value);
      if (isCategoryNameError) {
        setIsCategoryNameError(false);
      }
    } else if (name === "display_name") {
      setCategoryDisplayName(value);
      if (isCategoryDisplayNameError) {
        setIsCategoryDisplayNameError(false);
      }
    } else if (name === "category_description") {
      setCategoryDescription(value);
      if (isDescriptionError) {
        setIsDescriptionError(false);
      }
    } else if (name === "display_order") {
      setDisplayOrder(value);
    }
    updateUnsavedChangeStatus();
  }

  function isParentCategory(value) {
    setIsParent(value);
  }

  function onTypeSelect(key) {
    setCategoryType(categoryTypesList[key]);
    isParentCategory(categoryTypesList[key] === "Main Category");
    updateUnsavedChangeStatus();
  }

  function onParentCategorySelect(index) {
    let item = parentCategories[index];
    let name = item["key_" + index]["name"];
    setParentCategoryName(name);
    updateUnsavedChangeStatus();
  }

  function isCategoryActivated(value) {
    setIsActivated(value);
    updateUnsavedChangeStatus();
  }

  function validateFields() {
    let hasError = false;

    if (categoryName === undefined || categoryName === "") {
      setIsCategoryNameError(true);
      hasError = true;
    }

    if ((categoryDisplayName === undefined || categoryDisplayName === "")) {
      setIsCategoryDisplayNameError(true);
      hasError = true;
    }

    return hasError;
  }

  function getParentCategoryName(item) {
    let key = Object.keys(item)[0];
    let name = item[key]["name"];

    return name;
  }

  function onCancel() {
    if (displayUnsavedWarning() || !hasUnsavedChanges) {
      onHide();
    }
  }

  async function onSave(onUpload = null, iconUrlParam = null, iconPathParam = null, photoUrl = null, photoPath = null) {
    if (validateFields()) {
      return;
    }

    if (isSaveInProgress && onUpload === null) {
      return;
    }

    setIsSaveInProgress(true);
    setIsSaveError(false);

    if (iconFile !== null && iconUrlParam === null && onUpload !== "logoUpload") {
      uploadImageToS3(true);
      return;
    }

    if (featuredPhotoFile !== null && photoUrl === null && onUpload !== "photoUpload") {
      uploadFeaturedImageToS3(true);
      return;
    }

    let postData = {
      category_name: categoryName,
      display_name: categoryDisplayName,
      category_type: categoryTypesDict[categoryType],
      parent_category: isParent ? "" : parentCategoryName,
      // description: categoryDescription,
      category_active: isActivated,
      icon: iconUrlParam === null ? iconUrl : iconUrlParam,
      icon_path: iconPathParam === null ? iconPath : iconPathParam,
      featured_photo: photoUrl === null ? featuredPhotoUrl : photoUrl,
      featured_photo_path: photoPath === null ? featuredPhotoPath : photoPath,
      display_order: displayOrder === "" ? "999" : displayOrder,
    };

    if (props.isEdit) {
      let putUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.PUT;
      postData["category_id"] = props.editData.category_id;
      try {
        //PUT
        await put(putUrl, postData);
        setIsSaveError(false);
        onHide();
        Notification["success"]({
          title: "Success",
          description: `${categoryDisplayName} successfully updated...`,
        });
        if (props.hasOwnProperty("onModalSubmit")) {
          props.onModalSubmit();
        }
        if (props.hasOwnProperty("handleOnSave")) {
          props.handleOnSave(postData);
        }
        setIsSaveInProgress(false);
      } catch (error) {
        setIsSaveError(true);
        if ( error?.statusCode === 500 && error?.statusText !== "" ) {
          setErrorText(error.statusText);
        }
        Notification["error"]({
          title: "Error",
          description: `Failed to update ${categoryDisplayName}`,
        });
        setIsSaveInProgress(false);
      }
    } else {
      //POST
      try {
        let postUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.POST;
        await post(postUrl, postData);
        setIsSaveError(false);
        onHide();
        Notification["success"]({
          title: "Success",
          description: `${categoryDisplayName} successfully created...`,
        });
        if (props.hasOwnProperty("onModalSubmit")) {
          props.onModalSubmit();
        }
        if (props.hasOwnProperty("handleOnSave")) {
          props.handleOnSave(postData);
        }
        setIsSaveInProgress(false);
      } catch (errorResp) {
        setIsSaveError(true);
        if ( errorResp?.statusCode === 500 && errorResp?.statusText !== "" ) {
          setErrorText(errorResp.statusText);
        }
        Notification["error"]({
          title: "Error",
          description: `Failed to create ${categoryDisplayName}`,
        });
        setIsSaveInProgress(false);
      }
    }
  }

  function onUploadQueueChange(evt) {
    if (evt.target.files !== undefined) {
      let file = evt.target.files[0];
      setIconFile(file);
      setIconFileInfo("");
    }
  }

  function onFeaturePhotoUploadQueueChange(evt) {
    if (evt.target.files !== undefined) {
      let file = evt.target.files[0];
      setFeaturedPhotoFile(file);
      setFeaturedPhotoFileInfo("");
    }
  }

  function getFileSize(fileObj, infoObject, infoSetter) {
    let inbytes = fileObj.size;
    let divider = inbytes <= 500000 ? 1000 : 1000000;
    let unit = divider === 1000 ? "KB" : "MB";

    let size = parseFloat(inbytes / divider).toFixed(2) + " " + unit;

    if (infoObject === "") {
      let tempImg = new Image();
      tempImg.src = window.URL.createObjectURL(fileObj);
      tempImg.onload = () => {
        infoSetter(", " + tempImg.width + "px x " + tempImg.height + "px");
      };
    }
    return size;
  }

  function getFileName(fileObj) {
    let name = fileObj.name;
    const MAX_LENGTH = 15;
    if (name.length > MAX_LENGTH) {
      let lastIndex = name.lastIndexOf(".");
      let ext = name.substring(lastIndex, name.length);
      name = name.substring(0, 5) + "..." + name.substring(lastIndex - ext.length, lastIndex) + ext;
    }
    return name;
  }

  function uploadImageToS3(evt) {
    // console.log("*** uploadImageToS3 ***");
    Auth.currentUserCredentials().then(auth => {
      setIconFileIsUploading(true);
      Storage.put("boostone/category/images/" + iconFile.name, iconFile, {
        level: "public",
        contentType: iconFile.type,
      })
        .then(result => {
          setIconUrl(EndPoints.CLOUD_FRONT + "public/" + result["key"]);
          setIconPath(result["key"]);
          setIconFile(null);
          setIconFileIsUploading(false);
          if (evt === true) {
            onSave("logoUpload", EndPoints.CLOUD_FRONT + "public/" + result["key"], result["key"]);
          }
        })
        .catch(error => {
          // console.log("*** uploadImageToS3 Failed ***");
          setIconFileIsUploading(false);
        });
    });
  }

  function uploadFeaturedImageToS3(evt) {
    Auth.currentUserCredentials().then(auth => {
      setFeaturedPhotoIsUploading(true);
      Storage.put("boostone/category/featured/images/" + featuredPhotoFile.name, featuredPhotoFile, {
        level: "public",
        contentType: featuredPhotoFile.type,
      })
        .then(result => {
          setFeaturedPhotoUrl(EndPoints.CLOUD_FRONT + "public/" + result["key"]);
          setFeaturedPhotoPath(result["key"]);
          setFeaturedPhotoFile(null);
          setFeaturedPhotoIsUploading(false);
          setHasUnsavedChanges(true);
          if (evt === true) {
            onSave("photoUpload", null, null, EndPoints.CLOUD_FRONT + "public/" + result["key"], result["key"]);
          }
        })
        .catch(error => {
          setFeaturedPhotoIsUploading(false);
        });
    });
  }

  function deleteIcon() {
    if (window.confirm("Are you sure you want to delete the icon?\nYou can upload a new icon after deleting!")) {
      setIconPath("");
      setIconUrl("");
      setIconFileInfo("");
      setHasUnsavedChanges(true);
    }
  }

  function deleteFeaturedPhoto() {
    if (window.confirm("Are you sure you want to delete the featured photo?\nYou can upload a new image after deleting!")) {
      setFeaturedPhotoPath("");
      setFeaturedPhotoUrl("");
    }
  }

  function getIconUrlPath() {
    let urlPath = iconUrl === null ? "" : iconUrl;
    if (iconUrl === "") {
      urlPath = iconPath === null ? "" : iconPath;
    }

    return urlPath;
  }

  const onCheckBoxToggle = evt => {
    setIsChecked(evt.target.checked);
  };

  const onIconUrlInput = evt => {
    setIconUrl(evt.target.value);
    setHasUnsavedChanges(true);
  };

  useEffect(async () => {
    let getRootCategoryUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET_ROOT_CATEGORY;
    try {
      const response = await get(getRootCategoryUrl);
      let categories = [];
      response.map((item, index) => {
        let key = "key_" + index;
        let value = {};
        value[key] = { id: item.category_id, name: item.category_name };
        categories.push(value);
        if (index === 0) {
          setParentCategoryName(item.category_name);
        }
        return item;
      });
      setParentCategories(categories);
    } catch (error) {
      console.log("GET ROOT CATEGORY ", error);
    }
  }, []);

  useEffect(() => {
    const hasIcon = () => {
      if (props.editData !== null && props.editData !== undefined) {
        if (props.editData.icon !== undefined && props.editData.icon !== null && props.editData.icon !== "") {
          setIconUrl(props.editData.icon);
        }
        if (props.editData.icon_path !== undefined && props.editData.icon_path !== null && props.editData.icon_path !== "") {
          setIconPath(props.editData.icon_path);
        }
      }

      if (props.editData !== null && props.editData !== undefined) {
        if (props.editData.featured_photo !== undefined && props.editData.featured_photo !== null && props.editData.featured_photo !== "") {
          setFeaturedPhotoUrl(props.editData.featured_photo);
        }
        if (props.editData.featured_photo_path !== undefined && props.editData.featured_photo_path !== null && props.editData.featured_photo_path !== "") {
          setFeaturedPhotoPath(props.editData.featured_photo_path);
        }
      }
    };

    const initForEdit = () => {
      if (props.editData !== undefined) {
        let data = props.editData;

        setCategoryName(data.category_name);
        setCategoryDisplayName(data.display_name !== null ? data.display_name : "");
        setCategoryDescription(data.description !== null ? data.description : "");

        if (data.category_type !== null) {
          switch (data.category_type.toLowerCase()) {
            case "maincategory":
              setCategoryType("Main Category");
              setIsParent(true);
              break;
            case "subcategory":
              setCategoryType("Sub Category");
              setIsParent(false);
              break;
            case "detailcategory":
              setCategoryType("Detail Category");
              setIsParent(false);
              break;
            case "cuisinetype":
            case "cusinetype":
              setCategoryType("Cuisine Type");
              setIsParent(false);
              break;
            default:
              break;
          }
        } else {
          setCategoryType("Main Category");
          setIsParent(true);
        }

        if (data.parent_category === null || data.parent_category === "") {
          setParentCategoryName("None");
        } else {
          setParentCategoryName(data.parent_category);
        }
        // setParentCategoryName(data.parent_category !== "" ? data.parent_category : "None");
        setIsActivated(data.category_active === true);
        setDisplayOrder(data.display_order);
        hasIcon();
      }
    };

    if (props.isEdit === true) {
      initForEdit();
    }
  }, [props.isEdit]);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  useEffect(() => {}, [
    showModal,
    categoryName,
    categoryDisplayName,
    categoryType,
    parentCategoryName,
    categoryDescription,
    isActivated,
    isCategoryNameError,
    isCategoryDisplayNameError,
    isDescriptionError,
    parentCategories,
    isParent,
    displayOrder,
    props.editData,
    errorText
  ]);

  useEffect(() => {}, [isSaveInProgress]);

  const title = props.isEdit ? "Edit Deal Category" : "Add New Category";

  return (
    <Modal show={showModal} onShow={onShow} onHide={onCancel} size="md">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <label
        style={{
          marginTop: 4,
          position: "absolute",
          right: 0,
          paddingRight: 16,
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: 12,
          color: "#FF0000",
        }}>
        (* marked fields are mandatory)
      </label>

      <div style={{ alignSelf: "center", paddingTop: 25 }}>
        {props.isEdit ? <LabelInput value={props.editData.category_id} label={"Category ID"} name={"category_id"} disabled={true} /> : null}
        <LabelInput
          value={categoryName}
          label={"Category Name"}
          isMandatory={true}
          placeholder={"Ex. Fitness Products"}
          name={"category_name"}
          onInputChange={onUserTextInput}
          isEmptyError={isCategoryNameError}
          required
        />
        <LabelInput
          value={categoryDisplayName}
          label={"Display Name"}
          isMandatory={true}
          placeholder={"Ex. Fitness Products"}
          name={"display_name"}
          onInputChange={onUserTextInput}
          isEmptyError={isCategoryDisplayNameError}
          required
        />

        <div
          style={{
            height: 48,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <div style={{ flex: 0.3, textAlign: "right" }}>
            <label
              style={{
                fontFamily: "Roboto",
                color: "#7F7F7F",
                fontWeight: 500,
                fontSize: 14,
              }}>
              Category Type<sup style={{ color: "#FF0000" }}> *</sup>
            </label>
          </div>
          <div style={{ flex: 0.065 }}></div>
          <div style={{ flex: 0.3, textAlign: "left" }}>
            <Dropdown title={categoryType} onSelect={onTypeSelect}>
              {categoryTypesList.map((item, index) => {
                return (
                  <Dropdown.Item key={`category-type-list-dropdown-${index}`} eventKey={index} active={categoryType === item}>
                    {item}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </div>
        </div>

        <div
          style={{
            height: 48,
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}>
          <div style={{ flex: 0.3, textAlign: "right" }}>
            <label
              style={{
                fontFamily: "Roboto",
                color: "#7F7F7F",
                fontWeight: 500,
                fontSize: 14,
              }}>
              Parent Category
            </label>
          </div>
          <div style={{ flex: 0.065 }}></div>
          <div style={{ flex: 0.3, textAlign: "left" }}>
            <Dropdown title={parentCategoryName} onSelect={onParentCategorySelect} disabled={isParent}>
              {parentCategories.map((item, index) => {
                return (
                  <Dropdown.Item key={`category-parent-dropdown-${index}`} eventKey={index} active={categoryType === item}>
                    {getParentCategoryName(item)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 64,
            alignItems: "center",
          }}>
          <div style={{ flex: 0.3, textAlign: "right" }}>
            <label
              style={{
                fontFamily: "Roboto",
                color: "#7F7F7F",
                fontWeight: 500,
                fontSize: 14,
              }}>
              Category Icon
            </label>
          </div>
          <div style={{ flex: 0.065, height: "100%" }} />
          {getIconUrlPath() !== "" ? (
            <div
              style={{
                display: "flex",
                flex: 0.5,
                height: "100%",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <img src={getIconUrlPath()} alt="" style={{ width: 32, height: 32 }} />
              <label
                style={{
                  marginLeft: 32,
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#3489ec",
                  cursor: "pointer",
                }}
                onClick={deleteIcon}>
                Remove Icon
              </label>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 0.6,
                  height: "100%",
                  alignItems: "center",
                }}>
                <label
                  htmlFor="file_upload"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    fontWeight: 400,
                    borderWidth: 0,
                    borderRadius: 3,
                    backgroundColor: "#DFDFDF",
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}>
                  Choose File
                </label>
                <label
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#7F7F7F",
                    marginLeft: 8,
                    marginRight: 8,
                  }}>
                  {iconFile === null
                    ? ""
                    : iconFileIsUploading
                    ? "Uploading, please wait..."
                    : getFileName(iconFile) + " " + (getFileSize(iconFile, iconFileInfo, setIconFileInfo) + iconFileInfo)}
                </label>
                <input id="file_upload" type="file" onChange={onUploadQueueChange} style={{ display: "none" }} />

                <button
                  disabled={iconFile === null || iconFileIsUploading}
                  onClick={uploadImageToS3}
                  style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    borderRadius: 3,
                  }}>
                  Upload
                </button>
              </div>
            </>
          )}
        </div>

        {/* <div
          style={{
            marginTop: 4,
            marginBottom: 16,
            display: "flex",
            flexDirection: "row",
            height: 36,
            alignItems: "center",
          }}>
          <label
            style={{
              flex: 0.325,
              fontFamily: "Roboto",
              color: "#7F7F7F",
              fontWeight: 500,
              fontSize: "14px",
              textAlign: "end",
            }}>
            Icon URL
          </label>
          <div style={{ flex: 0.065 }}></div>
          <input type="checkbox" onChange={onCheckBoxToggle} style={{ width: 18, height: 18 }} />
          <input
            type="text"
            value={getIconUrlPath()}
            onChange={onIconUrlInput}
            disabled={!isChecked}
            style={{
              flex: 0.535,
              marginLeft: 16,
              border: "none",
              backgroundColor: "#EEF1F6",
              borderRadius: 3,
              paddingLeft: 8,
              paddingRight: 8,
              outline: "none",
              height: 32,
            }}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <div style={{ flex: 0.3, textAlign: "right" }}>
            <label
              style={{
                fontFamily: "Roboto",
                color: "#7F7F7F",
                fontWeight: 500,
                fontSize: 14,
              }}>
              Featured Photo
            </label>
          </div>
          <div style={{ flex: 0.065, height: "100%" }} />
          {featuredPhotoUrl !== "" ? (
            <div
              style={{
                display: "flex",
                flex: 0.6,
                height: "100%",
                alignItems: "center",
                flexDirection: "row",
              }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                  src={featuredPhotoUrl}
                  alt=""
                  style={{ borderRadius: 3, width: 240, height: 156 }}
                  onLoad={e => setFeaturedPhotoFileInfo(`${e.target.naturalWidth}px x ${e.target.naturalHeight}px`)}
                />
                <span style={{ fontSize: 13 }}>{featuredPhotoFileInfo}</span>
              </div>
              <label
                style={{
                  marginLeft: 32,
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#3489ec",
                  cursor: "pointer",
                }}
                onClick={deleteFeaturedPhoto}>
                Remove Photo
              </label>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 0.6,
                height: "100%",
                alignItems: "center",
              }}>
              <label
                htmlFor="featured_file_upload"
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  fontWeight: 400,
                  borderWidth: 0,
                  borderRadius: 3,
                  backgroundColor: "#DFDFDF",
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}>
                Choose File
              </label>
              <label
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#7F7F7F",
                  marginLeft: 8,
                  marginRight: 8,
                }}>
                {featuredPhotoFile === null
                  ? ""
                  : featuredPhotoIsUploading
                  ? "Uploading, please wait..."
                  : getFileName(featuredPhotoFile) +
                    " " +
                    (getFileSize(featuredPhotoFile, featuredPhotoFileInfo, setFeaturedPhotoFileInfo) + featuredPhotoFileInfo)}
              </label>
              <input id="featured_file_upload" type="file" onChange={onFeaturePhotoUploadQueueChange} style={{ display: "none" }} />
              <button
                disabled={featuredPhotoFile === null || featuredPhotoIsUploading}
                onClick={uploadFeaturedImageToS3}
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 3,
                }}>
                Upload
              </button>
            </div>
          )}
        </div>

        <div
          style={{
            height: 48,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}>
          <div style={{ flex: 0.3, textAlign: "right" }}>
            <label
              style={{
                fontFamily: "Roboto",
                color: "#7F7F7F",
                fontWeight: 500,
                fontSize: 14,
              }}>
              Active
            </label>
          </div>
          <div style={{ flex: 0.12, textAlign: "right" }}>
            <Toggle size="md" checked={isActivated} onChange={isCategoryActivated} />
          </div>
        </div>
        <div>
          <LabelInput value={displayOrder} type="number" label={"Display Order"} name={"display_order"} onInputChange={onUserTextInput} />
        </div>
      </div>

      <div
        style={{
          height: 42,
          display: isSaveError ? "flex" : "none",
          alignItems: "center",
          justifyContent: "right",
        }}>
        <label
          style={{
            fontFamily: "Roboto",
            fontSize: 12,
            color: "red",
            fontWeight: 300,
          }}>
          {errorText}
        </label>
      </div>

      <div
        style={{
          marginTop: 16,
          height: 48,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Button
          onClick={onCancel}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: 100,
            height: 42,
            borderColor: "#579EEF",
            borderRadius: 3,
            borderStyle: "solid",
            borderWidth: 0.5,
            backgroundColor: "#FFFFFF",
          }}>
          <label
            style={{
              cursor: "pointer",
              margin: "auto",
              fontSize: 16,
              fontFamily: "Roboto",
              color: "#579EEF",
              fontWeight: 500,
            }}>
            Cancel
          </label>
        </Button>
        <Button
          disabled={isSaveInProgress || iconFileIsUploading || featuredPhotoIsUploading}
          onClick={onSave}
          loading={isSaveInProgress}
          style={{
            cursor: "pointer",
            display: "flex",
            width: 100,
            height: 42,
            borderColor: "#579EEF",
            borderRadius: 3,
            borderStyle: "solid",
            borderWidth: 0.5,
            backgroundColor: "#579EEF",
          }}>
          {!isSaveInProgress && (
            <label
              style={{
                cursor: "pointer",
                margin: "auto",
                fontSize: 16,
                fontFamily: "Roboto",
                color: "#FFFFFF",
                fontWeight: 500,
                verticalAlign: "middle",
              }}>
              Save
            </label>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default AddNewCategory;
