import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import IconNoConnection from "../icons/error_states/NoConnection.png";
import IconNoDocuments from "../icons/error_states/NoDocuments.png";
import IconNoSearchResult from "../icons/error_states/NoSearchResult.png";
// import IconConfirm from "../icons/error_states/Confirm.png";
// import IconDone from "../icons/error_states/Done.png";
// import IconEmptyInbox from "../icons/error_states/EmptyInbox.png";
// import IconError from "../icons/error_states/Error.png";
// import IconNoImages from "../icons/error_states/NoImages.png";
// import IconNoItemsCart from "../icons/error_states/NoItemsCart.png";
// import IconNoMessages from "../icons/error_states/NoMessages.png";
// import IconNoTasks from "../icons/error_states/NoTasks.png";

import { calcWidth, calcHeight } from "./table";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: calcHeight(25, 10),
    width: calcWidth(15, 10),
    backgroundColor: "white",
    borderRadius: 4,
    margin: 10,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    margin: "auto",
  },
  title: {
    fontFamily: "Roboto",
    textAlign: "center",
  },
  text: {
    fontFamily: "Roboto",
    textAlign: "center",
    width: "100%",
    margin: "auto",
    whiteSpace: "pre-wrap",
  },
}));

const SwitchData = (data, title, noSubtitle = false) => {
  const classes = useStyles();
  if (data === "no-vpn") {
    return (
      <div className={classes.wrapper}>
        <img src={IconNoConnection} alt="" className={classes.icon} />
        <Typography variant="h6" className={classes.title}>
          Connection Error
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          Check your internet connection and restart page
        </Typography>
      </div>
    );
  } else if (data === "work-in-progress") {
    return (
      <div className={classes.wrapper}>
        <SettingsIcon className={classes.icon} />
        <Typography className={classes.text}>Work in Progress</Typography>
      </div>
    );
  } else if (data === "no-data") {
    return (
      <div className={classes.wrapper}>
        <img src={IconNoSearchResult} alt="" className={classes.icon} />
        <Typography variant="h6" className={classes.title}>
          {`No ${title} found`}
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          {noSubtitle ? "" : "Try additional options such as date, brand name, merchant name, or other search criteria."}
        </Typography>
      </div>
    );
  } else if (data === "empty-data") {
    return (
      <div className={classes.wrapper}>
        <img src={IconNoDocuments} alt="" className={classes.icon} />
        <Typography className={classes.text}>No data available..</Typography>
      </div>
    );
  } else {
    return null;
  }
};

const ErrorPage = props => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={props.hasOwnProperty("disableHeight") ? { height: "auto" } : {}}>
      {SwitchData(props.data, props.title, props.hasNoSubtitle)}
    </div>
  );
};

export default ErrorPage;
