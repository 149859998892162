import React, { useState, useEffect, useRef } from "react";
import { filterModalStyles, searchFilterContainerStyles } from "../../config/theme";
import { InputGroup, Icon, Input, FlexboxGrid, Modal, Button, DateRangePicker, AutoComplete, Placeholder, ButtonGroup, Loader } from "rsuite";
import { Button as MaterialButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import { parseDate, setISODate } from "../../components/table";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import { get, post } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { FilterLoader } from "../../components/loader";
import { globalStyles } from "../../config/theme";

export const MerchantSearch = props => {
  const classes = searchFilterContainerStyles();
  const getFiltersUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.FILTER;

  const [modal, setModal] = useState(false);
  const [searchVal, setVal] = useState("");
  const [filters, setFilters] = useState({});
  const [filterObject, setFilterObject] = useState(props.payloadFilter);
  const [filtersToView, setFiltersToView] = useState([]);
  const [isSearchApplied, setSearchApplied] = useState(false);

  //Handlers
  const handleSetSearchInput = event => {
    setVal(event);
    setFilterObject(prev => ({ ...prev, search: event }));
    setSearchApplied(false);
  };

  const handleClearSearch = event => {
    if (event.target.accessKey === "clear") {
      setVal("");
      setFilterObject(prev => ({ ...prev, search: "" }));
      isSearchApplied && props.onSearch({ ...filterObject, search: "" });
    }
  };

  const handleSearch = () => {
    setFilterObject(prev => ({ ...prev, search: searchVal }));
    if (searchVal !== "") {
      props.onSearch({ ...filterObject, search: searchVal });
      setSearchApplied(true);
    }
  };

  const handleModalView = status => {
    typeof status === "boolean" && setModal(status);
  };

  const getFiltersFromModal = (filters, filtersToAppend, filtersToView) => {
    setFilters(filters);
    setFiltersToView(filtersToView);
    if (isSearchApplied) {
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend }));
      props.onSearch({ ...filterObject, filters: filtersToAppend });
    } else {
      setVal("");
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend, search: "" }));
      props.onSearch({ ...filterObject, filters: filtersToAppend, search: "" });
    }
  };

  const getConcatenatedString = inputArray => {
    if (inputArray !== null && inputArray !== undefined && Array.isArray(inputArray) && inputArray.length > 0) {
      return inputArray.reduce((text, value, i, array) => text + (i < array.length - 1 ? ", " : " and ") + value);
    }
  };

  const handleRemoveOneFilter = (id, value) => {
    if (id === "deal_provider_name" || id === "category" || id === "subcategory" || id === "merchant_active") {
      setFilters(prev => ({ ...prev, [id]: { ...prev[id], values: ["All"] } }));
    } else if (id === "locationcount" || id === "maxdiscountvalueamount") {
      setFilters(prev => ({ ...prev, [id]: { ...prev[id], selected: [], value: "" } }));
    } else if (id === "merchant_added_date") {
      setFilters(prev => ({ ...prev, [id]: { ...prev[id], values: [] } }));
    } else if (id === "city") {
      setFilters(prev => ({ ...prev, [id]: { ...prev[id], value: "" } }));
    }
    setFiltersToView(filtersToView.filter(filter => filter.id !== id));
    setFilterObject(prev => ({ ...prev, ["filters"]: prev.filters.filter(fil => fil.field !== id) }));
    props.onSearch({ ...filterObject, filters: filterObject.filters.filter(fil => fil.field !== id) });
  };

  const handleRemoveAllFilters = () => {
    let temp = { ...filters };
    Object.keys(temp).map(id => {
      if (id === "deal_provider_name" || id === "category" || id === "subcategory" || id === "merchant_active") {
        setFilters(prev => ({ ...prev, [id]: { ...prev[id], values: ["All"] } }));
      } else if (id === "locationcount" || id === "maxdiscountvalueamount") {
        setFilters(prev => ({ ...prev, [id]: { ...prev[id], selected: [], value: "" } }));
      } else if (id === "merchant_added_date") {
        setFilters(prev => ({ ...prev, [id]: { ...prev[id], values: [] } }));
      } else if (id === "city") {
        setFilters(prev => ({ ...prev, [id]: { ...prev[id], value: "" } }));
      }
    });
    setFiltersToView([]);
    setFilterObject(prev => ({ ...prev, ["filters"]: [] }));
    props.onSearch({
      ...filterObject,
      filters: [],
    });
  };

  const formatResponse = response => {
    let op = {};
    response !== undefined &&
      response.map(fil => {
        if (fil.key === "city") {
          op[fil.key] = {
            ...fil,
            value: "",
          };
        } else if (fil.type === "gt_lt_eq") {
          op[fil.key] = {
            ...fil,
            value: "",
            options: ["gt", "lt", "eq"],
            selected: [],
          };
        } else if (fil.key === "merchant_active") {
          op[fil.key] = {
            ...fil,
            options: ["All", "Active", "Inactive"],
            values: ["All"],
          };
        } else if (fil.key === "subcategory") {
          op[fil.key] = {
            ...fil,
            options: { default: "All" },
            values: ["All"],
          };
        } else {
          op[fil.key] = {
            ...fil,
            values: fil.hasOwnProperty("options") && fil.options.includes("All") ? ["All"] : [],
          };
        }
      });
    delete op["cuisinetype"];
    return op;
  };

  const fetchMerchantFilters = async () => {
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await post(getFiltersUrl, {
          extraItems: [
            { header: "Status", defaultvalues: [], key: "merchant_active", type: "button" },
            { header: "Created", defaultvalues: [], key: "merchant_added_date", type: "date-range" },
            {
              header: "Merchant Location",
              defaultvalues: [],
              key: "city",
              type: "button",
              tablename: "unified_merchant_location_tbl",
            },
          ],
        });
        setFilters(formatResponse(response));
      } catch (error) {
        console.log(error);
      }
    }
  };

  //useEffects

  useEffect(() => {}, [filters]);

  useEffect(() => {}, [filterObject]);

  useEffect(() => {}, [filtersToView]);

  useEffect(() => {}, [isSearchApplied]);

  useEffect(() => {
    fetchMerchantFilters();
  }, []);

  //Render

  return (
    <>
      <div>
        <div className={classes.container}>
          <InputGroup inside>
            <Input
              value={searchVal}
              onChange={handleSetSearchInput}
              className={classes.search}
              placeholder={`Search in Merchants`}
              onPressEnter={handleSearch}
            />
            <InputGroup.Addon>
              <Icon
                accessKey={searchVal.length > 0 ? "clear" : undefined}
                icon={searchVal.length === 0 ? "search" : "close"}
                onClick={handleClearSearch}
                className={classes.searchIcon}
              />
            </InputGroup.Addon>
          </InputGroup>
          <MaterialButton onClick={handleSearch} className={classes.filterButton}>
            Search
          </MaterialButton>
          <MaterialButton
            startIcon={<FilterListIcon className={classes.blueIcon} />}
            endIcon={<ExpandMoreIcon className={classes.blueIcon} />}
            onClick={() => handleModalView(true)}
            className={classes.filterButton}>
            Filter
          </MaterialButton>
        </div>
        <div className={classes.filterContainer}>
          {filtersToView.length > 0 &&
            filtersToView.map((filter, index) => {
              return (
                <>
                  {filter.hasOwnProperty("values") && Array.isArray(filter.values) && !filter.values.includes("All") ? (
                    <MaterialButton
                      key={`filter-button-${index}`}
                      className={classes.filterListButton}
                      endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                      {`${filter.key} - ${getConcatenatedString(filter.values)}`}
                    </MaterialButton>
                  ) : filter.hasOwnProperty("value") ? (
                    Array.isArray(filter.value) && filter.value.length > 0 ? (
                      <MaterialButton
                        key={`filter-button-${index}`}
                        className={classes.filterListButton}
                        endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                        {`${filter.key} : ${filter.value !== undefined && filter.value.map(el => parseDate(el, "short")).join(" - ")}`}
                      </MaterialButton>
                    ) : (
                      <MaterialButton
                        key={`filter-button-${index}`}
                        className={classes.filterListButton}
                        endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                        {`${filter.key} : ${filter.value}`}
                      </MaterialButton>
                    )
                  ) : null}
                </>
              );
            })}
          {filtersToView.length > 0 && (
            <Typography className={classes.clearFilters} onClick={handleRemoveAllFilters}>
              Clear Filters
            </Typography>
          )}
        </div>
      </div>
      <MerchantFilter show={modal} onHide={() => handleModalView(false)} filters={filters} getFilter={getFiltersFromModal} />
    </>
  );
};

const MerchantFilterModal = props => {
  //Constants
  const getFiltersUrl = EndPoints.BOOST_DOMAIN + EndPoints.MERCHANT.FILTER;
  const getSubCategories = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET_SUB_CHILD_CATEGORY;
  const classes = filterModalStyles();
  const globalClasses = globalStyles();

  //useStates
  const [showModal, setShowModal] = useState(props.show);
  const [filters, setFilters] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [hasSubCategories, resetSubCategories] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: null, description: null, handler: null });
  const [isReset, setReset] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  //Handlers
  const handleConfirmDialogView = state => {
    typeof state === "boolean" && setConfirmDialog(state);
  };

  const onHide = (overrideConfirm = false) => {
    if (overrideConfirm) {
      // setFilters(prev => prev.map(fil => (fil.key === "subcategory" ? { ...fil, options: { default: "All" } } : fil)));
      setShowModal(false);
      props.onHide();
    } else {
      setDialog({
        title: "Cancel",
        description: ["Applied filters will be lost.", " Are you sure you want to cancel?"],
        helperText: ``,
        handler: () => {
          setFilters(prev => prev.map(fil => (fil.key === "subcategory" ? { ...fil, options: { default: "All" } } : fil)));
          setShowModal(false);
          props.onHide();
          setConfirmDialog(false);
        },
      });
      setConfirmDialog(true);
    }
  };

  const onCancel = () => {
    if (!hasChanges) {
      setShowModal(false);
      props.onHide();
    } else {
      setDialog({
        title: "Cancel",
        description: ["Selected filters will not be applied.", " Are you sure you want to cancel?"],
        helperText: ``,
        handler: () => {
          onHide(true);
          if (isReset) {
            props.getFilter([], []);
          }
          setHasChanges(false);
          setConfirmDialog(false);
        },
      });
      setConfirmDialog(true);
    }
  };

  const setISODate = (inputDate, isEnd = false) => {
    let date = new Date(inputDate);
    let yr = date.getFullYear();
    let mn = date.getMonth() + 1;
    mn = mn >= 10 ? mn : "0" + mn;
    let dt = date.getDate();
    dt = dt >= 10 ? dt : "0" + dt;
    let time = isEnd ? "23:59:59.000Z" : "00:00:00.000Z";
    return `${yr}-${mn}-${dt}T${time}`;
  };

  const getConcatenatedString = inputArray => {
    return inputArray.reduce((text, value, i, array) => text + (i < array.length - 1 ? ", " : " and ") + value);
  };

  const onApply = () => {
    let filtersToAppend = [];
    let filtersToView = [];
    let hasNoInputError = {
      state: false,
      errors: [],
    };
    filters.map(filter => {
      let objectToAppend = {};
      let objectToView = {};
      if (filter.type === "date-range") {
        if (Array.isArray(filter.active) && filter.active.length > 0) {
          objectToAppend["field"] = filter.key;
          objectToAppend["start"] = setISODate(filter.active[0]);
          objectToAppend["end"] = setISODate(filter.active[1], true);
          objectToAppend["condition"] = "between";
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = [setISODate(filter.active[0]), setISODate(filter.active[1], true)];
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      } else if (filter.type === "autocomplete") {
        if (filter.active !== "" && filter.active !== null) {
          objectToAppend["field"] = filter.key;
          objectToAppend["value"] = filter.active;
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      } else if (filter.type === "gt_lt_eq") {
        if (
          !(
            (Array.isArray(filter.active) && filter.active.length === 0) ===
            (filter.hasOwnProperty("input_value") && (filter.input_value === "" || filter.input_value === null || filter.input_value === undefined))
          )
        ) {
          hasNoInputError.state = true;
          hasNoInputError.errors.push(filter.header);
        } else {
          if (
            Array.isArray(filter.active) &&
            filter.active.length > 0 &&
            filter.hasOwnProperty("input_value") &&
            filter.input_value !== "" &&
            filter.input_value !== null &&
            filter.input_value !== undefined &&
            parseInt(filter.input_value) > 0
          ) {
            objectToAppend["field"] = filter.key;
            objectToAppend["condition"] = filter.active.join("_");
            objectToAppend["value"] = filter.input_value;
            objectToView["id"] = filter.key;
            objectToView["key"] = filter.header;
            let concatenatedStr = "";
            if (filter.active.includes("gt")) concatenatedStr = ">";
            else if (filter.active.includes("lt")) concatenatedStr = "<";
            if (filter.active.includes("eq")) concatenatedStr += "=";
            concatenatedStr += " " + filter.input_value;
            objectToView["value"] = concatenatedStr;
            filtersToAppend.push(objectToAppend);
            filtersToView.push(objectToView);
          }
        }
      } else if (filter.type === "button") {
        if (!filter.active.includes("All")) {
          objectToAppend["field"] = filter.key;
          if (filter.key === "merchant_active") {
            if (filter.active.includes("Active")) objectToAppend["value"] = true;
            else if (filter.active.includes("Inactive")) objectToAppend["value"] = false;
          } else {
            objectToAppend["values"] = filter.active;
          }
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["values"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      }
    });
    if (hasNoInputError.state) {
      window.alert(`Fields missing at ${getConcatenatedString(hasNoInputError.errors)}`);
    } else if (filtersToAppend.length === 0 && !isReset && !hasChanges) {
      onHide(true);
    } else {
      props.getFilter(filtersToAppend, filtersToView);
      onHide(true);
      setHasChanges(false);
    }
  };

  const handleSelectFilter = async (key, value, event = undefined, isInput = false) => {
    setHasChanges(true);
    if (key === "category" && value !== "All") {
      let subcategoryObj = filters.find(el => el.key === "subcategory");
      if (filters.find(el => el.key === "category").active.includes(value)) {
        event.target.blur();
        delete subcategoryObj.options[value];
        subcategoryObj.active = ["All"];
        setFilters(prev => prev.map(fil => (fil.key === "subcategory" ? { ...fil, active: [], options: subcategoryObj.options } : fil)));
      } else {
        try {
          setBtnLoading(true);
          let response = await get(`${getSubCategories}?category=${value}`);
          subcategoryObj.options[value] = response.filter(el => el.category_name !== "None").map(cat => cat.category_name);
          setFilters(prev =>
            prev.map(fil =>
              fil.key === "subcategory"
                ? { ...fil, options: { ...fil.options, [value]: response.filter(el => el.category_name !== "None").map(cat => cat.category_name) } }
                : fil
            )
          );
          setBtnLoading(false);
        } catch (error) {
          console.log(error);
          setBtnLoading(false);
        }
      }
    }
    setFilters(
      filters.map(filter => {
        if (filter.key === key) {
          if (filter.type === "date-range" || filter.type === "autocomplete") {
            return { ...filter, active: value };
          } else if (filter.type === "gt_lt_eq") {
            if (isInput) {
              return { ...filter, input_value: value };
            } else {
              if (value === "gt" || value === "lt") {
                if (filter.active.includes(value)) {
                  event !== undefined && event.target.blur();
                  return { ...filter, active: [] };
                } else {
                  return { ...filter, active: [value] };
                }
              } else {
                if (filter.active.includes(value)) {
                  event !== undefined && event.target.blur();
                  if (filter.active.length === 1) {
                    return { ...filter, active: ["All"] };
                  } else {
                    return { ...filter, active: filter.active.filter(el => el !== value) };
                  }
                } else {
                  return { ...filter, active: [...filter.active, value] };
                }
              }
            }
          } else {
            if (value === "All") {
              if (filter.key === "category") {
                resetSubCategories(true);
              }
              return { ...filter, active: ["All"] };
            } else if (filter.active.includes(value)) {
              if (filter.active.length === 1) {
                event !== undefined && event.target.blur();
                return { ...filter, active: ["All"] };
              } else {
                return { ...filter, active: filter.active.filter(el => el !== value) };
              }
            } else if (
              [...filter.active.filter(el => el !== "All"), value].length === filter.options.length - 1 ||
              [...filter.active.filter(el => el !== "All"), value].length === [...new Set(Object.values(filter.options).flat())].length - 1
            ) {
              event !== undefined && event.target.blur();
              return { ...filter, active: ["All"] };
            } else {
              return { ...filter, active: [...filter.active.filter(el => el !== "All"), value] };
            }
          }
        } else {
          return filter;
        }
      })
    );
  };

  const getUniqueUpperCase = elementsArray => {
    let result = [];
    elementsArray.forEach(el => {
      result.push(el.toLocaleUpperCase());
    });
    return [...new Set(result)];
  };

  const modifyRecievedFilter = recievedFilter => {
    recievedFilter !== undefined &&
      recievedFilter.map(filter => {
        filter["active"] = filter.hasOwnProperty("options") ? [filter.options[0]] : [];
        if (filter.hasOwnProperty("options")) {
          filter.options = filter.options.filter(el => el !== "None");
        }
        if (filter.key === "city") {
          filter.type = "autocomplete";
          filter["active"] = "";
          filter.options = getUniqueUpperCase(filter.options);
        } else if (filter.key === "merchant_active") {
          filter.options = ["All", "Active", "Inactive"];
          filter["active"] = ["All"];
        } else if (filter.key === "subcategory") {
          filter["options"] = { default: "All" };
          filter["active"] = ["All"];
        } else if (filter.type === "date-range") {
          filter["active"] = [];
        } else if (filter.type === "gt_lt_eq") {
          filter["options"] = ["gt", "lt", "eq"];
          filter["active"] = [];
          filter["input_value"] = null;
        }
      });
    return recievedFilter;
  };

  const updateWithAppliedFilters = () => {};

  const resetFilters = (isHardReset = false) => {
    if (isHardReset || props.modalUpdateOnFilterChange.length <= 0) {
      setFilters(
        filters !== undefined &&
          filters.map(fil =>
            fil.type === "autocomplete"
              ? { ...fil, active: "" }
              : fil.type === "date-range"
              ? { ...fil, active: [] }
              : fil.type === "gt_lt_eq"
              ? { ...fil, input_value: "", active: [] }
              : fil.key === "subcategory"
              ? { ...fil, options: { default: "All" }, active: ["All"] }
              : { ...fil, active: ["All"] }
          )
      );
    } else {
      setFilters(
        filters !== undefined &&
          filters.map(filter =>
            filter.type === "date-range"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: [] }
              : filter.type === "gt_lt_eq"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: [] }
              : filter.key === "subcategory"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: [], options: { default: "All" } }
              : filter.type === "autocomplete"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: "" }
              : props.modalUpdateOnFilterChange.includes(filter.key)
              ? filter
              : { ...filter, active: ["All"] }
          )
      );
    }
  };

  //useEffects

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(async () => {
    setHasChanges(false);
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        let response = await post(getFiltersUrl, {
          extraItems: [
            { header: "Status", defaultvalues: [], key: "merchant_active", type: "button" },
            { header: "Created", defaultvalues: [], key: "merchant_added_date", type: "date-range" },
            {
              header: "Merchant Location",
              defaultvalues: [],
              key: "city",
              type: "button",
              tablename: "unified_merchant_location_tbl",
            },
          ],
        });
        setFilters(modifyRecievedFilter(response.filter(el => el.key !== "cuisinetype")));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    console.log(filters);
  }, [filters]);
  useEffect(() => {}, [isLoading]);

  useEffect(() => {}, [confirmDialog]);

  useEffect(() => {
    resetFilters();
  }, [props.modalUpdateOnFilterChange]);

  useEffect(() => {}, [isBtnLoading]);
  useEffect(() => {}, [isReset]);
  useEffect(() => {
    console.log(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    if (hasSubCategories) {
      setFilters(prev => prev.map(fil => (fil.key === "subcategory" ? { ...fil, options: { default: "All" } } : fil)));
      resetSubCategories(false);
    }
  }, [hasSubCategories]);

  //Renders

  return (
    <div>
      <Modal show={showModal} onHide={() => onHide()} size="md" style={{ minHeight: "60vh" }}>
        <Modal.Header>
          <Modal.Title>Filter Merchants</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal}>
          {isLoading ? (
            <FilterLoader />
          ) : (
            filters !== undefined &&
            filters.length > 0 &&
            filters.map((filter, index) => {
              return (
                <div
                  key={`filter-container-${index}`}
                  style={
                    filter.type === "date-range" || filter.type === "autocomplete" || filter.key === "deal_provider_name"
                      ? { width: "100%", marginTop: 10, marginBottom: 10, marginRight: 30 }
                      : { marginTop: 10, marginBottom: 10, marginRight: 30 }
                  }>
                  {filter.type === "button" ? (
                    Array.isArray(filter.options) ? (
                      <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography className={classes.subtitle}>{filter.header}</Typography>
                          {isBtnLoading && filter.key === "category" && <Loader size="xs" style={{ marginLeft: 3, marginBottom: 5 }} />}
                        </div>
                        <FlexboxGrid>
                          {filter.options.map((option, i) => {
                            return (
                              <Button
                                className={`${classes.optionButton} ${filter.active.includes(option) && classes.active}`}
                                active={filter.active.includes(option) ? true : false}
                                value={option}
                                onClick={e => handleSelectFilter(filter.key, option, e)}
                                key={`buttons-${i}`}>
                                {option}
                              </Button>
                            );
                          })}
                        </FlexboxGrid>
                      </>
                    ) : typeof filter.options === "object" && filter.options !== null && [...new Set(Object.values(filter.options).flat())].length > 1 ? (
                      <>
                        <Typography className={classes.subtitle}>{filter.header}</Typography>
                        <FlexboxGrid>
                          {[...new Set(Object.values(filter.options).flat())].map((option, i) => {
                            return (
                              <Button
                                className={`${classes.optionButton} ${filter.active.includes(option) && classes.active}`}
                                active={filter.active.includes(option) ? true : false}
                                value={option}
                                onClick={e => handleSelectFilter(filter.key, option, e)}
                                key={`buttons-${i}`}>
                                {option}
                              </Button>
                            );
                          })}
                        </FlexboxGrid>
                      </>
                    ) : null
                  ) : filter.type === "date-range" ? (
                    <>
                      <Typography className={classes.subtitle}>{filter.header}</Typography>
                      <FlexboxGrid>
                        <DateRangePicker
                          value={filter.active}
                          onChange={e => handleSelectFilter(filter.key, e)}
                          cleanable={true}
                          placeholder="Created in between"
                          ranges={[]}
                          placement="rightEnd"
                          key={`date-range-${index}`}
                          style={{
                            width: "50%",
                            margin: "10px 0px 10px 0px",
                            "&:hover": { borderColor: "none" },
                          }}
                        />
                      </FlexboxGrid>
                    </>
                  ) : filter.type === "autocomplete" ? (
                    <>
                      <Typography className={classes.subtitle}>{filter.header}</Typography>
                      <FlexboxGrid>
                        <InputGroup
                          inside
                          key={`merchant-filter-autocomplete-${index}`}
                          style={{
                            width: "50%",
                            margin: "10px 0px 10px 0px",
                            "&:hover": { borderColor: "none" },
                          }}>
                          <InputGroup.Button>
                            <Icon icon="search" style={{ color: "#3c8dde", "&:hover": { cursor: "none" } }} />
                          </InputGroup.Button>
                          <AutoComplete
                            value={filter.active}
                            data={filter.options}
                            onChange={e => handleSelectFilter(filter.key, e.toLocaleUpperCase())}
                            menuClassName={classes.menuClass}
                            placeHolder={"Search in city"}
                          />
                        </InputGroup>
                      </FlexboxGrid>
                    </>
                  ) : null}
                  {filter.type === "gt_lt_eq" ? (
                    <div style={{ display: "inline-block" }}>
                      <Typography className={classes.subtitle}>{filter.header}</Typography>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ButtonGroup style={{ margin: 5 }}>
                          {filter.options.map((option, index) => {
                            return (
                              <Button
                                key={`optionBtn-${index}`}
                                className={`${classes.optionButtonWoBorderRadius} ${filter.active.includes(option) && classes.active}`}
                                style={
                                  index === 0
                                    ? { borderBottomLeftRadius: 2, borderTopLeftRadius: 2, margin: 0 }
                                    : index === 1
                                    ? { margin: 0, borderLeftWidth: 0, borderRightWidth: 0 }
                                    : { borderBottomRightRadius: 2, borderTopRightRadius: 2, margin: 0 }
                                }
                                onClick={e => handleSelectFilter(filter.key, option, e)}>
                                {option === "gt" ? (
                                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`>`}</Typography>
                                ) : option === "lt" ? (
                                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`<`}</Typography>
                                ) : (
                                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`=`}</Typography>
                                )}
                              </Button>
                            );
                          })}
                        </ButtonGroup>
                        <input
                          value={filter.input_value}
                          className={classes.input}
                          style={{ minWidth: 50, padding: `8px 12px` }}
                          onChange={e => {
                            e.target.style.width = `${50 + e.target.value.length * 5.5}px`;
                            handleSelectFilter(filter.key, e.target.value, undefined, true);
                          }}
                          type="number"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <FlexboxGrid justify="space-between" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Placeholder.Graph active width={100} height={20} className={classes.placeholderButton} />
              </div>
              <FlexboxGrid>
                <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
                <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
              </FlexboxGrid>
            </FlexboxGrid>
          ) : (
            <FlexboxGrid justify="space-between" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                {props.modalUpdateOnFilterChange.length > 0 || hasChanges ? (
                  <Typography
                    className={classes.clearFilters}
                    onClick={e => {
                      setDialog({
                        title: "Clear",
                        description: ["Are you sure to reset all applied filters? "],
                        helperText: `You need to re-apply filters`,
                        handler: () => {
                          resetFilters(true);
                          setReset(true);
                          setHasChanges(true);
                          setConfirmDialog(false);
                        },
                      });
                      setConfirmDialog(true);
                    }}>
                    Clear Filter
                  </Typography>
                ) : null}
              </div>
              <FlexboxGrid>
                <Button className={classes.cancelBtn} onClick={onCancel}>
                  Cancel
                </Button>
                <Button className={classes.saveBtn} onClick={onApply}>
                  Apply Filter
                </Button>
              </FlexboxGrid>
            </FlexboxGrid>
          )}
        </Modal.Footer>
      </Modal>
      <Dialog open={confirmDialog} onClose={() => handleConfirmDialogView(false)}>
        <DialogContent>
          <DialogTitle style={{ padding: 0 }}>{`Confirm ${dialog.title}`}</DialogTitle>
          <div style={{ padding: `5px 30px`, width: "20vw" }}>
            {dialog.description !== null &&
              dialog.description.map((desc, i) => {
                return <Typography>{desc}</Typography>;
              })}
            <Typography style={{ marginTop: 5, marginBottom: 5, whiteSpace: "break-spaces" }}>{dialog.helperText}</Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: `0px 10px 10px 10px` }}>
          <Button
            className={globalClasses.buttonSecondary}
            onClick={() => {
              setDialog({ title: null, description: null, handler: null });
              handleConfirmDialogView(false);
            }}>
            Cancel
          </Button>
          <Button className={globalClasses.buttonPrimary} onClick={dialog.handler}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MerchantFilter = props => {
  //constants
  const getSubCategories = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.GET_SUB_CHILD_CATEGORY;
  const classes = filterModalStyles();
  const globalClasses = globalStyles();

  //useStates
  const [showModal, setShowModal] = useState(props.show);
  const [isLoading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [isSubCategoryLoading, setSubCategoryLoading] = useState(false);

  const [dealProvider, setDealProvider] = useState([]);
  const [locationCountSelected, setLocationCountSelected] = useState([]);
  const [locationCountValue, setLocationCountValue] = useState("");
  const [maxDiscountSelected, setMaxDiscountSelected] = useState([]);
  const [maxDiscountValue, setMaxDiscountValue] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [merchantActive, setMerchantActive] = useState([]);
  const [merchantLocation, setMerchantLocation] = useState("");
  const [created, setCreated] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: null, description: null, handler: null });
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  //useRef

  const locationCountErrorRef = useRef(null);
  const locationErrorRef = useRef(null);
  const maxDiscountErrorRef = useRef(null);

  //functions

  const onHide = () => {
    setShowModal(false);
    props.onHide();
  };

  const onClose = () => {
    if (hasChanges) {
      setDialog({
        title: "Close",
        description: ["Are you sure to close the window? "],
        helperText: `Note: Closing the window will revert the selected filters but the previously applied filters will be retained`,
        handler: () => {
          resetFilters();
          setConfirmDialog(false);
          onHide();
        },
      });
      setConfirmDialog(true);
    } else {
      onHide();
    }
  };

  const onCancel = () => {
    if (hasChanges) {
      setDialog({
        title: "Cancel",
        description: ["Are you sure to cancel changes? "],
        helperText: `Note: Cancelling will revert the selected filters but the previously applied filters will be retained`,
        handler: () => {
          resetFilters();
          setConfirmDialog(false);
          onHide();
        },
      });
      setConfirmDialog(true);
    } else {
      onHide();
    }
  };

  const onClearAllFilters = () => {
    setDialog({
      title: "Clear",
      description: ["Are you sure to reset all applied filters? "],
      helperText: `Note: If Cancelled after clearing filters, the previously applied filters will be retained`,
      handler: () => {
        resetFilters(true);
        setConfirmDialog(false);
      },
    });
    setConfirmDialog(true);
  };

  const onApply = e => {
    let filtersToAppend = [];
    let filtersToView = [];
    if (checkConditionalInputs()) {
      if (hasChanges) {
        let res = {
          deal_provider_name: { ...filters.deal_provider_name, values: [...dealProvider] },
          locationcount: { ...filters.locationcount, selected: [...locationCountSelected], value: locationCountValue },
          maxdiscountvalueamount: { ...filters.maxdiscountvalueamount, selected: [...maxDiscountSelected], value: maxDiscountValue },
          category: { ...filters.category, values: [...category] },
          subcategory: { ...filters.subcategory, values: category.includes("All") ? ["All"] : [...subCategory] },
          merchant_active: { ...filters.merchant_active, values: [...merchantActive] },
          city: { ...filters.city, value: merchantLocation },
          merchant_added_date: { ...filters.merchant_added_date, values: created },
        };
        if (!dealProvider.includes("All")) {
          filtersToAppend = [...filtersToAppend, { field: filters.deal_provider_name.key, values: dealProvider }];
          filtersToView = [...filtersToView, { id: filters.deal_provider_name.key, key: filters.deal_provider_name.header, values: dealProvider }];
        }
        if (locationCountSelected.length > 0) {
          filtersToAppend = [
            ...filtersToAppend,
            { field: filters.locationcount.key, condition: locationCountSelected.sort().reverse().join("_"), value: locationCountValue },
          ];
          let concatenatedStr = "";
          if (locationCountSelected.includes("gt")) concatenatedStr = ">";
          else if (locationCountSelected.includes("lt")) concatenatedStr = "<";
          if (locationCountSelected.includes("eq")) concatenatedStr += "=";
          concatenatedStr += " " + locationCountValue;
          filtersToView = [...filtersToView, { id: filters.locationcount.key, key: filters.locationcount.header, value: concatenatedStr }];
        }
        if (maxDiscountSelected.length > 0) {
          filtersToAppend = [
            ...filtersToAppend,
            { field: filters.maxdiscountvalueamount.key, condition: maxDiscountSelected.sort().reverse().join("_"), value: maxDiscountValue },
          ];
          let concatenatedStr = "";
          if (maxDiscountSelected.includes("gt")) concatenatedStr = ">";
          else if (maxDiscountSelected.includes("lt")) concatenatedStr = "<";
          if (maxDiscountSelected.includes("eq")) concatenatedStr += "=";
          concatenatedStr += " " + maxDiscountValue;
          filtersToView = [...filtersToView, { id: filters.maxdiscountvalueamount.key, key: filters.maxdiscountvalueamount.header, value: concatenatedStr }];
        }
        if (!category.includes("All")) {
          filtersToAppend = [...filtersToAppend, { field: filters.category.key, values: category }];
          filtersToView = [...filtersToView, { id: filters.category.key, key: filters.category.header, values: category }];
          if (!subCategory.includes("All")) {
            filtersToAppend = [...filtersToAppend, { field: filters.subcategory.key, values: subCategory }];
            filtersToView = [...filtersToView, { id: filters.subcategory.key, key: filters.subcategory.header, values: subCategory }];
          }
        }
        if (!merchantActive.includes("All")) {
          filtersToAppend = [...filtersToAppend, { field: filters.merchant_active.key, value: merchantActive.includes("Active") ? true : false }];
          filtersToView = [...filtersToView, { id: filters.merchant_active.key, key: filters.merchant_active.header, values: merchantActive }];
        }
        if (merchantLocation.length > 0) {
          filtersToAppend = [...filtersToAppend, { field: filters.city.key, value: merchantLocation }];
          filtersToView = [...filtersToView, { id: filters.city.key, key: filters.city.header, value: merchantLocation }];
        }
        if (created.length > 0) {
          filtersToAppend = [
            ...filtersToAppend,
            { field: filters.merchant_added_date.key, start: setISODate(created[0]), end: setISODate(created[1], true), condition: "between" },
          ];
          filtersToView = [
            ...filtersToView,
            { id: filters.merchant_added_date.key, key: filters.merchant_added_date.header, value: [setISODate(created[0]), setISODate(created[1], true)] },
          ];
        }
        console.log("Filter Applied - ", res);
        console.log("Append - ", filtersToAppend);
        console.log("View - ", filtersToView);
        props.getFilter(res, filtersToAppend, filtersToView);
        onHide();
      } else {
        onHide();
      }
    } else {
      e.target.blur();
    }
  };

  const checkConditionalInputs = () => {
    if (locationCountSelected.length > 0 && (locationCountValue === "" || parseInt(locationCountValue) < 0)) {
      locationCountErrorRef.current.innerText = `Value not entered`;
      locationCountErrorRef.current.parentElement.nextElementSibling.lastElementChild.style.border = "1px solid red";
      return false;
    } else if (locationCountSelected.length == 0 && (locationCountValue !== "" || parseInt(locationCountValue) >= 0)) {
      locationCountErrorRef.current.innerText = `Condition not selected`;
      locationCountErrorRef.current.parentElement.nextElementSibling.firstChild.classList.add(classes.errorButtonGroup);
      return false;
    }
    if (maxDiscountSelected.length > 0 && (maxDiscountValue === "" || parseInt(maxDiscountValue) < 0)) {
      maxDiscountErrorRef.current.innerText = `Value not entered`;
      maxDiscountErrorRef.current.parentElement.nextElementSibling.lastElementChild.style.border = "1px solid red";
      return false;
    } else if (maxDiscountSelected.length == 0 && (maxDiscountValue !== "" || parseInt(maxDiscountValue) >= 0)) {
      maxDiscountErrorRef.current.innerText = `Condition not selected`;
      maxDiscountErrorRef.current.parentElement.nextElementSibling.firstChild.classList.add(classes.errorButtonGroup);
      return false;
    }
    if (merchantLocation !== "" && !filters.city.options.includes(merchantLocation)) {
      locationErrorRef.current.innerText = "Invalid location, select from the dropdown.";
      locationErrorRef.current.parentElement.nextElementSibling.firstChild.lastElementChild.style.border = "1px solid red";
      return false;
    }
    return true;
  };

  const deletePropertyFromObject = (key, object) => {
    delete object[key];
    return object;
  };

  const arrayEquals = (arr1, arr2, isDate = false) => {
    if (isDate) {
      if (arr1.length === 0 && arr2.length === 0) {
        return true;
      } else {
        let array1 = {
          startDate: new Date(arr1[0]),
          endDate: new Date(arr1[1]),
        };
        let array2 = {
          startDate: new Date(arr2[0]),
          endDate: new Date(arr2[1]),
        };
        if (array1.startDate.getTime() !== array2.startDate.getTime() || array1.endDate.getTime() !== array2.endDate.getTime()) {
          return false;
        }
        return true;
      }
    } else {
      let array1 = arr1.sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }));
      let array2 = arr2.sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }));
      if (array1.length >= array2.length) {
        for (let i = 0; i < array1.length; i++) {
          if (!array2.includes(array1[i])) {
            return false;
          }
        }
        return true;
      } else {
        for (let i = 0; i < array2.length; i++) {
          if (!array1.includes(array2[i])) {
            return false;
          }
        }
        return true;
      }
    }
  };

  const handleSelect = async (option, state, handler, key) => {
    if (key === "category") {
      if (Object.keys(filters.subcategory.options).includes(option)) {
        setFilters(prev => ({
          ...prev,
          subcategory: {
            ...prev.subcategory,
            options: deletePropertyFromObject(option, prev.subcategory.options),
          },
        }));
      } else if (option === "All") {
        setFilters(prev => ({
          ...prev,
          subcategory: {
            ...prev.subcategory,
            options: { default: "All" },
          },
        }));
      } else {
        try {
          setSubCategoryLoading(true);
          let response = await get(`${getSubCategories}?category=${option}`);
          setFilters(prev => ({
            ...prev,
            subcategory: {
              ...prev.subcategory,
              options: { ...prev.subcategory.options, [option]: response.filter(el => el.category_name !== "None").map(cat => cat.category_name) },
            },
          }));
          setSubCategoryLoading(false);
        } catch (error) {
          console.log(error);
          setSubCategoryLoading(false);
        }
      }
    }

    if (key === "locationcount" || key === "maxdiscountvalueamount") {
      if (option === "gt" || option === "lt") {
        if (state.includes(option)) {
          handler([]);
        } else {
          handler([option]);
        }
      } else {
        if (state.includes(option)) {
          handler(prev => prev.filter(el => el !== option));
        } else {
          handler(prev => [...prev, option]);
        }
      }
    } else if (
      option === "All" ||
      (!state.includes(option) &&
        Array.isArray(filters[key].options) &&
        [...state, option].filter(el => el !== "All").length === filters[key].options.filter(el => el !== "All").length) ||
      (typeof filters[key].options === "object" &&
        filters[key].options !== null &&
        [...state, option].filter(el => el !== "All").length === [...new Set(Object.values(filters[key].options).flat())].filter(el => el !== "All").length) ||
      (state.length === 1 && state.includes(option))
    ) {
      handler(["All"]);
    } else if (state.includes(option)) {
      handler(prev => prev.filter(el => el !== option));
    } else {
      handler([...state.filter(el => el !== "All"), option]);
    }
  };

  const handleConfirmDialogView = state => {
    typeof state === "boolean" && setConfirmDialog(state);
  };

  const removeSubCategory = (removeAll = false) => {
    if (removeAll) {
      setFilters(prev => ({ ...prev, subcategory: { ...prev.subcategory, options: { default: "All" } } }));
    } else {
      let optionObj = props.filters.subcategory.options;
      filters.category.options.filter(el => !el.includes(props.filters.category.values)).forEach(id => delete optionObj[id]);
      setFilters(prev => ({ ...prev, subcategory: { ...prev.subcategory, options: optionObj } }));
    }
  };

  const resetFilters = (isHardRest = false) => {
    if (isHardRest) {
      setDealProvider(["All"]);
      setLocationCountSelected([]);
      setLocationCountValue("");
      setMaxDiscountSelected([]);
      setMaxDiscountValue("");
      setCategory(["All"]);
      setSubCategory(["All"]);
      setMerchantActive(["All"]);
      setMerchantLocation("");
      setCreated([]);
      removeSubCategory(true);
      setShowClearFilters(false);
    } else {
      setDealProvider(props.filters.deal_provider_name.values);
      setLocationCountSelected(props.filters.locationcount.selected);
      setLocationCountValue(props.filters.locationcount.value);
      setMaxDiscountSelected(props.filters.maxdiscountvalueamount.selected);
      setMaxDiscountValue(props.filters.maxdiscountvalueamount.value);
      setCategory(props.filters.category.values);
      setSubCategory(props.filters.subcategory.values);
      setMerchantActive(props.filters.merchant_active.values);
      setMerchantLocation(props.filters.city.value);
      setCreated(props.filters.merchant_added_date.values);
      removeSubCategory();
      setHasChanges(false);
    }
  };

  //useEffects
  useEffect(() => {
    if (Object.keys(props.filters).length > 0) {
      setFilters(props.filters);
      setDealProvider(props.filters.deal_provider_name.values);
      setLocationCountSelected(props.filters.locationcount.selected);
      setLocationCountValue(props.filters.locationcount.value);
      setMaxDiscountSelected(props.filters.maxdiscountvalueamount.selected);
      setMaxDiscountValue(props.filters.maxdiscountvalueamount.value);
      setCategory(props.filters.category.values);
      setSubCategory(props.filters.subcategory.values);
      setMerchantActive(props.filters.merchant_active.values);
      setMerchantLocation(props.filters.city.value);
      setCreated(props.filters.merchant_added_date.values);
      setLoading(false);
    }
  }, [props.filters]);

  useEffect(() => {
    if (
      Object.keys(props.filters).length > 0 &&
      arrayEquals(props.filters.deal_provider_name.values, dealProvider) &&
      arrayEquals(props.filters.locationcount.selected, locationCountSelected) &&
      props.filters.locationcount.value === locationCountValue &&
      arrayEquals(props.filters.maxdiscountvalueamount.selected, maxDiscountSelected) &&
      props.filters.maxdiscountvalueamount.value === maxDiscountValue &&
      arrayEquals(props.filters.category.values, category) &&
      arrayEquals(props.filters.subcategory.values, subCategory) &&
      arrayEquals(props.filters.merchant_active.values, merchantActive) &&
      props.filters.city.value === merchantLocation &&
      arrayEquals(props.filters.merchant_added_date.values, created, true)
    ) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
    if (
      Object.keys(props.filters).length > 0 &&
      dealProvider.includes("All") &&
      locationCountSelected.length === 0 &&
      locationCountValue === "" &&
      maxDiscountSelected.length === 0 &&
      maxDiscountValue === "" &&
      category.includes("All") &&
      subCategory.includes("All") &&
      merchantActive.includes("All") &&
      merchantLocation === "" &&
      created.length === 0
    ) {
      setShowClearFilters(false);
    } else {
      setShowClearFilters(true);
    }
  }, [
    dealProvider,
    locationCountSelected,
    locationCountValue,
    maxDiscountSelected,
    maxDiscountValue,
    category,
    subCategory,
    merchantActive,
    merchantLocation,
    created,
  ]);

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, [isLoading]);
  useEffect(() => {}, [isSubCategoryLoading]);
  useEffect(() => {}, [hasChanges]);
  useEffect(() => {}, [showClearFilters]);
  useEffect(() => {}, [confirmDialog]);

  //render
  return (
    <div>
      <Modal show={showModal} onHide={onClose} size="md" style={{ minHeight: "60vh" }}>
        <Modal.Header>
          <Modal.Title className={classes.title}>Filter Merchants</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal}>
          {isLoading ? (
            <FilterLoader />
          ) : (
            <div style={{ width: "100%" }}>
              <div className={classes.container} name="Deal Provider Name">
                <Typography className={classes.subtitle}>{filters.deal_provider_name.header}</Typography>
                <FlexboxGrid>
                  {filters.deal_provider_name.options.map((option, i) => {
                    return (
                      <Button
                        className={`${classes.optionButton} ${dealProvider.includes(option) && classes.active}`}
                        active={dealProvider.includes(option) ? true : false}
                        value={option}
                        onClick={() => handleSelect(option, dealProvider, setDealProvider, "deal_provider_name")}
                        key={`buttons-${i}`}>
                        {option}
                      </Button>
                    );
                  })}
                </FlexboxGrid>
              </div>
              <div className={classes.container} name="Locations and Max Discount">
                <div style={{ display: "flex" }}>
                  <div name="Locations" style={{ marginRight: 50, minWidth: "30%" }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <Typography className={classes.subtitle}>{filters.locationcount.header}</Typography>
                      <Typography ref={locationCountErrorRef} className={classes.errorMsg}></Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      <ButtonGroup style={{ margin: 5 }}>
                        {filters.locationcount.options.map((option, index) => {
                          return (
                            <Button
                              key={`optionBtn-${index}`}
                              className={`${classes.optionButtonWoBorderRadius} ${locationCountSelected.includes(option) && classes.active}`}
                              style={
                                index === 0
                                  ? { borderBottomLeftRadius: 2, borderTopLeftRadius: 2, margin: 0 }
                                  : index === 1
                                  ? { margin: 0, borderLeftWidth: 0, borderRightWidth: 0 }
                                  : { borderBottomRightRadius: 2, borderTopRightRadius: 2, margin: 0 }
                              }
                              onClick={() => {
                                handleSelect(option, locationCountSelected, setLocationCountSelected, "locationcount");
                                if (locationCountErrorRef !== null) {
                                  locationCountErrorRef.current.innerText = "";
                                  locationCountErrorRef.current.parentElement.nextElementSibling.firstChild.classList.remove(classes.errorButtonGroup);
                                }
                              }}>
                              {option === "gt" ? (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`>`}</Typography>
                              ) : option === "lt" ? (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`<`}</Typography>
                              ) : (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`=`}</Typography>
                              )}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                      <input
                        value={locationCountValue}
                        className={classes.input}
                        type="number"
                        min={0}
                        style={{ minWidth: 50, padding: `8px 12px`, width: `${50 + locationCountValue.length * 5.5}px` }}
                        onChange={e => {
                          e.target.style.width = `${50 + e.target.value.length * 5.5}px`;
                          e.target.value >= 0 && setLocationCountValue(e.target.value);
                          if (locationCountErrorRef !== null) {
                            locationCountErrorRef.current.innerHTML = "";
                            locationCountErrorRef.current.parentElement.nextElementSibling.lastElementChild.style.border = `1px solid #E6E7ED`;
                          }
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                  <div name="Max Discount Value" style={{ marginLeft: 30 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <Typography className={classes.subtitle}>{filters.maxdiscountvalueamount.header}</Typography>
                      <Typography ref={maxDiscountErrorRef} className={classes.errorMsg}></Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      <ButtonGroup style={{ margin: 5 }}>
                        {filters.maxdiscountvalueamount.options.map((option, index) => {
                          return (
                            <Button
                              key={`optionBtn-${index}`}
                              className={`${classes.optionButtonWoBorderRadius} ${maxDiscountSelected.includes(option) && classes.active}`}
                              style={
                                index === 0
                                  ? { borderBottomLeftRadius: 2, borderTopLeftRadius: 2, margin: 0 }
                                  : index === 1
                                  ? { margin: 0, borderLeftWidth: 0, borderRightWidth: 0 }
                                  : { borderBottomRightRadius: 2, borderTopRightRadius: 2, margin: 0 }
                              }
                              onClick={() => {
                                handleSelect(option, maxDiscountSelected, setMaxDiscountSelected, "maxdiscountvalueamount");
                                if (maxDiscountErrorRef !== null) {
                                  maxDiscountErrorRef.current.innerText = "";
                                  maxDiscountErrorRef.current.parentElement.nextElementSibling.firstChild.classList.remove(classes.errorButtonGroup);
                                }
                              }}>
                              {option === "gt" ? (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`>`}</Typography>
                              ) : option === "lt" ? (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`<`}</Typography>
                              ) : (
                                <Typography style={{ fontSize: 18, fontWeight: 500 }}>{`=`}</Typography>
                              )}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                      <input
                        value={maxDiscountValue}
                        className={classes.input}
                        style={{ minWidth: 50, padding: `8px 12px`, width: `${50 + maxDiscountValue.length * 5.5}px` }}
                        type="number"
                        min={0}
                        onChange={e => {
                          e.target.style.width = `${50 + e.target.value.length * 5.5}px`;
                          e.target.value >= 0 && setMaxDiscountValue(e.target.value);
                          if (maxDiscountErrorRef !== null) {
                            maxDiscountErrorRef.current.innerHTML = "";
                            maxDiscountErrorRef.current.parentElement.nextElementSibling.lastElementChild.style.border = `1px solid #E6E7ED`;
                          }
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.container} name="Category">
                <div style={{ display: "flex" }}>
                  <Typography className={classes.subtitle}>{filters.category.header}</Typography>
                  {isSubCategoryLoading && <Loader size="xs" style={{ marginLeft: 3, marginBottom: 5 }} />}
                </div>
                <FlexboxGrid>
                  {filters.category.options.map((option, i) => {
                    return (
                      <Button
                        className={`${classes.optionButton} ${category.includes(option) && classes.active}`}
                        active={category.includes(option) ? true : false}
                        value={option}
                        onClick={() => handleSelect(option, category, setCategory, "category")}
                        key={`buttons-${i}`}>
                        {option}
                      </Button>
                    );
                  })}
                </FlexboxGrid>
              </div>
              {typeof filters.subcategory.options === "object" &&
              filters.subcategory.options !== null &&
              [...new Set(Object.values(filters.subcategory.options).flat())].length > 1 ? (
                <div className={classes.container} name="Sub Category">
                  <Typography className={classes.subtitle}>{filters.subcategory.header}</Typography>
                  <FlexboxGrid>
                    {[...new Set(Object.values(filters.subcategory.options).flat())].map((option, i) => {
                      return (
                        <Button
                          className={`${classes.optionButton} ${subCategory.includes(option) && classes.active}`}
                          active={subCategory.includes(option) ? true : false}
                          value={option}
                          onClick={() => handleSelect(option, subCategory, setSubCategory, "subcategory")}
                          key={`buttons-${i}`}>
                          {option}
                        </Button>
                      );
                    })}
                  </FlexboxGrid>
                </div>
              ) : null}
              <div className={classes.container} name="Status">
                <Typography className={classes.subtitle}>{filters.merchant_active.header}</Typography>
                <FlexboxGrid>
                  {filters.merchant_active.options.map((option, i) => {
                    return (
                      <Button
                        className={`${classes.optionButton} ${merchantActive.includes(option) && classes.active}`}
                        active={merchantActive.includes(option) ? true : false}
                        value={option}
                        onClick={() => handleSelect(option, merchantActive, setMerchantActive, "merchant_active")}
                        key={`buttons-${i}`}>
                        {option}
                      </Button>
                    );
                  })}
                </FlexboxGrid>
              </div>
              <div className={classes.container} name="Merchant Location">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Typography className={classes.subtitle}>{filters.city.header}</Typography>
                  <Typography ref={locationErrorRef} className={classes.errorMsg}></Typography>
                </div>
                <FlexboxGrid>
                  <InputGroup
                    inside
                    style={{
                      width: "50%",
                      margin: 5,
                      "&:hover": { borderColor: "none" },
                    }}>
                    <InputGroup.Button>
                      <Icon icon="search" style={{ color: "#3c8dde", "&:hover": { cursor: "none" } }} />
                    </InputGroup.Button>
                    <AutoComplete
                      value={merchantLocation}
                      data={filters.city.options}
                      onChange={e => {
                        locationErrorRef.current.innerText = "";
                        locationErrorRef.current.parentElement.nextElementSibling.firstChild.lastElementChild.style.border = "1px solid #e5e5ea";
                        setMerchantLocation(e.toLocaleUpperCase());
                      }}
                      menuClassName={classes.menuClass}
                      placeholder={`Search Cities`}
                      style={{
                        "& input::placeholder": {
                          color: "#9397AA !important",
                        },
                      }}
                    />
                  </InputGroup>
                </FlexboxGrid>
              </div>
              <div className={classes.container} name="Created">
                <Typography className={classes.subtitle}>{filters.merchant_added_date.header}</Typography>
                <FlexboxGrid>
                  <DateRangePicker
                    value={created}
                    onChange={e => setCreated(e)}
                    cleanable={true}
                    placeholder="Select Date(s)"
                    ranges={[]}
                    placement="rightEnd"
                    style={{
                      width: "50%",
                      margin: 5,
                      fontFamily: "Roboto",
                      "::placeholder": {
                        color: "#9397AA",
                        opacity: 0.5,
                      },
                      "&:hover": { borderColor: "none" },
                    }}
                  />
                </FlexboxGrid>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <FlexboxGrid justify="space-between" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Placeholder.Graph active width={100} height={20} className={classes.placeholderButton} />
              </div>
              <FlexboxGrid>
                <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
                <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
              </FlexboxGrid>
            </FlexboxGrid>
          ) : (
            <FlexboxGrid justify="space-between" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                {showClearFilters && (
                  <Typography className={classes.clearFilters} onClick={onClearAllFilters}>
                    Clear Filter
                  </Typography>
                )}
              </div>
              <FlexboxGrid>
                <Button className={classes.cancelBtn} onClick={onCancel}>
                  Cancel
                </Button>
                <Button className={classes.saveBtn} onClick={onApply}>
                  Apply Filter
                </Button>
              </FlexboxGrid>
            </FlexboxGrid>
          )}
        </Modal.Footer>
      </Modal>
      <Dialog open={confirmDialog} onClose={() => handleConfirmDialogView(false)}>
        <DialogContent>
          <DialogTitle style={{ padding: 0 }}>{`Confirm ${dialog.title}`}</DialogTitle>
          <div style={{ width: "20vw" }}>
            {dialog.description !== null &&
              dialog.description.map((desc, i) => {
                return <Typography>{desc}</Typography>;
              })}
            <Typography style={{ marginTop: 5, marginBottom: 5, whiteSpace: "break-spaces", fontSize: 11, fontStyle: "italic" }}>
              {dialog.helperText}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: `0px 10px 10px 10px` }}>
          <MaterialButton
            className={globalClasses.buttonSecondary}
            onClick={() => {
              setDialog({ title: null, description: null, handler: null });
              handleConfirmDialogView(false);
            }}>
            Cancel
          </MaterialButton>
          <MaterialButton className={globalClasses.buttonPrimary} style={{ backgroundColor: "#EB5757", border: `1px solid #EB5757` }} onClick={dialog.handler}>
            Confirm
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
