import React, { useState, useEffect } from "react";
import { InputGroup, Icon, Input, FlexboxGrid, Modal, Button as OptionButton, Dropdown, Loader } from "rsuite";
import { PAGE_CONFIG } from "../../config/page_config";
import { Button, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import { filterModalStyles, searchFilterContainerStyles } from "../../config/theme";

Array.prototype.remove = function (key, value) {
  const index = this.findIndex(obj => obj[key] === value);
  return index >= 0 ? [...this.slice(0, index), ...this.slice(index + 1)] : this;
};

const mapFilter = {
  "Parent Category": {
    defaultKey: "parent_category",
    defaultValue: "All",
  },
  Status: {
    defaultKey: "category_active",
    defaultValue: "All",
  },
  "Category Type": {
    defaultKey: "category_type",
    defaultValue: "All",
  },
};

const CategoriesFilterModal = props => {
  const classes = filterModalStyles();
  const [showModal, setShowModal] = useState(props.show);
  const [categoryName, setCategoryName] = useState(props.selectedOptions["Parent Category"]);
  const [statusButton, setStatusButton] = useState("");
  const [statusButtonVal, setStatusButtonVal] = useState(props.selectedOptions["Status"]);
  const [selectedCategoryType, setCategoryType] = useState(props.selectedOptions["Category Type"]);
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);

  const onHide = () => {
    setShowModal(false);
    props.onHide();
  };

  const onApply = () => {
    let res = [];
    res.push({ category_name: categoryName });
    res.push({ category_active: statusButton });
    res.push({ category_type: selectedCategoryType });
    res.push({ search: props.searchVal });

    let filtersToView = [];
    if (categoryName !== "" && categoryName !== "All") {
      filtersToView.push({ "Parent Category": categoryName });
    }
    if (statusButtonVal !== "" && statusButtonVal !== "All") {
      filtersToView.push({ "Status": statusButtonVal });
    }
    if (selectedCategoryType !== "" && selectedCategoryType !== "All") {
      filtersToView.push({ "Category Type": PAGE_CONFIG.categories.category_mapper[selectedCategoryType] });
    }
    if (filtersToView.length > 0) {
      props.manageFilters(filtersToView, res, selectedOptions);
      onHide();
    }
  };

  const onCancel = () => {
    onHide();
  };

  const handleSelectCategoryName = e => {
    categoryName === e.target.value ? setCategoryName("") : setCategoryName(e.target.value);
    setSelectedOptions(prev => ({
      ...prev,
      ["Parent Category"]: e.target.value,
    }));
  };

  const handleStatusButtons = e => {
    if (e.target.value === "Active") {
      setStatusButton(true);
    } else if (e.target.value === "Inactive") {
      setStatusButton(false);
    } else if (e.target.value === "All") {
      setStatusButton(null);
    }
    setStatusButtonVal(e.target.value);
    setSelectedOptions(prev => ({
      ...prev,
      ["Status"]: e.target.value,
    }));
  };

  const handleSelectCategoryType = e => {
    selectedCategoryType === e.target.value ? setCategoryType("") : setCategoryType(e.target.value);
    setSelectedOptions(prev => ({
      ...prev,
      ["Category Type"]: e.target.value,
    }));
  };

  const clearInputs = () => {
    setCategoryName("");
    setStatusButton("");
    setStatusButtonVal("");
    setCategoryType("");
    setSelectedOptions({
      "Parent Category": "All",
      Status: "All",
      "Category Type": "All",
    });
  };
  useEffect(() => {
    if (props.clearModal === true) {
      clearInputs();
    }
  }, [props.clearModal]);

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {}, [props.searchVal]);

  useEffect(() => {}, [categoryName]);
  useEffect(() => {}, [statusButtonVal]);
  useEffect(() => {}, [selectedCategoryType]);
  useEffect(() => {}, [selectedOptions]);

  return (
    <Modal show={showModal} onHide={onHide} size="md">
      <Modal.Header>
        <Modal.Title className={classes.title}>{props.header ? `Filter ${props.header}` : `Filter`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.modal} style={{ flexWrap: "nowrap", flexDirection: "column" }}>
        {props.isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Loader size="md" />
          </div>
        ) : (
          props.data.map((filter, index) => {
            return (
              <div className={classes.container} key={`data-div-${index}`}>
                {filter.header === "Parent Category" && (
                  <div>
                    <span className={classes.subtitle}>{filter.header}</span>
                    <FlexboxGrid>
                      {filter.options
                        .filter(option => option != "")
                        .map((option, inIndex) => {
                          return (
                            <OptionButton
                              className={`${classes.optionButton} ${option === selectedOptions[filter.header] && classes.active}`}
                              active={option === selectedOptions[filter.header] ? true : false}
                              value={option}
                              onClick={handleSelectCategoryName}
                              key={`buttons-${inIndex}`}>
                              {option}
                            </OptionButton>
                          );
                        })}
                    </FlexboxGrid>
                  </div>
                )}
                {filter.header === "Status" && (
                  <div>
                    <span className={classes.subtitle}>{filter.header}</span>
                    <FlexboxGrid>
                      {filter.options.map((option, inIndex) => {
                        return (
                          <OptionButton
                            className={`${classes.optionButton} ${option === selectedOptions[filter.header] && classes.active}`}
                            active={option === selectedOptions[filter.header] ? true : false}
                            value={option}
                            onClick={handleStatusButtons}
                            key={`buttons-${inIndex}`}>
                            {option}
                          </OptionButton>
                        );
                      })}
                    </FlexboxGrid>
                  </div>
                )}
                {filter.header === "Category Type" && (
                  <div>
                    <span className={classes.subtitle}>{filter.header}</span>
                    <FlexboxGrid>
                      {filter.options.map((option, inIndex) => {
                        return (
                          <OptionButton
                            className={`${classes.optionButton} ${option === selectedOptions[filter.header] && classes.active}`}
                            active={option === selectedOptions[filter.header] ? true : false}
                            value={option}
                            onClick={handleSelectCategoryType}
                            key={`buttons-${inIndex}`}>
                            {option === "All" ? option : PAGE_CONFIG.categories.category_mapper[option]}
                          </OptionButton>
                        );
                      })}
                    </FlexboxGrid>
                  </div>
                )}
              </div>
            );
          })
        )}
      </Modal.Body>
      <Modal.Footer>
        <FlexboxGrid justify="end">
          <Button className={classes.cancelBtn} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={classes.saveBtn}
            disabled={categoryName === "All" && statusButtonVal === "All" && selectedCategoryType === "All"}
            onClick={onApply}>
            Apply
          </Button>
        </FlexboxGrid>
      </Modal.Footer>
    </Modal>
  );
};

const CategoriesFilter = props => {
  const classes = searchFilterContainerStyles();
  const [modal, setModal] = useState(false);
  const [searchVal, setVal] = useState("");
  const [filters, setFilters] = useState([]);
  const [clearModal, setClearModal] = useState(false);
  const [filterObjects, setFilterObjects] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    "Parent Category": "All",
    "Status": "All",
    "Category Type": "All",
  });

  const handleShowModal = () => {
    setModal(true);
  };

  const handleHideModal = () => {
    setModal(false);
  };

  const handleInput = e => {
    setVal(e);
    let temp =
      filterObjects.length > 0 ? filterObjects.map(filter => (Object.keys(filter)[0] === "search" ? { ...filter, ["search"]: e } : filter)) : [{ search: e }];
    setFilterObjects(temp);
    props.searchHandler(temp);
  };

  const handleClearSearch = event => {
    if (event.target.accessKey === "clear") {
      setVal("");
      let temp =
        filterObjects.length > 0
          ? filterObjects.map(filter => (Object.keys(filter)[0] === "search" ? { ...filter, ["search"]: "" } : filter))
          : [{ search: "" }];
      setFilterObjects(temp);
      props.searchHandler(temp);
    }
  };

  const manageFilters = (uiData, actualData, selectedOptionsData) => {
    setFilters(uiData);
    setFilterObjects(actualData);
    setSelectedOptions(selectedOptionsData);
    props.filterHandler(actualData);
    setClearModal(false);
  };

  const resetValue = uiKey => {
    filterObjects.map(filter => {
      Object.entries(filter).map(([key, value], i) => {
        console.log(key, value, mapFilter[uiKey]);
        if (key === mapFilter[uiKey]["defaultKey"]) {
          filter[key] = mapFilter[uiKey]["defaultValue"];
        }
      });
    });
    setFilterObjects(filterObjects);
  };

  const handleRemoveOneFilter = (rkey, rvalue) => {
    let filterLength = [];
    filters.map(filt => {
      Object.values(filt)[0] && filterLength.push(Object.values(filt)[0]);
    });
    let updatedData = filters.remove(rkey, rvalue);
    if (filterLength.length <= 1) {
      handleClearFilters();
    } else {
      console.log(rkey);
      resetValue(rkey);
      let newOptions = selectedOptions;
      newOptions[rkey] = "All";
      props.filterHandler(filterObjects);
      manageFilters(updatedData, filterObjects, newOptions);
    }
  };

  const handleClearFilters = () => {
    setFilters([]);
    setClearModal(true);
    setFilterObjects([{ search: searchVal }]);
    props.filterHandler([{ search: searchVal }]);
  };

  useEffect(() => {}, [clearModal]);
  useEffect(() => {}, [selectedOptions]);
  useEffect(() => {}, [filters, filterObjects, searchVal]);

  return (
    <>
      <div>
        <div className={classes.container}>
          <InputGroup inside>
            <Input value={searchVal} onChange={handleInput} className={classes.search} placeholder={"Search for Category Name"} />
            <InputGroup.Addon>
              <Icon
                accessKey={searchVal.length > 0 ? "clear" : null}
                icon={searchVal.length === 0 ? "search" : "close"}
                onClick={handleClearSearch}
                className={classes.searchIcon}
              />
            </InputGroup.Addon>
          </InputGroup>
          <Button
            startIcon={<FilterListIcon className={classes.blueIcon} />}
            endIcon={<ExpandMoreIcon className={classes.blueIcon} />}
            onClick={() => handleShowModal()}
            className={classes.filterButton}>
            Filter
          </Button>
        </div>
        <div className={classes.filterContainer}>
          {filters.length > 0 &&
            filters.map((filter, index) =>
              Object.values(filter)[0] !== undefined
                ? Object.values(filter)[0] !== "" && Object.values(filter)[0] !== "All"
                  ? Object.entries(filter).map(([key, value], index) => {
                      return (
                        <>
                          <Button
                            key={`filter-container-button-${index}`}
                            className={classes.filterListButton}
                            endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(key, value)} />}>
                            {key} - {value}
                          </Button>
                        </>
                      );
                    })
                  : null
                : null
            )}
          {filters.length > 0 && (
            <span className={classes.clearFilters} onClick={handleClearFilters}>
              Clear Filters
            </span>
          )}
        </div>
      </div>

      <CategoriesFilterModal
        show={modal}
        onHide={handleHideModal}
        header={props.filterHeader}
        data={props.data}
        isLoading={props.isLoading}
        filterHandler={props.filterHandler}
        manageFilters={manageFilters}
        clearModal={clearModal}
        selectedOptions={selectedOptions}
        searchVal={searchVal}
      />
    </>
  );
};

export default CategoriesFilter;
