import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import EndPoints from "../webservices/endpoints";
import { BreadcrumbsHeader } from "../components/header";
import { TableWithPagination } from "../components/table";
import { PAGE_CONFIG } from "../config/page_config";
import { get } from "../webservices/apiservice";
import UtilityBar from "../components/utility_bar";
import { globalStyles } from "../config/theme";
import { TableLoader as Loader } from "../components/loader";
import ErrorPage from "../components/error_page";
import { useHistory } from "react-router-dom";

const tabHeaders = ["Import Audit Log"];

function Import(props) {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(tabHeaders[0]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // const classes = globalStyles();

  // const tableHeaders = {
  //   ID: "execution_id",
  //   Name: "state_name",
  //   "Import Type": "triggersource",
  //   Source: "source",
  //   Status: "status",
  //   "Total Deals": "numberofoffers",
  //   "Total Merchants": "numberofmerchants",
  //   "Total City/States": "numberofcitystates",
  //   Date: "execution_date",
  // };

  // const onTabSelect = (evt) => {
  //   let divName = evt.target.id;
  //   divName = divName.replace(/tab_div_/g, "");
  //   divName = divName.replace(/_/g, " ");

  //   setActiveTab(divName);
  //   if (divName === "Import Audit Log") {
  //     onAuditLogSelect();
  //   }
  // };

  const onAuditLogSelect = () => {
    fetch(EndPoints.BOOST_DOMAIN + EndPoints.AUDIT_LOG, {
      headers: EndPoints.getRequestHeader(),
    })
      .then(response => response.json())
      .then(data => {
        setTableData(data);
      })
      .catch(error => {
        setTableData([]);
      });
  };

  const getTabHeader = (label, index) => {
    return (
      <div
        key={`import-tab-header=${index}`}
        id={"tab_div_" + label.split(" ").join("_")}
        style={{
          cursor: "pointer",
          width: 144,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}>
        <label
          style={{
            pointerEvents: "none",
            paddingTop: 16,
            textAlign: "center",
            fontFamily: "Roboto",
            height: 42,
            fontSize: "0.875em",
            marginTop: 16,
            fontWeight: 500,
            color: activeTab === label ? "#3489EC" : "#545964",
          }}>
          {label}
        </label>
        {label === activeTab ? (
          <div
            style={{
              width: "100%",
              height: 2,
              justifyContent: "bottom",
              backgroundColor: "#3489EC",
            }}></div>
        ) : null}
      </div>
    );
  };

  const formatDate = response => {
    let updatedResponse = response.map((item, index) => {
      item["execution_date"] = item["execution_date"].substring(0, 10);
      return item;
    });
    return response;
  };
  const getUrl = EndPoints.BOOST_DOMAIN + EndPoints.AUDIT_LOG;

  const handleReload = async () => {
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await get(getUrl);
        setTableData(formatDate(response));
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/settings/import",
      });
    }
  };

  useEffect(async () => {
    setLoading(true);
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await get(getUrl);
        setTableData(formatDate(response));
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/settings/import",
      });
    }
  }, []);

  return (
    <div>
      {/* <Header title={"Import"} /> */}
      <BreadcrumbsHeader options={[{ order: 0, title: "Import" }]} />
      {/* <UtilityBar
        button={
          <Button
            className={classes.buttonPrimary}
            variant="filled"
            startIcon={<SystemUpdateAltIcon />}>
            Import Deals
          </Button>
        }
      /> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          height: 42,
        }}>
        {tabHeaders.map((item, index) => {
          return getTabHeader(item, index);
        })}
      </div>

      {isLoading ? (
        <Loader header={PAGE_CONFIG.import.header_mapper} />
      ) : error ? (
        <ErrorPage data={"no-vpn"} />
      ) : tableData.length < 1 ? (
        <ErrorPage data={"empty-data"} />
      ) : (
        <TableWithPagination data={tableData} pageConfig={PAGE_CONFIG.import} disableRowOptions={true} hideToolBars="left" handleReload={handleReload} />
      )}
    </div>
  );
}

export default Import;
