import React, { useState, useEffect } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import Toggle from "../../components/toggle";
import RightArrow from "../../icons/right_arrow.svg";
import EditIcon from "@material-ui/icons/Edit";
import AddNewCategory from "../modals/new_edit_category";
import Logout from "../../components/logout";
import Header, { BreadcrumbsHeader } from "../../components/header";
import { put } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";
import { Notification, Placeholder } from "rsuite";
import { globalStyles, detailScreenStyles } from "../../config/theme";
import { calcHeight, calcWidth } from "../../components/table";
import { useHistory } from "react-router-dom";

const putUrl = EndPoints.BOOST_DOMAIN + EndPoints.CATEGORY.PUT;

const page_styles = makeStyles({
  parentContainer: {
    width: calcWidth(15, 10),
    height: calcHeight(15, 10),
  },
  detailsHeaderContainer: {
    height: 40,
    margin: "8px 10px 2px 10px",
    paddingLeft: 32,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
  },
  detailsContainer: {
    height: calcHeight(21, 10),
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 50,
    background: "#FFFFFF",
    paddingLeft: 32,
    paddingTop: 24,
    overflow: "auto",
  },
  activeHeaderLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.75,
    color: "#3489EC",
  },
  categoryLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 400,
    color: "#545964",
  },
  valueText: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 14,
    color: "#2E384D",
    letterSpacing: 0.1,
  },
  descriptionText: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.25,
    color: "#2E384D",
  },
  editButton: {
    borderRadius: "3px",
    borderColor: "#3489EC",
    fontWeight: 500,
    fontFamily: "Roboto",
    letterSpacing: 0.75,
    fontSize: 14,
    color: "#3489EC",
    "&:hover": {
      cursor: "pointer",
    },
    editIcon: {
      color: "#3489EC",
    },
  },
  activeHeader: {
    color: "#3489EC",
  },
});

const CategoryDetails = props => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const detailScreenClasses = detailScreenStyles();
  const [categoryData, setCategoryData] = useState(history.location.state);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [featuredPhotoDimensions, setFeaturedPhotoDimensions] = useState("");
  const [isUpdating, setUpdating] = useState(false);

  const pageStyles = page_styles();

  const editCategory = () => {
    setIsEdit(true);
    setShowModal(true);
  };

  const onModalHide = () => {
    setIsEdit(false);
    setShowModal(false);
  };

  const onSuccessfulEdit = editedData => {
    setCategoryData(editedData);
    sessionStorage.setItem("updated_category", JSON.stringify(editedData));
  };

  const handleToggle = async (id, state) => {
    setUpdating(true);
    let payload = {
      category_id: id,
      category_active: state,
    };
    try {
      await put(putUrl, payload);
      setCategoryData(prev => ({ ...prev, category_active: state }));
      Notification["success"]({
        title: "Success",
        description: state ? `Successfully activated` : `Successfully Deactivated`,
      });
      setUpdating(false);
      if (sessionStorage.getItem("updated_category") === null) {
        sessionStorage.setItem("updated_category", JSON.stringify(payload));
      } else {
        let existingUpdate = JSON.parse(sessionStorage.getItem("updated_category"));
        existingUpdate = { ...existingUpdate, ["category_active"]: state };
        sessionStorage.setItem("updated_category", JSON.stringify(existingUpdate));
      }
    } catch (error) {
      setCategoryData(prev => ({ ...prev, category_active: !state }));
      Notification["error"]({
        title: "Error",
        description: "Unable to activate/ deactivate",
      });
      setUpdating(false);
    }
  };

  const getLabelValueTextItem = (label, value) => {
    let tempValue = value === null || value === undefined || value === "" ? "NA" : value;
    if (label === "Category Type") {
      switch (value) {
        case "maincategory":
          tempValue = "Main Category";
          break;
        case "detailcategory":
          tempValue = "Detail Category";
          break;
        case "subcategory":
          tempValue = "Sub Category";
          break;
        case "cuisinetype":
          tempValue = "Cuisine Type";
          break;
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: 32,
          marginBottom: label === "Category Type" ? 24 : 16,
        }}>
        <div
          style={{
            width: 160,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
          <label className={pageStyles.valueText}>{tempValue}</label>
        </div>
      </div>
    );
  };

  const getDescriptionText = (label, text) => {
    let tempValue = text === null || text === undefined || text === "" ? "NA" : text;

    return (
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
        <div style={{ width: 160, display: "flex", alignItems: "center" }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 24,
            width: 540,
            maxLines: 4,
          }}>
          <p className={pageStyles.descriptionText}>{tempValue}</p>
        </div>
      </div>
    );
  };

  const getFeaturedPhoto = (label, url) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
        <div style={{ width: 160, display: "flex", alignItems: "center" }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 24,
            width: 320,
            height: url === "" || url === null ? 152 : 190,
            borderRadius: 3,
            backgroundColor: "#EEF1F7",
          }}>
          {url === null || url === "" ? (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}>
              No Image available
            </Typography>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img
                src={url}
                style={{
                  borderRadius: 3,
                  width: url !== "" && url !== null ? 320 : 32,
                  height: url !== "" && url !== null ? 208 : 32,
                }}
                onLoad={e => setFeaturedPhotoDimensions(`${e.target.naturalWidth}px x ${e.target.naturalHeight}px`)}
              />
              {featuredPhotoDimensions !== "" && <span>{featuredPhotoDimensions}</span>}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getIcon = (label, url) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 24 }}>
        <div style={{ width: 160, display: "flex", alignItems: "center" }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
          <img src={url} alt="NA" style={{ width: 32, height: 32 }} />
        </div>
      </div>
    );
  };

  const getToggle = (label, status, id) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 16,
          height: 32,
        }}>
        <div style={{ width: 160, display: "flex", alignItems: "center" }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
          <Toggle initialState={status} id={id} handler={handleToggle} />
        </div>
      </div>
    );
  };

  const getPageHeader = () => {
    return (
      <div
        style={{
          width: "100%",
          height: 56,
          display: "flex",
          alignItems: "center",
          paddingLeft: 24,
          backgroundColor: "#FFFFFF",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}>
          <label
            style={{
              paddingLeft: 16,
              width: 134,
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#3489EC",
              fontWeight: 400,
            }}>
            {"Deal Category"}
          </label>
          <div style={{ width: 24, height: 24, alignItems: "center" }}>
            <img src={RightArrow} alt="" style={{}} />
          </div>
          <label
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#2E384D",
              fontWeight: 400,
            }}>
            {"Deal Category Detail"}
          </label>
        </div>

        <div style={{ position: "absolute", right: 0, paddingRight: 24 }}>
          <Logout />
        </div>
      </div>
    );
  };

  const getLoader = label => {
    return (
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 16, height: 32 }}>
        <div style={{ width: 160, display: "flex", alignItems: "center" }}>
          <label className={pageStyles.categoryLabel}>{label}</label>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
          <p className={pageStyles.descriptionText}>
            <Placeholder.Graph style={{ width: 34, height: 16, borderRadius: 10 }} active />
          </p>
        </div>
      </div>
    );
  };

  //useEffect

  useEffect(() => {
    setUpdating(true);
    setTimeout(() => {
      setUpdating(false);
    }, 100);
  }, [categoryData]);
  useEffect(() => {}, [isUpdating]);
  useEffect(() => {}, [featuredPhotoDimensions]);

  return (
    <div>
      {/* {getPageHeader()} */}
      {/* <Header
        title={"Deal Category"}
        titleStyles={pageStyles.activeHeader}
        subtitle={["Deal Category Detail"]}
        canGoBack={true}
      /> */}
      <BreadcrumbsHeader
        options={[
          { order: 0, title: "Deal Categories", to: "/categories" },
          { order: 1, title: "Deal Category Detail" },
        ]}
      />

      <div className={detailScreenClasses.subHeader} style={{ justifyContent: "flex-end" }}>
        <Button onClick={editCategory} variant="outlined" startIcon={<EditIcon className={pageStyles.editIcon} />} className={globalClasses.buttonSecondary}>
          Edit
        </Button>
      </div>

      <div className={pageStyles.detailsHeaderContainer}>
        <div
          style={{
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            borderBottomColor: "#3489EC",
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 42,
          }}>
          <label className={pageStyles.activeHeaderLabel}>Details</label>
        </div>
      </div>

      <div className={pageStyles.detailsContainer}>
        {getLabelValueTextItem("Category ID", categoryData.category_id)}
        {getLabelValueTextItem("Display Order", categoryData.display_order)}
        {getLabelValueTextItem("Category Name", categoryData.category_name)}
        {getLabelValueTextItem("Display Name", categoryData.display_name)}
        {getLabelValueTextItem("Parent Category", categoryData.parent_category)}
        {getLabelValueTextItem("Category Type", categoryData.category_type)}
        {/* {getDescriptionText("Description", categoryData.description)} */}
        {getFeaturedPhoto("Featured Photo", categoryData.featured_photo)}
        {getIcon("Icon", categoryData.icon)}
        {isUpdating ? getLoader("Active") : getToggle("Active", categoryData.category_active, categoryData.category_id)}
      </div>

      <AddNewCategory show={showModal} onHide={onModalHide} isEdit={isEdit} editData={categoryData} handleOnSave={onSuccessfulEdit} source="details" />
    </div>
  );
};

export default CategoryDetails;
