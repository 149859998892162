import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BreadcrumbsHeader } from "../components/header";
import UtilityBar from "../components/utility_bar";
import { globalStyles } from "../config/theme";
import { DealsSearch } from "./modals/filter_deals";
import { Button } from "@material-ui/core";
import EndPoints from "../webservices/endpoints";
import { post } from "../webservices/apiservice";
import { TableLoader as Loader } from "../components/loader";
import { TableWithPagination } from "../components/table";
import ErrorPage from "../components/error_page";
import { PAGE_CONFIG } from "../config/page_config";
import DealModal from "./modals/new_edit_deal";
import { Content, Header, Notification } from "rsuite";
import { TriStateToggle } from "../components/toggle";

import AddIcon from "@material-ui/icons/Add";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ConfirmationBox from "../components/confirmation_box";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import BlockIcon from "@material-ui/icons/Block";
import GradeRoundedIcon from "@material-ui/icons/GradeRounded";

const FeaturedDeals = props => {
  //constants
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const getUrl = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.POST_GET;
  const featureGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FEATURE;
  const featureGroupedDealMany = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FEATURE_MANY;
  const activateGroupedDeal = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.ACTIVATE;
  const activateGroupedDealMany = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.ACTIVATE_MANY;

  //useStates
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [payloadFilter, setPayloadFilter] = useState({
    startRecIndex: 0,
    limitPerPage: 500,
    filters: [],
    search: "",
  });
  const [totalItems, setTotalItems] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentObjAfterLoad, setCurrentObjAfterLoad] = useState({
    page: 0,
    rowsPerPage: null,
  });
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });
  const [resetTristateToggle, setTristateToggleToDefault] = useState(false);

  //functions
  const fetchFeaturedDeals = async (payload = null) => {
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        let response = await post(getUrl, payload ? payload : payloadFilter);
        setData(response.items);
        setTotalItems(response.totalItems);
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/deals/featured",
      });
    }
  };

  const handleModalView = state => {
    typeof state === "boolean" && setModal(state);
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    setEditData(row);
    handleModalView(true);
  };

  const getResultOnParams = (payload = null) => {
    payload !== null && setPayloadFilter(payload);
    fetchFeaturedDeals(payload);
    setCurrentObjAfterLoad(prev => ({ ...prev, page: 0 }));
  };

  const handleOnSuccessfulCreate = state => {
    if (typeof state === "boolean" && state === true) {
      handleReload();
    }
  };

  const handleFeatureToggle = async (id, state) => {
    let payload = {
      offer: id,
      is_featured: state,
    };
    try {
      await post(featureGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Featured Deal - ${id} successfully` : `Defeatured Deal - ${id} successfully`,
      });
      handleReload();
    } catch (error) {
      console.log(error);
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Feature Deal - ${id}` : `Couldn't Defeature Deal - ${id}`,
      });
      handleReload();
    }
  };

  const handleActivateToggle = async (id, state) => {
    let payload = {
      offer: id,
      deal_active: state,
    };
    try {
      await post(activateGroupedDeal, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Deal - ${id} successfully` : `Deactivated Deal - ${id} successfully`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Deal - ${id}` : `Couldn't Deactivate Deal - ${id}`,
      });
      handleReload();
    }
  };

  const handleReload = async () => {
    try {
      await fetchFeaturedDeals();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLoadMore = async currentObj => {
    setIndex(index + 1);
    setLoading(true);
    // currentObj.page -= 1;
    try {
      let response = await post(getUrl, { ...payloadFilter, startRecIndex: payloadFilter.startRecIndex + 1 });
      setData([...data, ...response.items]);
      setCurrentObjAfterLoad(currentObj);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const onHideConfirmationBox = () => {
    setTristateToggleToDefault(true);
    setTimeout(() => {
      setTristateToggleToDefault(false);
    }, 100);
    setConfirmationBoxModal(false);
  };

  const onBatchActivate = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Activate` : `Deactivate`,
      description: [`Do you want to ${state === true ? `Activate` : `Deactivate`} ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}? `],
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          deal_active: state,
          offerlist: [],
        };
        selectedItems.forEach(el => {
          payload.offerlist.push({ offer: el });
        });
        try {
          await post(activateGroupedDealMany, payload);
          Notification["success"]({
            title: "Success",
            description: state
              ? `Activated ${selectedItems.length} Deal${selectedItems.length > 1 ? `s` : ""} successfully`
              : `Deactivated ${selectedItems.length} Deal${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state
              ? `Couldn't Activate ${selectedItems.length} Deal${selectedItems.length > 1 ? `s` : ""}`
              : `Couldn't Deactivate ${selectedItems.length} Deal${selectedItems.length > 1 ? `s` : ""}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchFeature = async selectedItems => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: `Defeature`,
      description: [`Do you want to Defeature ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}? `],
      helperText: "Note: Once defeatured, the deal will not be available in this table.",
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          is_featured: false,
          offerlist: [],
        };
        selectedItems.forEach(el => {
          payload.offerlist.push({ offer: el });
        });
        try {
          await post(featureGroupedDealMany, payload);
          Notification["success"]({
            title: "Success",
            description: `Defeatured ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: `Couldn't Defeature ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchExport = selectedItems => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      type: "export",
      data: data,
      selected: selectedItems,
      pageConfig: PAGE_CONFIG.featured_deals,
      onCancel: onHideConfirmationBox,
    });
  };

  const onBatchDelete = selectedItems => {
    console.log("Batch delete clicked", selectedItems);
  };

  const onBatchAssign = selectedItems => {
    console.log("Batch assign clicked", selectedItems);
  };

  const checkboxOptions = [
    {
      key: "Toggle right to Activate and left to Deactivate",
      icon: TriStateToggle,
      handler: onBatchActivate,
      isHidden: false,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },
    {
      key: "Defeature",
      icons: {
        disabled: GradeOutlinedIcon,
        active: GradeRoundedIcon,
      },
      handler: onBatchFeature,
      isHidden: false,
    },
    {
      key: "Delete",
      icon: DeleteIcon,
      handler: onBatchDelete,
      isHidden: true,
    },
    {
      key: "Export",
      icon: VerticalAlignBottomIcon,
      handler: onBatchExport,
      isHidden: false,
    },
    {
      key: "Assign",
      icon: LocationOnIcon,
      handler: onBatchAssign,
      isHidden: true,
    },
  ];

  //useEffects
  useEffect(() => {
    fetchFeaturedDeals();
  }, []);
  useEffect(async () => {
    if (location.pathname === "/deals/featured" && sessionStorage.getItem("refreshFeaturedDeals") !== null) {
      if (JSON.parse(sessionStorage.getItem("refreshFeaturedDeals")) === true) {
        handleReload();
        sessionStorage.setItem("refreshFeaturedDeals", false);
      }
    }
  }, [location]);
  useEffect(() => {}, [data]);
  useEffect(() => {}, [resetTristateToggle]);
  useEffect(() => {}, [confirmationDialog]);
  useEffect(() => {}, [confirmationBoxModal]);

  //render
  return (
    <div className={classes.mainContainer}>
      <Header className={classes.headerContainer}>
        <BreadcrumbsHeader
          options={[
            { order: 0, title: "Deals", to: "/deals" },
            { order: 1, title: "Featured Deals" },
          ]}
        />
      </Header>
      <Content className={classes.contentContainer}>
        <UtilityBar
          searchBar={<DealsSearch payloadFilter={payloadFilter} onSearch={getResultOnParams} parentName={"Featured Deals"} />}
          button={
            <Button
              className={classes.buttonPrimary}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditData({});
                handleModalView(true);
              }}>
              New Deal
            </Button>
          }
        />
        {isLoading ? (
          <Loader header={PAGE_CONFIG.featured_deals.header_mapper} checkbox />
        ) : error ? (
          <ErrorPage data={"no-vpn"} title="Offer" />
        ) : data.length < 1 ? (
          <ErrorPage data={"no-data"} title="Offer" />
        ) : (
          <>
            <TableWithPagination
              data={data}
              pageConfig={PAGE_CONFIG.featured_deals}
              totalItems={totalItems}
              checkbox={checkboxOptions}
              disableRowOptions
              handleToggle={{ is_featured: handleFeatureToggle, deal_active: handleActivateToggle }}
              handleReload={handleReload}
              handleLoadMore={handleLoadMore}
              currentObjAfterLoad={currentObjAfterLoad}
            />
          </>
        )}
      </Content>
      <DealModal dealData={editData} show={modal} onCreate={handleOnSuccessfulCreate} onHide={() => handleModalView(false)} />
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </div>
  );
};

export default FeaturedDeals;
