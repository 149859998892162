import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { BreadcrumbsHeader } from "../components/header";
import { TableLoader as Loader } from "../components/loader";
import { TableWithPagination } from "../components/table";
import UtilityBar from "../components/utility_bar";
import { PAGE_CONFIG } from "../config/page_config";
import AddIcon from "@material-ui/icons/Add";
import { deleteRecord, post, put } from "../webservices/apiservice";
import EndPoints from "../webservices/endpoints";
import ErrorPage from "../components/error_page";
import { Content, Header, Notification } from "rsuite";
import DealModal from "./modals/new_edit_deal";
import { DealsSearch } from "./modals/filter_deals";
import { globalStyles } from "../config/theme";
import { TriStateToggle } from "../components/toggle";
import GradeIcon from "@material-ui/icons/Grade";
import DeleteIcon from "@material-ui/icons/Delete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import ConfirmationBox from "../components/confirmation_box";

const getUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.POST_GET;
const deleteUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.DELETE;
const activateUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.ACTIVATE;
const activateManyUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.ACTIVATE_MANY;

const Deals = props => {
  //Constants
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();

  //useStates
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [payloadFilter, setPayloadFilter] = useState({
    startRecIndex: 0,
    limitPerPage: 500,
    filters: [],
    search: "",
  });
  const [totalItems, setTotalItems] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentObjAfterLoad, setCurrentObjAfterLoad] = useState({
    page: 0,
    rowsPerPage: null,
  });
  const [resetTristateToggle, setTristateToggleToDefault] = useState(false);
  const [confirmationBoxModal, setConfirmationBoxModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ title: null, desc: null, helperText: null, onCancel: null, onConfirm: null });

  //functions
  const handleModalView = state => {
    typeof state === "boolean" && setModal(state);
  };

  const onHideConfirmationBox = () => {
    setTristateToggleToDefault(true);
    setTimeout(() => {
      setTristateToggleToDefault(false);
    }, 100);
    setConfirmationBoxModal(false);
  };

  const handleRowClick = (id, secondId = null) => {
    history.push({
      pathname: `/deals/detail:?deal_detail_id=${id}`,
      state: { offer_detail_id: id },
    });
  };

  const handleToggle = async (id, state) => {
    let payload = {
      offer_detail_id: id,
      mapping_active: state,
    };
    try {
      await post(activateUrl, payload);
      Notification["success"]({
        title: "Success",
        description: state ? `Activated Deal - ${id} successfully` : `Deactivated Deal - ${id} successfully`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: state ? `Couldn't Activate Deal - ${id}. Reloading again.` : `Couldn't Deactivate Deal - ${id}. Reloading again.`,
      });
      handleReload();
    }
  };

  const handleEdit = (event, row) => {
    event.stopPropagation();
    setEditData(row);
    handleModalView(true);
  };

  const handleDelete = async (id, row, data, handler) => {
    try {
      await deleteRecord(deleteUrl, row.offer_detail_id);
      data.splice(id, 1);
      setData(data.filter(rec => rec.offer_detail_id !== row.offer_detail_id));
      handler(data);
      Notification["success"]({
        title: "Success",
        description: `Deleted Deal - ${row.offertextshort}`,
      });
    } catch (error) {
      Notification["error"]({
        title: "Error",
        description: `Unable to delete Deal - ${row.offertextshort}`,
      });
    }
  };

  const handleOnSuccessfulCreate = state => {
    if (typeof state === "boolean" && state === true) {
      handleReload();
    }
  };

  const fetchData = async (payload = null) => {
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        let response = await post(getUrl, payload ? payload : payloadFilter);
        setData(response.items);
        setTotalItems(response.totalItems);
        setError(false);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    }
  };

  const handleReload = async () => {
    try {
      await fetchData();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLoadMore = async currentObj => {
    setIndex(index + 1);
    setLoading(true);
    // currentObj.page -= 1;
    try {
      let response = await post(getUrl, { ...payloadFilter, startRecIndex: payloadFilter.startRecIndex + 1 });
      setData([...data, ...response.items]);
      setCurrentObjAfterLoad(currentObj);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const getResultOnParams = (payload = null) => {
    payload !== null && setPayloadFilter(payload);
    fetchData(payload);
    setCurrentObjAfterLoad(prev => ({ ...prev, page: 0 }));
  };

  const onBatchActivate = async (selectedItems, state) => {
    setConfirmationBoxModal(true);
    setConfirmationDialog({
      title: state === true ? `Activate` : `Deactivate`,
      description: [`Do you want to ${state === true ? `Activate` : `Deactivate`} ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}? `],
      onCancel: onHideConfirmationBox,
      onConfirm: async () => {
        let payload = {
          mapping_active: state,
          offerdetaillist: [],
        };
        selectedItems.forEach(el => {
          payload.offerdetaillist.push({ offer_detail_id: el });
        });
        try {
          await post(activateManyUrl, payload);
          Notification["success"]({
            title: "Success",
            description: state
              ? `Activated ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`
              : `Deactivated ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""} successfully`,
          });
          handleReload();
        } catch (error) {
          Notification["error"]({
            title: "Error",
            description: state
              ? `Couldn't Activate ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}`
              : `Couldn't Deactivate ${selectedItems.length} deal${selectedItems.length > 1 ? `s` : ""}`,
          });
          handleReload();
        }
        onHideConfirmationBox();
      },
    });
  };

  const onBatchFeature = selectedItems => {
    console.log("Batchfeature clicked", selectedItems);
  };

  const onBatchExport = selectedItems => {
    console.log("export btn clicked");
  };

  const onBatchDelete = selectedItems => {
    console.log("Batch delete clicked", selectedItems);
  };

  const onBatchAssign = selectedItems => {
    console.log("Batch assign clicked", selectedItems);
  };

  const checkboxOptions = [
    {
      key: "Toggle right to Activate and left to Deactivate",
      icon: TriStateToggle,
      handler: onBatchActivate,
      isHidden: false,
      isIconAComponent: true,
      isReset: resetTristateToggle,
    },
    {
      key: "Feature",
      icon: GradeIcon,
      handler: onBatchFeature,
      isHidden: true,
    },
    {
      key: "Delete",
      icon: DeleteIcon,
      handler: onBatchDelete,
      isHidden: true,
    },
    {
      key: "Export",
      icon: VerticalAlignBottomIcon,
      handler: onBatchExport,
      isHidden: true,
    },
    {
      key: "Assign",
      icon: LocationOnIcon,
      handler: onBatchAssign,
      isHidden: true,
    },
  ];

  //useEffects
  useEffect(async () => {
    if (EndPoints.hasLoggedIn()) {
      try {
        let response = await post(getUrl, payloadFilter);
        setData(response.items);
        setTotalItems(response.totalItems);
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      history.push({
        pathname: "/",
        state: "/deals",
      });
    }
  }, []);

  useEffect(() => {}, [data]);
  useEffect(() => {}, [totalItems]);
  useEffect(() => {}, [resetTristateToggle]);
  useEffect(() => {}, [editData]);
  useEffect(() => {
    if (location.pathname === "/deals") {
      if (sessionStorage.getItem("updated_deal") !== null) {
        let update = JSON.parse(sessionStorage.getItem("updated_deal"));
        setData(prev => prev.map(item => (item.offer_detail_id === update.offer_detail_id ? { ...item, ...update } : item)));
        sessionStorage.removeItem("updated_deal");
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
      if (sessionStorage.getItem("refreshDeals") !== null) {
        if (JSON.parse(sessionStorage.getItem("refreshDeals")) === true) {
          handleReload();
          sessionStorage.setItem("refreshDeals", false);
        }
      }
    }
  }, [location]);

  //Render
  return (
    <div className={classes.mainContainer}>
      <Header className={classes.headerContainer}>
        <BreadcrumbsHeader options={[{ order: 0, title: "Deals" }]} />
      </Header>
      <Content className={classes.contentContainer}>
        <UtilityBar
          searchBar={<DealsSearch payloadFilter={payloadFilter} onSearch={getResultOnParams} parentName={"Deals"} />}
          button={
            <Button
              className={classes.buttonPrimary}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setEditData({});
                handleModalView(true);
              }}>
              New Deal
            </Button>
          }
        />
        {isLoading ? (
          <Loader header={PAGE_CONFIG.deals.header_mapper} hasEditOption checkbox />
        ) : error ? (
          <ErrorPage data={"no-vpn"} title="Offer" />
        ) : data.length < 1 ? (
          <ErrorPage data={"no-data"} title="Offer" />
        ) : (
          <>
            <TableWithPagination
              data={data}
              totalItems={totalItems}
              checkbox={checkboxOptions}
              pageConfig={PAGE_CONFIG.deals}
              handleToggle={handleToggle}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleRowClick={handleRowClick}
              handleReload={handleReload}
              handleLoadMore={handleLoadMore}
              currentObjAfterLoad={currentObjAfterLoad}
            />
          </>
        )}
      </Content>
      <DealModal dealData={editData} show={modal} onCreate={handleOnSuccessfulCreate} onHide={() => handleModalView(false)} />
      <ConfirmationBox open={confirmationBoxModal} dialog={confirmationDialog} />
    </div>
  );
};

export default Deals;
