import React, { useState, useEffect } from "react";
import { filterModalStyles, searchFilterContainerStyles } from "../../config/theme";
import { InputGroup, Icon, Input, FlexboxGrid, Modal, Button, DateRangePicker, AutoComplete, Placeholder } from "rsuite";
import { Button as MaterialButton, Typography } from "@material-ui/core";
import { parseDate, parseDateRange } from "../../components/table";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import { post, get } from "../../webservices/apiservice";
import EndPoints from "../../webservices/endpoints";

export const DealsSearch = props => {
  const classes = searchFilterContainerStyles();
  const [modal, setModal] = useState(false);
  const [searchVal, setVal] = useState("");
  const [filterObject, setFilterObject] = useState(props.payloadFilter);
  const [filtersToView, setFiltersToView] = useState([]);
  const [isSearchApplied, setSearchApplied] = useState(false);

  //Handlers
  const handleSetSearchInput = event => {
    setVal(event);
    setFilterObject(prev => ({ ...prev, search: event }));
    setSearchApplied(false);
  };

  const handleClearSearch = event => {
    if (event.target.accessKey === "clear") {
      setVal("");
      setFilterObject(prev => ({ ...prev, search: "" }));
      isSearchApplied && props.onSearch({ ...filterObject, search: "" });
    }
  };

  const handleSearch = () => {
    setFilterObject(prev => ({ ...prev, search: searchVal }));
    if (searchVal !== "") {
      props.onSearch({ ...filterObject, search: searchVal });
      setSearchApplied(true);
    }
  };

  const handleModalView = status => {
    typeof status === "boolean" && setModal(status);
  };

  const getFiltersFromModal = (filtersToAppend, filtersToView) => {
    setFiltersToView(filtersToView);
    if (isSearchApplied) {
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend }));
      props.onSearch({ ...filterObject, filters: filtersToAppend });
    } else {
      setVal("");
      setFilterObject(prev => ({ ...prev, ["filters"]: filtersToAppend, search: "" }));
      props.onSearch({ ...filterObject, filters: filtersToAppend, search: "" });
    }
  };

  const handleRemoveOneFilter = (id, value) => {
    setFiltersToView(filtersToView.filter(filter => filter.id !== id));
    setFilterObject(prev => ({ ...prev, ["filters"]: prev.filters.filter(fil => fil.field !== id) }));
    props.onSearch({ ...filterObject, filters: filterObject.filters.filter(fil => fil.field !== id) });
  };

  const handleRemoveAllFilters = () => {
    setFiltersToView([]);
    setFilterObject(prev => ({ ...prev, ["filters"]: [] }));
    props.onSearch({
      ...filterObject,
      filters: [],
    });
  };

  useEffect(() => {}, [filterObject]);

  useEffect(() => {}, [filtersToView]);

  useEffect(() => {}, [isSearchApplied]);

  return (
    <>
      <div>
        <div className={classes.container}>
          <InputGroup inside>
            <Input
              value={searchVal}
              onChange={handleSetSearchInput}
              className={classes.search}
              placeholder={`Search in ${props.parentName}`}
              id="deals-search-input"
              onPressEnter={handleSearch}
            />
            <InputGroup.Addon>
              <Icon
                accessKey={searchVal.length > 0 ? "clear" : undefined}
                icon={searchVal.length === 0 ? "search" : "close"}
                onClick={handleClearSearch}
                className={classes.searchIcon}
              />
            </InputGroup.Addon>
          </InputGroup>
          <MaterialButton onClick={handleSearch} className={classes.filterButton}>
            Search
          </MaterialButton>
          <MaterialButton
            startIcon={<FilterListIcon className={classes.blueIcon} />}
            endIcon={<ExpandMoreIcon className={classes.blueIcon} />}
            onClick={() => handleModalView(true)}
            className={classes.filterButton}>
            Filter
          </MaterialButton>
        </div>
        <div className={classes.filterContainer}>
          {filtersToView.length > 0 &&
            filtersToView.map((filter, index) => {
              return (
                <>
                  {typeof filter.value === "string" && filter.value !== "" ? (
                    <MaterialButton
                      key={`filter-button-${index}`}
                      className={classes.filterListButton}
                      endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                      {`${filter.key} - ${filter.value}`}
                    </MaterialButton>
                  ) : Array.isArray(filter.value) && filter.value.length > 0 ? (
                    <MaterialButton
                      key={`filter-button-${index}`}
                      className={classes.filterListButton}
                      endIcon={<ClearIcon className={classes.clearBtn} onClick={() => handleRemoveOneFilter(filter.id, filter.value)} />}>
                      {`${filter.key} : ${filter.value.map(el => parseDate(el, "short")).join(" - ")}`}
                    </MaterialButton>
                  ) : null}
                </>
              );
            })}
          {filtersToView.length > 0 && (
            <span className={classes.clearFilters} onClick={handleRemoveAllFilters}>
              Clear Filters
            </span>
          )}
        </div>
      </div>
      <DealsFilterModal
        show={modal}
        onHide={() => handleModalView(false)}
        parentName={props.parentName}
        getFilter={getFiltersFromModal}
        modalUpdateOnFilterChange={filtersToView.map(filter => filter.id)}
      />
    </>
  );
};

export const DealsFilterModal = props => {
  //Constants and States
  const getDealsFiltersUrl = EndPoints.BOOST_DOMAIN + EndPoints.DEALS.FILTER;
  const getFeaturedDealsFiltersUrl = EndPoints.BOOST_DOMAIN + EndPoints.FEATURED_DEALS.FILTER;
  const classes = filterModalStyles();
  const [showModal, setShowModal] = useState(props.show);
  const [filters, setFilters] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //Handlers
  const onHide = () => {
    setShowModal(false);
    props.onHide();
  };

  const onCancel = () => {
    onHide();
  };

  const setISODate = (inputDate, isEnd = false) => {
    let date = new Date(inputDate);
    let yr = date.getFullYear();
    let mn = date.getMonth() + 1;
    mn = mn >= 10 ? mn : "0" + mn;
    let dt = date.getDate();
    dt = dt >= 10 ? dt : "0" + dt;
    let time = isEnd ? "23:59:59.000Z" : "00:00:00.000Z";
    return `${yr}-${mn}-${dt}T${time}`;
  };

  const onApply = () => {
    let filtersToAppend = [];
    let filtersToView = [];
    filters.map(filter => {
      let objectToAppend = {};
      let objectToView = {};
      if (filter.type === "date-range") {
        if (Array.isArray(filter.active) && filter.active.length > 0) {
          objectToAppend["field"] = filter.key;
          objectToAppend["start"] = setISODate(filter.active[0]);
          objectToAppend["end"] = setISODate(filter.active[1], true);
          objectToAppend["condition"] = "between";
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      } else if (filter.type === "autocomplete") {
        if (filter.active !== "" && filter.active !== null) {
          objectToAppend["field"] = filter.key;
          objectToAppend["value"] = filter.active;
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      } else if (filter.type === "button") {
        if (filter.active !== "All") {
          objectToAppend["field"] = filter.key;
          if (filter.key === "deal_active") {
            if (filter.active === "Active") objectToAppend["value"] = true;
            else if (filter.active === "Inactive") objectToAppend["value"] = false;
          } else {
            objectToAppend["value"] = filter.active;
          }
          objectToView["id"] = filter.key;
          objectToView["key"] = filter.header;
          objectToView["value"] = filter.active;
          filtersToAppend.push(objectToAppend);
          filtersToView.push(objectToView);
        }
      }
    });
    props.getFilter(filtersToAppend, filtersToView);
    onHide();
  };

  const handleSelectFilter = (key, value) => {
    setFilters(filters.map(filter => (filter.key === key ? { ...filter, active: value } : filter)));
  };

  const getUniqueUpperCase = elementsArray => {
    let result = [];
    elementsArray !== undefined &&
      elementsArray.forEach(el => {
        result.push(el.toLocaleUpperCase());
      });
    return [...new Set(result)];
  };

  const modifyFilter = recievedFilter => {
    if (!recievedFilter || !Array.isArray(recievedFilter) || recievedFilter.length < 1) return [];
    return recievedFilter.map(filter => ({
      ...filter,
      active: filter.type === 'autocomplete' ? '' : (filter.options && filter.options[0]) ?? [],
      key: filter.key === 'createddate' ? 'start_date' : filter.key,
      options: filter.key === 'city' ? getUniqueUpperCase(JSON.parse(sessionStorage.getItem('cityStates')) ?? []) : filter.options,
    }));
  };

  const fetchCityStatesCached = async () => {
    if (sessionStorage.getItem('cityStates') === null) {
      try {
        const url = EndPoints.BOOST_DOMAIN + EndPoints.GET_CITY_STATES;
        const response = await get(url);
        const cities = response.map(item => item.city);
        sessionStorage.setItem('cityStates', JSON.stringify(cities));
      } catch (error) {
        console.log(error);
        }
        }
  };

  const fetchFilters = async () => {
    let url = props.parentName === "Deals" ? getDealsFiltersUrl : getFeaturedDealsFiltersUrl;
    if (EndPoints.hasLoggedIn()) {
      try {
        setLoading(true);
        await fetchCityStatesCached();
        let response = await post(url, {
          extraItems: [
            { header: 'Location', key: 'city', type: 'autocomplete' },
            {
              header: 'Offer Added',
              key: 'offer_added_date',
              type: 'date-range',
            },
          ],
        });
        setFilters(modifyFilter(response));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  //useEffects

  useEffect(() => {
    if (props.show !== showModal) {
      setShowModal(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {}, [filters]);

  useEffect(() => {
    if (props.modalUpdateOnFilterChange.length > 0) {
      setFilters(
        filters !== undefined &&
          filters.map(filter =>
            filter.type === "date-range"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: [] }
              : filter.type === "autocomplete"
              ? props.modalUpdateOnFilterChange.includes(filter.key)
                ? filter
                : { ...filter, active: "" }
              : props.modalUpdateOnFilterChange.includes(filter.key)
              ? filter
              : { ...filter, active: "All" }
          )
      );
    } else {
      setFilters(
        filters !== undefined &&
          filters.map(filter =>
            filter.type === "date-range" ? { ...filter, active: [] } : filter.type === "autocomplete" ? { ...filter, active: "" } : { ...filter, active: "All" }
          )
      );
    }
  }, [props.modalUpdateOnFilterChange]);

  //Render

  return (
    <div>
      <Modal show={showModal} onHide={() => onHide()} size="md" style={{ minHeight: "60vh" }}>
        <Modal.Header>
          <Modal.Title className={classes.title}>{`Filter ${props.parentName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modal} style={{ flexWrap: "nowrap", flexDirection: "column" }}>
          {isLoading ? (
            <div>
              {[...Array(4)].map((item, index) => {
                return (
                  <div key={`filter-text-placeholder-div-${index}`} style={{ marginTop: 15, marginBottom: 10 }}>
                    <Placeholder.Graph key={`filter-text-placeholder-${index}`} width={70} height={20} active className={classes.placeholderText} />
                    <FlexboxGrid>
                      {[...Array(6)].map((item, inIndex) => {
                        return (
                          <Placeholder.Graph
                            key={`filter-button-placeholder-${inIndex}`}
                            active
                            width={100}
                            height={35}
                            className={classes.placeholderButton}
                          />
                        );
                      })}
                    </FlexboxGrid>
                  </div>
                );
              })}
            </div>
          ) : (
            filters !== undefined &&
            filters.length > 0 &&
            filters.map((filter, index) => {
              return (
                <div className={classes.container} key={`filter-container-${index}`}>
                  <div>
                    <span className={classes.subtitle}>{filter.header}</span>
                    <FlexboxGrid>
                      {filter.type === "button" ? (
                        filter.options.map((option, i) => {
                          return (
                            <Button
                              className={`${classes.optionButton} ${option === filter.active && classes.active}`}
                              active={option === filter.active ? true : false}
                              value={option}
                              onClick={() => handleSelectFilter(filter.key, option)}
                              key={`buttons-${i}`}>
                              {option}
                            </Button>
                          );
                        })
                      ) : filter.type === "date-range" ? (
                        <DateRangePicker
                          value={filter.active}
                          onChange={e => handleSelectFilter(filter.key, e)}
                          cleanable={true}
                          placeholder={`${filter.header} in between`}
                          ranges={[]}
                          placement="rightEnd"
                          key={`date-range-${index}`}
                          style={{
                            fontFamily: 'Roboto',
                            width: '50%',
                            margin: '10px 0px 10px 0px',
                            '&:hover': { borderColor: 'none' },
                          }}
                        />
                      ) : filter.type === 'autocomplete' ? (
                        <InputGroup
                          inside
                          style={{
                            width: '50%',
                            margin: '10px 0px 10px 0px',
                            '&:hover': { borderColor: 'none' },
                          }}>
                          <InputGroup.Button>
                            <Icon
                              icon="search"
                              style={{
                                color: '#3c8dde',
                                '&:hover': { cursor: 'none' },
                          }}
                        />
                          </InputGroup.Button>
                          <AutoComplete
                            value={filter.active}
                            data={filter.options}
                            onChange={e => handleSelectFilter(filter.key, e.toLocaleUpperCase())}
                            menuClassName={classes.menuClass}
                            placeHolder={'Search in city'}
                          />
                        </InputGroup>
                      ) : null}
                    </FlexboxGrid>
                  </div>
                </div>
              );
            })
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <FlexboxGrid justify="end">
              <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
              <Placeholder.Graph active width={100} height={35} className={classes.placeholderButton} />
            </FlexboxGrid>
          ) : (
            <FlexboxGrid justify="end">
              <Button className={classes.cancelBtn} onClick={onCancel}>
                Cancel
              </Button>
              <Button className={classes.saveBtn} onClick={onApply}>
                Apply
              </Button>
            </FlexboxGrid>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
